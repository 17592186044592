<template>
  <div v-if="user && ifIsInArray(user.roles, 'ROLE_ADMIN')" id="employees">
    <HeaderTitle title="Empleados" />
    <div class="d-flex flex-row justify-content-start">
      <img src="@/assets/icons/search-blue.svg" width="30" alt="">
      <div class="title-text ml-4">
        Undanitas
      </div>
    </div>
    <el-row class="mt-2">
      <el-card class="card-search card-abs w-100 wback" body-style="padding:0">

        <div class="search-form">
          <div class="row">
            <div class="col-4">
              <div class="form-item">
                <label>Nombre</label>
                <el-input v-model="employeeName" placeholder="Buscar por nombre" clearable @change="search()">
                </el-input>
              </div>
            </div>
            <div class="col-4">
              <div class="form-item">
                <label class="w-100">Departamento</label>
                <el-select v-model="department" placeholder="Todos" filterable clearable @change="search()">
                  <el-option v-for="item in departments" :key="item.id" :label="item.name" :value="item.name">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="col-4">
              <div class="form-item">
                <label class="w-100">Teletrabajo</label>
                <el-select v-model="remote" placeholder="Todos" filterable clearable @change="search()">
                  <el-option v-for="item in remoteStatus" :key="item.id" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 20px">
            <div class="col-4">
              <div class="form-item">
                <label class="w-100">Puesto de trabajo</label>
                <el-select v-model="job" placeholder="Todos" filterable clearable @change="search()">
                  <el-option v-for="item in jobs" :key="item.id" :label="item.name" :value="item.name">
                  </el-option>
                </el-select>
              </div>
            </div>

            <div class="col-2">
              <div class="form-item">
                <label class="label-employees w-100">Ver empleados</label>
                <el-select v-model="active" placeholder="Todos" filterable clearable @change="search()">
                  <el-option v-for="item in actives" :key="item.id" :label="item.name" :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </div>

            <div class="col-2">
              <div class="form-item">
                <label class="label-employees w-100">Tipo empleado</label>
                <el-select v-model="companyIntern" placeholder="Todos" filterable clearable @change="search()">
                  <el-option v-for="item in companyInterns" :key="item.id" :label="item.name" :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="col-4">
              <div class="d-flex justify-content-center">
                <div class="col-md-6 align-self-center ">
                  <button type="submit" class="btn btn_red btn__new" @click="openEmployee({})">
                    Nuevo
                  </button>
                </div>
                <div class="col-md-6 align-self-center ">
                  <button type="submit" @click="search()" class="btn btn_yellow btn__search">
                    BUSCAR
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </el-card>
    </el-row>
    <div class="table table-users">
      <div v-if="employees && employees.length > 0">
        <table>
          <thead>
            <tr class="justify-content-start gap-2">
              <th>
                <img src="@/assets/icons/user.svg" width="20" height="20" alt="name-icon" />
                NOMBRE
              </th>
              <th>
                <img src="@/assets/icons/email-icon.svg" width="20" height="20" alt="email-icon" />
                EMAIL
              </th>
              <th>
                <img src="@/assets/icons/mouse-icon.svg" width="20" height="20" alt="department-icon" />
                DEPARTAMENTO
              </th>
              <th>
                <img src="@/assets/icons/medal-icon.svg" width="20" height="20" alt="position-icon" />
                PUESTO
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="employee in filteredEmployees" :key="employee.id">
              <td>
                <div class="row-table-image-name-link">
                  <EmployeeAvatar :employee="employee" />

                  <div class="link-name col-7">
                    <router-link :to="{ name: 'employee', params: { id: employee.id } }" style="text-decoration: none">
                      {{ employee.name }} {{ employee.lastName }}
                    </router-link>
                  </div>
                </div>
              </td>
              <td>
                {{ employee.email }}
              </td>
              <td>
                {{
                  (employee.job && employee.job.department && employee.job.department.name) ?
                    employee.job.department.name : ''
                }}
              </td>
              <td>
                {{ (employee.job && employee.job.name) ? employee.job.name : '' }}
              </td>
              <td>
                <!-- <div class="buttons d-inline-flex" v-if="user && ifIsInArray('ROLE_SUPER_ADMIN', user.rol)">-->
                <div class="buttons d-inline-flex">
                  <div class="pointer">
                    <font-awesome-layers class="fa-2x" style="margin-left: 5px" title="Editar"
                      @click="openEmployee(employee)">
                      <font-awesome-icon icon="circle" style="color: #f7d20e" />
                      <font-awesome-icon icon="plus" class="fa-inverse" transform="shrink-6" />
                    </font-awesome-layers>
                  </div>
                  <!--                                <div class="pointer">-->
                  <!--                                    <font-awesome-icon-->
                  <!--                                            class="btn_icon delete_btn"-->
                  <!--                                            icon="times"-->
                  <!--                                            title="Eliminar"-->
                  <!--                                            style="margin-left: 5px"-->
                  <!--                                            @click="deleteDialogShown = true; selectedEmployee = employee;"/>-->
                  <!--                                </div>-->
                  <div class="pointer">
                    <!--                    <font-awesome-icon-->
                    <!--                        class="btn_icon delete_btn"-->
                    <!--                        icon="times"-->
                    <!--                        mask="circle"-->
                    <!--                        size="2x"-->
                    <!--                        title="Dar de baja"-->
                    <!--                        style="margin-left: 5px; color: red"-->
                    <!--                        @click="showModalUnsubscribe = true; selectedEmployee = employee;"/>-->
                    <font-awesome-layers class="fa-2x" style="margin-left: 5px" title="Dar de baja"
                      @click="showModalUnsubscribe = true; selectedEmployee = employee;">
                      <font-awesome-icon icon="circle" style="color:red" />
                      <font-awesome-icon icon="times" class="fa-inverse" transform="shrink-6" />
                    </font-awesome-layers>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <h5>No se han encontrado empleados según los filtros especificados...</h5>
      </div>
      <SharedPagination v-if="employees && totalResults > itemsPerPage" class="pagination" :page="page"
        :total-results="totalResults" :page-size="itemsPerPage" style="padding: 0px 0px 20px 0px;"
        @change="page = $event; getEmployees()" />
    </div>


    <el-dialog v-model="deleteDialogShown" :title="'¿Desea eliminar/dar de baja permanentemente este empleado?'"
    :show-close="false" @confirm="deleteEmployee(selectedEmployee)"
      @close="selectedEmployee = null; deleteDialogShown = false;"
      @decline="selectedEmployee = null; deleteDialogShown = false;" />

    <el-dialog style="height: 400px;" v-model="showModalUnsubscribe" :title="'Dar de Baja'" :theme="'secondary'"
      size="small" width="20%" @close="closeModalUnsubscribe()">
      <div>
        <p class="text-center">
          Seleccione la fecha de baja en la empresa para el empleado:
        </p>
        <p class="text-center">
          {{ selectedEmployee.name }} {{ selectedEmployee.lastName }}
        </p>
      </div>
      <div class="row item">
        <div class="form-item date-picker">
          <label for="dateLeave" class="w-100">Fecha de baja</label>
          <el-date-picker v-model="dateLeave" type="date" placeholder="Seleccione la fecha de baja" style="width: 100%"
            :clearable="true" :picker-options="{ firstDayOfWeek: 1 }" />
        </div>
      </div>
      <hr>
      <div class="row item">
        <div class="col-sm-6">
          <button class="btn btn_red" @click="closeModalUnsubscribe()">
            Cancelar
          </button>
        </div>
        <div class="col-sm-6">
          <button class="btn btn_yellow" :disabled="!dateLeave"
            @click="unsubscribeEmployee(selectedEmployee); closeModalUnsubscribe()">
            Aceptar
          </button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script setup>

import SharedPagination from "@/components/shared/SharedPagination.vue";
import HeaderTitle from "@/components/layout/HeaderTitle";


// Services
import EmployeeService from '@/api/services/employee.service';
import ResourceService from '@/api/services/resource.service';
import Notification from '@/utils/plugins/NotificationService';
import Common from '@/api/services/common.service';

import { useStore } from 'vuex';
import moment from "moment";
import EmployeeAvatar from "@/components/shared/EmployeeAvatar.vue";
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";

const store = useStore();
const router = useRouter();

const employees = ref([]);
const filteredEmployees = ref([]);
const jobs = ref([]);
const remote = ref(null);
const departments = ref([]);
const department = ref(null);
const job = ref(null);
const employeeName = ref('');
const imageEmployee = ref(null);
const params = ref({});
const showModalUnsubscribe = ref(false);
const deleteDialogShown = ref(false);
const showModalEmployeeImage = ref(false);
const selectedEmployee = ref(null);
const dateLeave = ref(null);
const page = ref(1);
const itemsPerPage = ref(10);
const totalResults = ref(0);
const nameEmployee = ref('');
const baseUrl = ref(process.env.VUE_APP_BASE_URI);
const active = ref(1);
const actives = ref([
  { id: 1, name: 'Activos', value: 1 },
  { id: 2, name: 'Inactivos', value: 0 },
  { id: 3, name: 'Todos', value: 2 }
]);

const remoteStatus = ref([
  { id: 1, name: 'Si', value: 1 },
  { id: 2, name: 'No', value: 0 },
  { id: 3, name: 'Todos', value: 2 }
]);

const companyIntern = ref(null);
const companyInterns = ref([
  { id: 1, name: 'Becario/Practicas', value: 1 },
  { id: 2, name: 'Empleado', value: 0 },
  { id: 3, name: 'Todos', value: 2 }
]);
// Referencias a los selectores excluyentes entre sí
// const excluyentsDropDownsRefs = ref(['departmentsSharedDropDown', 'jobsSharedDropDown', 'activeSharedDropDown', 'remoteSharedDropDown']);

const user = computed(() => {
  return store.state.user;
});

onMounted(() => {
  if (user.value.roles.includes('ROLE_ADMIN')) {
    params.value.active = active.value;
    params.value['order[name]'] = 'ASC';
    getEmployees();
    getJobs();
    getDepartments();
  }
})

// eslint-disable-next-line no-unused-vars
// const setRemote = (remote) => {
//   if (remote !== null) {
//     if (remote === 3) {
//       delete params.value['remote']
//     } else {
//       params.value.remote = remote.value
//     }
//   } else {
//     delete params.value['remote']
//   }
//   page.value = 1;
//   getEmployees();
// }

const search = () => {
  page.value = 1;
  getEmployees();
}

const filterEmployees = () => {
  if (employeeName.value && employeeName.value.length > 0) {
    params.value.orSearch_fullName = employeeName.value.toLowerCase()
  } else {
    delete params.value['orSearch_fullName'];
  }
  if (department.value) {
    params.value['job.department.name'] = department.value
  } else {
    delete params.value['job.department.name'];
  }
  if (job.value) {
    params.value['job.name'] = job.value
  } else {
    delete params.value['job.name'];
  }
  // if (remote.value !== null && remote.value != 3) {
  if (remote.value) {
    params.value.remote = remote.value
  } else {
    delete params.value['remote']
  }
  if (active.value != null) {
    params.value.active = active.value
  } else {
    delete params.value['active'];
  }
  if (companyIntern.value != null) {
    params.value.companyInterns = companyIntern.value
  } else {
    delete params.value['companyInterns'];
  }
}

const getEmployees = () => {
  filterEmployees();
  params.value.page = page.value;
  params.value.itemsPerPage = itemsPerPage.value;
  // store.state.loading = true;
  store.commit('startLoading');
  EmployeeService.getEmployees(params.value)
    .then((res) => {
      // res.data['hydra:member'].forEach(
      //     (employee) => {
      //       // if (employee && employee.photo && employee.photo.contentUrl)
      //         // while (employee.photo.contentUrl.charAt(0) === '/') {
      //         //   employee.photo.contentUrl = employee.photo.contentUrl.substr(1);
      //         // }
      //     // }
      // );
      employees.value = res.data['hydra:member'];
      filteredEmployees.value = employees.value;
      totalResults.value = res.data['hydra:totalItems'];
      // store.state.loading = false;
      store.commit('stopLoading');
    })
    .catch(() => {
      Notification.addNotification('Error al intentar eliminar el empleado seleccionado.', 'error', 6000);
      // store.state.loading = false;
      store.commit('stopLoading');
    })
}
const getJobs = (searchQuery = '') => {
  // const params = {query: {name: searchQuery}};
  const params = (searchQuery.length > 0 ? { name: searchQuery } : {});

  ResourceService.getJobs(params).then((res) => {
    jobs.value = res.data['hydra:member'];
  })
}
const getDepartments = (searchQuery = '') => {
  //const params = {query: {name: searchQuery}};
  const params = (searchQuery.length > 0 ? { name: searchQuery } : {});

  ResourceService.getDepartments(params).then((res) => {
    departments.value = res.data['hydra:member'];
  })
}
const openEmployee = (employee) => {
  router.push(`/employees/${employee.id ? employee.id : 'new'}`)
}
const deleteEmployee = (employee) => {
  // store.state.loading = true;
  store.commit('startLoading');
  EmployeeService.deleteEmployee(employee.id)
    .then(() => {
      Notification.addNotification('Empleado eliminado correctamente.', 'success', 6000);
      // store.state.loading = false;
      store.commit('stopLoading');
      selectedEmployee.value = null;
      deleteDialogShown.value = false;
      getEmployees();
    })
    .catch(() => {
      Notification.addNotification('Error al intentar eliminar el empleado seleccionado.', 'error', 6000);
      // store.state.loading = false;
      store.commit('stopLoading');
      selectedEmployee.value = null;
      deleteDialogShown.value = false;
      getEmployees();
    })
}
const unsubscribeEmployee = async (employee) => {


  if (dateLeave.value) {
    // store.state.loading = true;
    store.commit('startLoading');
    employee.active = false;
    employee.dateLeave = dateLeave;
    await EmployeeService.updateEmployee(employee, employee.id)
      .then(() => {
        Notification.addNotification('Empleado dado de baja correctamente', 'success', 6000);
        // store.state.loading = false;
        store.commit('stopLoading');
        selectedEmployee.value = null;
        dateLeave.value = null;
        getEmployees();
      })
      .catch(() => {

        Notification.addNotification('Error al dar de baja al empleado', 'error', 6000);
        // store.state.loading = false;
        store.commit('stopLoading');
        selectedEmployee.value = null;
        dateLeave.value = null;
        getEmployees();
      });
  }
}
// eslint-disable-next-line no-unused-vars
const formatDate = (value) => {
  return moment(value).format('YYYY-MM-DD');
}
// eslint-disable-next-line no-unused-vars
const openModalUnsubscribe = () => {
  showModalUnsubscribe.value = true;
}
const closeModalUnsubscribe = () => {
  showModalUnsubscribe.value = !showModalUnsubscribe.value;
}
// eslint-disable-next-line no-unused-vars
const openModalEmployeeImage = (employee) => {
  nameEmployee.value = employee.name + " " + employee.lastName;
  imageEmployee.value = baseUrl.value + employee.photo.contentUrl;

  showModalEmployeeImage.value = true;
}
// eslint-disable-next-line no-unused-vars
const closeModalEmployeeImage = () => {
  showModalEmployeeImage.value = !showModalEmployeeImage.value;
}

/* Evento que se ejecuta cuando un selector se despliega */
// eslint-disable-next-line no-unused-vars
// const sharedDropdownOpened = (openedDropdownRef) => {
//   // Ordena el cierre del resto de selectores excluyentes...
//   for (let i = 0; i < excluyentsDropDownsRefs.value.length; ++i) {
//     if (openedDropdownRef != excluyentsDropDownsRefs[i]) {
//       excluyentsDropDownsRefs[i].value.closeDropdown();
//     }
//   }
// }
const ifIsInArray = (userRoles, role) => {
  return Common.ifIsInArray(userRoles, role);
}

</script>

<style lang="scss" scoped>
.card-search {
  height: auto;
  border-radius: 15px;
  max-height: 480px;
  background: white url("../../assets/images/rrhh_empleados_coffe.png") no-repeat;
  background-position-x: 100%;
  overflow: visible !important;
}

#employees {
  //text-align: left;

  margin-bottom: 20px;

  .label-employees {
    color: #434343;
    float: left;

  }


  label.checkbox-label input[type=checkbox] {
    position: relative;
    vertical-align: middle;
    bottom: 1px;
  }

}

.subtitle {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .subtitle__search-icon {
    fill: $bg-blue;
    width: 45px;
    margin-right: 10px;
  }

  .subtitle__search-text {
    color: $bg-blue;
    letter-spacing: -0.05em;
    font-size: 2.5em;
    font-weight: bolder;
  }
}

.btn__search {
  color: $bg-blue;
  text-transform: uppercase;
  font-weight: bolder;
  margin-left: 10px;

}

.btn__search,
.btn__new {
  height: 3em;
  margin-top: 22px;
}


.icon__table {
  fill: $bg-blue;
  width: 2em;
}

.table-users {
  table {
    tbody {
      td {
        .link-name {
          margin: auto;
          margin-left: 10px;
        }
      }
    }
  }
}

.img-thumbnail {
  width: 50px;
}

.img-modal {
  width: 325px;
}

.modal-image {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
</style>
