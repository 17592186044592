<template>
  <div>
    <el-row>
      <el-col :span="20">
        <h2>Los siguientes empleados no tienen sincronizado su ID de clockify</h2>
      </el-col>
      <el-col :span="4">
        <el-button
          type="primary"
          @click="synchronize"
        >
          Sincronizar
        </el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-table
          :data="employeesWithoutClockify"
          border
        >
          <el-table-column width="60">
            <template #default="scope">
              <el-avatar :src="photo(scope.row)" :size="40">
                {{ employeeInitials(scope.row) }}
              </el-avatar>
            </template>
          </el-table-column>
          <el-table-column
            label="Nombre"
            prop="name"
          />
          <el-table-column
            label="Apellidos"
            prop="lastname"
          />
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script setup>

import {employeeInitials, photo} from "@/views/production/mixin/common";
import {defineProps} from 'vue';
import ReportService from '@/api/services/report.dailyproduction.service';

  defineProps({
    employeesWithoutClockify: {
      type: Array,
      default: () => []
    }
  });

     const synchronize  = () =>{
       ReportService.syncClockify().then(result => {
         const userSynced = parseInt(result.data.before) - parseInt(result.data.after);
         this.$message.success(userSynced + 'empleados sincronizados correctamente');
         this.$emit('clockify-synced');
       })

      }

</script>

<style lang="scss">

</style>
