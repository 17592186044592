<script setup>
import moment from "moment";
import {computed, defineProps} from "vue";
import { useStore } from "vuex";

const props = defineProps({
  totalTime: Number,
  title:{
    type: String,
    default: 'Total'
  },
  weekEntries: Object,
})

const store = useStore();

const totalTime = computed(() => {  
  let total = 0
  if(!props.weekEntries){
    if(props.totalTime){
      return props.totalTime;
    }else{
      return 0;
    }
  }
  if(!props.weekEntries) return 0
  if(props.weekEntries.length === 0) return 0  
  props.weekEntries.forEach((entry) => {
    if(!entry.timeEnd){
      total += store.state.entryDuration;
    }else{
      if(entry.duration){
        total += entry.duration
      }else{
        total += moment().diff(entry.timeStart, 'seconds')
      }
    }
  })
  return total;
});


const timeInfo = computed(() => {
  const hours = Math.floor(totalTime.value / 3600).toString().padStart(2, '0');
  const minutes = Math.floor((totalTime.value % 3600) / 60).toString().padStart(2, '0');
  const remainingSeconds = (totalTime.value % 60).toString().padStart(2, '0');
  return `${hours}:${minutes}:${remainingSeconds}`;
});
</script>

<template>
  <div>
    <div class="tracker-total-time">
      <div class="tracker-total-time__title">
       <span>{{ props.title }}: </span> 
       <span class="tracker-total-time__time tracker-time">{{timeInfo}}</span>
      </div>

    </div>
  </div>
</template>

<style scoped lang="scss">
 .tracker-total-time {
   display: flex;
   justify-content: start;
   align-items: center;
   white-space: nowrap;
   .tracker-total-time__title span{
     color: #474ce6;
     font-size: 16px;
   }
   .tracker-total-time__time {
     font-size: 24px!important;
     color: black!important;
     font-weight: 500;
     margin-left: 5px;
     min-width: 100px;
     text-align: right;
     display: inline-block;
   }
 }
</style>