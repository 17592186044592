<!-- eslint-disable no-unused-vars -->
<script setup>

import {computed, onMounted, ref, defineProps} from "vue";

const props = defineProps(['startTime']);

const currentTime = ref(new Date());
const startTime = computed(() =>{
  return props.startTime.timeStart;
})
let timer = null;
const tiempo = computed(()=>{
  const hours = `${totalTime.value.hours<10 ? '0'+totalTime.value.hours:totalTime.value.hours}`;
  const minutes = `${totalTime.value.minutes<10 ? '0'+totalTime.value.minutes:totalTime.value.minutes}`;
  const seconds = `${totalTime.value.seconds<10 ? '0'+totalTime.value.seconds:totalTime.value.seconds}`;


  return `${hours}:${minutes}:${seconds}`

});

const totalTime = computed(() => props.startTime)

onMounted(() => {
  currentTime.value = new Date();
});



</script>

<template>
    <div class="tracker-dtime" v-if="tiempo">{{ tiempo }}</div>
    <div class="tracker-dtime" v-else> {{ time }}</div>
</template>

<style scoped lang="scss">

</style>