<template>
  <div>
    <HeaderTitle title="Ajustes" />
    <div class="d-flex flex-row justify-content-start">
      <img src="@/assets/icons/group.svg" width="30" alt="">
      <div class="title-text pl-4">
        Tipos de Baja
      </div>
    </div>
    <el-card
             class="card-search w-100 wback"
             body-style="padding:0"
             style="height: auto !important;"
    >
      <div class="row mx-5">
    <div class="container-leave-type">
      <div class="title-leave-type">
        <div class="row">
          <div class="col-md-8">
            <div class="row">
              <div class="col-md-6">
                <div class=" form-item  date-picker  label-common">
                  <label class=" w-100 mb-2">Tipo de baja</label>
                  <el-input
                      v-model="searchFilterLeaveTypeName"
                      placeholder="Tipo de Baja"
                      prefix-icon="el-icon-search"
                      clearable />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ml-auto d-flex justify-content-end align-items-end">
          <div class="">
            <button
              @click="createOrUpdateLeaveType = true" 
              class="btn btn_darkpink btn-search"
              style="min-width:250px; max-width: 300px; color:white;"
            >
              NUEVO TIPO DE BAJA
            </button>
          </div>
          <div class="">
            <button
            style="max-width: 250px;"
            class="btn btn_yellow btn-search"
            @click="search()"
            >
            Buscar
          </button>
        </div>
      </div>
      </div>
    </div>
      </div>
    </el-card>
    <div class="table">
      <div v-if="leaveTypes && leaveTypes.length > 0">
        <table>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Observaciones</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="leaveType in leaveTypes"
              :key="leaveType.id"
            >
              <td>
                {{ leaveType.name }}
              </td>
              <td>{{ leaveType.observations }}</td>
              <td>
                <div class="buttons d-inline-flex">
                  <div class="pointer">
                    <font-awesome-icon
                      class="btn_icon blue_btn"
                      icon="eye"
                      title="Editar"
                      style="margin-left: 5px"
                      @click="editLeaveType(leaveType)"
                    />
                  </div>
                  <div class="pointer">
                    <font-awesome-icon
                      class="btn_icon delete_btn"
                      icon="times"
                      title="Eliminar"
                      style="margin-left: 5px"
                      @click="deleteDialogShown = true; selectedLeaveType = leaveType;"
                    />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <h5>No se han encontrado tipos de baja laboral</h5>
      </div>

      <SharedPagination
        v-if="leaveTypes && leaveTypes.length > 4"
        class="pagination"
        :page="page"
        :total-results="totalResults"
        :page-size="itemsPerPage"
        style="padding: 0px 0px 20px 0px;"
        @change="page = $event; getLeaveTypes()"
      />


    </div>

    <el-dialog
        v-model="createOrUpdateLeaveType"
        class="dense-dialog"
        @closed="closeCreateOrUpdateDialog()"
        :show-close="false"
        size="'medium'"
        width="750"
        :center="true"
        :lock-scroll="true"
        :style="{ borderRadius: `var(--el-border-radius-round)` }"
        >
        <div class="my-header">
        <svg
            id="asterisk"
            xmlns="http://www.w3.org/2000/svg"
            width="724"
            height="1024"
            viewBox="0 0 724 1024"
        >
          <path
              d="M0 499.8l59-181.7 332.5 108.6-59 180.8-332.5-107.7zM79.6 744.3l206.1-282.9 154.5 111.5-206.1 283.8-154.5-112.4zM266.9 167.3h191.1v350.3h-191.1v-350.3zM285.6 572.9l154.5-111.5 206.1 282.9-154.5 112.4-206.1-283.8zM332.5 426.8l332.5-108.6 59 181.7-331.6 107.7-59.9-180.8z"
          />
        </svg>
        <span class="text-header ml-5 mt-3">{{ selectedLeaveType ? 'Actualizar' : 'Nuevo'}} Tipo de Baja</span>

        <img
            class="close-icon pointer"
            src="@/assets/images/aspa_blue_small_entrada.png"
            alt=""
            @click="closeCreateOrUpdateDialog()"
        >
      </div>
      <div class="my-body">
        <form @submit.prevent="">
          <div class="row">
            <div class="col-md-10">
              <label for="leaveTypeName" class="w-100">Tipo de Baja</label>
              <el-input
                  v-model="leaveTypeName"
                  :placeholder="'Tipo de Baja'"
              />
            </div>
            <div class="col-md-6" />
          </div>
          <div class="col-md-10 mt-3">
            <div class="form-item">
              <label class="w-100">Motivo de la baja</label>
              <textarea
                  rows="8"
                  class="w-100 p-2"
                  v-model="leaveTypeObservations"
                  :placeholder="'Motivo de la baja'"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-md-4 offset-md-4 d-flex flex-column justify-content-end align-items-end">
              <button
                  class="btn btn_darkpink"
                  @click="selectedLeaveType ? handleUpdateDepartment() : createLeaveType(leaveType)"
              >
                {{ selectedLeaveType ? 'Actualizar' : 'Nuevo'}}
              </button>
            </div>
          </div>
        </form>
      </div>
    </el-dialog>

    <el-dialog
      v-model="deleteDialogShown"
      class="dense-dialog"
      :title="'¿Desea eliminar permanentemente este tipo de baja?'"
      @closed="selectedLeaveType = null;deleteDialogShown = false;"
    >
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="selectedLeaveType = null;deleteDialogShown = false;">
            Cancelar
          </el-button>
          <el-button type="primary" @click="deleteLeaveType(selectedLeaveType)">Confirmar</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>

// Components
import SharedPagination from "@/components/shared/SharedPagination.vue";
import HeaderTitle from "@/components/layout/HeaderTitle.vue";

// Services
import ResourceService from '@/api/services/resource.service';
import Notification from '@/utils/plugins/NotificationService';


export default {
  name: "LeaveTypes",
  components: {
    SharedPagination,
    HeaderTitle,
  },
  data() {
    return {
      leaveTypes: [],
      leaveType: {},
      submitted: false,
      selectedLeaveType: null,
      page: 1,
      itemsPerPage: 10,
      totalResults: null,
      leaveTypeName: '',
      leaveTypeObservations: '',
      deleteDialogShown: false,
      createOrUpdateLeaveType: false,
      searchFilterLeaveTypeName: ''

    }
  },
  mounted() {
    this.getLeaveTypes();
  },
  methods: {

    notEmptyObject(someObject) {
      return Object.keys(someObject).length;
    },
    editLeaveType(leaveType) {
      this.selectedLeaveType = leaveType;
      this.leaveTypeName = leaveType.name;
      this.leaveTypeObservations = leaveType.observations;
      this.createOrUpdateLeaveType = true
    },
    handleUpdateDepartment(){
      this.selectedLeaveType.name = this.leaveTypeName;
      this.updateLeaveType(this.selectedLeaveType)
    },
    getLeaveTypes(searchQuery = null) {
      const params = searchQuery ? {name: searchQuery} : {};
      ResourceService.getLeaveTypes(params)
          .then((res) => {
            this.leaveTypes = res.data['hydra:member'];
          })
    },
    createLeaveType(leaveType) {
      leaveType.name = this.leaveTypeName;
      leaveType.observations = this.leaveTypeObservations;
      // this.$store.state.loading = true;
      this.$store.commit('startLoading');
      ResourceService.createLeaveType(leaveType)
          .then(() => {
            Notification.addNotification('Tipo de baja creada correctamente', 'success', 6000);
            // this.$store.state.loading = false;
            this.$store.commit('stopLoading');
            this.cancel();
          })
          .catch(() => {
            Notification.addNotification('Error al crear tipo de baja', 'error', 6000);
            // this.$store.state.loading = false;
            this.$store.commit('stopLoading');
          })
          .finally(()=>{
            this.closeCreateOrUpdateDialog()
          })
    },
    updateLeaveType(selectedLeaveType) {
      if (this.leaveTypeName) {
        selectedLeaveType.name = this.leaveTypeName;
      }

      selectedLeaveType.observations = this.leaveTypeObservations;

      // this.$store.state.loading = true;
      this.$store.commit('startLoading');
      ResourceService.updateLeaveType(selectedLeaveType, selectedLeaveType.id)
          .then(() => {
            Notification.addNotification('Tipo de baja actualizado correctamente', 'success', 6000);
            // this.$store.state.loading = false;
            this.$store.commit('stopLoading');
            this.cancel();
          })
          .catch(() => {
            Notification.addNotification('Error al actualizar el tipo de baja', 'error', 6000);
            // this.$store.state.loading = false;
            this.$store.commit('stopLoading');
          })
          .finally(()=>{
            this.closeCreateOrUpdateDialog()
          })
    },
    deleteLeaveType(leaveType) {
      ResourceService.deleteLeaveType(leaveType.id)
          .then(() => {
            Notification.addNotification('Tipo de baja eliminado correctamente.', 'success', 6000);
            this.deleteDialogShown = false;
            this.cancel();
          }).catch(() => {
        Notification.addNotification('Error al eliminar el tipo de baja seleccionado', 'error', 6000);
        // this.$store.state.loading = false;
        this.$store.commit('stopLoading');
      })
    },
    reset(){
      this.leaveType = {};
      this.leaveTypeName = '';
      this.leaveTypeObservations = '';
      this.selectedLeaveType = null;
    },
    cancel() {
      this.reset()
      this.getLeaveTypes();
    },
    closeCreateOrUpdateDialog(){
      this.createOrUpdateLeaveType = false
      this.reset()
    },
    search(){
      this.getLeaveTypes(this.searchFilterLeaveTypeName)
    }
  }


}
</script>

<style lang="scss" scoped>

.leave-type-input {
  /*display: flex;*/
  vertical-align: baseline;
  margin: 30px 10px 10px 10px;
}

.table-leave-types {
  table {
    //margin-left: auto;
    //margin-right: auto;
    //width: auto;
  }
}


.el-dialog__body {
  background: #f3f0fb !important;
  min-height: 800px !important;

  .el-dialog__header {
    padding: 0;
  }

  .my-header {
    margin-top: 2px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .text-header {
      text-align: left;
      font-size: 4em;
      font-weight: 900;
      letter-spacing: -0.05em;
      color: #f9156f;
    }

    .close-icon {
      width: 8%;
      height: 20%;
    }

    .inter-icon {
      width: 2%;
      height: 2%;
    }

    #asterisk {
      position: absolute;
      vertical-align: super;
      height: auto;
      width: 2.2em;
      fill: #f9156f;
    }

  }

  .my-body{
    margin-right: 5%;
  }

}
</style>
