<template>
  <div v-if="!store.state.loading && suggestion">
    <FormSuggestion
        :update="true"
        :suggestion="suggestion"
    />
  </div>
</template>
<script setup>
import FormSuggestion from "./FormSuggestion.vue";
import SuggestionServices from "@/api/services/suggestion.service";
import {useStore} from "vuex";
import {onMounted, ref} from "vue";
import {useRoute} from "vue-router";


const store = useStore();
const route = useRoute();
const suggestion = ref({});

onMounted(()=>{
  // store.dispatch('setLoading', true);
  store.commit('stopLoading');
  getSuggestion()
})

const getSuggestion = async () => {

  const id = route.params.id;
  await SuggestionServices.getSuggestion(id)
      .then((resp) => {
        suggestion.value = resp.data;
        // store.dispatch('setLoading', false);
        store.commit('stopLoading');
        //this.suggestions = resp.data['hydra:member'];
      })





}
</script>