<script setup>

import { ElNotification } from 'element-plus';
import { defineEmits, ref, defineProps, onMounted, computed } from 'vue';
import TrackerService from "@/api/services/tracker.service";
import { useStore } from "vuex";


const store = useStore();
const splitValue = ref(0);
const inputSplit = ref();
const refInputSplit = ref();

const halfSplitValue = computed(() => {
  let temp = (totalMinutes.value - 1) - minMinutes.value;

  temp = Math.floor(temp / 2);
  temp = minMinutes.value + temp;
  return temp;
})


const startTime = computed(() => {
  if (props.entry.timeStart) {
    return new Date(props.entry.timeStart)
  } else {
    const temp = new Date();
    temp.setDate(temp.getDate() - 1)
    return temp;
  }
});
const endTime = computed(() => {
  if (props.entry.timeEnd) {
    return new Date(props.entry.timeEnd)
  } else {
    if (props.timeEntry.endTime) {
      const newD = new Date(props.entry.timeStart);
      const startD = new Date(props.entry.timeStart);
      const timeEnd = props.timeEntry.endTime.split(':')
      newD.setHours(timeEnd[0]);
      newD.setMinutes(timeEnd[1]);
      if (newD < startD) {
        newD.setDate(newD.getDate() + 1);
      }
      return newD;
    } else {
      return new Date()
    }
  }
})

const timeBeforeSplit = computed(() => {
  const start = new Date();
  const split = new Date();

  const startDiv = props.timeEntry.startTime.split(':');
  start.setHours(startDiv[0])
  start.setMinutes(startDiv[1]);

  const splitDiv = getFormattedDateTime(splitValue.value).split(':');
  split.setHours(splitDiv[0])
  split.setMinutes(splitDiv[1])

  return formatTimeDifference(start, split);
});

const timeAfterSplit = computed(() => {
  const end = new Date();
  const split = new Date();

  const splitDiv = getFormattedDateTime(splitValue.value).split(':');
  split.setHours(splitDiv[0])
  split.setMinutes(splitDiv[1])

  const endDiv = props.timeEntry.endTime.split(':');
  end.setHours(endDiv[0])
  end.setMinutes(endDiv[1]);

  return formatTimeDifference(split, end);
})

const minMinutes = computed(() => 1);
const maxMinutes = computed(() => totalMinutes.value - 1);

const emits = defineEmits(['reloadEntries', 'closeDialog']);

const reloadEntries = () => {
  emits('reloadEntries');
}

const closeDialog = () => {
  splitValue.value = halfSplitValue.value;
  emits('closeDialog')
}

const props = defineProps({
  entry: Object,
  timeEntry: Object,
})

onMounted(() => {
  openDialogSplit();
})

const totalMinutes = computed(() => {
  return Math.floor((endTime.value - startTime.value) / 60000);
})
const getFormattedTime = (minutes) => {
  const newDate = new Date(startTime.value.getTime() + minutes * 60000);
  return newDate.toLocaleTimeString("es-ES", { hour: "2-digit", minute: "2-digit" });
};

// Formatea el tooltip del slider para mostrar la hora
const formatTooltip = (val) => getFormattedTime(val);

const formatDate = (date) => {
  // Obtener el desplazamiento de la zona horaria
  const offset = new Date().getTimezoneOffset();
  const sign = offset > 0 ? '-' : '+';
  const hours = String(Math.floor(Math.abs(offset) / 60)).padStart(2, '0');
  const minutes = String(Math.abs(offset) % 60).padStart(2, '0');

  // Formato de fecha
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  // Formato de hora
  const hoursOfDay = String(date.getHours()).padStart(2, '0');
  const minutesOfDay = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  // Devolver la cadena en formato ISO con el desplazamiento de la zona horaria
  return `${year}-${month}-${day}T${hoursOfDay}:${minutesOfDay}:${seconds}${sign}${hours}:${minutes}`;
}


const split = async () => {
  // store.state.loading = true;
  store.commit('startLoading');
  let newDate = getNewDate(splitValue.value)
  let params = {
    splitTime: newDate,
  }
  await TrackerService.splitEntry(props.entry.id, params).then(() => {
    ElNotification({
      message: "Se actualizó la tarea correctamente",
      type: "success",
      duration: 6000
    })
    reloadEntries();
  }).catch(() => {

  }).finally(() => {
    // store.state.loading = false;
    store.commit('stopLoading');
  })
}

const openDialogSplit = () => {
  setSplitValue();
  onSliderChange();
}

const focusInputSplit = () => {
  inputSplit.value = inputSplit.value.split(':');
  let temp = inputSplit.value[0] + inputSplit.value[1];
  inputSplit.value = temp;
}

const blurInputSplit = () => {
  inputSplit.value = getFormattedDateTime(splitValue.value);
}

const onSliderChange = () => {
  inputSplit.value = getFormattedDateTime(splitValue.value);
}

const changeInputSplit = () => {
  //Comprobar si el valor es menos de 2, que lo tome como horas
  let temp;
  const baseDate = new Date(startTime.value);
  if (inputSplit.value.length == 3 || inputSplit.value.length == 4) {

    if (inputSplit.value.length == 3) temp = inputSplit.value.slice(0, 1) + ':' + inputSplit.value.slice(1);
    if (inputSplit.value.length == 4) temp = inputSplit.value.slice(0, 2) + ':' + inputSplit.value.slice(2);
    temp = temp.split(':')
    baseDate.setHours(temp[0], temp[1], 0, 0)
  }
  if (inputSplit.value.length == 1 || inputSplit.value.length == 2) {
    baseDate.setHours(inputSplit.value, 0, 0, 0)
  }

  const diffMin = Math.ceil((baseDate - startTime.value) / 60000);

  if (diffMin >= minMinutes.value && diffMin <= maxMinutes.value) {
    splitValue.value = diffMin;
    refInputSplit.value.blur();
    return;
  }
  //Comprobar que el valor entra dentro de los valores
  if (inputSplit.value.length >= 5 || diffMin < minMinutes.value || diffMin > maxMinutes.value) {
    refInputSplit.value.blur();
    return;
  }
}

const closeDialogSplit = () => {
  closeDialog();
  setSplitValue();
  onSliderChange();
}

const setSplitValue = () => {
  splitValue.value = halfSplitValue.value;
}

const getFormattedDateTime = (minutes) => {
  const newDate = new Date(startTime.value.getTime() + minutes * 60000);
  return newDate.toLocaleString("es-ES", {
    hour: "2-digit",
    minute: "2-digit",
  });
};

const getNewDate = (minutes) => {
  const newDate = new Date(startTime.value.getTime() + minutes * 60000)

  return formatDate(newDate)
}

//Funcion para el antes y despues
const formatTimeDifference = (date1, date2) => {
  const diffMs = Math.abs(date2 - date1); // Diferencia en milisegundos
  const hours = String(Math.floor(diffMs / 3600000)).padStart(2, "0");
  const minutes = String(Math.floor((diffMs % 3600000) / 60000)).padStart(2, "0");
  const seconds = String(Math.floor((diffMs % 60000) / 1000)).padStart(2, "0");

  return `${hours}:${minutes}:${seconds}h`;
};
</script>

<template>
  <div>
    <div class="row d-flex flex-row justify-content-center align-content-center">
      <div class="col-12 mb-4 pt-2">
        <span style="margin-bottom: 10px;">Dividir en </span>
        <el-input ref="refInputSplit" v-model="inputSplit" class="inputSplit" @focus="focusInputSplit"
          @blur="blurInputSplit" @change="changeInputSplit" />

      </div>
      <div class="col-2">
        <span>{{ timeEntry.startTime }}</span>
      </div>
      <div class="col-8">
        <el-slider v-model="splitValue" :min="minMinutes" :max="maxMinutes" :step="1" :format-tooltip="formatTooltip"
          @input="onSliderChange" style="width: auto;" />
        <div class="d-flex justify-content-between mt-2">
          <span>{{ timeBeforeSplit }}</span>
          <span>{{ timeAfterSplit }}</span>
        </div>
      </div>
      <div class="col-2">
        <span>{{ timeEntry.endTime }}</span>
      </div>
    </div>

    <div class="mt-4 d-flex justify-content-end align-content-end">
      <el-button @click="closeDialogSplit">Cancelar</el-button>
      <el-button type="primary" @click="split">
        Guardar
      </el-button>
    </div>
  </div>
</template>
