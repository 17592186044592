<script setup>

import { computed } from 'vue';

// eslint-disable-next-line no-undef
const props = defineProps({
  project: Object,
  select: Boolean,
  currentEntry: Boolean,
  report: Boolean,
  editImport: Boolean
})

const computedStyle = computed(() => {
  return Object.assign(
    props.select ? { marginLeft: 13 + 'px', color: props.project.color } : { color: props.project.color },
    props.currentEntry ? { marginLeft: 13 + 'px', maxWidth: 150 + 'px', marginTop: '4px' } : {},
    props.report ? { marginLeft: 10 + 'px', marginTop: -6 + 'px', lineHeight: 1, maxWidth: 70 + '%' } : {},
    props.editImport ? { maxWidth: 300 + 'px', marginLeft: 13 + 'px', marginTop: '4px' } : {}
  )
})

const computedCircleStyle = computed(() => {
  return Object.assign(
    props.select ? { marginTop: 2 + 'px', backgroundColor: props.project.color, } : { backgroundColor: props.project.color },
    props.report ? { marginTop: -3 + 'px', } : {}
  )
})
</script>

<template>
  <el-row>
    <el-col :span="1">
      <div class="project-circle" :style="computedCircleStyle">
      </div>
    </el-col>
    <el-col :span="23">
      <div class="font-weight-bold" :class="props.select ? 'project-text' : 'new-project-text'" :style="computedStyle">
        {{ props.project.name }}
      </div>
    </el-col>
  </el-row>
</template>

<style scoped lang="scss">
.project-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-top: 6px;
}

.project-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 85px;
  max-width: 95px;
}

.new-project-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media (min-width:1366px) {
  .project-text {
    max-width: 140px;
  }
}
</style>