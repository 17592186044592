<script setup>
import { onMounted, ref, defineProps, computed, defineEmits } from 'vue';
import { ElMessageBox, ElNotification } from "element-plus";
import { useStore } from 'vuex';

import TrackerReportEntry from "@/components/shared/TrackerReportEntry.vue";

import TrackerService from "@/api/services/tracker.service";
import TrackerImportEdit from './TrackeReportEdit.vue';
const store = useStore();

const totalAmount = ref(0);
const selectedEntries = ref([]);
const dialogEdit = ref(false);

const props = defineProps({
    entries: Array
})

const emits = defineEmits(['reloadEntries']);

const reloadEntries = () => {
    emits('reloadEntries');
}


const totalHours = computed(() => {
    let duration = 0;
    if (entries.value.length > 0) {
        for (let i = 0; i < entries.value.length; i++) {
            const entrie = entries.value[i];
            duration += entrie.duration;
        }
    }

    return duration;
});

const totalHoursFacturable = computed(() => {
    let duration = 0;
    if (entries.value.length > 0) {
        for (let i = 0; i < entries.value.length; i++) {
            const entrie = entries.value[i];
            if (entrie.billable) {
                duration += entrie.duration;
            }
        }
    }

    return duration;
});

const entries = computed(() => {
    return props.entries;
})

onMounted(() => {
    totalAmount.value = 0;
})



// Funcionalidad checkbox
const updateSelection = (entry, isChecked) => {
    if (isChecked) {
        if (!selectedEntries.value.includes(entry)) {
            selectedEntries.value.push(entry);
        }
    } else {
        selectedEntries.value = selectedEntries.value.filter(item => item !== entry);
    }
}

const formatDuration = (duration) => {
    let hours = 0;
    let minutes = 0;
    let remainingSeconds = duration;
    do {
        if (remainingSeconds >= 60) {
            remainingSeconds = remainingSeconds - 60;
            minutes = minutes + 1;
        }
        if (minutes >= 60) {
            minutes = minutes - 60;
            hours = hours + 1;
        }
    } while (remainingSeconds >= 60)
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
}

// Funcionalidad eliminar
const deleteEntries = () => {
    if (selectedEntries.value.length < 1) {
        ElNotification({
            message: "Tienes que elegir alguna entrada",
            type: "warning",
            duration: 6000
        })
        return;
    }

    let message = selectedEntries.value.length > 1 ? "estas entradas" : "esta entrada";
    ElMessageBox.confirm(
        '¿Estás seguro de descartar ' + message + ' ?',
        'Warning',
        {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning',
        }
    ).then(async () => {
        // store.state.loading = true;
        store.commit('startLoading');
        for (let i = 0; i < selectedEntries.value.length; i++) {
            const id = selectedEntries.value[i].id;
            await TrackerService.discardEntry(id);
        }
        // store.state.loading = false;
        store.commit('stopLoading');
        reloadEntries();
        let message = selectedEntries.value.length > 1 ? "Las entradas se han" : "La entrada se ha";
        ElNotification({
            message: message + " eliminado",
            type: "success",
            duration: 6000
        })
        selectedEntries.value = [];
    })
        .catch(() => {

        })

}

// Funcionalidad Dialog Edit

const closeDialogEdit = () => {
    dialogEdit.value = false;
}

const openDialogEdit = () => {
    if (selectedEntries.value.length < 1) {
        ElNotification({
            message: "Tienes que elegir alguna entrada",
            type: "warning",
            duration: 6000
        })
        return;
    }
    dialogEdit.value = true;
}

</script>
<template>
    <el-card class="card-tracker mt-3">
        <template #header>
            <div class="card-tracker-header">
                <div class="row ms-0" style="min-width: 625px;">
                    <div class="col-4 ms-0">
                        Total:
                        <span class="inputHour">
                            {{ formatDuration(totalHours) }}
                        </span>
                    </div>
                    <div class="col-4 ms-0">
                        Facturable:
                        <span class="inputHour">
                            {{ formatDuration(totalHoursFacturable) }}
                        </span>
                    </div>
                    <div class="col-4 ms-0">
                        Importe:
                        <span class="inputHour">
                            {{ formatDuration(totalAmount) }}
                        </span>
                    </div>
                </div>
            </div>
        </template>
        <template #default>
            <div class="col-12">
                <div class="row d-flex mb-2 mt-1">
                    <div class="col-5 columnReport1">
                        <div class="row d-flex">
                            <div class="col-auto">
                                <span class="editSpan" @click="openDialogEdit">Edicion en masa</span>
                            </div>
                            <div class="col-auto">
                                <span class="deleteFilter" @click="deleteEntries">Eliminar</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-7 columnReport1">
                        <div class="row d-flex" style="margin-left: 10px;">
                            <div class="col-3">
                                <span>Importe</span>
                            </div>
                            <div class="col-2">
                                <span>Usuario</span>
                            </div>
                            <div class="col-3" style="margin-left: 15px;">
                                <span>Tiempo</span>
                            </div>
                            <div class="col-3" style="margin-left: 15px;">
                                <span>Duracion</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-12">
                <hr style="margin-bottom: 5px;">
                <el-checkbox-group v-model="selectedEntries">
                    <TrackerReportEntry v-for="entry in entries" :key="entry.id" :entry="entry"
                        :selected-entries="selectedEntries" @update-selection="updateSelection"
                        @reload-entries="reloadEntries" />
                </el-checkbox-group>
            </div>
        </template>
    </el-card>
    <div>
        <el-dialog v-model="dialogEdit" title="Edicion Masiva de una entrada" width="650"
            style="height: 630px; overflow: hidden; border-radius: 10px;" :lock-scroll="true"
            v-if="selectedEntries.length > 0" @close="closeDialogEdit">
            <template #header>
                <div class="custom-header">
                    Edición masiva de una entrada
                </div>
            </template>
            <TrackerImportEdit v-if="dialogEdit" :selectedEntries="selectedEntries" @reload-entries="reloadEntries"
                @close-dialog="closeDialogEdit" />
        </el-dialog>
    </div>
</template>
<style lang="scss" scoped>
.inputHour {
    font-weight: 500;
    font-size: 24px;
    color: #000;
}

.editSpan {
    text-transform: uppercase;
    font-size: 16px;
    color: #474CE6;
    cursor: pointer;
}

:deep(.el-dialog__header) {
    padding: 0;
    margin-right: -33px;
}

:deep(.el-dialog__headerbtn .el-dialog__close) {
    color: white !important;
}

:deep(.el-dialog__headerbtn .el-dialog__close:hover) {
    color: #f0f0f0 !important;
}

.custom-header {
    background-color: $bg-blue;
    text-transform: uppercase;
    color: white;
    text-align: left;
    padding: 15px;
    font-size: 18px;
    font-weight: bold;
    width: 100%;
    margin-bottom: 10px;
    margin-top: -16px;
    margin-left: -17px;

    span {
        margin-left: 5px;
    }
}
</style>