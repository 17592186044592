<template>
  <div>
    <div :class="{ 'loading': loading }">
      <!--      <div id="grad" v-if="userLogged && authenticated"></div>-->
      <main class="main-content"
        :class="{ 'full-width': !authenticated, 'opened': openSidebar, 'closed': !openSidebar }">
        <SharedSpinner v-if="loading" />
        <div style="margin-right: 15px;">
          <router-view />
        </div>

      </main>

      <Navbar v-if="userLogged && authenticated" />
      <AdminSidebar v-if="userLogged && authenticated" @close="showSidebar" />

    </div>
  </div>
</template>

<script setup>

// Component Dependencies
import { useStore } from 'vuex'

// Components
import AdminSidebar from '@/components/layout/AdminSidebar.vue'
import Navbar from '@/components/layout/Navbar.vue'
import SharedSpinner from '@/components/shared/SharedSpinner.vue'


// Authentication
import auth from '@/api/auth/auth'
import { computed, onMounted, onUpdated, ref, watch } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const store = useStore();
const authenticated = ref(false);
const openSidebar = ref(true);

const userLogged = computed(() => store.state.user);
const loading = computed(() => store.state.loading);

watch(
  'route', (to, from) => {
    if (from.name === 'login' && to.name !== 'recover-password') {
      initDataApplication();
    }
  });

onMounted(() => {
  store.state.activeRequests = 0;
  initDataApplication();
  authenticated.value = auth.checkAuth();
  const storage = localStorage.getItem('sidebarState');
  if (storage != null) {
    openSidebar.value = JSON.parse(storage);
  }
})
onUpdated(() => {
  authenticated.value = auth.checkAuth();
})

const initDataApplication = () => {
  if (route.name !== 'login') {
    store.dispatch('beforeCreate');
    if (!store.getters.getUserLogged) {
      auth.logout();
    }
  }
}
// eslint-disable-next-line no-unused-vars
const showSidebar = () => {
  openSidebar.value = !openSidebar.value
}

</script>

<style lang="scss">
#app {
  font-family: $font-main;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $primary-color;
  //height: 100vh;
  font-size: 1rem;
  background-color: #F3F0FB;


  &.loading {
    display: flex;
    align-items: center;
    justify-items: center;
  }

  .main-content {
    width: 100%;
    height: 100%;
    background-color: #F3F0FB;

    /*text-align: center;*/
    &.opened {
      padding: 80px 40px 80px 270px;
    }

    &.closed {
      padding: 80px 10px 80px 130px;
    }

    &.full-width {
      background-color: $bg-blue;
      padding: 0px;
      /*        padding-left: 0px;
              padding-top: 0px;*/
    }

  }

  @media screen and (max-width: 768px) {
    .main-content {
      padding-left: 0px;
    }
  }
}
</style>
