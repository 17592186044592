<template>
  <div class="employees-absences-container">
    <el-row>
      <el-col :span="20">
        <h2>Empleados con ausencias</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-table
            :data="sortedAbsences"
            border
            style="width: 100%"
            :max-height="500"
        >
          <el-table-column width="60">
            <template #default="scope">
              <el-avatar :src="photo(scope.row)" :size="40">
                {{ employeeInitials(scope.row) }}
              </el-avatar>
            </template>
          </el-table-column>
          <!--                    <el-table-column label="Id" prop="id"></el-table-column>-->
          <el-table-column
              label="Nombre"
              prop="name"
              min-width="100"
          />
          <el-table-column
              label="Apellidos"
              prop="lastname"
              min-width="140"
          />
          <el-table-column
              label="Observaciones"
              prop="observations"
              min-width="140"
              show-overflow-tooltip
          />
          <el-table-column
              label="Desde"
              prop="fromDate"
              min-width="140"
          />
          <el-table-column
              label="Hasta"
              prop="toDate"
              min-width="150"
          />
          <el-table-column
              label="Duración"
              min-width="120"
          >
            <template #default="scope">
              {{ formatAbsentTime(scope.row.duration) }}
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script setup>
import _ from 'lodash';
import { employeeInitials, photo } from "@/views/production/mixin/common";
import { computed, defineProps } from 'vue';


const props = defineProps({
  absences: {
    type: Array,
    default: () => []
  }
});

// onMounted(() => {
//   console.log('Componente montado, ausencias:', props.absences);
// })

const sortedAbsences = computed(() => {
  // console.log('Procesando ausencias:', props.absences);
  return _.orderBy(props.absences, ['lastname']);
});

// const photo = (employee) => {
//   return employee.photo || '';
// };

const formatAbsentTime = (minutes) => {
  if (minutes === 0) return '0'

  const days = Math.floor(minutes / (24 * 60))
  const hours = Math.floor((minutes % (24 * 60)) / 60)
  const mins = minutes % 60

  let result = ''
  if (days > 0) result += `${days}D:`
  if (hours > 0 || days > 0) result += `${hours.toString().padStart(2, '0')}H:`
  result += `${mins.toString().padStart(2, '0')}M`

  return result.trim()
}

</script>

<style scoped>
el-dialog__wrapper {
  max-width: 90vw;
  margin: 0 auto;
}

.el-table {
  width: 100%;
}

.el-table .cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>
