<template>
  <div class="holiday-incompatibilities">
    <HeaderTitle title="Ajustes" />
    <div class="d-flex flex-row justify-content-start">
      <img src="@/assets/icons/group.svg" width="30" alt="">
      <div class="title-text pl-4">
        Vacaciones incompatibles
      </div>
    </div>
    <el-card
        class="card-search w-100 wback"
        body-style="padding:0"
        style="height: auto !important;"
    >
      <div class="row mx-5">
        <div class="container-holiday-incompatibilities">
          <form>

            <div class="row">
              <div class="col-md-6">
                <div class="form-item">
                  <label for="holidayIncompatibilityName" class="w-100">Nombre incompatibilidad</label>
                  <el-input
                      clearable
                      v-model="holidayIncompatibilityName"
                      :placeholder="'Nombre Incompatibilidad'"
                      @change="filterHolidayIncompatibilityByName($event)"
                  />
                </div>
              </div>
            </div>


            <div class="row">
              <div class="col-md-6">
                <div class="form-item">
                  <label for="employeeName" class="w-100">Nombre empleado</label>
                  <el-input
                      clearable
                      v-model="employeeName"
                      :placeholder="'Nombre'"
                  />
                </div>
              </div>
            </div>

            <!--
            <div class="form-item">
                <div class="row text-center">
                    <div class=col-md-6>
                        <div class="row">
                            <div class="col-md-2">
                                <label class="label-state">Estado:</label>
                            </div>
                            <div class="col-md-10">
                                <div class="colsd">
                                    <SharedDropdown
                                            id="status"
                                            v-model="selectedStatusFilter"
                                            :items="statusOptions"
                                            :selected="selectedStatusFilter"
                                            @selectItem="filterByStatus($event)"
                                            data-vv-as="selectedStatusFilter"
                                            :rules="{ required: false }"
                                            data-vv-name="statusOptions"
                                            :label="'ddd'"
                                            :param="'name'"
                                            :color="'light'"
                                            :title="'Todos'"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            -->
            <div class="ml-auto d-flex justify-content-end align-items-end">
              <div class="">
                <button
                    type="submit"
                    class="btn btn_darkpink btn-search"
                    style="min-width:250px; max-width: 300px; color:white;"
                    @click="openModalConfigHolidayIncompatibility()"
                >
                  Nueva Incompatibilidad
                </button>
              </div>
              <div class="">
                  <button
                  style="max-width: 250px;"
                  class="btn btn_yellow btn-search"
                  @click="search()"
                  >
                  Buscar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </el-card>
    <div class="table">
      <div v-if="dataSynchronized && holidayIncompatibilities && holidayIncompatibilities.length > 0">
        <table>
          <thead>
          <tr>
            <th>Nombre</th>
            <th>Empleado</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="holidayIncompatibility in holidayIncompatibilities">
            <tr
                v-for="employee in holidayIncompatibility.employees"
                :id="holidayIncompatibility.id + '-' + employee.id"
                :key="holidayIncompatibility.id + '-' + employee.id"
            >
              <td
                  class="pointer"
                  @click="openModalConfigHolidayIncompatibility(holidayIncompatibility)"
              >
                {{ holidayIncompatibility.name }}
              </td>
              <td
                  class="pointer"
                  @click="openModalConfigHolidayIncompatibility(holidayIncompatibility)"
              >
                {{ employee.name }} {{ employee.lastName }}
              </td>
              <td>
                <!-- <div class="buttons d-inline-flex" v-if="user && ifIsInArray('ROLE_SUPER_ADMIN', user.rol)">-->
                <div class="buttons d-inline-flex">
                  <div class="pointer">
                    <font-awesome-icon
                        class="btn_icon blue_btn"
                        icon="eye"
                        title="Editar"
                        style="margin-left: 5px"
                        @click="openModalConfigHolidayIncompatibility(holidayIncompatibility);"
                    />
                  </div>
                  <div class="pointer">
                    <font-awesome-icon
                        class="btn_icon delete_btn"
                        icon="times"
                        title="Eliminar"
                        style="margin-left: 5px"
                        @click="deleteDialog(holidayIncompatibility, employee);"
                    />
                  </div>
                </div>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
      <div v-else>
        <h5>No se han encontrado incompatibilidades</h5>
      </div>

      <SharedPagination
          v-if="holidayIncompatibilities && totalResults > 10"
          class="pagination"
          :page="page"
          :total-results="totalResults"
          :page-size="itemsPerPage"
          style="padding: 0px 0px 20px 0px;"
          @change="page = $event; getHolidayIncompatibilities()"
      />
    </div>

    <!-- Confirmación de eliminacion de una incompatibilidad -->
    <SharedDialog
        v-if="deleteDialogShown"
        :title="'Eliminar Incompatibilidad'"
        :message="'¿Desea eliminar permanentemente esta incompatibilidad de '+ selectedHolidayIncompatibilityText + '?'"
        :close-icon="false"
        @confirm="deleteSelectedHolidayIncompatibility(selectedHolidayIncompatibility, selectedEmployee)"
        @decline="closeStatusConfirmationModal()"
    />

    <!-- Modal de configuración de incompatibilidad ########################################################################################################-->
    <el-dialog
        v-model="showModalConfigIncompatibility"
        @closed="closeModalConfigHolidayIncompatibility()"
        :show-close="false"
        size="'medium'"
        width="890"
        :center="true"
        :lock-scroll="true"
        :style="{ borderRadius: `var(--el-border-radius-round)` }"
        >
        <div class="my-header">
        <svg
            id="asterisk"
            xmlns="http://www.w3.org/2000/svg"
            width="724"
            height="1024"
            viewBox="0 0 724 1024"
        >
          <path
              d="M0 499.8l59-181.7 332.5 108.6-59 180.8-332.5-107.7zM79.6 744.3l206.1-282.9 154.5 111.5-206.1 283.8-154.5-112.4zM266.9 167.3h191.1v350.3h-191.1v-350.3zM285.6 572.9l154.5-111.5 206.1 282.9-154.5 112.4-206.1-283.8zM332.5 426.8l332.5-108.6 59 181.7-331.6 107.7-59.9-180.8z"
          />
        </svg>
        <span class="text-header ml-5 mt-3">{{ isUpdateHoliday ? 'Actualizar ' : 'Nueva '}}Incompatibilidad</span>
        <img
            class="close-icon pointer"
            src="@/assets/images/aspa_blue_small_entrada.png"
            alt=""
            @click="closeModalConfigHolidayIncompatibility()"
        >
      </div>
      <div class="my-body">
        <div class="row item-modal">
          <div class="col-sm-12">
            <label class="modal-config-label">Configurador</label>
          </div>
        </div>


        <el-form
            ref="newHolidayIncompatibilityForm"
            class="newHolidayIncompatibilityForm"
            :model="selectedHolidayIncompatibility"
            name="newHolidayIncompatibilityForm"
            :rules="newHolidayIncompatibilityRules"
            status-icon
            @submit.prevent
        >
          <div class="row item-modal">
            <div class="col-sm-12 form-item">
              <el-input
                  v-model="selectedHolidayIncompatibility.name"
                  :label="'Nombre:'"
                  :color="'light'"
                  :param="'name'"
                  :placeholder="'Nombre'"
              />
            </div>
          </div>

          <div class="row item-modal">
            <div class="col-sm-12 form-item">
              <el-input
                  v-model.number="selectedHolidayIncompatibility.minNumberEmployees"
                  :label="'Número mínimo de empleados:'"
                  :type="'number'"
                  :color="'light'"
                  :param="'minNumberEmployees'"
                  :placeholder="'0'"
              />
            </div>
          </div>

          <div class="row item-modal">
            <div class="col-sm-12 form-item">
              <!-- col-sm-6 -->
              <label class="modal-add-label">Añadir empleados</label>
              <font-awesome-icon
                  class="btn_icon modal-add-employee-icon"
                  icon="plus-circle"
                  title="Añadir"
                  @click="addEmployeeFromConfigModal()"
              />
            </div>
            <!--
            <div class="col-sm-6">
                <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="incompatibility-active"
                        :checked="selectedHolidayIncompatibility.active ? true : false">
                    <label class="custom-control-label" for="incompatibility-active">Incompatibilidad activa</label>
                </div>
            </div>
            -->
          </div>

          <div class="row item-modal">
            <div class="col-sm-12">
              <div class="table">
                <div
                    v-if="selectedHolidayIncompatibility.employees && selectedHolidayIncompatibility.employees.length > 0"
                >
                  <table>
                    <thead>
                    <tr>
                      <th>Nombre</th>
                      <th />
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="employee in selectedHolidayIncompatibility.employees"
                        :key="employee.id"
                    >
                      <td>
                        {{ employee.name }} {{ employee.lastName }}
                      </td>
                      <td>
                        <font-awesome-icon
                            class="btn_icon modal-remove-employee-icon"
                            icon="minus-circle"
                            title="Eliminar"
                            @click="deleteEmployeeFromConfigModal(selectedHolidayIncompatibility, employee)"
                        />
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div v-else>
                  <label class="mt-1">No hay empleados todavía...</label>
                </div>
              </div>
            </div>
          </div>

          <div class="row item-modal offset-md-3">
            <div class="col-sm-4">
              <button
                  class="btn btn-block btn_red"
                  type="button"
                  @click="closeModalConfigHolidayIncompatibility()"
              >
                Cancelar
              </button>
            </div>
            <div class="col-sm-4">
              <button
                  class="btn btn_blue"
                  type="button"
                  @click="requestSaveConfigHolidayIncompatibility(selectedHolidayIncompatibility)"
              >
                Aceptar
              </button>
            </div>
          </div>
        </el-form>
      </div>
    </el-dialog>

    <!-- Modal Añadir Miembros a la incompatibilidad -->
    <el-dialog
        v-model="showModalEmployees"
        :title="'Añadir empleados'"
        :close-button-in-header="true"
    >
        <div>
          <div class="row modal-item">
            <div class="col-md-9 form-item">
              <el-input
                  v-model="searchNameModalEmployees"
                  label="Nombre"
                  placeholder="Nombre"
                  @change="filterEmployeesByName($event)"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="table">
                <div v-if="allEmployeesList && allEmployeesList.length > 0">
                  <table>
                    <thead>
                    <tr>
                      <th>Nombre</th>
                      <th>Departamento</th>
                      <th>Puesto</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="employee in allEmployeesList"
                        :key="employee.id"
                        class="pointer"
                        :class="{ selected: isEmployeeSelected(employee) }"
                        @click="addEmployeeToConfigIncompatibility(employee)"
                    >
                      <td>
                        {{ employee.name }} {{ employee.lastName }}
                      </td>
                      <td>
                        {{ employee.job ? employee.job.name : null }}
                      </td>
                      <td>
                        {{
                          employee.job && employee.job.department ? employee.job.department.name
                              : '--'
                        }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <SharedPagination
                      v-if="allEmployeesList && allEmployeesListTotal > itemsPerPage"
                      class="pagination"
                      :page="page"
                      :total-results="allEmployeesListTotal"
                      :page-size="itemsPerPage"
                      style="padding: 0px 0px 20px 0px;"
                      @change="page = $event; getEmployees();"
                  />
                </div>
                <div v-else>
                  <label>No hay empleados disponibles</label>
                </div>
              </div>
            </div>
          </div>


          <!--
          <div>
              <br/>
              <div class="row item">
                  <div class="col">
                      <button class="btn btn-block btn_red" @click="closeModalEmployees()">
                          Cancelar
                      </button>
                  </div>
                  <div class="col">

                      <button class="btn btn_blue">
                          Aceptar
                      </button>
                  </div>
              </div>
          </div>
          -->
        </div>
    </el-dialog>
  </div>
</template>

<script>

// Components
import SharedPagination from "@/components/shared/SharedPagination.vue";
import SharedDialog from "@/components/shared/SharedDialog.vue";
//import SharedDropdown from "@/components/shared/SharedDropdown.vue";
import HeaderTitle from "@/components/layout/HeaderTitle";

// Services
import HolidayIncompatibleService from '@/api/services/holidayIncompatibility.service';
import EmployeeService from '@/api/services/employee.service';

import Notification from '@/utils/plugins/NotificationService'
import { ElNotification } from "element-plus";


export default {
  name: "HolidayIncompatibilities",
  components: {
    HeaderTitle,
    SharedPagination: SharedPagination,
    SharedDialog: SharedDialog,
    //SharedDropdown,
  },
  data() {
    const nameRule = (rule, value, callback) => {
      if (!value) {
        this.newHolidayIncompatibilityRules.name.error = true;
        callback(new Error("Debe especificar un nombre"));
      } else {
        this.newHolidayIncompatibilityRules.name.error = false;
        callback();
      }
    };
    const minNumberEmployeesRule = (rule, value, callback) => {
      // TODO: verificar la validación de este número
      if (value == null || value < 0) {
        this.newHolidayIncompatibilityRules.minNumberEmployees.error = true;
        callback(new Error("Debe especificar un mínimo de empleados válido"));
      } else {
        this.newHolidayIncompatibilityRules.minNumberEmployees.error = false;
        callback();
      }
    };
    const employeesRule = (rule, value, callback) => {
      if (!value || value.length <= 0) {
        this.newHolidayIncompatibilityRules.employees.error = true;
        callback(new Error("Debe seleccionar al menos un empleado"));
      } else {
        this.newHolidayIncompatibilityRules.employees.error = false;
        callback();
      }
    };

    return {
      holidayIncompatibility: {
        name: '',
        minNumberEmployees: 0,
        employees: [],
      },
      holidayIncompatibilities: [],
      holidayIncompatibilityName: '',
      employeeName: '',
      params: {},
      page: 1,
      itemsPerPage: 10,
      totalResults: null,
      searchName: '',
      deleteDialogShown: false,
      isUpdateHoliday: false,
      dataSynchronized: false,
      newHolidayIncompatibilityRules: {
        name: {
          required: true,
          validator: nameRule,
          error: false,
          trigger: "blur"
        },
        minNumberEmployees: {
          required: true,
          validator: minNumberEmployeesRule,
          error: false,
          trigger: "blur"
        },
        employees: {
          required: true,
          validator: employeesRule,
          error: false,
          trigger: "blur"
        },
      },
      selectedHolidayIncompatibility: {
        name: '',
        minNumberEmployees: 0,
        employees: [],
      },
      selectedEmployee: null,
      selectedHolidayIncompatibilityText: '',

      /* Estado de una incompatibiliodad
      statusOptions: [
          {id: 0, name: 'Activa', value: 1},
          {id: 1, name: 'Inactiva', value: 0}
      ],
      selectedStatusFilter:  {id: 0, name: 'Activa', value: 1},
      */

      // Modal de cofiguración/nueva incompatibilidad
      showModalConfigIncompatibility: false,
      // Modal de añadir empleados a la incompatibilidad
      showModalEmployees: false,
      searchNameModalEmployees: '',
      allEmployeesList: [],
      allEmployeesListTotal: 0,
    }
  },
  mounted() {
    this.getHolidayIncompatibilities();
  },
  methods: {
    async getHolidayIncompatibilities(filters) {
      this.params = {
        ...this.params,
        filter: filters,
        page: this.page,
        itemsPerPage: this.itemsPerPage
      };
      // this.$store.state.loading = true;
      this.$store.commit('startLoading');
      this.dataSynchronized = false;
      await HolidayIncompatibleService.getHolidayIncompatibilities(this.params)
          .then((res) => {
            this.holidayIncompatibilities = res.data['hydra:member'];
            this.totalResults = res.data['hydra:totalItems'];
          })
          .catch(() => {
            Notification.addNotification('Error al obtener las incompatibilidades', 'error', 6000);
          })
      // this.$store.state.loading = false;
      this.$store.commit('stopLoading');
      this.dataSynchronized = true;
    },
    filterHolidayIncompatibilityByName(name) {
      if (name && name.length > 0) {
        name = name.toLowerCase();
        this.params = {
          ...this.params,
          name: name,
        };
      } else {
        delete this.params.name;
      }
      this.getHolidayIncompatibilities();
    },
    filterHolidayIncompatibilityByEmployeeName(name) {
      if (name && name.length > 0) {
        name = name.toLowerCase();
        this.params = {
          ...this.params,
          orSearch_fullName: name,
        };
      } else {
        delete this.params.orSearch_fullName;
      }
      this.getHolidayIncompatibilities();

    },
    deleteDialog(holidayIncompatibility, employee) {
      this.selectedHolidayIncompatibility = holidayIncompatibility;
      this.selectedEmployee = employee;
      this.deleteDialogShown = true;
      this.selectedHolidayIncompatibilityText = this.selectedHolidayIncompatibility.name + ' ' + this.selectedEmployee.name + ' ' + this.selectedEmployee.lastName;
      // this.deleteHolidayIncompatibility(holidayIncompatibility, employee);
    },
    deleteSelectedHolidayIncompatibility(holidayIncompatibility, employee) {
      if (this.selectedHolidayIncompatibility.employees.length === 1) {
        this.deleteHolidayIncompatibility(this.selectedHolidayIncompatibility);
      } else {
        let employees = holidayIncompatibility.employees.filter(function (emp) {
          return emp['@id'] !== employee['@id'];
        })

        holidayIncompatibility.employees = employees;
        this.saveHolidayIncompatibility(holidayIncompatibility);
      }
    },
    async deleteHolidayIncompatibility(holidayIncompatibility) {
      // this.$store.state.loading = true;
      this.$store.commit('startLoading');
      this.holidayIncompatibilities = []
      await HolidayIncompatibleService.deleteHolidayIncompatibility(holidayIncompatibility.id)
          .then(() => {
            Notification.addNotification('Incompatibilidad eliminada correctamente.', 'success', 6000);
          })
          .catch(() => {
            Notification.addNotification('Error al intentar eliminar la incompatibilidad seleccionado.', 'success', 6000);
          })
      // this.$store.state.loading = false;
      this.$store.commit('stopLoading');
      this.selectedHolidayIncompatibility = {};
      this.selectedEmployee = null;
      this.deleteDialogShown = false;
      this.getHolidayIncompatibilities();
    },
    async updateHolidayIncompatibility(holidayIncompatibility) {
      // this.$store.state.loading = true;
      this.$store.commit('startLoading');
      await HolidayIncompatibleService.updateHolidayIncompatibility(holidayIncompatibility, holidayIncompatibility.id)
          .then(() => {
            Notification.addNotification('Incompatibilidad actualizada correctamente', 'success', 6000);
            this.selectedHolidayIncompatibility = {};
            this.selectedEmployee = {};
            this.deleteDialogShown = false;
          })
          .catch(() => {
            Notification.addNotification('Error al actualizar la incompatibilidad', 'error', 6000);
          });
      // this.$store.state.loading = false;
      this.$store.commit('stopLoading');
      await this.getHolidayIncompatibilities();

    },
    async createHolidayIncompatible(holidayIncompatibility) {
      // this.$store.state.loading = true;
      this.$store.commit('startLoading');
      await HolidayIncompatibleService.createHolidayIncompatibility(holidayIncompatibility)
          .then(() => {
            Notification.addNotification('Incompatibilidad creada correctamente', 'success', 6000);
          })
          .catch(() => {
            Notification.addNotification('Error al crear la incompatibilidad', 'error', 6000);
          });
      // this.$store.state.loading = false;
      this.$store.commit('stopLoading');
      await this.getHolidayIncompatibilities();
    },
    openModalConfigHolidayIncompatibility(holidayIncompatibility) {
      if (holidayIncompatibility && holidayIncompatibility.id) {
        this.selectedHolidayIncompatibility = holidayIncompatibility;
        this.isUpdateHoliday = true;
      } else {
        this.selectedHolidayIncompatibility = {
          name: '',
          minNumberEmployees: 0,
          employees: [],
        }
      }

      this.showModalConfigIncompatibility = true;
    },
    closeModalConfigHolidayIncompatibility() {
      this.selectedHolidayIncompatibility = {};
      this.showModalConfigIncompatibility = false;
      this.isUpdateHoliday = false;

      this.newHolidayIncompatibilityRules.name.error = false;
      this.newHolidayIncompatibilityRules.minNumberEmployees.error = false;
      this.newHolidayIncompatibilityRules.employees.error = false;
      this.getHolidayIncompatibilities();
    },
    requestSaveConfigHolidayIncompatibility(holidayIncompatible) {
      this.saveHolidayIncompatibilityWithFormValidation(holidayIncompatible);
    },
    addEmployeeFromConfigModal() {
      this.openModalEmployees();
    },
    deleteEmployeeFromConfigModal(selectedHolidayIncompatibility, employee) {
      this.selectedHolidayIncompatibility.employees = selectedHolidayIncompatibility.employees.filter(function (emp) {
        return emp['@id'] !== employee['@id'];
      });
    },
    async openModalEmployees() {
      await this.getEmployees();
      this.showModalEmployees = true;
    },
    closeModalEmployees() {
      this.showModalEmployees = false;
    },
    isEmployeeSelected(itemSelected) {
      if (this.selectedHolidayIncompatibility.employees) {
        return this.selectedHolidayIncompatibility.employees.some(member => member.id === itemSelected.id);
      }
      return false;
    },
    addEmployeeToConfigIncompatibility(employee) {
      if (this.selectedHolidayIncompatibility != null) {
        if (this.selectedHolidayIncompatibility.employees) {
          let existMember = this.selectedHolidayIncompatibility.employees.findIndex(member => member.id === employee.id);
          if (existMember == -1) {
            this.selectedHolidayIncompatibility.employees.push(employee);
            ElNotification({
              type: 'success',
              message: 'Empleado añadido.',
              duration: 6000
            })
          } else {
            this.selectedHolidayIncompatibility.employees.splice(existMember, 1);
            ElNotification({
              type: 'warning',
              message: 'Empleado eliminado',
              duration: 6000
            })
          }
        } else {
          this.selectedHolidayIncompatibility.employees.push(employee);
          ElNotification({
              type: 'success',
              message: 'Empleado añadido',
              duration: 6000
            })
        }
      }
    },
    filterEmployeesByName(name) {
      delete this.params.name;
      if (name && name.length > 0) {
        name = name.toLowerCase();

        this.params = {
          ...this.params,
          orSearch_fullName: name
        };
      } else {
        delete this.params['orSearch_fullName'];
      }

      this.page = 1;
      this.getEmployees();
      delete this.params.name;
    },
    getEmployees() {
      delete this.params.name;
      this.params = {
        ...this.params,
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        active: true
      };
      // this.$store.state.loading = true;
      this.$store.commit('startLoading');
      EmployeeService.getEmployees(this.params)
          .then((res) => {
            // this.$store.state.loading = false;
            this.$store.commit('stopLoading');
            this.allEmployeesList = res.data['hydra:member'];
            this.allEmployeesListTotal = res.data['hydra:totalItems'];
          })
          .catch(() => {
            // this.$store.state.loading = false;
            this.$store.commit('stopLoading');
            Notification.addNotification('Error al obtener los empleados', 'error', 6000);
          });
    },
    saveHolidayIncompatibilityWithFormValidation(holidayIncompatibility) {
      this.$refs["newHolidayIncompatibilityForm"].validate(valid => {
        if (valid) {
          this.saveHolidayIncompatibility(holidayIncompatibility);
        } else {
          return false;
        }
      })
    },
    saveHolidayIncompatibility(holidayIncompatibility) {
      if (holidayIncompatibility.id) {
        let employees = [];
        holidayIncompatibility.employees.forEach(employee => {
          employees.push(employee['@id']);
        });
        holidayIncompatibility.employees = employees;
        this.dataSynchronized = false;
        this.updateHolidayIncompatibility(holidayIncompatibility);
      } else {
        let employees = [];
        holidayIncompatibility.employees.forEach(employee => {
          employees.push(employee['@id']);
        });
        holidayIncompatibility.employees = employees;
        this.createHolidayIncompatible(holidayIncompatibility);
      }
      this.closeModalConfigHolidayIncompatibility();
    },
    search(){
      this.params.name = this.holidayIncompatibilityName
          ? this.holidayIncompatibilityName.toLowerCase() : null;
      this.params.orSearch_fullName = this.employeeName
          ? this.employeeName.toLowerCase() : null;
      // Perform the search with the parameters
      this.getHolidayIncompatibilities();
    },
    closeStatusConfirmationModal(){
      this.deleteDialogShown = false; 
      this.selectedHolidayIncompatibility = {};
      this.selectedEmployee = null;
    }
  }
}
</script>

<style lang="scss" scoped>

.holiday-incompatibilities {
  .label-holiday-incompatibility {
    color: #434343;
    float: left;
    display: block;
    white-space: nowrap;

  }
}


.table-holiday-incompatibilities {
  padding-top: 40px;
  /*display: inline-block;*/
  table {

    width: 100%;
    color: #858796;

    th {
      border: 1px solid #e3e6f0;
      padding: 12px 12px 12px 20px;
      font-size: 20px;
      text-align: left;
    }

    tbody {

      tr:nth-child(odd) {
        td {
          background-color: rgba(0, 0, 0, .05);
        }
      }

      td {
        border: 1px solid #e3e6f0;
        padding: 12px;
        padding-left: 20px;
        background-color: white;
        text-align: left;

        .link-name {
          margin: auto;
          margin-left: 10px;
        }

        a {
          font-size: 18px;
          color: #4e73df;
        }
      }
    }
  }
}


.modal-config-label {
  font-size: 20px;
  font-weight: bold;
}

.item-modal {
  margin-top: 5px;
}

.form-item {
  margin-bottom: 5px !important;
}

.el-form-item {
  margin-bottom: 10px !important;
}

.modal-add-employee-icon {
  margin-left: 20px;
  color: green;
}

.modal-remove-employee-icon {
  color: red;
}

.modal-table-incompatibilities {
  margin-bottom: 40px;

  table {
    width: 100%;
    color: #858796;

    th {
      border: 1px solid #e3e6f0;
      padding: 12px 12px 12px 20px;
      font-size: 20px;
      text-align: left;
    }

    tbody {

      tr:nth-child(odd) {
        td {
          background-color: rgba(0, 0, 0, .05);
        }
      }

      td {
        border: 1px solid #e3e6f0;
        padding: 12px;
        padding-left: 20px;
        background-color: white;
        text-align: left;
      }
    }
  }
}

.modal-table-employees {
  padding-top: 10px;

  table {
    width: 100%;
    color: #858796;

    th {
      border: 1px solid #e3e6f0;
      padding: 12px;
      padding-left: 20px;
      font-size: 20px;
      text-align: left;
    }

    tbody {
      .selected {
        background-color: rgba(0, 0, 0, .06);

        td {
          background-color: rgba(0, 0, 0, .06);
        }
      }

      tr:nth-child(odd) {
        td {
          background-color: rgba(0, 0, 0, .05);
        }
      }

      td {
        border: 1px solid #e3e6f0;
        padding: 12px;
        padding-left: 20px;
        text-align: left;

        a {
          font-size: 18px;
          color: #4e73df;
        }
      }
    }
  }
}

.custom-checkbox {
  text-align: end;
}

.error-validation {
  border: 2px solid #F56C6C;
}

.el-dialog{
  background: #f3f0fb !important;
}

.el-dialog__body {
  background: #f3f0fb !important;
  min-height: 800px !important;

  .el-dialog__header {
    padding: 0;
  }

  .my-header {
    margin-top: 2px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .text-header {
      text-align: left;
      font-size: 4em;
      font-weight: 900;
      letter-spacing: -0.05em;
      color: #f9156f;
    }

    .close-icon {
      width: 8%;
      height: 20%;
    }

    .inter-icon {
      width: 2%;
      height: 2%;
    }

    #asterisk {
      position: absolute;
      vertical-align: super;
      height: auto;
      width: 2.2em;
      fill: #f9156f;
    }

  }

  .my-body{
    margin-right: 5%;
  }

}
</style>
