<script setup>
/* eslint-disable */
import HeaderTitle from "@/components/layout/HeaderTitle.vue";
import {onMounted, ref} from "vue";
import ProjectsService from "@/api/services/projects.service";
import ClientServices from "@/api/services/clients";
import store from "@/app/store/store";
import notification from '@/utils/plugins/NotificationService'
import router from "@/app/router/router";
import SharedButtonPlus from "@/components/shared/SharedButtonPlus.vue";
import Notification from "@/utils/plugins/NotificationService";
import UsersExtranetService from "@/api/services/usersExtranet";
import {ElMessageBox} from "element-plus";
import {useRoute} from "vue-router";


const route = useRoute();
const extranetUsers = ref([]);
const user = ref({
  name:'',
  lastName:'',
  email:'',
  roles:null
})
const resetUser = () => {
  user.value = {
      name:'',
      lastName:'',
      email:'',
      roles: null
  }
}
const client = ref({
  phone:'',
  contactPersonName:'',
  name:'',
  email:'',
  tag:''
})
const optionsClient = ref([]);
const optionsProject = ref([]);
const optionsRoles = ref([])
const dialogVisible = ref(false);
const getRoles = async () => {
  const response = await UsersExtranetService.getExtranetRolesUsers();
  optionsRoles.value = response.data["hydra:member"];
}
const getClients = async () =>{
  const response = await ProjectsService.getClients();
  optionsClient.value = response.data["hydra:member"];
}
const getProjects = async () => {
  let params = {
    archived: false,
    itemsPerPage:99999,
    page:1,
    'order[initialDate]': 'DESC'
  }
  await ProjectsService.getProjects(params).then((response) => {
    optionsProject.value = response.data["hydra:member"];
  });

}
const getClient = async () => {
  const id = route.params.id;
  await ClientServices.getClient(id).then((response) => {
    client.value = response.data;
  });
}
const loadExtranetUsers = async () => {
  const id = route.params.id;
  const response = await ClientServices.getExtranetUsers(id);
  extranetUsers.value = response.data["hydra:member"].filter(v=>v.active===true);

 extranetUsers.value.forEach(us=>{
    us.roles = optionsRoles.value.filter(v=>us.roles.includes(v.roleName));
  })



}
onMounted(async ()=>{
  // store.state.loading = true;
  store.commit('startLoading');
  await Promise.all([getClients(),getProjects(),getRoles()]);
  await getClient();
  await loadExtranetUsers();
  // store.state.loading = false;
  store.commit('stopLoading');
})
const onSave = async ()=> {

  if (!client.value.email) {
    store.state.loading = false;
    Notification.addNotification('No se pudo completar el proceso, ha de rellenar todos los campos marcados y requeridos', 'warning', 6000);
    return;
  }
  // store.state.loading = true;
  store.commit('startLoading');
  const id = route.params.id;
  delete client.value.projects;
  delete client.value.cif;
  client.value.phone = client.value.phone.toString();
  if (!client.value.contactPersonName) delete client.value.contactPersonName;
  delete client.value.extranetUsers;

  try {
    await ClientServices.editClient(id,client.value);
    notification.addNotification("Datos guardados correctamente","success",10000)
  }catch (e){
    Notification.addNotification('No se pudo completar el proceso, ocurrió un error', 'warning', 6000);
  }

  // store.state.loading = false;
  store.commit('stopLoading');

}
const openDialog = () => {

  dialogVisible.value = true;

}
const desactiveUsuario = async (userToDelete) => {
  ElMessageBox.confirm(
      'Esta seguro que desea continuar?',
      'Warning',
      {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      }
  )
      .then(async () => {
        const id = route.params.id;
        const extUser = JSON.parse(JSON.stringify(userToDelete));
        extUser.client = `/api/clients/${id}`;
        extUser.active = false;
        delete  extUser.roles;
        await ClientServices.updateUserExtranet(extUser.id,extUser);
        await loadExtranetUsers();
        notification.addNotification("Usuario dado de baja correctamente","success",10000)
      })
      .catch(() => {

      })

}

const saveUser = async () => {

  // store.state.loading = true;
  store.commit('startLoading');
  const id = route.params.id;
  const extUser = JSON.parse(JSON.stringify(user.value));
  extUser.client = `/api/clients/${id}`;
  extUser.roles = [user.value.roles['@id']]
  if(!extUser.id){
    await ClientServices.createUserExtranet(extUser);
  }else {
    await ClientServices.updateUserExtranet(extUser.id,extUser);
  }
  await loadExtranetUsers();
  resetUser();
  // store.state.loading = false;
  store.commit('stopLoading');
  dialogVisible.value = false;


}
const showRoles = (roles)=>{
  const rolesShow = JSON.parse(JSON.stringify(roles));
  return rolesShow.map(v=>{
    if( v.roleName==="ROLE_CUSTOMER_ADMIN") return "ADMINISTRADOR";
    if( v.roleName==="ROLE_CUSTOMER") return "USUARIO";
  }).join(',')
}
const editUser = (userToEdit)=>{
  // console.log("USerrrr",userToEdit);
   user.value = userToEdit;

   dialogVisible.value = true;
}
</script>

<template>
  <div>
    <HeaderTitle title="Producción" />
    <div class="d-flex flex-row justify-content-start align-items-center my-2">
      <svg
        id="icon-search"
        class="search-icon"
        width="44"
        height="37"
        viewBox="0 0 44 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.15269 30.0573H20.8415V34.6802H11.5851C10.8865 34.6802 10.4324 35.1448 10.4324 35.8301C10.4324 36.527 10.8981 36.98 11.5851 36.98H32.4266C33.1252 36.98 33.5793 36.5154 33.5793 35.8301C33.5793 35.1332 33.1135 34.6802 32.4266 34.6802H23.1585V30.0573H42.8473C43.5459 30.0573 44 29.5927 44 28.9074V1.16994C44 0.473018 43.5343 0.0200195 42.8473 0.0200195H1.15269C0.465732 0.0200195 0 0.484633 0 1.16994V28.8958C0 29.5927 0.465732 30.0573 1.15269 30.0573ZM2.31702 2.33147H41.683V27.7458H2.31702V2.33147Z"
          fill="#474CE6"
        />
        <path
          d="M38.2133 4.64294H5.78672C5.08812 4.64294 4.63403 5.10756 4.63403 5.79286V24.2729C4.63403 24.9698 5.09977 25.4228 5.78672 25.4228H38.2133C38.9119 25.4228 39.366 24.9582 39.366 24.2729V5.79286C39.366 5.10756 38.9119 4.64294 38.2133 4.64294ZM37.049 23.123H6.95105V6.9544H37.0606V23.123H37.049Z"
          fill="#474CE6"
        />
      </svg>

      <div class="title-text ml-4">
        Clientes
      </div>
    </div>
    <el-row>
      <el-card
        class="card-search"
        body-style="padding:0"
      >
        <div class="row">
          <div class="col-md-4 d-flex flex-column justify-content-start">
            <div class="d-flex flex-row w-100">
              <div class="d-flex flex-nowrap flex-column mt-4 ml-4 w-100 label-common">
                <label
                  for="client.id"
                  class=""
                >Cliente</label>
                <el-input
                  id="phone"
                  v-model="client.name"
                  class="search-input"
                  :rules="{ required: true }"
                  :color="'light'"
                  :placeholder="'Ingrese el nombre'"
                />
              </div>
            </div>
          </div>
          <div class="col-md-4 d-flex flex-column justify-content-start">
            <div class="d-flex flex-row">
              <div class="d-flex flex-nowrap flex-column mt-4 ml-4 w-100 label-common">
                <label
                  for="client.id"
                  class=""
                >Persona de contacto</label>
                <el-input
                  id="phone"
                  v-model="client.contactPersonName"
                  class="search-input"
                  :color="'light'"
                  :placeholder="'Ingrese el contacto'"
                />
              </div>
            </div>
          </div>
          <div class="col-md-4 d-flex flex-column justify-content-start">
            <div class="d-flex flex-row">
              <div class="d-flex flex-nowrap flex-column mt-4 ml-4 w-100 label-common">
                <label
                  for="client.id"
                  class=""
                >Teléfono</label>
                <el-input
                  id="phone"
                  v-model="client.phone"
                  class="search-input"
                  type="number"
                  :rules="{ min: 9, max:13 }"

                  :color="'light'"
                  :placeholder="'Ingrese el número'"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 d-flex flex-column justify-content-start">
            <div class="d-flex flex-row">
              <div class="d-flex flex-nowrap flex-column mt-4 ml-4 w-100 label-common">
                <label
                  for="client.id"
                  class=""
                >Email</label>
                <el-input
                  id="phone"
                  v-model="client.email"
                  class="search-input"
                  :rules="{ required: true }"
                  :color="'light'"
                  :placeholder="'Ingrese el email'"
                />
              </div>
            </div>
          </div>
          <div class="col-md-4 d-flex flex-column justify-content-start">
            <div class="d-flex flex-row">
              <div class="d-flex flex-nowrap flex-column mt-4 ml-4 w-100 label-common">
                <label
                  for="client.id"
                  class=""
                >Etiqueta</label>
                <el-input
                  id="tag"
                  v-model="client.tag"
                  class="search-input"
                  :rules="{ max:5 }"

                  :color="'light'"
                  :placeholder="'Ingrese una etiqueta'"
                />
              </div>
            </div>
          </div>
          <div class="col-md-4 d-flex flex-column justify-content-end  align-items-center  ">
            <button
              class="btn btn_darkpink search w-50"
              @click="onSave"
            >
              GUARDAR
            </button>
          </div>
        </div>
      </el-card>
    </el-row>
    <el-row>
      <div class="d-flex flex-row justify-content-start align-items-center my-2">
        <img src="@/assets/icons/pc-icon.svg" class=" mx-2" width="30" height="30"  alt="name-icon" />

        <div class="title-text ml-4">
          Usuarios Extranet
        </div>
      </div>
    </el-row>
    <el-row>
      <div class="top-line" />
      <div
        v-for="user_extranet in extranetUsers"
        :key="user_extranet.id"
        class="list-user py-2"
      >
        <div class="row">
          <div class="col-md-4 d-flex flex-row justify-content-start align-items-center">
            <img src="@/assets/icons/envelop-icon.svg" class=" mx-2" width="25" height="25"  alt="name-icon" />
            <span class="mt-2 ml-1">{{ user_extranet.email }}</span>
          </div>
          <div class="col-md-4 d-flex flex-row justify-content-start align-items-center">
            <img src="@/assets/icons/user.svg" class=" mx-2" width="25" height="25"  alt="name-icon" />
            <span class="mt-2 ml-1">{{ user_extranet.name+' '+user_extranet.lastName }}</span>
          </div>
          <div class="col-md-2 d-flex flex-row text-primary align-items-center justify-content-start">
            <svg
              version="1.1"
              class="icon-user"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 595.3 841.9"
              style="enable-background: new 0 0 595.3 841.9"
              xml:space="preserve"
            >

              <path
                transform="matrix(1,0,0,1,-2,-0.2)"
                class="st0"
                d="M 265.6 725.7 c -27.1 0 -52.7 -5.5 -75.8 -16.2 c -23.3 -10.7 -45 -26.7 -64.3 -47.5 c -19.1 -20.5 -33.8 -44.1 -43.5 -69.6 c -9.7 -25.6 -14.5 -53.4 -14.5 -82.9 V 208.5 h 154.4 v 301.1 c 0.4 13.9 4.6 25.1 12.8 34 c 8.4 8.9 18.5 13.5 30.9 13.7 c 12.8 -0.5 23.1 -4.8 31.5 -13.9 c 8.4 -8.9 12.6 -20.1 12.8 -34 l 0 -210.7 l 154.4 -96.8 v 307.7 c 0 29.4 -4.8 57.3 -14.5 82.9 c -9.7 25.6 -24.2 49.1 -43.5 69.6 c -19.1 20.8 -40.8 36.7 -64.3 47.5 C 318.7 720.2 292.9 725.4 265.6 725.7 z M 84.3 226.7 v 282.8 c 0 27.2 4.4 52.7 13.2 76 c 8.8 23.3 22.1 44.5 39.7 63.5 c 17.9 19.2 37.8 33.8 59 43.6 c 21.2 9.8 44.5 14.6 69.3 14.6 c 25.2 0 48.7 -5 70.2 -14.6 c 21.4 -9.8 41.2 -24.4 58.8 -43.6 c 17.6 -18.9 30.9 -40.2 39.7 -63.5 c 8.8 -23.3 13.2 -48.8 13.2 -76 v -276.2 l -120.8 75.8 v 200.4 c -0.4 18.9 -6.5 34.5 -17.9 46.8 c -11.6 12.3 -26 18.7 -43.1 18.9 l -0.2 0 l -0.2 0 c -16.8 -0.5 -31.1 -6.8 -42.6 -18.9 c -11.3 -12.3 -17.4 -27.8 -17.9 -46.6 l 0 -0.2 V 226.7 H 84.3 z"
              />
            </svg>
            <span class="mt-2"> {{
              showRoles(user_extranet.roles)
            }}</span>
          </div>
          <div class="col-md-2 d-flex flex-row text-primary align-items-center justify-content-end">
            <font-awesome-layers
              class="fa-2x pointer"
              style="margin-left: 5px"
              title="Dar de baja"
              @click="desactiveUsuario(user_extranet)"
            >
              <font-awesome-icon
                icon="circle"
                style="color:red"
              />
              <font-awesome-icon
                icon="times"
                class="fa-inverse"
                transform="shrink-6"
              />
            </font-awesome-layers>
            <shared-button-plus
              title="Editar usuario"
              @onClick="editUser(user_extranet)"
            />
          </div>
        </div>
      </div>
    </el-row>
    <el-row class="d-flex flex-row justify-content-end">
      <div class="d-flex flex-row justify-content-end  mt-lg-5 w-75">
        <button
          class="btn btn_yellow search w-25 mr-5"
          @click="router.go(-1)"
        >
          VOLVER
        </button>
        <button
          class="btn btn_darkpink search w-25"
          @click="openDialog"
        >
          NUEVO USUARIO
        </button>
      </div>
    </el-row>
    <el-dialog
      v-model="dialogVisible"
      :show-close="false"
      :style="{ borderRadius: `var(--el-border-radius-round)` }"
    >
      <template #title>
        <div class="d-flex flex-row justify-content-start align-items-center my-2">
          <svg
            id="icon-search"
            class="search-icon"
            width="44"
            height="37"
            viewBox="0 0 44 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.15269 30.0573H20.8415V34.6802H11.5851C10.8865 34.6802 10.4324 35.1448 10.4324 35.8301C10.4324 36.527 10.8981 36.98 11.5851 36.98H32.4266C33.1252 36.98 33.5793 36.5154 33.5793 35.8301C33.5793 35.1332 33.1135 34.6802 32.4266 34.6802H23.1585V30.0573H42.8473C43.5459 30.0573 44 29.5927 44 28.9074V1.16994C44 0.473018 43.5343 0.0200195 42.8473 0.0200195H1.15269C0.465732 0.0200195 0 0.484633 0 1.16994V28.8958C0 29.5927 0.465732 30.0573 1.15269 30.0573ZM2.31702 2.33147H41.683V27.7458H2.31702V2.33147Z"
              fill="#474CE6"
            />
            <path
              d="M38.2133 4.64294H5.78672C5.08812 4.64294 4.63403 5.10756 4.63403 5.79286V24.2729C4.63403 24.9698 5.09977 25.4228 5.78672 25.4228H38.2133C38.9119 25.4228 39.366 24.9582 39.366 24.2729V5.79286C39.366 5.10756 38.9119 4.64294 38.2133 4.64294ZM37.049 23.123H6.95105V6.9544H37.0606V23.123H37.049Z"
              fill="#474CE6"
            />
          </svg>

          <div class="title-text ml-4 mr-auto">
            Nuevo ususario
          </div>


          <svg
            class="close-icon pointer"
            width="724"
            height="1024"
            viewBox="0 0 724 1024"
            xmlns="http://www.w3.org/2000/svg"
            @click="dialogVisible=false"
            style="width: 30px;  transform: translateY(-10px);"
          >
            <defs />
            <path d="M0 499.8l59-181.7 332.5 108.6-59 180.8-332.5-107.7zM79.6 744.3l206.1-282.9 154.5 111.5-206.1 283.8-154.5-112.4zM266.9 167.3h191.1v350.3h-191.1v-350.3zM285.6 572.9l154.5-111.5 206.1 282.9-154.5 112.4-206.1-283.8zM332.5 426.8l332.5-108.6 59 181.7-331.6 107.7-59.9-180.8z" />
          </svg>
        </div>
      </template>

      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6">
            <label for="nuser">Nombre usuario</label>
            <el-input
              id="nuser"
              v-model="user.name"
              :rules="{ required: true }"
              :color="'light'"
              :placeholder="'Ingrese el nombre'"
            />
          </div>
          <div class="col-md-6">
            <label for="lastname">Apellido</label>
            <el-input
              id="lastname"
              v-model="user.lastName"
              class="search-input"
              :rules="{ required: true }"
              :color="'light'"
              :placeholder="'Ingrese los apellidos'"
            />
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <label for="email">Email</label>
            <el-input
              id="email"
              v-model="user.email"
              :rules="{ required: true }"
              :color="'light'"
              :placeholder="'Ingrese el nombre'"
            />
          </div>
        </div>
        <div class="row row-cols-1 mt-2">
          <div class="col-md-12">
            <label
              for="role"
              class=""
            >Role</label>
            <el-select
                v-model="user.roles"
                size="small"
                value-key="@id"
            >
              <el-option
                  v-for="item in optionsRoles"
                  :key="item.id"
                  :label="item.name"
                  :value="item"
              />
            </el-select>
          </div>
        </div>
      </div>
      <el-row>
        <div class="d-flex justify-content-end align-items-center mt-5">
          <button
            class="btn btn_yellow search  mr-5"
            @click="dialogVisible=false;resetUser()"
          >
            CANCELAR
          </button>
          <button
            class="btn btn_darkpink search"
            @click="saveUser"
          >
            GUARDAR
          </button>
        </div>
      </el-row>
    </el-dialog>
  </div>
</template>

<style  lang="scss">
$--el-border-radius-round:10%;
.close-icon {
  width:6%;
  height: 6%;
  fill: $bg-blue;

}

.icon-email{
  fill: $bg-blue;
  width: 1.8em;
}
.icon-user{
  fill: $bg-blue;
  width: 1.4em;
}
.top-line {
  display: flex;
  width: 100%;

  border-bottom: 1px solid #474CE6;

}
.list-user {
  width: 100%;

  border-bottom: 1px solid #474CE6;

}
.card-search {
  border-radius: 45px;
  height: 320px;
  background: url('../../assets/icons/bg-edit-cliente.png'), white;
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: -2px;
  padding: 15px;
  background-size: 48%;
  overflow: visible !important;


}
#icon-search{
  fill:$bg-blue;
  width: 3%;

}
</style>
