<script setup>


import {ref, defineProps, onMounted} from "vue";

const props = defineProps({
  employee: {
    type: Object,
    required: true
  }
});
const  baseUrl = ref(process.env.VUE_APP_BASE_URI);
const showModalEmployeeImage = ref(false);
const existsPhoto = ref(true);

onMounted(()=>{
  if(props.employee.photo && props.employee.photo.contentUrl){
    try {
      if(!imageExists(baseUrl.value+props.employee.photo.contentUrl)){
        existsPhoto.value = false
      }
    } catch (error) {
      return;
    }
  }else{
    existsPhoto.value = false;
  }
})

const imageExists = (image_url) =>{
  var http = new XMLHttpRequest();
  http.open('HEAD', image_url, false);
  http.send();

  return http.status!='404';
}
</script>

<template>
  <div
      v-if="employee.photo && employee.photo.contentUrl && existsPhoto"
      class="media pointer"
  >
    <img
        class="rounded-circle img-thumbnail"
        :src="baseUrl + employee.photo.contentUrl"
        :alt="'Employee image'"
        @click="showModalEmployeeImage = true"
        :title="'Click para ampliar'"
    >
  </div>
  <div
      v-else
      class="media"
  >
    <img
        class="rounded-circle img-thumbnail"
        src="~@/assets/images/user.png"
        :alt="'Default Image'"
    >
  </div>
  <el-dialog
      width="30%"
      height="400px"
      v-model="showModalEmployeeImage"
      :title="employee.name + ' ' + employee.lastName"
      theme="secondary"
      size="small"
  >
    <div class="d-flex justify-content-center mx-auto">
      <img
          class="img-modal mx-auto"
          :src="baseUrl + employee.photo.contentUrl"
          :alt="'Employee image {{employee.name}} {{employee.lastName}}'"
      >
    </div>
  </el-dialog>
</template>

<style scoped lang="scss">
.media {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 50%;
  overflow: hidden;
  // aspect-ratio: 1/1;
  width: 56px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.img-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.img-modal {
  width: 325px;
}
.modal-image {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

</style>
