<script setup>
import HeaderTitle from "@/components/layout/HeaderTitle.vue";
import { nextTick, onMounted, onUnmounted, ref } from "vue";
import ProjectsService from "@/api/services/projects.service";
import TimeEntryTagService from "@/api/services/timeEntryTag.service";
import { useStore } from "vuex";
import { MoreFilled } from "@element-plus/icons-vue";
import TrackerService from "@/api/services/tracker.service";
import TrackerProjectInfo from "@/components/shared/TrackerProjectInfo.vue";
import moment from "moment";
import TrackerWeek from "@/components/shared/TrackerWeek.vue";
import TrackerCurrentEntry from "@/components/shared/TrackerCurrentEntry.vue";
import { ElNotification } from "element-plus";
import TrackerProjectSearch from "@/components/shared/TrackerProjectSearch.vue";

const store = useStore();
const PROJECT_API = "/api/projects/";
const TAGS_API = "/api/time_entry_tags/";

const timeEntry = ref({
  description: null,
  project: null,
  tags: [],
  timeStart: new Date(),
  timeEnd: new Date(),
  billable: true,
});
const page = ref(1);
const pageTimeEntries = ref(1);
const itemsPerPage = ref(9999);
const itemsPerPageTimeEntries = ref(50);
const totalEntries = ref(10);
const archived = ref(false);

const optionProjects = ref([]);
const optionTags = ref([]);
const entries = ref([]);
const currentEntry = ref(null);
const newDuration = ref(0);
const newDescription = ref(null)

const groupEntriesByWeek = ref([]);
const isWeek = ref(false);

const isManual = ref(false);
const manualEntry = ref({
  timeStart: new Date(),
  timeEnd: new Date(),
})
const refInputStartTime = ref();
const refInputEndTime = ref();
const inputStartTime = ref('');
const inputEndTime = ref('');
const daysDiff = ref(0)

const dropdownProject = ref(false);
const refDropdownProject = ref();
const refSelectTag = ref();

// Autocomplete Description
const refAutocompleteDescription = ref();
const entriesOptions = ref([]);

const setDuration = () => {
  const duration = parseInt(newDuration.value, 10);
  if (!isNaN(duration)) {
    store.commit('setEntryDuration', duration);
  }
}

const startInterval = () => store.dispatch('startInterval');
const stopInterval = () => store.dispatch('stopInterval');
const changeIsEntryActive = (isActive) => store.commit('setIsEntryActive', isActive);


onMounted(() => {
  // store.state.loading = true;
  store.commit('startLoading');
  isWeek.value = false;
  stopInterval();
  getProjects();
  getTags();
  getEntries();
  setManualTime();
  store.commit('stopLoading');
})

onUnmounted(() => {
  stopInterval();
})

const getEntries = () => {
  // store.state.loading = true;
  store.commit('startLoading');

  timeEntry.value.description = null;
  newDescription.value = '';
  timeEntry.value.billable = true;
  timeEntry.value.project = null;
  timeEntry.value.tags = [];
  timeEntry.value.timeEnd = null;
  timeEntry.value.timeStart = new Date();

  currentEntry.value = null;

  newDuration.value = 0;

  let params = {
    page: pageTimeEntries.value,
    itemsPerPage: itemsPerPageTimeEntries.value,
    'order[timeStart]': 'DESC'
  }

  let temp = 0;
  TrackerService.getEmployeeEntries(store.state.user.id, params).then((response) => {
    entries.value = response.data['hydra:member'];
    totalEntries.value = response.data['hydra:totalItems'];
    entries.value.map((entry) => {
      if (!entry.timeEnd) {
        currentEntry.value = entry;
        setTotalTime();
        let total = currentEntry.value.totalTime.hours * 3600;
        total += currentEntry.value.totalTime.minutes * 60;
        total += currentEntry.value.totalTime.seconds;
        currentEntry.value.duration = total;
        newDuration.value = currentEntry.value.duration;
        temp++;
        if (!store.state.isEntryActive) {
          startInterval();
        }
      }
    })
  }).finally(() => {
    setDuration();
    if (!store.state.isEntryActive && temp > 0) {
      changeIsEntryActive(true);
    }
    store.state.entryDuration = newDuration.value;
    groupEntriesByWeek.value = getGroupedEntriesByWeek();
    isWeek.value = true;
    // store.state.loading = false;
    store.commit('stopLoading');
    setManualTime();
  })
}

const setTotalTime = () => {
  currentEntry.value.totalTime = calcularTiempoTarea(currentEntry.value.timeStart);
}

const calcularTiempoTarea = (fecha) => {
  let time = new Date(fecha)
  const now = new Date();
  const diferenciaMs = now - time;
  if (diferenciaMs < 0) {
    throw new Error("La fecha proporcionada es  futura, no se puede calcular el tiempo transcurrido.");
  }
  const segundosTotales = Math.floor(diferenciaMs / 1000);
  const hours = Math.floor(segundosTotales / 3600);
  const minutes = Math.floor((segundosTotales % 3600) / 60);
  const seconds = segundosTotales % 60;
  return {
    hours,
    minutes,
    seconds,
  };
}

// Getters Projects and Tags

const getProjects = () => {
  let params = {
    page: page.value,
    itemsPerPage: itemsPerPage.value,
    archived: archived.value,
    'order[initialDate]': 'DESC'
  }
  // store.state.loading = true;
  store.commit('startLoading');
  ProjectsService.getProjects(params)
    .then((response) => {
      optionProjects.value = response.data['hydra:member'];
    })
    .catch((error) => {
      console.log(error);
      ElNotification({
        message: "Error al cargar los proyectos",
        type: "error",
        duration: 6000
      });
    })
    .finally(() => {
      // store.state.loading = false;
      store.commit('stopLoading');
    });
}

const getTags = () => {
  let params = {
    page: page.value,
    itemsPerPage: itemsPerPage.value,
  }
  params['order[name]'] = 'ASC';
  // store.state.loading = true;
  store.commit('startLoading');
  TimeEntryTagService.getTags(params)
    .then((response) => {
      optionTags.value = response.data['hydra:member'];
    })
    .catch((error) => {
      console.log(error);
      Notification.addNotification(
        "Error al cargar las etiquetas"
      );
    })
    .finally(() => {
      // store.state.loading = false;
      store.commit('stopLoading');
    });

}

// Function Save Entry

const saveTimeEntry = () => {
  let tags = [];
  for (let i = 0; i < timeEntry.value.tags.length; i++) {
    const tag = timeEntry.value.tags[i];
    tags.push(TAGS_API + tag.id)
  }

  if (tags.length < 1 || timeEntry.value.project === null || timeEntry.value.description == '' || timeEntry.value.description == null) {
    let mssg = "No se ha podido cerrar la tarea, falta: ";
    const arrayErr = [];
    if (timeEntry.value.description == '' || timeEntry.value.description == null) arrayErr.push('descripcion')
    if (timeEntry.value.project === null) arrayErr.push('proyecto')
    if (tags.length < 1) arrayErr.push('etiquetas')
    for (let i = 0; i < arrayErr.length; i++) {
      if(i==0){
        mssg = mssg + arrayErr[0];
      }else{
        mssg = mssg + ', ' +arrayErr[i];
      }
    }
    ElNotification({
      message: mssg,
      type: "error",
      duration: 6000
    })
    return;
  }

  if (timeEntry.value.description.length < 5) {
    ElNotification({
      message: "La descripción tiene que contener mas de 5 caracteres",
      type: "error",
      duration: 6000
    })
    return;
  }
  // Hacer comprobación para empezar la tarea vacia

  const project = timeEntry.value.project ? PROJECT_API + timeEntry.value.project.id : "";
  let entry;
  if (isManual.value) {
    entry = {
      timeStart: formatDate(manualEntry.value.timeStart),
      timeEnd: formatDate(manualEntry.value.timeEnd),
      employee: store.state.user['@id'],
      project: project,
      tags: tags,
      description: timeEntry.value.description,
      billable: timeEntry.value.billable,
    }
  } else {
    entry = {
      timeStart: formatDate(new Date()),
      employee: store.state.user['@id'],
      project: project,
      tags: tags,
      description: timeEntry.value.description,
      billable: timeEntry.value.billable,
    }
  }
  TrackerService.createEntry(entry).then((response) => {
    currentEntry.value = response.data;
    getEntries();
    startInterval();
  })
}

const formatDate = (date) => {
  // Obtener el desplazamiento de la zona horaria
  const offset = new Date().getTimezoneOffset();
  const sign = offset > 0 ? '-' : '+';
  const hours = String(Math.floor(Math.abs(offset) / 60)).padStart(2, '0');
  const minutes = String(Math.abs(offset) % 60).padStart(2, '0');

  // Formato de fecha
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  // Formato de hora
  const hoursOfDay = String(date.getHours()).padStart(2, '0');
  const minutesOfDay = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  // Devolver la cadena en formato ISO con el desplazamiento de la zona horaria
  return `${year}-${month}-${day}T${hoursOfDay}:${minutesOfDay}:${seconds}${sign}${hours}:${minutes}`;
}

// Descripcion // Autocompletar
// Cuando se elige una nueva entrada, hace los cambios y actualiza el autocompletar
const querySearch = (queryString, callback) => {
  // Filtra las entradas basándose en el queryString (valor actual del input)
  if (queryString.length < 3 || queryString == '') {
    callback([]);
    return;
  }
  let params = {
    page: 1,
    itemsPerPage: 9,
    'order[timeStart]': 'DESC',
    description: queryString,
  }

  TrackerService.getEmployeeEntries(store.state.user.id, params).then((res) => {
    entriesOptions.value = [];
    for (let i = 0; i < res.data['hydra:member'].length; i++) {
      const entry = res.data['hydra:member'][i];
      if (entry.timeEnd) {
        entriesOptions.value.push(entry)
      }
    }
    callback(entriesOptions.value);
  })
};

// Cuando eliges una entrada del autocompletar
const handleSelect = (val) => {
  selectNewEntry(val)
}

const selectNewEntry = (entry) => {
  timeEntry.value.description = entry.description;
  newDescription.value = entry.description;
  timeEntry.value.billable = entry.billable;
  timeEntry.value.project = entry.project;
  timeEntry.value.tags = entry.tags;
}

const getTagsText = (tags) => {
  let text = '';
  for (let i = 0; i < tags.length; i++) {
    const element = tags[i];
    if (i == 0) {
      text = element.name;
    } else {
      text = text + ", " + element.name
    }
  }
  return text;
}

// Paginacion

const changePagination = () => {
  getEntries();
}

// Ordenar semanas

const getGroupedEntriesByWeek = () => {
  const groupedByWeek = {};
  if (entries.value.length === 0) return []
  entries.value.forEach(entry => {
    const weekOfYear = moment(entry.timeStart).week();
    if (!groupedByWeek[weekOfYear]) {
      groupedByWeek[weekOfYear] = [];
    }
    entry.number = weekOfYear;
    groupedByWeek[weekOfYear].push(entry);
  });
  return Object.keys(groupedByWeek).map(week => ({
    week: getWeekStatus(parseInt(week)),
    entries: groupedByWeek[week]
  })).sort((a, b) => b.week - a.week);
}

const getWeekStatus = (weekNumber) => {
  const now = new Date();
  const currentWeekNumber = moment(now).week();
  const lastWeekNumber = currentWeekNumber - 1;

  if (weekNumber === currentWeekNumber) {
    return "Semana Actual";
  }
  if (weekNumber === lastWeekNumber) {
    return "Semana Anterior"
  }
  return getWeekRange(weekNumber)
}

const getWeekRange = (weekNumber) => {
  const firstDayOfYear = new Date(new Date().getFullYear(), 0, 1);
  const firstWeekday = firstDayOfYear.getDay();
  const daysToAdd = (weekNumber - 1) * 7 - (firstWeekday - 1);

  const startOfWeek = new Date(firstDayOfYear);
  startOfWeek.setDate(firstDayOfYear.getDate() + daysToAdd);

  const endOfWeek = new Date(startOfWeek);
  endOfWeek.setDate(startOfWeek.getDate() + 6);

  return `${capitalizeTitle(formatWeekDate(startOfWeek))} - ${capitalizeTitle(formatWeekDate(endOfWeek))}`;
}

const formatWeekDate = (date) => {
  return date.toLocaleDateString("es-ES", {
    day: "numeric",
    month: "long",
  })
}

const capitalizeTitle = (text) => {
  const prepositions = ["de", "la", "el", "en", "y", "con", "por", "a", "del", "los", "las", "al", "un", "una", "unos", "unas", "para", "sobre"];

  return text
    .toLowerCase()
    .split(" ")
    .map((word, index) =>
      prepositions.includes(word) && index !== 0
        ? word // No cambia preposiciones (excepto si es la primera palabra)
        : word.charAt(0).toUpperCase() + word.slice(1)
    )
    .join(" ");
}

// Manual Timer

const setManualTime = () => {
  manualEntry.value.timeStart = new Date();
  manualEntry.value.timeEnd = new Date();
  manualEntry.value.timeEnd.setSeconds(0)
  manualEntry.value.timeStart.setSeconds(0)
  inputStartTime.value = moment(manualEntry.value.timeStart).format('HH:mm')
  inputEndTime.value = moment(manualEntry.value.timeEnd).format('HH:mm')
}

const setEntryManual = () => {
  isManual.value = true;
}

const setEntryTimer = () => {
  isManual.value = false;
}

const focusInputHour = (event) => {
  if (refInputStartTime.value?.input === event.target) {
    inputStartTime.value = inputStartTime.value.split(':');
    let temp = inputStartTime.value[0] + inputStartTime.value[1];
    inputStartTime.value = temp;
    nextTick(() => {
      refInputStartTime.value.select();
    });
  } else if (refInputEndTime.value?.input === event.target) {
    inputEndTime.value = inputEndTime.value.split(':');
    let temp = inputEndTime.value[0] + inputEndTime.value[1];
    inputEndTime.value = temp;
    nextTick(() => {
      refInputEndTime.value.select();
    });
  }
}

const blurInputHour = (event) => {
  if (refInputStartTime.value?.input === event.target) {
    inputStartTime.value = moment(manualEntry.value.timeStart).format('HH:mm')
  } else if (refInputEndTime.value?.input === event.target) {
    inputEndTime.value = moment(manualEntry.value.timeEnd).format('HH:mm')
  }
}

const checkChangeInputHour = (input) => {
  let tempInput;
  tempInput = input;

  let temp;
  const newDuration = new Date();

  if (tempInput.length == 3 || tempInput.length == 4) {
    if (tempInput.length == 3) temp = tempInput.slice(0, 1) + ':' + tempInput.slice(1);
    if (tempInput.length == 4) temp = tempInput.slice(0, 2) + ':' + tempInput.slice(2);
    temp = temp.split(':')

    newDuration.setHours(parseInt(temp[0]))
    newDuration.setMinutes(parseInt(temp[1]))
  }
  if (tempInput.length == 1 || tempInput.length == 2) {
    newDuration.setHours(parseInt(tempInput))
    newDuration.setMinutes(0);
    newDuration.setSeconds(0);
  }

  if (isNaN(newDuration) || tempInput.length >= 5) {
    return false;
  }
  newDuration.setSeconds(0)
  return newDuration;
}

const changeStartInput = () => {
  const newH = checkChangeInputHour(inputStartTime.value)
  if (newH === false) {
    inputStartTime.value = moment(manualEntry.value.timeStart).format('HH:mm')
    return;
  }
  if (newH > convertToDate(inputEndTime.value)) {
    manualEntry.value.timeEnd.setDate(manualEntry.value.timeEnd.getDate() + 1);
    daysDiff.value = 1;
    refInputEndTime.value.blur();
  } else {
    daysDiff.value = 0;
    manualEntry.value.timeEnd.setDate(new Date().getDate());
  }
  manualEntry.value.timeStart = newH;
  refInputStartTime.value.blur();

}

const changeEndInput = () => {
  const newH = checkChangeInputHour(inputEndTime.value)
  if (newH === false) {
    inputEndTime.value = moment(manualEntry.value.timeEnd).format('HH:mm')
    return;
  }
  if (newH < convertToDate(inputStartTime.value)) {
    newH.setDate(newH.getDate() + 1);
    daysDiff.value = 1;
  } else {
    daysDiff.value = 0;
  }

  manualEntry.value.timeEnd = newH;
  refInputEndTime.value.blur();
}

const convertToDate = (timeString) => {
  const fullDate = moment(timeString, "HH:mm").toDate();
  return fullDate;
}

// Proyecto
const getNewProject = (newProject) => {
  timeEntry.value.project = newProject;
  refDropdownProject.value.handleClose();
}

const isDropdownOpen = ref(false);

const onDropdownToggle = (isOpen) => {
  isDropdownOpen.value = isOpen;
}

const clearSelectTag = () => {
  if (refSelectTag.value) {
    refSelectTag.value.blur(); // Cierra el dropdown y limpia el input de búsqueda
    refSelectTag.value.focus(); // Vuelve a enfocar para que siga funcional
  }
}
</script>

<template>
  <div class="tracker-net">
    <HeaderTitle title="Talento" />
    <div class="d-flex flex-row justify-content-start sticky-title">
      <img src="@/assets/icons/tracker-net-blue.svg" alt="">
      <div class="title-text ml-4">
        TrackerNet
      </div>
    </div>
    <el-row class="mt-2">
      <el-card v-if="currentEntry" class="card-search w-100 sticky-card">
        <tracker-current-entry :entry="currentEntry" @reload-entries="getEntries()" />
      </el-card>
      <el-card v-else class="card-search w-100 sticky-card">
        <div class="row">
          <!-- COLUMN 1 -->
          <div class="column1" :class="isManual ? 'col-6' : 'col-6'">
            <div class="row d-flex">
              <!-- DESCRIPTION -->
              <div class="col-8">
                <el-autocomplete ref="refAutocompleteDescription" v-model="timeEntry.description" :fetch-suggestions="querySearch" style="width: 100%;"
                  @select="handleSelect" placeholder="Descripción de proyecto" class="inputDescription" :trigger-on-focus="false"
                  popper-class="custom-autocomplete-popper" :title="timeEntry.description" value-key="description">
                  <template #default="{ item }">
                    <div class="custom-suggestion d-flex fixed-dropdown-item">
                      <span class="ellipsis-span" style="max-width: 40%;" :title="item.description">
                        {{ item.description }}
                      </span>
                      <span class="ellipsis-span" style="max-width: 20%;" :title="item.project.name">
                        <tracker-project-info :project="item.project" :select="true" class="projectSearch" />
                      </span>
                      <span class="ellipsis-span tracker-tag" style="max-width: 40%;" :title="getTagsText(item.tags)">
                        {{ getTagsText(item.tags) }}
                      </span>
                    </div>
                  </template>
                </el-autocomplete>
              </div>
              <!-- PROJECT -->
              <div class="col-4 justify-content-center align-content-center">
                <el-dropdown trigger="click" :hide-on-click="false" ref="refDropdownProject" class="addProjectDropdown"
                  placement="bottom" @visible-change="onDropdownToggle">
                  <p v-if="!timeEntry.project" @click="dropdownProject = true" style="margin-top: 13px;">
                    Añadir Proyecto
                  </p>
                  <tracker-project-info :project="timeEntry.project" class="el-dropdown-link"
                    @click="dropdownProject = true" v-if="timeEntry.project" :currentEntry="true" />
                  <template #dropdown>
                    <el-dropdown-menu class="dropProject">
                      <el-dropdown-item style="height: 300px;" v-if="dropdownProject">
                        <TrackerProjectSearch @get-project="getNewProject" :project="timeEntry.project"
                          :dropdown-open="isDropdownOpen" />
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </div>
            </div>
          </div>
          <!-- COLUMN 2 -->
          <div class="column2" :class="isManual ? 'col-6' : 'col-6'">
            <div class="row d-flex">
              <!-- TAGGS -->
              <div :class="isManual ? 'col-6' : 'col-6'">
                <el-select ref="refSelectTag" :value-key="'@id'" clearable filterable multiple collapse-tags
                  collapse-tags-tooltip placeholder="Etiqueta" v-model="timeEntry.tags" class="tracker-select"
                  @change="clearSelectTag">
                  <el-option v-for="tag in optionTags" :key="tag['@id']" :label="tag.name" :value="tag">
                  </el-option>
                </el-select>
              </div>
              <!-- BILLABLE -->
              <div class="text-center p-2" style="border-left: 1px solid #3333;" :class="isManual ? 'col-1' : 'col-4'">
                <el-button class="tracker-button"
                  :class="timeEntry.billable ? 'tracker-button--active' : 'tracker-button--inactive'"
                  @click="timeEntry.billable = !timeEntry.billable" style="background: none;"
                  :style="isManual ? 'margin-left: 5px; padding-left: 0;' : ''">
                  €
                </el-button>
              </div>
              <!-- TIME INPUTS // ADD BUTTON -->
              <div :class="isManual ? 'col-5' : 'col-2'">
                <div class="row">
                  <!-- TIME INPUTS (MANUAL TIMER) -->
                  <div class="col-5 tracker-select mt-2" v-if="isManual"
                    style="margin-left: 30px; margin-right: -30px;">
                    <el-row :gutter="10">
                      <el-col :span="10">
                        <el-input ref="refInputStartTime" v-model="inputStartTime" @focus="focusInputHour"
                          @change="changeStartInput()" @blur="blurInputHour" class="inputManual"
                          style="margin-left: -45px;"></el-input>
                      </el-col>
                      <el-col :span="10">
                        <el-input ref="refInputEndTime" v-model="inputEndTime" @focus="focusInputHour"
                          @change="changeEndInput()" @blur="blurInputHour" class="inputManual">
                        </el-input>
                      </el-col>
                      <el-col :span="1">
                        <sup v-if="daysDiff > 0 && !isNaN(daysDiff)" style="margin-left: 40px;"> +{{ daysDiff }}</sup>
                      </el-col>
                    </el-row>
                  </div>
                  <!-- ADD ENTRY -->
                  <div class="text-center p-2 flex-column justify-content-end align-content-end"
                    :class="isManual ? 'col-6' : 'col-7'">
                    <el-button type="primary" class="w-10" @click="saveTimeEntry"
                      :style="isManual ? 'margin-right: -70px;' : ''">
                      Añadir
                    </el-button>
                  </div>
                  <!-- MENU DROPDOWN -->
                  <div class="col-1 text-center p-2 flex-column justify-content-end">
                    <el-dropdown class="dropdown">
                      <span class="el-dropdown-link">
                        <el-icon class="el-icon--right" style="height: 30px;">
                          <MoreFilled
                            style="transform: rotate(90deg);color:#1D1D1B80; margin-left: -10px; font-size: 20px;" />
                        </el-icon>
                      </span>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <el-dropdown-item @click="setEntryTimer()">
                            <el-icon>
                              <Clock />
                            </el-icon>
                            Timer
                          </el-dropdown-item>
                          <el-dropdown-item @click="setEntryManual()">
                            <el-icon>
                              <Memo />
                            </el-icon>
                            Manual
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-card>
      <div v-if="isWeek" style="width: 100%;">
        <tracker-week v-for="entries in groupEntriesByWeek.slice().reverse()" :key="entries.week"
          :entriesWeek="entries.entries" :titleWeek="entries.week" :projects="optionProjects" :tags="optionTags"
          @reload-entries="getEntries" />
      </div>
    </el-row>
    <div class="mt-4">
      <el-pagination v-if="entries.length > 0" v-model:current-page="pageTimeEntries"
        v-model:page-size="itemsPerPageTimeEntries" size="default" :page-sizes="[50, 100, 200]"
        layout="prev, pager, next, sizes" :total="totalEntries" class="tracker-pagination" @change="changePagination" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.tracker-net {
  margin-bottom: 20px;
}

.card-search {
  height: auto;
  border-radius: 15px;
  max-height: 200px;
  background: white;
  background-position-x: 100%;
  overflow: visible !important;
}

.tracker {
  width: 100%;
  //border: 0 !important;
  //border-radius: 0 !important;
}

//.el-select__selection {
//  border: none !important;
//  border-radius: 0 !important;
//}

//input{
//  width: 100%;
//  border: none !important;
//  border-radius: 0 !important;
//}

.el-select {
  border: none !important;
  border-radius: 0 !important;

  .el-select__wrapper {
    border: none !important;
    border-radius: 0 !important;
  }
}

.el-select__input {
  border: none !important;
  border-radius: 0 !important;
}

.dropdown {
  margin-left: 20px;
}

.this-week {
  font-size: 20px;
}

.addProjectDropdown {
  margin-left: 10px;
}

.dropdownDescription {
  .el-input__wrapper {
    .el-input__inner {
      cursor: text !important;
    }
  }
}

.tracker-tag {
  background-color: #f7d20e !important;
  color: #474ce6 !important;
  padding-left: 10px;
  padding-right: 10px;
}

.fixed-dropdown-item {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  padding: 3px;

  >span {
    // flex: 1 1 auto; 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 8px;
  }
}


:deep(.custom-autocomplete-popper) {
  width: 300px !important;
  max-height: 200px !important;
}

.custom-autocomplete-popper {
  width: 300px !important;
  /* Ajusta el ancho deseado */
  max-height: 200px !important;
  /* Ajusta el alto deseado, o usa height si es fijo */
  /* Puedes agregar otros estilos, como padding, borde, etc. */
}

// Que se quede pegado arriba?
// .sticky-card {
//   position: sticky;
//   top: 130px;
//   z-index: 1000;
// }

// // Añadir fondo?
// .sticky-title {
//   position: sticky;
//   top: 80px;
//   z-index: 1000;
// }</style>
