<template>
  <div>
    <el-container v-if="info">
      <el-main>
        <el-row :gutter="10">
          <el-col :lg="8" :md="12" class="pb-2">
            <el-card class="production-dashboard-card">
              <div class="production-dashboard-card-content">
                <div class="dashboard-icon employee">
                  <User />
                </div>
                <div class="dashboard-text">
                  <h2>
                    {{ countNumEmployeesWorking }} Empleados
                    <el-button v-if="info.employeesWithoutClockify.length > 0"
                      @click="employeesWithoutClockifyDialogVisible = true">
                      {{ info.employeesWithoutClockify.length }} sin id de clockify
                    </el-button>
                  </h2>
                  <occupation-progress :percentaje="occupationPercent" />
                </div>
              </div>
            </el-card>
          </el-col>

          <el-col :lg="8" :md="12" class="pb-2">
            <el-card class="production-dashboard-card">
              <div class="production-dashboard-card-content">
                <div class="dashboard-icon project">
                  <Checked />
                </div>
                <div class="dashboard-text">
                  <h2>
                    {{ size(info.projects) }} proyectos
                    <el-button @click="projectDialogVisible = true">
                      ( <font-awesome-icon icon="thumbs-up" />
                      {{ toHours(info.workedInSignins) }} /
                      <font-awesome-icon icon="clock" />
                      {{ toHours(info.workedInProjects) }})
                    </el-button>
                  </h2>
                  <small> Trabajando en {{ size(info.currentProjects) }} proyectos en estos momentos
                    ({{ toHours(info.workedCurrentInProjects) }}) </small>
                </div>
              </div>
            </el-card>
          </el-col>

          <el-col :lg="8" :md="12" class="pb-2">
            <el-card class="production-dashboard-card">
              <div class="production-dashboard-card-content">
                <div class="dashboard-icon holidays-absences">
                  <Management />
                </div>
                <div class="dashboard-text">
                  <h6 class="pointer h6-text" @click="holidaysDialogVisible = true">
                    {{
                      size(info.holidays.holidaysOn7Days) }} empleado{{
                      size(info.holidays.holidaysOn7Days) == 0 || size(info.holidays.holidaysOn7Days)
                        > 1 ? 's' : '' }} estará{{ size(info.holidays.holidaysOn7Days) == 0 ||
                      size(info.holidays.holidaysOn7Days) > 1 ? 'n' : '' }} de vacaciones en los
                    próximos 7 días
                  </h6>
                  <h6 class="pointer h6-text" @click="holidaysDialogVisible = true">
                    {{
                      size(info.holidays.holidaysToday) }} empleado{{
                      size(info.holidays.holidaysOn7Days) == 0 || size(info.holidays.holidaysOn7Days)
                        > 1 ? 's' : '' }} está{{ size(info.holidays.holidaysOn7Days) == 0 ||
                      size(info.holidays.holidaysOn7Days) > 1 ? 'n' : '' }} de vacaciones
                  </h6>
                  <h6 class="pointer h6-text" @click="absencesDialogVisible = true">
                    {{ size(info.absences) }} empleado{{ size(info.absences) == 0 ||
                      size(info.absences) > 1 ? 's' : '' }} ausente{{ size(info.absences) == 0 ||
                      size(info.absences) > 1 ? 's' : '' }}
                  </h6>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>

        <el-divider class="my-2" />

        <div class="col-md-6">
          <div class="row">
            <div class="col-md-4">
              <div class="form-item mb-1">
                <label class="label-state">Departamentos:</label>
                <el-select clearable class="w-100" size="large" v-model="selectedDepartmentFilter" placeholder="TODOS"
                  @change="filterByDepartment()">
                  <el-option v-for="department in departments" :key="department.id" :label="department.name"
                    :value="department.id" />
                </el-select>
              </div>
            </div>
            <div class="col-md-1 flex justify-content-center align-center align-items-center refresh-col">
              <img @click="filterByDepartment" src="@/assets/icons/refresh-icon.png" alt="actualizar"
                class="refresh-icon" />
            </div>
          </div>
        </div>

        <el-divider class="my-2" />

        <el-row class="pt-2" :gutter="10">
          <el-col v-for="user in userData" :key="user.id" :xl="6" :lg="6" :md="12">
            <user-info :info="user" class="mb-3" />
          </el-col>
        </el-row>
      </el-main>
      <el-dialog v-model="projectDialogVisible" title="Proyectos">
        <accumulative-project-info :projects="info.projects" :current-projects="info.currentProjects" />
      </el-dialog>
      <el-dialog v-model="employeesWithoutClockifyDialogVisible" title="Empleados sin sincronizar con clockify">
        <employees-without-clockify :employees-without-clockify="info.employeesWithoutClockify"
          @clockify-synced="clockifySynced" />
      </el-dialog>
      <el-dialog v-model="holidaysDialogVisible" title="Empleados de Vacaciones">
        <employees-with-holidays :holidays="info.holidays" />
      </el-dialog>
      <el-dialog v-model="absencesDialogVisible" title="Empleados ausentes">
        <employees-with-absences :absences="info.absences" />
      </el-dialog>
    </el-container>
  </div>
</template>

<script setup>
import UserInfo from './components/UserInfo'
import AccumulativeProjectInfo from './components/AccumulativeProjectInfo';
import EmployeesWithoutClockify from './components/EmployeesWithoutClockify';
import ReportService from '@/api/services/report.dailyproduction.service';
import ResourceService from '@/api/services/resource.service';
import OccupationProgress from './components/OccupationProgress'
import _ from 'lodash'
import EmployeesWithAbsences from "./components/EmployeesWithAbsences";
import EmployeesWithHolidays from "./components/EmployeesWithHolidays";
import Notification from '@/utils/plugins/NotificationService';
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { toHours } from "@/views/production/mixin/common";
import { Checked, Management, User } from "@element-plus/icons-vue";

const projectDialogVisible = ref(false);
const employeesWithoutClockifyDialogVisible = ref(false);
const holidaysDialogVisible = ref(false);
const info = ref(null);
const absencesDialogVisible = ref(false);
const departments = ref(null);
// eslint-disable-next-line no-unused-vars
const department = ref(null);
const selectedDepartmentFilter = ref(null);
const params = ref({});
// eslint-disable-next-line no-unused-vars
const numEmployees = ref(0);

const store = useStore();

onMounted(() => {
  loadInfo();
})



const occupationPercent = computed(() => {
  return parseFloat((info.value.workedInProjects * 100 / info.value.workedInSignins).toFixed(2));
})
const userData = computed(() => {
  // usamos el metodo toArray por que la variable a la que estamos intentando acceder es un proxy, no un array
  return _.toArray(info.value.employees)
})
const countNumEmployeesWorking = computed(() => {
  let employeeWithHolidays = 0;
  let employeeWithAbsence = 0;
  info.value.employees.forEach(employee => {
    if (employee.holidays.length !== 0) {
      employeeWithHolidays += 1;
    }
    if (employee.absence.length !== 0 && !employee.absence.toDate) {
      employeeWithAbsence += 1;
    }
  })
  return info.value.employees.length - employeeWithHolidays - employeeWithAbsence;
})


const loadInfo = () => {
  getDailyReport();
  getDepartments();
}
const getDailyReport = () => {
  // store.state.loading = true;
  store.commit('startLoading');
  ReportService.getDailyReport(params.value)
    .then(result => {
      info.value = result.data;
      // store.state.loading = false;
    })
    .catch(() => {
      Notification.addNotification('Error al obtener datos de Producción.', 'error', 6000);
      // store.state.loading = false;
    }).finally(
      () => {
        store.commit('stopLoading');
      }
  )
}
const getDepartments = () => {
  // store.state.loading = true;
  store.commit('startLoading');
  params.value = {
    'jobs.productive': true
  }
  ResourceService.getDepartments(params.value)
    .then(result => {
      departments.value = result.data['hydra:member'];
      // store.state.loading = false;
      store.commit('stopLoading');
    })
    .catch(() => {
      Notification.addNotification('Error obtener listado de Departamentos.', 'error', 6000);
      // store.state.loading = false;
      store.commit('stopLoading');
    })
}
const clockifySynced = () => {
  employeesWithoutClockifyDialogVisible.value = false;
  loadInfo();

}
const size = (object) => {
  return _.size(object);
}
// handleCommand(command) {
//     $message('click on item ' + command);
// },
/* Incluye un filtro según el estado de las vacaciones */
const filterByDepartment = () => {
  // eslint-disable-next-line no-prototype-builtins
  if (!selectedDepartmentFilter.value) {
    delete params.value.department;
  } else {
    params.value.department = selectedDepartmentFilter.value
  }
  getDailyReport();
}

</script>

<style lang="scss">
.refresh-col {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  padding-top: 10px;
}

.refresh-icon {
  cursor: pointer;
  width: 35px;
  height: 35px;
  margin-top: 5px;
  background: #F7D20E;
  border-radius: 50%;
  padding: 5px;
  padding-right: 3px;
}

.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.el-card.production-dashboard-card {
  height: 100%;

  .el-card__body {
    padding: 0px;
    height: 100%;
  }

  .production-dashboard-card-content {
    display: flex;
    align-items: center;
    height: 100%;

    h2 {
      margin-top: 0;
      font-size: 1.4em;
      font-weight: bold;
    }

    .dashboard-icon {
      height: 100%;
      width: 70px;
      padding: 5px;

      color: #ffffff;

      i {
        padding: 15px;
        font-size: 45px;
        margin: auto;
      }

      &.project {
        background-color: rgb(156, 39, 176);
      }

      &.employee {
        background-color: #4167D5;
      }

      &.holidays-absences {
        background-color: #d54141;
      }
    }

    .dashboard-text {
      width: 100%;
      display: flex;
      height: 100%;
      flex-direction: column;
      padding: 10px;
      gap: 5px
    }

    .h6-text {
      font-size: 12px;
    }
  }
}
</style>
