<script setup>
import HeaderTitle from "@/components/layout/HeaderTitle.vue";
import ProjectsService from "@/api/services/projects.service";
// import TimeEntryTagService from "@/api/services/timeEntryTag.service";
import ClientService from "@/api/services/clients";
import EmployeeService from "@/api/services/employee.service";
import TrackerProjectInfo from "@/components/shared/TrackerProjectInfo.vue";
import TrackerProjectSearch from "@/components/shared/TrackerProjectSearch.vue";
import TrackerFullReport from "@/components/shared/TrackerFullReport.vue";
import { computed, onMounted, ref, } from "vue";
import { useStore } from "vuex";
import TrackerService from "@/api/services/tracker.service";

const store = useStore();
const user = computed(() => store.state.user);

const recharge = ref(false);

const page = ref(1);
const itemsPerPage = ref(9999);
const archived = ref(false);
const optionsEmployees = ref([])
const optionsClients = ref([]);
const optionsProjects = ref([]);
const description = ref('');
const employee = ref(null);
const client = ref(null);
const project = ref(null);
// const dateStart = ref(null);
// const dateEnd = ref(null);
const dateRange = ref([]);

const pageEntries = ref(1);
const itemsPerPageEntries = ref(50);
const totalEntries = ref(0)

const dropdownProject = ref(false);
const refDropdownProject = ref();

const entries = ref([]);
onMounted(() => {
  getEmployees();
  getClients();
  getProjects();
  search();
});

// Getters API
const getEmployees = () => {
  let params = {
    page: page.value,
    itemsPerPage: itemsPerPage.value
  }
  params['order[name]'] = 'ASC';
  EmployeeService.getEmployees(params)
    .then((res) => {
      optionsEmployees.value = res.data['hydra:member'];
    })
}

const getProjects = () => {
  let params = {
    page: page.value,
    itemsPerPage: itemsPerPage.value,
    archived: archived.value,
  }
  store.state.loading = true;
  store.commit('startLoading');
  ProjectsService.getProjects(params)
    .then((response) => {
      optionsProjects.value = response.data['hydra:member'];
    })
    .catch((error) => {
      console.log(error);
      Notification.addNotification(
        "Error al cargar los proyectos"
      );
    })
    .finally(() => {
      // store.state.loading = false;
      store.commit('stopLoading');
    });
}

const getClients = () => {
  let params = {
    page: page.value,
    itemsPerPage: itemsPerPage.value,
  }
  params['order[name]'] = 'ASC';
  // store.state.loading = true;
  store.commit('startLoading');
  ClientService.searchClients(params)
    .then((response) => {
      optionsClients.value = response.data['hydra:member'];
    })
    .catch((error) => {
      console.log(error);
      Notification.addNotification(
        "Error al cargar los clientes"
      );
    })
    .finally(() => {
      // store.state.loading = false;
      store.commit('stopLoading');
    });
}

// Funcion Busqueda

const search = () => {
  recharge.value = true;
  // store.loading = true;
  store.commit('startLoading');
  const params = {
    itemsPerPage: itemsPerPageEntries.value,
    page: pageEntries.value,
    'order[timeStart]': 'DESC'
  }

  if (employee.value) {
    params['employee.id'] = employee.value.id;
  }
  if (client.value) {
    params['project.client.id'] = client.value.id;
  }
  if (project.value) {
    params['project.id'] = project.value.id;
  }
  if (description.value && description.value !== '') {
    params.description = description.value;
  }
  if (dateRange.value) {
    if (dateRange.value.length > 1) {
      const newStart = new Date(dateRange.value[0]).toISOString();
      params['timeStart[after]'] = newStart;
      const newEnd = new Date(dateRange.value[1]);
      newEnd.setHours(23);
      newEnd.setMinutes(59);
      newEnd.setSeconds(59);
      params['timeEnd[before]'] = newEnd.toISOString();
    }
  }

  TrackerService.getEntries(params)
    .then((response) => {
      entries.value = response.data['hydra:member'].filter(entry => entry.timeEnd !== null && entry.timeEnd !== undefined);
      totalEntries.value = response.data['hydra:totalItems']
    })
    .catch((error) => {
      console.log(error);
      Notification.addNotification(
        "Error al cargar los registros"
      );
    })
    .finally(() => {
      // store.state.loading = false;
      store.commit('stopLoading');
      recharge.value = false;
    });
}

const getNewProject = (newProject) => {
  project.value = newProject;
  refDropdownProject.value.handleClose();
  search();
}

const deleteProject = () => {
  project.value = null;
  search();
}

//Paginacion
const changePagination = () => {
  search();
}

// Function Borrar filtros
const deleteFilters = () => {
  description.value = '';
  project.value = null;
  client.value = null;
  employee.value = null;
  dateRange.value = [];
  search();
}

</script>

<template>
  <div v-if="user && (user.roles.includes('ROLE_ADMIN'))">
    <HeaderTitle title="Talento" />
    <div class="d-flex flex-row justify-content-start">
      <img src="@/assets/icons/tracker-net-blue.svg" alt="">
      <div class="title-text ml-4">
        <!--          TrackerNet &bull; Informes-->
        TrackerNet &middot; Informes
      </div>
    </div>
    <el-row class="mt-2">
      <el-card class="card-search w-100">
        <div class="row">
          <!-- EMPLEADO -->
          <div class="col-2">
            <el-select class="form-item" :value-key="'@id'" clearable filterable placeholder="Empleado"
              v-model="employee" @change="search">
              <el-option v-for="employee in optionsEmployees" :key="employee['@id']" :label="employee.fullName"
                :value="employee">

              </el-option>
            </el-select>
          </div>
          <!-- CLIENTE -->
          <div class="col-2">
            <el-select class="form-item" :value-key="'@id'" clearable filterable placeholder="Cliente" v-model="client"
              @change="search">
              <el-option v-for="client in optionsClients" :key="client['@id']" :label="client.name" :value="client">
              </el-option>
            </el-select>
          </div>
          <!-- PROYECTO -->
          <div class="col-2">
            <el-dropdown trigger="click" :hide-on-click="false" ref="refDropdownProject"
              class="addProjectDropdown el-select" placement="bottom">
              <p v-if="!project" @click="dropdownProject = true" style="margin-top: 13px; margin-left: 15px;">
                Añadir Proyecto
              </p>
              <div style="display: flex; align-items: center; margin-top: 5px; margin-left: 5px;" v-if="project">
                <tracker-project-info :project="project" class="el-dropdown-link" @click="dropdownProject = true"
                  :editImport="true" :style="!projectCheck ? 'cursor:default;' : ''" style="overflow: hidden;" />
                <el-button circle size="small" type="danger" style="margin-left: 8px; margin-top: 2px;"
                  @click="deleteProject">
                  <el-icon>
                    <Close />
                  </el-icon>
                </el-button>
              </div>
              <template #dropdown>
                <el-dropdown-menu class="dropProject">
                  <el-dropdown-item style="height: 300px;">
                    <TrackerProjectSearch @get-project="getNewProject" :project="project" :client="client" />
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
          <!-- DESCRIPCION -->
          <div class="col-2">
            <el-input v-model="description" clearable class="search-input" placeholder="Descripción" @change="search" />
          </div>
          <!-- FECHA -->
          <div class="col-2">
            <el-date-picker v-model="dateRange" type="daterange" unlink-panels start-placeholder="Desde"
              end-placeholder="Hasta" format="DD/MM/YYYY" value-format="YYYY-MM-DD" clearable @change="search"
              class="w-100 rounded-pill border border-black dateRange" />
          </div>
          <!-- BOTON BUSCAR -->
          <div class="col-2">
            <button class="btn btn_red btn-search w-75" @click="search()">
              BUSCAR
            </button>
          </div>
        </div>
      </el-card>
    </el-row>
    <!-- BORRAR FILTROS -->
    <div class="d-flex">
      <span class="ms-auto deleteFilter" @click="deleteFilters">Borrar filtros</span>
    </div>
    <!-- INFORMES -->
    <div v-if="!recharge">
      <div>
        <TrackerFullReport :entries="entries" v-if="entries.length > 0 && !recharge" @reload-entries="search" />
      </div>
      <!-- PAGINACION -->
      <div class="mt-4">
        <el-pagination v-if="entries.length > 0" v-model:current-page="pageEntries"
          v-model:page-size="itemsPerPageEntries" size="default" :page-sizes="[50, 100, 200]"
          layout="prev, pager, next, sizes" :total="totalEntries" class="tracker-pagination"
          @change="changePagination" />
      </div>
    </div>
  </div>
  <div v-else>No tienes permiso para ver esta sección</div>

</template>

<style scoped lang="scss">
.card-search {
  height: auto;
  border-radius: 15px;
  max-height: 200px;
  background: white;
  background-position-x: 100%;
  overflow: visible !important;
}

.btn-search {
  &.btn_red {
    color: white;
  }
}

.date-picker-tracker {
  .el-date-editor {
    border-radius: 25px !important;
    width: 100% !important;
  }
}

.addProjectDropdown {
  margin-top: 2px;
}

@media (max-width: 1630px) {
  .dateRange {
    .el-range-editor.el-input__wrapper {
      padding: 0;
    }
  }
}
</style>
