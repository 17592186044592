<template>
  <div v-if="user && ifIsInArray(user.roles, 'ROLE_ADMIN')" class="absences">
    <!--    <h1>Absentismo</h1>-->
    <HeaderTitle title="Talento" />
    <div class="d-flex flex-row justify-content-start">
      <img src="@/assets/icons/search-blue.svg" alt="">
      <div class="title-text ml-4">
        Absentismo
      </div>
    </div>
    <el-row class="mt-2">
      <el-card class="card-search card-abs w-100 wback" body-style="padding:0">

        <div class="container-absences">
          <div class="row">
            <div class="col-md-8">
              <div class="form-item label-common">
                <label class="label-absence w-100">Nombre</label>
                <el-input v-model="employeeName" clearable placeholder="Buscar por nombre" @change="search()" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="form-item label-common">
                <label class="w-100">Departamento</label>
                <el-select v-model="selectedDepartmentFilter.value" placeholder="Todos" clearable filterable
                  @change="search()">
                  <el-option v-for="item in departments" :key="item.id" :label="item.name" :value="item.id" />
                </el-select>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-8">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-item date-picker label-common">
                    <label class="mb-2 w-100">Desde</label>
                    <el-date-picker class="w-100" v-model="selectedFromDate" type="date" format="DD/MM/YYYY"
                      value-format="YYYY-MM-DD" :prefix-icon="customPrefix" :picker-options="{ firstDayOfWeek: 1 }" :disabled-date="deshabilitarFechasDesde"
                      @change="manejarCambioDesde" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-item date-picker label-common">
                    <label class="w-100 mb-2">Hasta</label>
                    <el-date-picker v-model="selectedToDate" class="w-100" type="date" format="DD/MM/YYYY"
                      value-format="YYYY-MM-DD" :prefix-icon="customPrefix" :picker-options="dueDatePickerOptions" :disabled-date="deshabilitarFechasHasta"
                      @change="manejarCambioHasta" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4 ">
              <div class="d-flex justify-content-center">
                <div class="col-md-6">
                  <button type="submit" class="btn btn_red btn-new" @click="openNewAbsence">
                    Nuevo
                  </button>
                </div>
                <div class="col-md-6 ">
                  <button class="btn btn_yellow btn_search" @click="search()">
                    Buscar
                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>

      </el-card>
    </el-row>
    <div class="table">
      <div v-if="absences && absences.length > 0">
        <table>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Fecha</th>
              <th>Inicio</th>
              <th>Fin</th>
              <th>
                <div class="d-flex justify-content-start align-items-center pointer" @click="changeOrderDuration()">
                  <div>
                    Duración
                    <font-awesome-icon v-if="orderDuration === 'ASC'" icon="sort-alpha-up" />
                    <font-awesome-icon v-else-if="orderDuration === 'DESC'" icon="sort-alpha-down" />
                    <font-awesome-icon v-else icon="sort" />
                  </div>
                </div>
              </th>
              <th>Motivo</th>
              <th>Observaciones</th>
              <th>Justificantes</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="absence in filteredAbsences" :key="absence.id">
              <td class="pointer">
                <div class="row-table-image-name-link">
                  <EmployeeAvatar :employee="absence.employee" />

                  <div class="link-name">
                    <router-link :to="{ name: 'employee', params: { id: absence.employee.id } }"
                      style="text-decoration: none">
                      {{ absence.employee?.name }} {{ absence.employee?.lastName }}
                    </router-link>
                  </div>
                </div>

              </td>
              <td class="pointer" @click="openNewAbsence(absence)">
                {{ formatDate(absence.timestamp) }}
              </td>
              <td class="pointer" @click="openNewAbsence(absence)">
                {{ formatDateFilter(absence.fromDate) }}
              </td>
              <td class="pointer" @click="openNewAbsence(absence)">
                {{ formatDateFilter(absence.toDate) }}
              </td>
              <td class="pointer" @click="openNewAbsence(absence)">
                {{ formatAbsentTime(absence.absentWorkTime) }}
              </td>
              <td class="pointer" @click="openNewAbsence(absence)">
                {{ absence.absenceType.name }}
              </td>
              <td class="pointer" @click="openNewAbsence(absence)">
                {{ absence.observations }}
              </td>
              <td class="pointer" @click="openNewAbsence(absence)">
                {{ absence.absenceSupportingDocuments.length }}
              </td>
              <td>
                <!--                            <div class="buttons d-inline-flex" v-if="user && ifIsInArray('ROLE_SUPER_ADMIN', user.rol)">-->
                <div class="buttons d-inline-flex">
                  <shared-button-plus color="#F9156F" icon="times"
                    @click="deleteDialogShown = true; selectedAbsence = absence;" />
                  <shared-button-plus icon="eye" @onClick="openNewAbsence(absence)" />
                </div>
              </td>
            </tr>
            <tr v-if="absences && absences.length > 0">
              <th>
                Total horas:
              </th>
              <td class="total-hours">
                {{ totalAbsencesWorkTime }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <h5>No se han encontrado ausencias según los filtros especificados...</h5>
      </div>
      <SharedPagination v-if="absences && totalResults > 10" class="pagination" :page="page"
        :total-results="totalResults" :page-size="itemsPerPage" style="padding: 0px 0px 20px 0px;"
        @change="page = $event; getAbsences()" />
    </div>


    <SharedDialog v-if="deleteDialogShown && selectedAbsence"
      :title="'¿Desea eliminar permanentemente esta ausencia de ' + selectedAbsence.employee.name + ' ' + selectedAbsence.employee.lastName + '?'"
      :close-icon="false" @confirm="deleteAbsence(selectedAbsence)"
      @close="selectedAbsence = null; deleteDialogShown = false;"
      @decline="selectedAbsence = null; deleteDialogShown = false;" />

    <el-dialog v-model="showModalNewAbsence" @close="closeModal()" width="816" :center="true" :lock-scroll="true"
      :show-close="false" class="el-dialog">
      <div class="my-header">
        <svg id="asterisk" xmlns="http://www.w3.org/2000/svg" width="724" height="1024" viewBox="0 0 724 1024">
          <path
            d="M0 499.8l59-181.7 332.5 108.6-59 180.8-332.5-107.7zM79.6 744.3l206.1-282.9 154.5 111.5-206.1 283.8-154.5-112.4zM266.9 167.3h191.1v350.3h-191.1v-350.3zM285.6 572.9l154.5-111.5 206.1 282.9-154.5 112.4-206.1-283.8zM332.5 426.8l332.5-108.6 59 181.7-331.6 107.7-59.9-180.8z" />
        </svg>
        <span class="text-header ml-5 mt-3">{{ absenceFormModel.id ? "Editar Ausencia" : "Nueva Ausencia" }}</span>

        <img class="close-icon pointer" src="@/assets/images/aspa_blue_small_entrada.png" alt=""
          @click="showModalNewAbsence = false">
      </div>
      <AbsenceDialog :absenceModal="absenceFormModel" v-if="showModalNewAbsence" @close-dialog="closeModal"
        @changeAbsence="changeAbsence" />
    </el-dialog>

  </div>
</template>

<script setup>


// Components
import SharedPagination from "@/components/shared/SharedPagination.vue";
import SharedDialog from "@/components/shared/SharedDialog.vue";
import HeaderTitle from "@/components/layout/HeaderTitle";
import AbsenceService from '@/api/services/absence.service';
import EmployeeService from '@/api/services/employee.service';
import ResourceService from '@/api/services/resource.service';
import Common from '@/api/services/common.service';
import { useStore } from 'vuex';
import moment from "moment";

import EmployeeAvatar from "@/components/shared/EmployeeAvatar.vue";
import { computed, h, onMounted, ref, shallowRef } from "vue";
import SharedButtonPlus from "@/components/shared/SharedButtonPlus.vue";
import { ElNotification } from "element-plus";
//import axios from "axios";
import AbsenceDialog from "../../components/shared/AbsenceDialog.vue";
//import {useRouter} from "vue-router";

const store = useStore();
//const router = useRouter();

const absences = ref([]);
const filteredAbsences = ref([]);
// eslint-disable-next-line no-unused-vars
const absence = ref({ employee: { name: '', lastName: '' } });
const absenceTypes = ref([]);
const page = ref(1);
const itemsPerPage = ref(10);
const totalResults = ref(0);
const params = ref({});
const employeeName = ref(null);
const employees = ref([]);
const selectedFromDate = ref(null);
const selectedToDate = ref(null);
const deleteDialogShown = ref(false);
const selectedAbsence = ref(null);
const totalAbsences = ref(0);
const toDateDisabledDates = ref({ to: null });
const selectedDepartmentFilter = ref({});
const orderDuration = ref('');
const departments = ref([]);
const showModalNewAbsence = ref(false);
const fileList = ref([]);

const now = new Date();
const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 0);
const absenceFormModel = ref({
  id: null,
  employee: null,
  employeeName: '',
  fromDate: startOfDay,
  toDate: endOfDay,
  absenceType: null,
  observations: null,
  absenceSupportingDocuments: []
});
// eslint-disable-next-line no-unused-vars
const defaultTime = ref([
  // fecha actual desde las 00:00
  new Date(new Date().setHours(0, 0, 0, 0)),
  // fecha actual hasta las 23:59
  new Date(new Date().setHours(23, 59, 59, 0))
]);

const calculateDisabledDates = (time) => {
  if (selectedFromDate.value) {
    let yesterday = new Date(selectedFromDate.value);
    yesterday.setDate(yesterday.getDate() - 1);
    return time.getTime() < yesterday.getTime();
  } else {
    return false;
  }
}

const dueDatePickerOptions = ref({
  firstDayOfWeek: 1,
  disabledDate: calculateDisabledDates
});


onMounted(() => {
  if (ifIsInArray(user.value.roles, 'ROLE_ADMIN')) {
    getAbsences();
    getDepartments();
    getAbsenceTypes();
    getEmployees();
  }
})

const user = computed(() => {
  return store.state.user;
});
const customPrefix = shallowRef({
  render() {
    return h('img', {
      src: '/calendar-blue.svg',
      style: 'width: 16px; height: 16px; margin-right: 10px;',
    });
  },
})

const totalAbsencesWorkTime = computed(() => {
  const totalMinutes = absences.value.reduce((total, absence) => {
    return total + absence.absentWorkTime;
  }, 0);
  return formatAbsentTime(totalMinutes);
})


const search = () => {
  page.value = 1;
  getAbsences();
}

const getAbsences = () => {
  params.value = {}
  params.value.page = page.value;
  params.value.itemsPerPage = itemsPerPage.value;

  if (employeeName.value && employeeName.value.length > 0) {
    params.value.orSearch_fullName = employeeName.value
  } else {
    delete params.value.orSearch_fullName;
  }
  if (selectedFromDate.value) {
    params.value['fromDate[after]'] = moment(selectedFromDate.value).format('YYYY-MM-DD 00:00:00')
  }
  if (selectedToDate.value) {
    params.value['toDate[before]'] = moment(selectedToDate.value).format('YYYY-MM-DD 23:59:59')
  }
  if (selectedDepartmentFilter.value) {
    params.value['employee.job.department.id'] = selectedDepartmentFilter.value.value;
  } else {
    delete params.value['employee.job.department.id'];
  }

  if (orderDuration.value) {
    params.value['order[absentWorkTime]'] = orderDuration.value;
  } else {
    delete params.value['order[absentWorkTime]'];
  }
  // store.dispatch('setLoading', true);
  store.commit('startLoading');
  AbsenceService.getAbsences(params.value)
    .then((res) => {
      absences.value = res.data['hydra:member'];
      filteredAbsences.value = absences.value;
      totalResults.value = res.data['hydra:totalItems'];
      totalAbsences.value = absences.value.length
      // store.dispatch('setLoading', false);
      store.commit('stopLoading');
    })
    .catch(() => {
      ElNotification({
        type: 'error',
        message: 'Error al obtener listado de absentismo.'
      });
      // store.dispatch('setLoading', false);
      store.commit('stopLoading');
    })

}

const getDepartments = (searchQuery = '') => {
  const params = (searchQuery.length > 0 ? { name: searchQuery } : {});
  // store.dispatch('setLoading', true);
  store.commit('startLoading');
  ResourceService.getDepartments(params).then((res) => {
    departments.value = res.data['hydra:member'];
    // store.dispatch('setLoading', false);
    store.commit('stopLoading');
  }).catch(() => {
    ElNotification({
      type: 'error',
      message: 'Error al obtener listado de departamentos.'
    });
    // store.dispatch('setLoading', false);
    store.commit('stopLoading');
  })
}

const openNewAbsence = (absence) => {
  showModalNewAbsence.value = true;
  if (absence.id) {
    absenceFormModel.value = {
      id: absence.id,
      employee: absence.employee,
      employeeName: absence.employee.name + ' ' + absence.employee.lastName,
      fromDate: absence.fromDate,
      toDate: absence.toDate,
      absenceType: absence.absenceType,
      observations: absence.observations,
      absenceSupportingDocuments: absence.absenceSupportingDocuments.map(doc => doc['@id']),
      absentWorkTime: absence.absentWorkTime,
      absenceFileList: absence.absenceSupportingDocuments,
    }
  }
  fileList.value = absence.absenceSupportingDocuments;
  //router.push(`/absences/${absence.id ? absence.id : 'new'}`)
}
// eslint-disable-next-line no-unused-vars
const getAbsenceTypes = (searchQuery = '') => {
  const params = { query: { name: searchQuery } };
  ResourceService.getAbsenceTypes(params)
    .then((res) => {
      absenceTypes.value = res.data['hydra:member'];
    })
}
const deleteAbsence = (absence) => {
  // store.dispatch('setLoading', true);
  store.commit('startLoading');
  AbsenceService.deleteAbsence(absence.id)
    .then(() => {
      ElNotification({
        type: 'success',
        message: 'Ausencia eliminada correctamente',
        duration: 6000
      });
      // store.dispatch('setLoading', false);
      store.commit('stopLoading');
      selectedAbsence.value = null;
      deleteDialogShown.value = false;
      getAbsences();
    })
    .catch(() => {
      ElNotification({
        type: 'error',
        message: 'Error al intentar eliminar la ausencia seleccionado.',
        duration: 6000
      });
      // store.dispatch('setLoading', false);
      store.commit('stopLoading');
      selectedAbsence.value = null;
      deleteDialogShown.value = false;
      getAbsences();
    })
}
const formatDate = (value) => {
  if (value) {
    return moment(value).format('DD/MM/YYYY');
  }
  return null;
}

// eslint-disable-next-line no-unused-vars
const fromDateSelected = (selectedDate) => {
  if (selectedDate != null) {
    // Deshabilitamos como fecha de fín todos los días anteriores a la fecha de 'desde'
    toDateDisabledDates.value.to = new Date();
    toDateDisabledDates.value.to.setDate(selectedDate.getDate() - 1);
  } else {
    toDateDisabledDates.value.to = null;
  }
}

const ifIsInArray = (userRoles, role) => {
  return Common.ifIsInArray(userRoles, role);
}

const formatDateFilter = (value) => {
  if (value) {
    return moment(value).format('DD/MM/YYYY');
  } else {
    return '';
  }

}
// eslint-disable-next-line no-unused-vars
const formatDateTime = (value) => {
  if (value) {
    return moment(value).format('DD/MM/YYYY HH:mm');
  } else {
    return '';
  }

}
// eslint-disable-next-line no-unused-vars
const formatCompleteDate = (value) => {
  moment.locale('es');
  return moment(value).format('DD MMMM YYYY');
}

const getEmployees = (filters) => {
  params.value = {
    ...params.value,
    filter: filters,
    'order[name]': 'asc',
    'active': 1,
    page: 1,
    itemsPerPage: 999999
  };
  store.commit('startLoading');
  EmployeeService.getEmployees(params.value)
    .then((res) => {
      employees.value = res.data['hydra:member'];
      totalResults.value = res.data['hydra:totalItems'];
      store.commit('stopLoading');
    })
}

const closeModal = () => {
  absenceFormModel.value = {};
  cancelDialog();
}

const cancelDialog = () => {
  showModalNewAbsence.value = false;
  deleteDialogShown.value = false;
  selectedAbsence.value = null;
  absenceFormModel.value = {
    id: null,
    employee: null,
    employeeName: '',
    fromDate: startOfDay,
    toDate: endOfDay,
    absenceType: null,
    observations: null,
    absenceSupportingDocuments: []
  };
}

const formatAbsentTime = (minutes) => {
  if (minutes === 0) return '0'

  const days = Math.floor(minutes / (24 * 60))
  const hours = Math.floor((minutes % (24 * 60)) / 60)
  const mins = minutes % 60

  let result = ''
  if (days > 0) result += `${days}D:`
  if (hours > 0 || days > 0) result += `${hours.toString().padStart(2, '0')}H:`
  result += `${mins.toString().padStart(2, '0')}M`

  return result.trim()
}

const changeOrderDuration = () => {
  if (orderDuration.value === '')
    orderDuration.value = 'ASC';
  else if (orderDuration.value === 'ASC') {
    orderDuration.value = 'DESC';
  } else if (orderDuration.value === 'DESC') {
    orderDuration.value = '';
  }
  search()
}

const changeAbsence = () => {
  closeModal();
  getAbsences();
}


const deshabilitarFechasHasta = (date) => {
  if (selectedFromDate.value) {
    const fechaDesdeDate = new Date(selectedFromDate.value);
    const dateToCheck = new Date(date);

    // Permitir el mismo día, pero no fechas anteriores
    return checkDateSameDay(fechaDesdeDate, dateToCheck);
  }
  return false
}
const deshabilitarFechasDesde = (date) => {
  if (selectedToDate.value) {
    const fechaHastaDate = new Date(selectedToDate.value);
    const dateToCheck = new Date(date);

    // Permitir el mismo día, pero no fechas posteriores
    return checkDateSameDay(dateToCheck, fechaHastaDate);
  }
  return false
}

const checkDateSameDay = (date1, date2) => {
  return (
      date1.getFullYear() > date2.getFullYear() ||
      (date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() > date2.getMonth()) ||
      (date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() > date2.getDate())
    );
}

const manejarCambioDesde = (val) => {
  if(selectedToDate.value && val > selectedToDate.value){
    const fechaDesdeDate = new Date(val);
    const fechaHastaDate = new Date(selectedToDate.value);

    // Si la fecha seleccionada es posterior a la fecha hasta, 
    // reset la fecha hasta
    if (fechaDesdeDate > fechaHastaDate) {
      selectedToDate.value = null;
    }
  }
  search();
}

const manejarCambioHasta = (val) => {
  if(selectedFromDate.value && val < selectedFromDate.value){
    const fechaDesdeDate = new Date(val);
    const fechaHastaDate = new Date(selectedFromDate.value);

    // Si la fecha seleccionada es posterior a la fecha hasta, 
    // reset la fecha hasta
    if (fechaDesdeDate < fechaHastaDate) {
      selectedFromDate.value = null;
    }
  }
  search();
}

</script>

<style lang="scss" scoped>
.card-search {
  border-radius: 15px;
  max-height: 380px;
  background: white url("../../assets/images/silla.png") no-repeat;
  background-position-x: 100%;
  overflow: visible !important;
}

.absences {
  .label-absence {
    color: #434343;
    float: left;
    display: block;
    white-space: nowrap;

  }
}


.total-hours {
  border-radius: 10px;
}

th:first-child,
td:first-child {
  width: 300px;
  /* Fijo el tamaño de la primera columna */
}

th:nth-child(2),
td:nth-child(2),
th:nth-child(3),
td:nth-child(3),
th:nth-child(4),
td:nth-child(4),
th:nth-child(5),
td:nth-child(5) {
  width: auto;
  white-space: nowrap;
}

table {
  table-layout: auto;
  width: 100%;
}

/* Para las columnas restantes, dejar que ocupen el espacio disponible */
th:nth-child(n+6),
td:nth-child(n+6) {
  width: auto;
}

.btn_search {
  color: $bg-blue;
  text-transform: uppercase;
  font-weight: bolder;
  margin-left: 10px;
}

.btn_search,
.btn-new {
  height: 40px;
  margin-top: 30px;
}

.btn_new_modal {
  min-height: 40px;
  margin-top: 10%;
  margin-left: -2%;
}


.el-dialog__body {
  background: #f3f0fb !important;
  min-height: 800px !important;

  .el-dialog__header {
    padding: 0;
  }

  .my-header {
    margin-top: 2px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .text-header {
      text-align: left;
      font-size: 4em;
      font-weight: 900;
      letter-spacing: -0.05em;
      color: #f9156f;
    }

    .close-icon {
      width: 8%;
      height: 20%;
    }

    .inter-icon {
      width: 2%;
      height: 2%;
    }

    #asterisk {
      position: absolute;
      vertical-align: super;
      height: auto;
      width: 2.2em;
      fill: #f9156f;
    }

  }

  .my-body {
    margin-right: 5%;
  }
}
</style>
