<template>
  <div
      v-if="user && ifIsInArray(user.roles, 'ROLE_ADMIN')"
      class="absence-item"
  >
    <div
        v-if="!profile"
        class="row"
    >
      <div
          class="col-md-2"
          style="text-align: left"
      >
        <routerLink
            :to="{ name: 'absences'}"
            class="return-btn btn btn-grey"
            style="padding-left: 0; display:initial;"
        >
          <font-awesome-icon icon="angle-left" />
          Volver
        </routerLink>
      </div>
      <div class="col-md-8 list-header">
        <h3>{{ title }}</h3>
      </div>
    </div>
    <hr>


    <el-form
        v-if="!store.state.loading"
        ref="absenceForm"
        class="absence-form"
        :model="absenceFormModel"
        name="absenceForm"
        status-icon
        @submit.prevent
    >
      <!-- Nombre del empleado -->
      <div class="form-item">
        <div class="row text-center">
          <div
              class="col-md-8 col-sm-8 pointer"
              @click="openModalEmployees"
          >
            <div class="row tex-center">
              <div class="col-md-3 d-inline-flex">
                <label
                    class="label-absence"
                    for="employeeName"
                >*Nombre</label>
              </div>
              <div class="col-md-9 form-item">
                <el-form-item prop="employeeName">
                  <el-input
                      v-model="absenceFormModel.employeeName"
                      placeholder="Seleccione un empleado"
                      :readonly="true"
                  />
                  <p v-if="v.employeeName.$error" class="invalid-feedback mb-0">
                    <small>
                      {{ v.employeeName.$errors[0].$message }}
                    </small>
                  </p>
                </el-form-item>
              </div>
            </div>
          </div>
          <div
              v-if="!absenceFormModel.id"
              class="col-md-2 col-sm-4 text-right"
          >
            <button
                class="btn btn_blue"
                style="padding-top: 4px"
                @click="openModalEmployees"
            >
              Seleccionar Empleado
            </button>
          </div>
        </div>
      </div>
      <!-- Fecha de inicio y de fin -->
      <div class="form-item">
        <div class="row">
          <div class="col-md-8">
            <div class="row">
              <div class="col-md-5">
                <div class="form-item date-picker">
                  <label class="w-100">*Desde:</label>
                  <el-date-picker
                      v-model="absenceFormModel.fromDate"
                      type="datetime"
                      placeholder="Selecciona fecha y hora"
                      format="DD/MM/YYYY HH:mm"
                      :clearable="false"
                      :prefix-icon="customPrefix"
                      :picker-options="{firstDayOfWeek: 1}"
                      :default-time="defaultTime[0]"
                  />
                  <p v-if="v.fromDate.$error" class="invalid-feedback mb-0">
                    <small>
                      {{ v.fromDate.$errors[0].$message }}
                    </small>
                  </p>
                </div>
              </div>
              <div class="col-md-5">
                <div class="form-item date-picker">
                  <label class="w-100">*Hasta:</label>
                  <el-date-picker
                      v-model="absenceFormModel.toDate"
                      type="datetime"
                      placeholder="Selecciona fecha y hora"
                      format="DD/MM/YYYY HH:mm"
                      :picker-options="dueDatePickerOptions"
                      :prefix-icon="customPrefix"
                      :default-time="defaultTime[1]"
                  />
                  <p v-if="v.toDate.$error" class="invalid-feedback mb-0">
                    <small>
                      {{ v.toDate.$errors[0].$message }}
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Tipo de absentismo -->
      <div class="form-item">
        <div class="row text-center">
          <div class="col-md-8">
            <div class="row tex-center mb-3">
              <div class="col-md-3 d-inline-flex">
                <label
                    class="label-absence"
                    for="absenceType"
                >*Tipo de absentismo</label>
              </div>
              <div class="col-md-9 form-item">
                  <el-select
                      v-model="absenceFormModel.absenceType"
                      placeholder="Tipo de Ausencia"
                      :value-key="'@id'"
                  >
                  <el-option
                      v-for="item in absenceTypes"
                      :key="item.id"
                      :label="item.name"
                      :value="item"
                  />
                  </el-select>
                <p v-if="v.absenceType.$error" class="invalid-feedback mb-0">
                  <small>
                    {{ v.absenceType.$errors[0].$message }}
                  </small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-item">
        <div class="row text-center">
          <div class="col-md-8">
            <div class="row tex-center">
              <div class="col-md-3 d-inline-flex">
                <label class="label-absence label-observations">Observaciones</label>
              </div>
              <div class="col-md-9">
                <el-form-item
                    prop="observations"
                    class="mb-0"
                >
                  <textarea
                      v-model="absenceFormModel.observations"
                      class="font18 w-100 p-2"
                      placeholder="Observaciones del motivo de la baja"
                  />
                  <small v-if="v.observations.$error" class="invalid-feedback mb-0">
                    {{ v.observations.$errors[0].$message }}
                  </small>
                </el-form-item>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div v-if="absenceFormModel.id != null">
        <div
            v-if="absenceFormModel && absenceFormModel.absenceSupportingDocuments && absenceFormModel.absenceSupportingDocuments.length > 0"
            class="row text-center"
        >
          <div class="col-md-8">
            <div class="row tex-center">
              <div class="col-md-3 d-inline-flex">
                <label class="label-absence">Justificantes</label>
              </div>
              <div class="col-md-9">
                <div class="table-documents">
                  <table>
                    <tbody>
                    <tr
                        v-for="(document, index) in fileList"
                        :key="index"
                    >
                      <td
                          class="pointer"
                          @click="downloadDocument(document.contentUrl, document.name)"
                      >
                        {{ document.name }}
                      </td>
                      <td>
                        <div class="buttons d-inline-flex">
                          <div class="pointer">
                            <font-awesome-icon
                                class="btn_icon delete_btn"
                                icon="times"
                                title="Eliminar documento"
                                style="margin-left: 5px"
                                @click="deleteSupportingDocument(document, index)"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row text-center mt-4">
          <div class="col-md-8">
            <div class="row text-center">
              <div class="col-md-3 d-inline-flex">
                <label class="label-absence">Añadir justificante</label>
              </div>
              <div
                  class="col-md-9 form-item text-left"
                  @click.stop="focusOnInput($event)"
              >
                <input
                    id="file"
                    ref="file"
                    type="file"
                    @change.stop="handleFileUpload"
                >
              </div>
            </div>
          </div>
        </div>
      </div>



      <!-- Botones de guardado-->
      <div class="row mt-4">
        <div class="col-md-8">
          <div class="row">
            <div class="offset-md-3 col-md-9 d-flex justify-content-center">
              <div class="col-md-6 ">
                <routerLink
                    :to="{ name: 'absences'}"
                    class="btn btn-block btn_red"
                    style="height: 33.5px;line-height: 23px;"
                    @click="removeDocuments"
                >
                  Cancelar
                </routerLink>
              </div>
              <div class="col-md-6">
                <button
                    class="btn btn-block btn_yellow"
                    @click="saveAbsence()"
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-form>

    <!-- Modal de 'Asignar ausencia' -->
    <el-dialog
        v-model="showModalEmployees"
        :title="'Asignar Ausencia'"
        @closed="closeModalEmployees()"
    >
        <div>
          <div class="col">
            <div class="form-item">
              <el-input
                  v-model="searchName"
                  :param="'searchName'"
                  :label="'Buscar por nombre'"
                  :placeholder="'Nombre'"
                  @change="filterEmployees($event)"
              />
            </div>

          </div>
          <div class="table">
            <table>
              <thead>
              <tr>
                <th>Nombre</th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(employee, index) in employees"
                  :key="index"
                  class="pointer"
                  :class="{ selected: isEmployeeSelected }"
                  @click="assignAbsenceToEmployee(employee)"
              >
                <td>
                  {{ employee.name }} {{ employee.lastName }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <SharedPagination
              v-if="employees && totalResults > 10"
              class="pagination"
              :page="page"
              :total-results="totalResults"
              :page-size="itemsPerPage"
              style="padding: 0px 0px 20px 0px;"
              @change="page = $event; getEmployees()"
          />
        </div>
    </el-dialog>
  </div>
</template>

<script setup>

// Components
import SharedPagination from "@/components/shared/SharedPagination.vue";

// Services
import AbsenceService from '@/api/services/absence.service';
import AbsenceSupportingDocument from '@/api/services/absenceSupportingDocument.service';
import EmployeeService from '@/api/services/employee.service';
import ResourceService from '@/api/services/resource.service';
import Common from '@/api/services/common.service';

import moment from "moment";
import Notification from '@/utils/plugins/NotificationService'
import axios from 'axios';
import {computed, onMounted, ref, defineProps, shallowRef, h} from "vue";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {helpers, required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import {ElNotification} from "element-plus";
import dayjs from "dayjs";

const store = useStore();
const router = useRouter();
const route = useRoute();
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  profile: {type: Boolean, default: false},
})
const customPrefix = shallowRef({
  render() {
    return h('img', {
      src: '/calendar-blue.svg',
      style: 'width: 16px; height: 16px; margin-right: 10px;',
    });
  },
})

const defaultTime = ref([
  // fecha actual desde las 00:00
  new Date(new Date().setHours(0, 0, 0, 0)),
  // fecha actual hasta las 23:59
  new Date(new Date().setHours(23, 59, 59, 0))
]);
const absenceFormModel = ref({
  id: null,
  employee: null,
  employeeName: '',
  fromDate: null,
  toDate: null,
  absenceType: null,
  observations: null,
  absenceSupportingDocuments: []
});
const rules = computed(() => {
  return {
    employeeName: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    fromDate:  {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    toDate: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    absenceType: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    observations: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
  };
});

const v$ = useVuelidate(rules, absenceFormModel);
const v = v$.value;

const title = ref('');
const showModalEmployees = ref(false);
const page = ref(1);
const itemsPerPage = ref(10);
const totalResults = ref(null);
const searchName = ref('');
const employees = ref([]);
const absenceTypes = ref([]);
const params = ref({
  'order[name]': 'asc',
  page: 1,
  itemsPerPage: 10
});
const calculateDisabledDates = (time) => {
  if (absenceFormModel.value.fromDate) {
    let Yesterday = new Date(absenceFormModel.value.fromDate);
    Yesterday.setDate(Yesterday.getDate() - 1);
    return time.getTime() < Yesterday;
  } else {
    return false;
  }
}
const isEmployeeSelected = ref(false);
const dueDatePickerOptions = ref({
  firstDayOfWeek: 1,
  disabledDate: calculateDisabledDates
});
const file = ref(null);
const fileList = ref([]);
const newFileList = ref([]);
const baseUrl = ref(process.env.VUE_APP_BASE_URI);

const user = computed(() => {
  return store.state.user;
});

onMounted(() =>{
  if (ifIsInArray(user.value.roles, 'ROLE_ADMIN')) {
    if (props.profile) {
      getAbsence(store.state.absence.id)
    } else if (route.params.id && route.params.id === 'new') {
      title.value = 'Nueva Ausencia';
      // let today = new Date().setHours(0, 0, 0, 0);
      absenceFormModel.value.fromDate = defaultTime.value[0];
      // console.log(absenceFormModel.value.fromDate)
      // absenceFormModel.value.fromDate = new Date().setHours(0, 0, 0, 0);
      // absenceFormModel.value.fromDate.setHours(0, 0, 0, 0);
    } else {
      getAbsence(route.params.id)
    }
    getAbsenceTypes();
  }
})

const removeDocuments = () => {
  newFileList.value.forEach(doc => {
    AbsenceSupportingDocument.deleteAbsenceSupportingDocument(doc.id)
        .then(() => {
          Notification.addNotification('Justificante eliminado correctamente.', 'success', 6000);
        })
        .catch(() => {
          Notification.addNotification('Error al intentar eliminar la ausencia seleccionado.', 'success', 6000);

        })
  });
}
const focusOnInput = () => {
  setTimeout(() => {
    document.querySelector('#file').focus()
    file.value.focus()
  }, 1000)
}
const handleFileUpload = () => {
  const fileTypes = ['pdf', 'zip', 'rar', 'jpg', 'jpeg', 'png', 'doc', 'docx'];
  const newFile = file.value.files[0];
  const extension = newFile.name.split('.').pop().toLowerCase(),
      isSuccess = fileTypes.indexOf(extension) > -1;
  if (newFile && newFile.size < 5242880) {
    if (isSuccess) {
      const reader = new FileReader();
      reader.readAsBinaryString(newFile);
      reader.onloadstart = () => {
        file.value = 'loadStart';
      };
      reader.onload = () => {
        file.value = newFile;
        saveDocument();
      };
      reader.onerror = () => {
        file.value = 'error';
      };
    } else {
      Notification.addNotification('Este formato no está permitido.', 'error', 6000)
      file.value = null;
    }
  } else {
    Notification.addNotification('Tamaño máximo permitido 5 MB.', 'error', 6000)
    file.value = null;
  }
}
const saveDocument = async () => {
  // store.state.loading = true;
  store.commit('startLoading');
  await AbsenceSupportingDocument.uploadDocumentFile(file.value)
      .then((res) => {
        let id = res.data['@id'];
        absenceFormModel.value.absenceSupportingDocuments.push(id);
        fileList.value.push(res.data);
        newFileList.value.push(res.data);
        Notification.addNotification('Documento cargado correctamente', 'success', 6000);
        // store.state.loading = false;
        store.commit('stopLoading');
      })
      .catch(() => {
        Notification.addNotification('Error al cargar el documento', 'error', 6000);
        // store.state.loading = false;
        store.commit('stopLoading');
      });
}
const deleteSupportingDocument = (document, index) => {
  // store.state.loading = true;
  store.commit('startLoading');
  AbsenceSupportingDocument.deleteAbsenceSupportingDocument(document.id)
      .then(() => {
        Notification.addNotification('Justificante eliminado correctamente.', 'success', 6000);
        let value = fileList.value[index]['@id'];
        fileList.value.splice(index, 1);
        absenceFormModel.value.absenceSupportingDocuments = absenceFormModel.value.absenceSupportingDocuments.filter(e => e !== value);
        if (absenceFormModel.value.absenceSupportingDocuments.length === 0) {
          delete absenceFormModel.value.absenceSupportingDocuments;
        }
        // store.state.loading = false;
        store.commit('stopLoading');
      })
      .catch(() => {
        Notification.addNotification('Error al intentar eliminar la ausencia seleccionado.', 'success', 6000);
        // store.state.loading = false;
        store.commit('stopLoading');
      })
}
// const getSupportingDocuments = (supportingDocuments) => {
//   supportingDocuments.forEach(
//       doc => AbsenceSupportingDocument.getAbsenceAbsenceSupportingDocument(doc)
//           .then((res) => {
//             let doc = {
//               ...res.data
//             }
//             fileList.value.push(doc);
//           })
//           .catch(() => {
//             Notification.addNotification('Error al obtener justificantes', 'error', 6000);
//           })
//   )
// }

const downloadDocument = (url, label) => {
  let type = 'application/' + label.split('.').pop();
  axios.get(baseUrl.value + url, {responseType: 'blob'})
      .then(response => {
        const blob = new Blob([response.data], {type: type})
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = label
        link.click()
        URL.revokeObjectURL(link.href)
      }).catch(() => {
    Notification.addNotification('Error al descargar el justificante', 'error', 6000);
  })
}
const getAbsence = (id) => {
  // store.state.loading = true;
  store.commit('startLoading');
  AbsenceService.getAbsence(id)
      .then((res) => {
        const resultData = res.data && res.data.id ? res.data : route.push('/absences');
        absenceFormModel.value = {
          id: resultData.id,
          employee: resultData.employee,
          employeeName: resultData.employee.name + ' ' + resultData.employee.lastName,
          fromDate: resultData.fromDate,
          toDate: resultData.toDate,
          absenceType: resultData.absenceType,
          observations: resultData.observations,
          absenceSupportingDocuments: resultData.absenceSupportingDocuments.map(doc => doc['@id'])
        }

        fileList.value = resultData.absenceSupportingDocuments;
        // if (absenceFormModel.value.absenceSupportingDocuments) {
        //   getSupportingDocuments(absenceFormModel.value.absenceSupportingDocuments);
        // }
        title.value = 'Editar ausencia de: ' + absenceFormModel.value.employeeName;
      })
  // store.state.loading = false;
        store.commit('stopLoading');
}

const updateAbsence = (absence) => {
  // store.state.loading = true;
  store.commit('startLoading');
  AbsenceService.updateAbsence(absence, absence.id)
      .then(() => {
        router.push('/absences');
        // store.state.loading = false;
        store.commit('stopLoading');
        Notification.addNotification('Ausencia actualizada correctamente', 'success', 6000);
      })
      .catch(() => {
        Notification.addNotification('Error al actualizar la ausencia', 'error', 6000);
        // store.state.loading = false;
        store.commit('stopLoading');
      });
}
const createAbsence = (absence) => {
  // store.state.loading = true;
  store.commit('startLoading');
  AbsenceService.createAbsence(absence)
      .then(() => {
        router.push('/absences');
        Notification.addNotification('Ausencia creada correctamente', 'success', 6000);
        // store.state.loading = false;
        store.commit('stopLoading');
      })
      .catch(() => {
        Notification.addNotification('Error al crear la ausencia', 'error', 6000);
        // store.state.loading = false;
        store.commit('stopLoading');
      });
}

const updateAbsenceFromData = (absence)=>{
  const { absenceType, ...rest } = absence;
  const updatedAbsence = {
    ...rest, // Any other field
    absenceType: absenceType?.['@id'],
  };
  return updatedAbsence
}

const saveAbsence = () => {
  v$.value.$reset()
  v$.value.$validate();

  if (!v$.value.$invalid) {
    absenceFormModel.value.employee = absenceFormModel.value.employee['@id'];
    absenceFormModel.value.fromDate = dayjs.utc(absenceFormModel.value.fromDate).tz("Europe/Madrid").format();
    absenceFormModel.value.toDate = dayjs.utc(absenceFormModel.value.toDate).tz("Europe/Madrid").format();
    if (absenceFormModel.value.id != null) {
      updateAbsence(updateAbsenceFromData(absenceFormModel.value));
    } else {
      createAbsence(updateAbsenceFromData(absenceFormModel.value));
    }
  }else{
    ElNotification({
      type:'error',
      message: 'Error al crear el registro. Revise los campos marcados en rojo',
      duration: 6000
    })
  }

}
const  openModalEmployees = () => {
  showModalEmployees.value = true;
  getEmployees();
}
const closeModalEmployees = () => {
  showModalEmployees.value = false;
}
const filterEmployees = (name) => {
  if (name && name.length !== 0) {
    name = name.toLowerCase();
  }
  params.value = {
    ...params.value,
    orSearch_fullName: name,
  };
  getEmployees();
}
const getEmployees = (filters) => {
  params.value = {
    ...params.value,
    filter: filters,
    'order[name]': 'asc',
    'active': 1,
    page: page,
    itemsPerPage: itemsPerPage
  };
  EmployeeService.getEmployees(params.value)
      .then((res) => {
        employees.value = res.data['hydra:member'];
        totalResults.value = res.data['hydra:totalItems'];
      })
}
const getAbsenceTypes = (searchQuery = '') => {
  const params = {query: {name: searchQuery}};
  ResourceService.getAbsenceTypes(params)
      .then((res) => {
        absenceTypes.value = res.data['hydra:member'];
      })
}
const assignAbsenceToEmployee = (employee) => {
  absenceFormModel.value.employeeName = employee.name + ' ' + employee.lastName;
  absenceFormModel.value.employee = employee;
  closeModalEmployees('aceptar');
}
// eslint-disable-next-line no-unused-vars
const formatDate = (value) => {
  if (value) {
    return moment(value).format('YYYY-MM-DD HH:mm')
  }
  return '';
}

const ifIsInArray = (userRoles, role) =>{
  return Common.ifIsInArray(userRoles, role);
}

// eslint-disable-next-line no-unused-vars
const formatCompleteDate = (value) => {
  moment.locale('es');
  return moment(value).format('DD MMMM YYYY');
}

</script>

<style lang="scss" scoped>
.absence-item {

  .form-item {
    position: relative;
    width: 100%;
    margin-bottom: 0;
  }

  .absence-form {
    margin-top: 15px;
  }

  .label-absence {
    color: #434343;
    float: left;
    display: block;
    white-space: nowrap;
    margin-top: 10px;
  }

  .label-observations {
    margin-bottom: 12px;
  }

  .el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: auto !important;
  }
}

.table-employees, .table-documents {
  padding-top: 5px;

  table {
    width: 100%;
    color: #858796;

    th {
      border: 1px solid #e3e6f0;
      padding: 12px;
      padding-left: 20px;
      font-size: 20px;
      text-align: left;
    }

    tbody {
      .selected {
        background-color: rgba(0, 0, 0, .06);

        td {
          background-color: rgba(0, 0, 0, .06);
        }
      }

      tr:nth-child(odd) {
        td {
          background-color: rgba(0, 0, 0, .05);
        }
      }

      td {
        border: 1px solid #e3e6f0;
        padding: 12px;
        padding-left: 20px;
        text-align: left;

        a {
          font-size: 18px;
          color: #4e73df;
        }
      }
    }
  }
}
</style>
