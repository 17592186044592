<script setup>

import HeaderTitle from "@/components/layout/HeaderTitle.vue";
import TicketStatusService from "@/api/services/ticket.status.service";
import TicketTypeService from "@/api/services/ticket.type.service";
import {useStore} from "vuex";
import {h, onMounted, ref, shallowRef, computed, onUnmounted} from "vue";
import InternalTicketsRequested from "@/views/internal-tickets/InternalTicketsRequested.vue";
import InternalTicketsDepartment from "@/views/internal-tickets/InternalTicketsDepartment.vue";
import InternalTicketsAssigned from "@/views/internal-tickets/InternalTicketsAssigned.vue";
import TicketPriorityService from "@/api/services/ticket.priority.service";
import {useRouter} from "vue-router";

const store = useStore();
const router = useRouter();

const customPrefix = shallowRef({
  render() {
    return h('img', {
      src: '/calendar-blue.svg',
      style: 'width: 16px; height: 16px; margin-right: 10px;',
    });
  },
})

const totalTicketsResults = ref(0);
const refreshData = ref(false);
const tag = ref("");
const issue = ref("");
const type = ref(null);
const types = ref([]);
const priority = ref(null);
const priorities = ref([]);
const selectedFromDate = ref(null);
const selectedToDate = ref(null);
const statusTicket = ref(null);
const optionsStatus = ref([]);
const refreshInterval = 5 * 60 * 1000; // 5 minutes
let intervalId = null;
const activeTab = ref('assigned');

const setupAutoRefresh = () => {
  intervalId = setInterval(() => {
    totalTicketsResults.value = 0;
    refreshData.value = !refreshData.value;
  }, refreshInterval);
}

const clearAutoRefresh = () => {
  clearInterval(intervalId);
}

onMounted(async () => {
  await getTypes();
  await getStates();
  await getPriorities();
  setupAutoRefresh();
})

onUnmounted(() => {
  clearAutoRefresh();
})

const search = async () => {
  refreshData.value = !refreshData.value;
}

const getStates = async () => {
  const response = await TicketStatusService.getStates({itemsPerPage: 999999, page: 1});
  optionsStatus.value = response.data['hydra:member'];
}

const getTypes = async () => {
  const response = await TicketTypeService.getTypes({itemsPerPage: 999999, page: 1});
  types.value = response.data['hydra:member'];
}

const getPriorities = async () => {
  const response = await TicketPriorityService.getPriorities({itemsPerPage: 999999, page: 1});
  priorities.value = response.data['hydra:member'];
}

const sumTotalTicket = (total) => {
  totalTicketsResults.value += total;
}

// Combina los filtros fijos con los opcionales seleccionados
const combinedFilters = computed(() => {
  const filters = {}
  if (statusTicket.value) {
    // pasar statusTicket.value en mayusculas
    if (statusTicket.value === 'Enviado' || statusTicket.value === 'Abierto' || statusTicket.value === 'Reabierto') {
      filters['status'] = 'ABIERTO';
    }
    if (statusTicket.value === 'Trabajando' || statusTicket.value === 'En espera') {
      filters['status'] = 'EN CURSO';
    }
    if (statusTicket.value === 'Cerrado') {
      filters['status'] = statusTicket.value.toUpperCase();
    }
    // filters['status'] = statusTicket.value.toUpperCase();
  }
  if (type.value) {
    filters['ticketType.id'] = type.value;
  }

  if (priority.value) {
    filters['ticketPriority.id'] = priority.value;
  }

  if (tag.value) {
    // filters.tag = tag.value;
    filters.ticketCode = tag.value;
  }

  if (issue.value) {
    filters.issue = issue.value;
  }

  if (selectedFromDate.value && selectedToDate) {
    filters["createdAt[before]"] = selectedToDate.value;
    filters["createdAt[after]"] = selectedFromDate.value;
  }
  return filters;
});

const setActiveTab = (tabId) => {
  activeTab.value = tabId;
}

const newInternalTicket = () => {
  router.push({name: 'new_internal_ticket'});
}

</script>

<template>
  <div id="tickets">
    <header-title title="Tickets Internos"/>
    <el-row>
      <el-card
          class="card-search w-100 wback"
          body-style="padding:0"
      >
        <div class="row mx-5">
          <div class="col-xl-3 col-md-6 col-lg-4">
            <div class="form-item date-picker mt-0 w-100 label-common pl-5">
              <label
                  for="ffrom"
                  class="w-100">Fecha desde</label>
              <el-date-picker
                  id="ffrom"
                  v-model="selectedFromDate"
                  name="from-dat'"
                  type="date"
                  placeholder="Fecha desde"
                  format="YYYY/MM/DD"
                  value-format="YYYY-MM-DD"
                  :prefix-icon="customPrefix"
                  :picker-options="{firstDayOfWeek: 1}"
                  @change="search()"
              />
            </div>
          </div>
          <div class="col-xl-3 col-md-6 col-lg-4">
            <div class="form-item date-picker mt-0 w-100 label-common pl-5">
              <label
                  for="fto"
                  class="w-100">Fecha hasta</label>
              <el-date-picker
                  id="fto"
                  v-model="selectedToDate"
                  name="from-date"
                  type="date"
                  placeholder="Fecha hasta"
                  format="YYYY/MM/DD"
                  value-format="YYYY-MM-DD"
                  :prefix-icon="customPrefix"
                  :picker-options="{firstDayOfWeek: 1}"
                  @change="search()"
              />
            </div>
          </div>
          <div class="col-xl-3 col-md-6 col-lg-4">
            <div class="form-item mt-0 w-100 label-common pl-5">
              <label
                  for="id-status"
                  class="w-100">Estado</label>
              <el-select
                  v-model="statusTicket"
                  filterable
                  clearable
                  @change="search()"
              >
                <el-option
                    v-for="item in optionsStatus"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                />
              </el-select>
            </div>
          </div>
          <div class="col-xl-3 col-md-4 col-lg-4">
            <div class="form-item mt-0 w-100 label-common">
              <label
                  for="domain-name"
                  class="w-100">Código</label>
              <el-input
                  id="title"
                  v-model="tag"
                  class="search-input"
                  placeholder="Código"
                  clearable
                  @change="search()"
              />
            </div>
          </div>
          <div class="col-xl-3 col-md-3 col-lg-3">
            <div class="form-item label-common">
              <label class="w-100 mt-0">Tipo</label>
              <el-select
                  v-model="type"
                  filterable
                  clearable
                  placeholder="TODOS"
                  @change="search()"
                  style="width: 90%"
              >
                <el-option
                    v-for="item in types"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                />
              </el-select>
            </div>
          </div>
          <div class="col-xl-3 col-md-3 col-lg-3">
            <div class="form-item label-common">
              <label class="w-100 mt-0">Prioridad</label>
              <el-select
                  v-model="priority"
                  filterable
                  clearable
                  placeholder="TODOS"
                  @change="search()"
                  style="width: 90%"
              >
                <el-option
                    v-for="item in priorities"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                />
              </el-select>
            </div>
          </div>
          <div class="col-xl-6 col-md-6 col-lg-6">
            <div class="form-item mt-0 w-100 label-common">
              <label
                  for="domain-name"
                  class="w-100">Asunto</label>
              <el-input
                  id="title"
                  v-model="issue"
                  class="search-input"
                  placeholder="Asunto"
                  clearable
                  @change="search()"
              />
            </div>
          </div>
        </div>
        <div
            class="col-xl-12 col-md-12 col-lg-12 mt-0 pe-5 d-flex justify-content-end align-items-end"
            style="padding-right: 0;">
          <button
              class="btn btn_darkpink btn-new mb-3"
              @click="newInternalTicket()"
          >
            NUEVO TICKET
          </button>
          <button
              class="btn btn_yellow btn-search mb-3"
              @click="search()">
            Buscar
          </button>
        </div>
      </el-card>
      <div
          class="mt-2"
          v-if="!store.state.loading">
        <span class="text-result">{{ totalTicketsResults }} Tickets </span>
        <span class="text-result-blue"> totales</span>
      </div>
    </el-row>
    <nav
        class="mt-3"
        v-if="!store.state.loading">
      <div
          class="nav nav-tabs"
          id="nav-tab"
          role="tablist">
        <button
            :class="['nav-link', { active: activeTab === 'assigned' }]"
            id="assigned-tab"
            @click="setActiveTab('assigned')"
            data-bs-toggle="tab"
            data-bs-target="#assigned"
            type="button"
            role="tab"
            aria-controls="assigned"
            aria-selected="true">
          <div class="d-flex">
            <img
                src="@/assets/icons/group.svg"
                width="30"
                alt="tickets asignados">
            <div class="title-text pl-4">
              Tickets asignados
            </div>
          </div>
        </button>
        <button
            v-if="store.getters.getUserLogged && store.getters.getUserLogged.departmentManager"
            :class="['nav-link', { active: activeTab === 'department' }]"
            id="department-tab"
            @click="setActiveTab('department')"
            data-bs-toggle="tab"
            data-bs-target="#department"
            type="button"
            role="tab"
            aria-controls="department"
            aria-selected="false">
          <div class="d-flex">
            <img
                src="@/assets/icons/group.svg"
                width="30"
                alt="Tickets Departamento">
            <div class="title-text pl-4">
              Tickets Departamento
            </div>
          </div>
        </button>
        <button
            :class="['nav-link', { active: activeTab === 'requested' }]"
            id="requested-tab"
            @click="setActiveTab('requested')"
            data-bs-toggle="tab"
            data-bs-target="#requested"
            type="button"
            role="tab"
            aria-controls="requested"
            aria-selected="false">
          <div class="d-flex">
            <img
                src="@/assets/icons/group.svg"
                width="30"
                alt="Tickets solicitados">
            <div class="title-text pl-4">
              Tickets solicitados
            </div>
          </div>
        </button>
      </div>
    </nav>
    <div
        class="tab-content"
        id="nav-tabContent">
      <div
          v-show="activeTab === 'assigned'"
          class="tab-pane fade show active"
          id="assigned"
          role="tabpanel"
          aria-labelledby="assigned-tab">
        <InternalTicketsAssigned
            @totalTicket="sumTotalTicket"
            :refreshData="refreshData"
            :filters="combinedFilters"/>
        <!--        <el-row>-->
        <!--          <SharedPagination-->
        <!--              v-if="tickets && totalTicketsResults > itemsPerPage.value"-->
        <!--              class="pagination"-->
        <!--              :page="page"-->
        <!--              :total-results="totalTicketsResults"-->
        <!--              :page-size="itemsPerPage.value"-->
        <!--              @change="page = $event;getTickets();"-->
        <!--          />-->
        <!--        </el-row>-->
      </div>

      <div
          v-if="store.getters.getUserLogged && store.getters.getUserLogged.departmentManager"
          v-show="activeTab === 'department'"
          class="tab-pane fade show active"
          id="department"
          role="tabpanel"
          aria-labelledby="department-tab">
        <InternalTicketsDepartment
            @totalTicket="sumTotalTicket"
            :refreshData="refreshData"
            :filters="combinedFilters"/>
      </div>

      <div
          v-show="activeTab === 'requested'"
          class="tab-pane fade show active"
          id="requested"
          role="tabpanel"
          aria-labelledby="requested-tab">
        <el-row>
          <InternalTicketsRequested
              @totalTicket="sumTotalTicket"
              :refreshData="refreshData"
              :filters="combinedFilters"/>
        </el-row>
<!--        <el-row class="text-right">-->
<!--          <el-col>-->
<!--            <router-link-->
<!--                :to="{name: 'new_internal_ticket'}"-->
<!--                class="btn btn_darkpink search btn-new"-->
<!--            >-->
<!--              NUEVO TICKET-->
<!--            </router-link>-->
<!--          </el-col>-->
<!--        </el-row>-->
      </div>
    </div>


  </div>
</template>

<style
    scoped
    lang="scss">

#tickets {
  .buttons {
    margin-left: auto;
  }

  .type-color {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 5px;
  }

  .title-text {
    text-align: center;
    align-self: center;

    font-size: 3em;
    font-weight: 900;
    letter-spacing: -0.05em;
    color: $font-blue-color;
  }

  .card-search {
    border-radius: 15px;
    height: 250px;
    background: url("../../assets/icons/altavoz.png"), white;
    background-repeat: no-repeat;
    background-position-x: 100%;
    overflow: visible !important;
  }

  #icon-search {
    fill: $bg-blue;
    width: 6%;
  }

  .label-common {
    label {
      font-size: 20px;
      font-weight: 800;
      letter-spacing: -2px;
    }
  }

  .btn-new {
    width: 20em;
    height: 3em;
    color: white;
    font-weight: bolder;
    text-transform: uppercase;
  }

  .table-domains {
    table {
      thead {
        tr {
          th {
            padding: 0 !important;
            margin: 0 !important;
          }
        }

        #icon-search {
          fill: $bg-blue;
          width: 2.9em;
        }

        #icon-user {
          fill: $bg-blue;
          width: 1.6em;
        }
      }

      tbody {
        td {
          .link-name {
            margin: auto;
            margin-left: 10px;
          }
        }
      }
    }
  }

  .close-icon {
    width: 30px;
    height: 30px;
  }

  #asterisk {
    position: absolute;
    vertical-align: super;
    height: auto;
    width: 2.2em;
    fill: #f9156f;
  }

  .el-dialog__header {
    padding: 0;
  }

  .my-header {
    margin-top: 2px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .text-header {
    text-align: left;
    font-size: 4em;
    font-weight: 900;
    letter-spacing: -0.05em;
    color: #f9156f;
  }

  .text-result {
    text-align: left;
    font-size: 2em;
    font-weight: 900;
    letter-spacing: -0.05em;
    color: #f9156f;

  }

  .text-result-blue {
    text-align: left;
    font-size: 2em;
    font-weight: 400;
    letter-spacing: -0.05em;
    color: $bg-blue;

  }

  .wback {
    background-image: url('../../assets/images/ticket-back.png');
    background-repeat: no-repeat;
    background-size: contain;
  }
}
</style>
