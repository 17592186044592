<template>
  <div>
    <header-title title="Producción" />
    <div class="d-flex flex-row justify-content-start my-2">
      <svg id="icon-search" xmlns="http://www.w3.org/2000/svg" class="search-icon" viewBox="0 0 1024 1024">
        <path
          d="M641.8 737.4c-66.4 47.3-147.7 75.2-235.5 75.2-224.4 0-406.3-181.9-406.3-406.3s181.9-406.3 406.3-406.3c224.4 0 406.3 181.9 406.3 406.3 0 87.8-27.8 169.1-75.2 235.5l266.9 266.9c26.3 26.3 26.1 68.4 0 94.5l-1.1 1.1c-26 26-68.4 26.1-94.5 0l-266.9-266.9zM406.3 717c171.6 0 310.7-139.1 310.7-310.7s-139.1-310.7-310.7-310.7c-171.6 0-310.7 139.1-310.7 310.7s139.1 310.7 310.7 310.7v0z" />
      </svg>
      <div class="title-text ml-4">
        Proyectos
      </div>
    </div>
    <el-row>
      <el-card class="card-search w-100" body-style="padding:0">
        <div class="row">
          <div class="col-md-4 d-flex flex-column justify-content-start">
            <div class="d-flex flex-column flex-nowrap w-100 label-common">
              <div class="form-item">
                <label for="name">Nombre</label>
                <el-input type="text" placeholder="Ingrese nombre del proyecto" v-model="name" clearable
                  @change="onSearch()" />
              </div>
            </div>
          </div>
          <div class="col-md-5 d-flex flex-column justify-content-start">
            <div class="d-flex flex-nowrap flex-column  w-100 label-common">
              <div class="form-item">
                <label class="w-100">Cliente</label>
                <el-select v-model="client" placeholder="Seleccione un cliente" clearable size="large" class="w-100"
                  :value-key="'@id'" filterable @change="onSearch()">
                  <el-option v-for="item in optionsClient" :key="item.id" :label="item.name" :value="item" />
                </el-select>
              </div>
            </div>
          </div>
          <div class="col-md-3 d-flex flex-column justify-content-start" />
        </div>
        <div class="row">
          <div class="col-md-4 d-flex flex-column justify-content-start">
            <div class="w-100">
              <div class="d-flex flex-column flex-nowrap">
                <div class="form-item w-100">
                  <label for="projectType.id" class="w-100">Tipo</label>
                  <el-select size="large" class="w-100" v-model="type" placeholder="Seleccione un tipo" clearable
                    :value-key="'@id'" filterable @change="onSearch()">
                    <el-option v-for="item in optionsType" :key="item.id" :label="item.name" :value="item" />
                  </el-select>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2 d-flex flex-column justify-content-start">
            <div class="w-100">
              <div class="d-flex flex-column flex-nowrap">
                <div class="form-item w-100">
                  <label for="projectType.id" class="w-100">Archivado</label>
                  <el-select size="large" class="w-100" v-model="archived" placeholder="TODOS" clearable filterable
                    @change="onSearch()">
                    <el-option :value="true" label="Si" />
                    <el-option :value="false" label="No" />
                  </el-select>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2 d-flex flex-column justify-content-start">
            <div class="w-100">
              <div class="d-flex flex-column flex-nowrap">
                <div class="form-item w-100">
                  <label for="projectType.id" class="w-100">Consumo mínimo</label>
                  <el-select size="large" class="w-100" v-model="minConsumedTime" placeholder="TODOS" clearable
                    filterable @change="onSearch()">
                    <el-option :value="true" label="Si" />
                  </el-select>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 d-flex flex-column justify-content-start">
            <div class="w-100">
              <div class="form-item">
                <label for="name">Etiqueta</label>
                <el-input type="text" placeholder="Ingrese la etiqueta" v-model="tag" clearable @change="onSearch()" />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="ml-auto d-flex justify-content-end align-items-end">
            <div class="mt-3">
              <button class="ml-auto btn btn_yellow search" @click="onSearch()">
                Buscar
              </button>
            </div>
          </div>
        </div>
      </el-card>
    </el-row>

    <el-row>
      <div class="table table-users">
        <div v-if="projects && projects.length > 0">
          <table>
            <thead>
              <tr>
                <th>
                  <div class="d-flex flex-row justify-content-start align-items-center ml-4">
                    <div class="mx-2">
                      <img src="@/assets/icons/pc-icon.svg" width="20" height="20" alt="name-icon" />
                    </div>
                    <div>NOMBRE</div>
                  </div>
                </th>
                <th>
                  <div class="d-flex flex-row justify-content-start align-items-center ml-4">
                    <div class="mx-2">
                      <img src="@/assets/icons/pc-icon.svg" width="20" height="20" alt="name-icon" />
                    </div>
                    <div>CLIENTE</div>
                  </div>
                </th>
                <th>
                  <div class="d-flex flex-row justify-content-start align-items-center ml-4">
                    <div class="mx-2">
                      <img src="@/assets/icons/pc-icon.svg" width="20" height="20" alt="name-icon" />
                    </div>
                    <div class="ml-2">TIPO</div>
                  </div>
                </th>
                <th>
                  <div class="d-flex flex-row justify-content-start align-items-center ml-4">
                    <div class="mx-2">
                      <img src="@/assets/icons/pc-icon.svg" width="20" height="20" alt="name-icon" />
                    </div>
                    <div class="ml-2">ETIQUETA</div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="project in projects" :key="project.id">
                <td>
                  <div class="row-table-image-name-link">
                    <div class="link-name">
                      <router-link :to="{ name: 'show-projects', params: { id: project.id } }">
                        {{ project.name }}
                      </router-link>
                    </div>
                  </div>
                </td>
                <td class="pointer">{{ project.client.name }}</td>
                <td class="pointer">{{ project.projectType.name }}</td>
                <td class="pointer">
                  <div class="d-flex justify-content-between">
                    <div>{{ project.tag }}</div>
                    <div class="d-flex">
                      <shared-button-plus
                        @onClick="router.push({ name: 'show-projects', params: { id: project.id } })" />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else>
          <h5>No se han encontrado proyectos según los filtros especificados...</h5>
        </div>
        <SharedPagination v-if="projects && totalResults > itemsPerPage" class="pagination" :page="page"
          :total-results="totalResults" :page-size="itemsPerPage" @change="page = $event; onSearch();" />
      </div>
    </el-row>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { ElNotification } from 'element-plus'
import ProjectsService from '@/api/services/projects.service'
import SharedPagination from '@/components/shared/SharedPagination.vue'
import HeaderTitle from '../../components/layout/HeaderTitle.vue'
import SharedButtonPlus from '@/components/shared/SharedButtonPlus.vue'

const store = useStore()
const router = useRouter()

// Estado
// const id = ref('')
const name = ref('')
const client = ref('')
const type = ref('')
const archived = ref(false)
const minConsumedTime = ref(null)
const optionsClient = ref([])
const optionsType = ref([])
const projects = ref([])
const page = ref(1)
const itemsPerPage = ref(10)
const totalResults = ref(0)
const tag = ref('')

// Métodos
const getTypes = async () => {
  // store.state.loading = true;
  store.commit('startLoading');
  try {
    const result = await ProjectsService.getTypes()
    optionsType.value = result.data['hydra:member']
  } catch (err) {
    console.error('Types', err)
    ElNotification({
      title: 'Proyectos',
      message: 'Error al obtener los tipos de proyecto' + err,
      type: 'error',
      duration: 6000
    })
  } finally {
    // store.state.loading = false
    store.commit('stopLoading');
  }
}

const getClients = async () => {
  // store.state.loading = true
  store.commit('startLoading');
  try {
    const result = await ProjectsService.getClients()
    optionsClient.value = result.data['hydra:member']
  } catch (err) {
    console.error('Types', err)
    ElNotification({
      title: 'Proyectos',
      message: 'Error al obtener los clientes' + err,
      type: 'error',
      duration: 6000
    })
  } finally {
    // store.state.loading = false
    store.commit('stopLoading');
  }
}

const onSearch = async () => {
  const params = {
    page: page.value,
    itemsPerPage: itemsPerPage.value,
    archived: archived.value,
    'order[name]': 'ASC'
  }

  if (name.value) params.name = name.value
  if (type.value?.name) params['projectType.name'] = type.value.name
  if (client.value?.name) params['client.name'] = client.value.name
  if (tag.value) params.tag = tag.value
  if (minConsumedTime.value === true) {
    params['minConsumptionHours[gt]'] = 0
    params['reviewPeriodMonths[gt]'] = 0
  }

  // store.state.loading = true
  store.commit('startLoading');
  try {
    const result = await ProjectsService.getProjects(params)
    projects.value = result.data['hydra:member']
    totalResults.value = result.data['hydra:totalItems']
  } catch (err) {
    ElNotification({
      title: 'Proyectos',
      message: 'Error al obtener los proyectos ' + err,
      type: 'error',
      duration: 6000
    })
  } finally {
    // store.state.loading = false
    store.commit('stopLoading');
  }
}

const syncClockifyProjects = async () => {
  // store.state.loading = true
  store.commit('startLoading');
  try {
    const result = await ProjectsService.syncClockifyProjects()
    console.log('Sync Clockify Projects OK', result)
  } catch (err) {
    console.error('Error SyncClockifyProjects', err)
    ElNotification({
      title: 'Proyectos',
      message: 'Error al sincronizar los proyectos de clockify.',
      type: 'error',
      duration: 6000
    })
  } finally {
    // store.state.loading = false
    store.commit('stopLoading');
  }
}

// Hooks del ciclo de vida
onBeforeMount(() => {
  getTypes()
  getClients()
})

onMounted(() => {
  onSearch()
  syncClockifyProjects()
})
</script>

<style lang="scss" scoped>
.title-text {
  text-align: left;
  font-size: 3em;
  font-weight: 900;
  letter-spacing: -0.05em;
  color: $font-blue-color;

}

#icon-search {
  fill: $bg-blue;
  width: 3%;

}

#icon-nombre {
  width: 5%;
  height: 5%;
}

.bombilla {
  float: right;
  width: 60%;
  height: 90%;

  /* border-top-right-radius: 20px; */
}

.card-search {
  border-radius: 15px;
  height: 285px;
  padding: 40px;
  background: url('../../assets/icons/bombilla.png'), white;
  background-repeat: no-repeat;
  background-position-x: 100%;
  overflow: visible !important;


}

.label-common {
  label {
    font-size: 20px;
    font-weight: 800;
    letter-spacing: -2px;


  }


}

.search-input {
  width: 100%;
}

.search {
  width: 20em;
  height: 3em;
  color: $bg-blue;
  font-weight: bolder;
  text-transform: uppercase;
}

.table-users {
  table {
    thead {
      tr {
        th {
          padding: 0px !important;
          margin: 0px !important;
        }
      }
    }

    tbody {
      td {
        .link-name {
          margin: auto;
          margin-left: 10px;
        }
      }
    }
  }
}

.pagination {
  padding: 0px 0px 20px 0px;
}

.row-table-image-name-link {
  display: flex;
  align-items: stretch;
}
</style>
