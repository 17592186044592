<template>
  <div>
    <HeaderTitle title="Ajustes" />
    <div class="d-flex flex-row justify-content-start">
      <img src="@/assets/icons/group.svg" width="30" alt="">
      <div class="title-text pl-4">
        Centros de Trabajo
      </div>
    </div>
    <el-card style="height: 200px" class="card-search w-100 wback" body-style="padding:0">
      <div class="row mx-5">
        <div class="container-workplaces">
          <div class="title-workplaces">
            <div class="row">
              <div class="col-md-8">
                <div class="row">
                  <div class="col-md-6">
                    <div class=" form-item  date-picker  label-common">
                      <label class=" w-100 mb-2">Nombre</label>
                      <el-input v-model="searchFilterWorkplaceName" placeholder="Nombre del Centro de Trabajo"
                        prefix-icon="el-icon-search" clearable @change="search()" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="ml-auto d-flex justify-content-end align-items-end">
              <div class="">
                <button @click="createNewWorkplace" class="btn btn_darkpink btn-search"
                  style="min-width:250px; max-width: 300px; color:white;">
                  NUEVO CENTRO DE TRABAJO
                </button>
              </div>
              <div class="">
                <button style="max-width: 250px;" class="btn btn_yellow btn-search" @click="search()">
                  Buscar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-card>
    <div class="table">
      <div v-if="workplaces && workplaces.length > 0">
        <table>
          <thead>
            <tr>
              <th>Nombre Centro de Trabajo</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="workplace in workplaces" :key="workplace.id">
              <td>
                {{ workplace.name }}
              </td>
              <td>
                <div class="buttons d-inline-flex">
                  <div class="pointer" @click="editWorkplace(workplace)">
                    <font-awesome-icon class="btn_icon blue_btn" icon="eye" title="Editar" style="margin-left: 5px" />
                  </div>
                  <div class="pointer" @click="deleteDialogShown = true; selectedWorkplace = workplace;">
                    <font-awesome-icon class="btn_icon delete_btn" icon="times" title="Eliminar"
                      style="margin-left: 5px" />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <h5>No se han encontrado centros de trabajo</h5>
      </div>
      <SharedPagination v-if="workplaces && workplaces.length > 4 && totalResults > itemsPerPage" class="pagination"
        :page="page" :total-results="totalResults" :page-size="itemsPerPage" style="padding: 0px 0px 20px 0px;"
        @change="page = $event; search()" />

    </div>

    <el-dialog v-model="createOrUpdateWorkplace" class="dense-dialog el-dialog" @closed="closeCreateOrUpdateDialog()"
      :show-close="false" size="'medium'" width="850" :center="true" :lock-scroll="true"
      :style="{ borderRadius: `var(--el-border-radius-round)` }">
      <div class="my-header">
        <svg id="asterisk" xmlns="http://www.w3.org/2000/svg" width="724" height="1024" viewBox="0 0 724 1024">
          <path
            d="M0 499.8l59-181.7 332.5 108.6-59 180.8-332.5-107.7zM79.6 744.3l206.1-282.9 154.5 111.5-206.1 283.8-154.5-112.4zM266.9 167.3h191.1v350.3h-191.1v-350.3zM285.6 572.9l154.5-111.5 206.1 282.9-154.5 112.4-206.1-283.8zM332.5 426.8l332.5-108.6 59 181.7-331.6 107.7-59.9-180.8z" />
        </svg>
        <span class="text-header ml-5 mt-3">{{ selectedWorkplace ? 'Actualizar' : 'Nuevo' }} Centro de trabajo</span>
        <img class="close-icon pointer" src="@/assets/images/aspa_blue_small_entrada.png" alt=""
          @click="closeCreateOrUpdateDialog()">
      </div>
      <div class="my-body">
        <form @submit.prevent="">
          <div class="form-group row">
            <div class="col-md-10">
              <div class="form-item">
                <label for="workplaceName" class="w-100">Nombre del Centro de Trabajo:</label>
                <el-input v-model="workplaceName" :placeholder="'Nombre del Centro de Trabajo'" class="mt-1" />
                <p v-if="errorWorkplace.name" class="invalid-feedback mb-0">
                  <small>
                    Este campo es obligatorio
                  </small>
                </p>
              </div>
            </div>
            <div class="col-md-10">
              <div class="form-item row">
                <div class="col-md-6">
                  <label for="workplaceProvinces" class="w-100">Provincia</label>
                  <el-select v-model="province.id" filterable placeholder="Provincia"
                    @focus="selectProvinceFocus = true" @blur="selectProvinceFocus = false"
                    @change="getLocationsById(province.id)">
                    <el-option v-for="province in provinces" :key="province.id" :label="province.name"
                      :value="province.id">
                    </el-option>
                  </el-select>
                  <p v-if="errorWorkplace.province" class="invalid-feedback mb-0">
                    <small>
                      Este campo es obligatorio
                    </small>
                  </p>
                </div>
                <div class="col-md-6">
                  <label for="workplaceLocations" class="w-100">Localidad</label>
                  <el-select v-model="location.id" placeholder="Localidad" filterable :disabled='selectProvinceFocus'
                    @change="changeLocation(location.id)">
                    <el-option v-for="location in locations" :key="location.id" :label="location.name"
                      :value="location.id">
                    </el-option>
                  </el-select>
                  <p v-if="errorWorkplace.location" class="invalid-feedback mb-0">
                    <small>
                      Este campo es obligatorio
                    </small>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-10">
              <div class="form-item">
                <label for="workplaceAddress" class="w-100">Direccion:</label>
                <el-input v-model="workplaceAddress" :placeholder="'Dirección del centro de trabajo'" class="mt-1" />
              </div>
            </div>
            <div class="col-md-10">
              <div class="form-item">
                <label for="workplacePostalCode" class="w-100">Codigo Postal:</label>
                <el-input v-model="postalCode" :placeholder="'Codigo Postal'" class="mt-1" @input="filterNumbers"
                  maxlength="5" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 offset-md-4 d-flex flex-column justify-content-end align-items-end">
                <button class="btn btn_darkpink"
                  @click="selectedWorkplace ? handleUpdateWorkplace() : createWorkplace(workplace)">
                  {{ selectedWorkplace ? 'Actualizar' : 'Nuevo' }}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </el-dialog>

    <el-dialog v-model="deleteDialogShown" class="dense-dialog"
      :title="'¿Desea eliminar permanentemente este centro de trabajo?'"
      @closed="selectedWorkplace = null; deleteDialogShown = false;">
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="selectedWorkplace = null; deleteDialogShown = false;">
            Cancelar
          </el-button>
          <el-button type="primary" @click="deleteWorkplace(selectedWorkplace)">Confirmar</el-button>
        </div>
      </template>
    </el-dialog>


    <!--    Direccion Postal del Centro de Trabajo-->
    <shared-modal v-if="showModalWorkplaceAddress" :title="'Dirección postal'" :theme="'secundary'" size="'medium'"
      @close="closeModalWorkplaceAddress()">
      <template #content>
        <div class="modal_address">
          <form class="address-form" @submit.prevent="saveEmployee(employee)">
            <!--            Calle-->
            <label style="color: black">Dirección</label>
            <SharedInput v-model="employee.address" :param="'employeeAdress'" :color="'address'"
              :rules="{ required: true }" :placeholder="'Dirección de la calle'" :validate="submitted" />


            <!--                <div class="col-md-4" v-if="!employee.province || employee.province && employee.province.id === 1">-->
            <div class="form-item">
              <div class="row">
                <div class="col-md-6">
                  <label for="employeeProvinces" style="color: black">Provincia</label>
                  <SharedDropdown id="employeeProvinces" v-model="employee.province" :items="provinces"
                    :selected="employee.province ? employee.province : province" :searchable="true"
                    data-vv-as="provincia" :rules="{ required: submitted }" data-vv-name="employeeProvinces"
                    :label="'employeeProvinces'" :removable="false" :param="'name'"
                    :is-invalid="submitted && errors.has('employeeProvinces')" :color="'address'" :title="'Provincia'"
                    @search="getProvinces($event)"
                    @selectItem="province = $event; employee.province = province; employee.postalCode = province.id; getLocationsByProvince(province);" />
                  <div v-if="submitted && errors.has('employeeProvinces')" class="animated fadeIn invalid-feedback">
                    <font-awesome-icon class="icon" icon="info-circle" />
                    {{ errors.first('employeeProvinces') }}
                  </div>
                </div>
                <div class="col-md-6">
                  <label for="employeeLocations" style="color: black">Localidad</label>
                  <!--                    @search="getLocations($event)"-->
                  <SharedDropdown id="employeeLocations" v-model="employee.location" :items="locations"
                    :selected="employee.location ? employee.location : location" :searchable="true"
                    data-vv-as="localidad" :rules="{ required: submitted }" data-vv-name="employeeLocations"
                    :label="'employeeLocations'" :removable="false" :param="'name'"
                    :is-invalid="submitted && errors.has('employeeLocations')" :color="'address'" :title="'Localidad'"
                    @search="getLocations($event)" @selectItem="location = $event; employee.location = location;" />
                  <div v-if="submitted && errors.has('employeeLocations')" class="animated fadeIn invalid-feedback">
                    <font-awesome-icon class="icon" icon="info-circle" />
                    {{ errors.first('employeeLocations') }}
                  </div>
                </div>
              </div>
            </div>
            <div class="form-item">
              <label style="color: black">Código postal</label>
              <SharedInput v-model="employee.postalCode" :type="'number'" :param="'employeePostalCode'"
                :color="'address'" :rules="{ required: true }" :placeholder="'Código postal'" :validate="submitted" />
            </div>
          </form>
          <br>
          <div class="row item">
            <div class="col">
              <button class="btn btn-block btn_red" @click="closeModalWorkplaceAddress('cancelar')">
                Cancelar
              </button>
            </div>
            <div class="col">
              <button class="btn btn_blue" @click="closeModalWorkplaceAddress('aceptar')">
                Aceptar
              </button>
            </div>
          </div>
        </div>
      </template>
    </shared-modal>
  </div>
</template>

<script>
// Components
//import SharedDialog from "../../../components/shared/SharedDialog.vue";
import SharedPagination from "../../../components/shared/SharedPagination.vue";
import SharedInput from "../../../components/shared/SharedInput.vue";
import SharedDropdown from "../../../components/shared/SharedDropdown.vue";
import SharedModal from "../../../components/shared/SharedModal.vue";
import HeaderTitle from "@/components/layout/HeaderTitle.vue";

// Services
import ResourceService from '@/api/services/resource.service';
import Notification from '@/utils/plugins/NotificationService';
import { ElNotification } from "element-plus";


export default {
  name: "Workplaces",
  components: {
    SharedPagination: SharedPagination,
    SharedInput: SharedInput,
    SharedDropdown: SharedDropdown,
    SharedModal: SharedModal,
    HeaderTitle: HeaderTitle,
  },
  props: {},
  data() {
    return {
      workplace: {},
      workplaces: [],
      submitted: false,
      deleteDialogShown: false,
      selectedWorkplace: null,
      workplaceName: '',
      workplaceAddress: '',
      postalCode: '',

      location: {},
      locations: [],
      province: {},
      provinces: [],

      showModalWorkplaceAddress: false,

      page: 1,
      itemsPerPage: 10,
      totalResults: null,

      createOrUpdateWorkplace: false,
      searchFilterWorkplaceName: '',

      selectProvinceFocus: false,
      errorWorkplace: {
        name: false,
        province: false,
        location: false,
      }
    }
  },
  computed: {},
  mounted() {
    this.getWorkplaces();
    this.getProvinces();
  },
  methods: {
    getWorkplaces(searchQuery = '') {
      const params = { name: searchQuery, page: this.page, itemsPerPage: this.itemsPerPage };
      ResourceService.getWorkplaces(params)
        .then((res) => {
          this.workplaces = res.data['hydra:member'];
          this.totalResults = res.data['hydra:totalItems']
        })
    },
    deleteWorkplace(workplace) {
      ResourceService.deleteWorkplace(workplace.id)
        .then(() => {
          Notification.addNotification('Centro de trabajo eliminado correctamente.', 'success', 6000);
          this.selectedWorkplace = null;
          this.deleteDialogShown = false;
          this.getWorkplaces();
        }).catch(() => {
          Notification.addNotification('Error al eliminar el centro de trabajo seleccionado', 'error', 6000);
          // this.$store.state.loading = false;
          this.$store.commit('stopLoading');
        })
    },
    createWorkplace(workplace) {
      if (this.checkErrors()) return;
      workplace.name = this.workplaceName;
      this.selectedWorkplace.province = this.workplace.province['@id'];
      this.selectedWorkplace.location = this.workplace.location['@id'];
      workplace.address = this.workplaceAddress;
      workplace.postalCode = parseInt(this.postalCode);
      // this.$store.state.loading = true;
      this.$store.commit('startLoading');
      ResourceService.createWorkplace(workplace)
        .then(() => {
          ElNotification({
            type: 'success',
            message: 'Centro de trabajo creado correctamente',
            duration: 6000
          })
          // this.$store.state.loading = false;
          this.$store.commit('stopLoading');
          this.cancel()
        })
        .catch(() => {
          Notification.addNotification('Error al crear el centro de trabajo', 'error', 6000);
          // this.$store.state.loading = false;
          this.$store.commit('stopLoading');
        })
        .finally(() => {
          this.closeCreateOrUpdateDialog()
        })

    },
    editWorkplace(workplace) {
      this.selectedWorkplace = workplace;
      this.workplaceName = workplace.name;
      this.createOrUpdateWorkplace = true
      if (workplace.province) {
        this.getLocationsById(workplace.province.id);
        this.province = workplace.province
      } else {
        this.province = {};
      }
      if (workplace.location) {
        this.location = workplace.location;
        this.workplace.location = this.location;
      } else {
        this.location = {};
      }
      this.workplaceAddress = workplace.address;
      this.postalCode = workplace.postalCode;
    },
    handleUpdateWorkplace() {
      if (this.checkErrors()) return;
      this.selectedWorkplace.name = this.workplaceName;
      this.selectedWorkplace.province = this.workplace.province['@id'];
      this.selectedWorkplace.location = this.workplace.location['@id'];
      this.selectedWorkplace.address = this.workplaceAddress;
      this.selectedWorkplace.postalCode = parseInt(this.postalCode);
      this.updateWorkplace(this.selectedWorkplace)
    },
    async updateWorkplace(workplace) {
      // this.$store.state.loading = true;
      this.$store.commit('startLoading');
      await ResourceService.updateWorkplace(workplace, workplace.id)
        .then(() => {
          ElNotification({
            type: 'success',
            message: 'Centro de trabajo actualizado',
            duration: 6000
          })
          // this.$store.state.loading = false;
          this.$store.commit('stopLoading');
          this.cancel()
        })
        .catch(() => {
          Notification.addNotification('Error al actualizar el centro de trabajo', 'error', 6000);
          // this.$store.state.loading = false;
          this.$store.commit('stopLoading');
        })
        .finally(() => {
          this.closeCreateOrUpdateDialog()
        })
    },
    reset() {
      this.workplace = {};
      this.workplaceName = '';
      this.selectedWorkplace = null;
      this.location = {};
      this.province = {};
      this.workplaceAddress = '';
      this.postalCode = '';
      this.errorWorkplace.name = false;
      this.errorWorkplace.province = false;
      this.errorWorkplace.location = false;
    },
    cancel() {
      this.reset()
      this.getWorkplaces();
    },
    closeCreateOrUpdateDialog() {
      this.createOrUpdateWorkplace = false
      this.reset()
    },
    openModalEmployeeAddress() {
      if (this.employee) {
        if (this.employee.province) {
          this.getLocationsByProvince(this.employee.province);
        }
      }
      this.showModalWorkplaceAddress = true;
    },
    closeModalWorkplaceAddress(str) {
      switch (str) {
        case 'cancelar':
          this.province = {};
          this.location = {};
          break;
        case 'aceptar':
          break;
        default:
          break;
      }
      this.showModalWorkplaceAddress = !this.showModalWorkplaceAddress;
    },
    getProvinces(searchQuery = null) {
      const params = searchQuery ? { name: searchQuery } : {};
      ResourceService.getProvinces(params)
        .then((res) => {
          this.provinces = res.data['hydra:member'];
        })
    },
    getLocationsById(id) {
      let params = {
        page: 1,
        itemsPerPage: 99999,
      };
      this.$store.commit('startLoading');
      this.workplace.province = this.provinces.find(x => x.id === id);
      if (this.workplace.postalCode) {
        if (this.workplace.province.id != this.workplace.postalCode.toString().substr(0, 2)) {
          this.workplace.postalCode = this.workplace.province.id;
        }
      } else {
        this.workplace.postalCode = this.workplace.province.id;
      }
      ResourceService.getLocationsByProvinceId(params, id)
        .then((res) => {
          this.locations = res.data['hydra:member'];
          if (this.workplace.location) {
            this.changeLocation(this.workplace.location.id)
          }
          this.$store.commit('stopLoading');
        })
    },
    changeLocation(id) {
      let newLocation = this.locations.find(x => x.id === id)
      if (newLocation) {
        this.workplace.location = newLocation;
      } else {
        this.location = {};
        this.workplace.location = {};
      }
    },
    checkErrors() {
      this.errorWorkplace.name = this.workplaceName === '' || this.workplaceName === null || this.workplace === undefined;
      this.errorWorkplace.province = this.province.id == null || this.province.id == undefined;
      this.errorWorkplace.location = this.location.id == null || this.location.id == undefined;
      return this.errorWorkplace.name || this.errorWorkplace.province || this.errorWorkplace.location;
    },
    filterNumbers() {
      this.postalCode = this.postalCode.replace(/\D/g, "");
    },
    search() {
      this.getWorkplaces(this.searchFilterWorkplaceName)
    },
    createNewWorkplace() {
      this.createOrUpdateWorkplace = true
      this.reset();
    },
  }
}
</script>

<style lang="scss" scoped>
.workplace-input {
  /*display: flex;*/
  vertical-align: baseline;
  margin: 30px 10px 10px 10px;
}

/*
.table-workplace {
  table {
    //margin-left: auto;
    //margin-right: auto;
    //width: auto;
  }
}
*/

.el-dialog__body {
  background: #f3f0fb !important;
  min-height: 800px !important;

  .el-dialog__header {
    padding: 0;
  }

  .my-header {
    margin-top: 2px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .text-header {
      text-align: left;
      font-size: 4em;
      font-weight: 900;
      letter-spacing: -0.05em;
      color: #f9156f;
    }

    .close-icon {
      width: 8%;
      height: 20%;
    }

    .inter-icon {
      width: 2%;
      height: 2%;
    }

    #asterisk {
      position: absolute;
      vertical-align: super;
      height: auto;
      width: 2.2em;
      fill: #f9156f;
    }

  }

  .my-body {
    margin-right: 5%;
  }

}
</style>
