<script setup>
import moment from "moment";
import { defineProps, defineEmits, computed} from "vue";
import TrackerDay from "@/components/shared/TrackerDay.vue";
import TrackerTotalTime from "@/components/shared/TrackerTotalTime.vue";

const props = defineProps({
    entriesWeek: Object,
    titleWeek: String,
    projects: Object,
    tags: Object
})

const thisWeek = computed(() => {
    if(props.entriesWeek.length >0){
        return props.entriesWeek;
    }

    return null;
});

const weekLength = computed(()=>{
  let num = 0;
  if (props.entriesWeek.length === 0) return [];
  props.entriesWeek.forEach(entry => {
    if(entry.timeEnd){
      num++;
    }
  });
  return num;
})

const emits = defineEmits(['reloadEntries'])

const reloadEntries = () => {  
  emits('reloadEntries')
}

// Get Entries divided by day (computed so that it reloads)

const groupEntriesByDay = computed(()=>{
    const groupedByDay = {};
  if (props.entriesWeek.length === 0) return [];
  props.entriesWeek.forEach(entry => {
    const day = moment(entry.timeStart).format('YYYY-MM-DD');
    if (!groupedByDay[day]) {
      groupedByDay[day] = [];
    }
    entry.day = day; // Optionally add the day to the entry for easy reference
    groupedByDay[day].push(entry);
  });

  // Convert to an array and sort by day if needed
  return Object.keys(groupedByDay).map(day => ({
    day,
    entries: groupedByDay[day]
  })).sort((a, b) => b.day.localeCompare(a.day)); // Sort by day in ascending order
});


</script>

<template>
    <div v-if="weekLength > 0">
        <div class="card-label">
          <span class="this-week">{{ props.titleWeek }}</span>
          <tracker-total-time title="Total semanal" :weekEntries="thisWeek" />
        </div>
        <tracker-day
        v-for="day in groupEntriesByDay"
        :key="day+Math.random()"
        :day="day"
        :projects="props.projects"
        :tags="props.tags"
        @reload-entries="reloadEntries()"
        />
    </div>
</template>

<style lang="scss">

.this-week{
  font-size: 20px;
  margin-left: 6px;
}
</style>