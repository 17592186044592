<script setup>
import HeaderTitle from "@/components/layout/HeaderTitle.vue";
import ProjectsService from "@/api/services/projects.service";
import ClientServices from "@/api/services/clients";
import SharedPagination from "@/components/shared/SharedPagination.vue";
import SharedButtonPlus from "@/components/shared/SharedButtonPlus.vue";
import {onMounted, ref} from "vue";
import {useStore} from "vuex";

const store = useStore();

const phone = ref("");
const project = ref("");
const client = ref("");
const clients = ref([]);
const page = ref(1);
const itemPerPage = ref(10);
const optionsClient = ref([]);
const optionsProject = ref([]);
const totalResults = ref(0);

 onMounted( async () => {
  //  await store.dispatch('setLoading', true)
   await store.commit('startLoading');
    await Promise.all([
      getClients(),
      getProjects()
    ]);
    await onSearch();
  //  await store.dispatch('setLoading', false)
   await store.commit('stopLoading');
  })
 const getProjects = async () => {
       let params = {
         archived: false,
         itemsPerPage: 99999,
         page: 1,
         'order[initialDate]': 'DESC'
       }
       const response = await ProjectsService.getProjects(params);
       optionsProject.value = response.data["hydra:member"];
     }
   const  onSearch = async () => {
    //  await store.dispatch('setLoading', true)
   await store.commit('startLoading');
      const params = {
        page:page.value,
        itemsPerPage:itemPerPage.value,
        "order[name]":"asc"
      }
      if(phone.value)params.phone = phone.value;
      if(client.value && client.value !=="Todos") params.name = client.value
      if(project.value && project.value !=="Todos") params["projects.name"] = project.value
      const response = await ClientServices.searchClients(params);
      clients.value = response.data["hydra:member"];
      totalResults.value = response.data["hydra:totalItems"];
      // await store.dispatch('setLoading', false)
      await store.commit('stopLoading');
    }
    const getClients = async () => {
      const response = await ProjectsService.getClients();
      optionsClient.value = response.data["hydra:member"];
    }
    const getId = (iri) =>{
      if (!iri) return ;
      const split = iri.split('/');
      return split[split.length-1];
    }

</script>
<template>
  <div>
    <HeaderTitle title="Producción" />
    <div class="d-flex flex-row justify-content-start align-items-center my-2">
      <svg
        id="icon-search"
        class="search-icon"
        width="44"
        height="37"
        viewBox="0 0 44 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.15269 30.0573H20.8415V34.6802H11.5851C10.8865 34.6802 10.4324 35.1448 10.4324 35.8301C10.4324 36.527 10.8981 36.98 11.5851 36.98H32.4266C33.1252 36.98 33.5793 36.5154 33.5793 35.8301C33.5793 35.1332 33.1135 34.6802 32.4266 34.6802H23.1585V30.0573H42.8473C43.5459 30.0573 44 29.5927 44 28.9074V1.16994C44 0.473018 43.5343 0.0200195 42.8473 0.0200195H1.15269C0.465732 0.0200195 0 0.484633 0 1.16994V28.8958C0 29.5927 0.465732 30.0573 1.15269 30.0573ZM2.31702 2.33147H41.683V27.7458H2.31702V2.33147Z"
          fill="#474CE6"
        />
        <path
          d="M38.2133 4.64294H5.78672C5.08812 4.64294 4.63403 5.10756 4.63403 5.79286V24.2729C4.63403 24.9698 5.09977 25.4228 5.78672 25.4228H38.2133C38.9119 25.4228 39.366 24.9582 39.366 24.2729V5.79286C39.366 5.10756 38.9119 4.64294 38.2133 4.64294ZM37.049 23.123H6.95105V6.9544H37.0606V23.123H37.049Z"
          fill="#474CE6"
        />
      </svg>

      <div class="title-text ml-4">
        Clientes
      </div>
    </div>
    <el-row>
      <el-card
        class="card-search"
        body-style="padding:0"
      >
        <div class="row">
          <div class="col-md-4 d-flex flex-column justify-content-start">
            <div class="d-flex flex-row w-100">
              <div class="form-item mt-4 ml-4 w-100 label-common">
                <label class="w-100">Cliente</label>
                <el-select
                  v-model="client"
                  placeholder="TODOS"
                  clearable
                  filterable
                  @change="onSearch()"
                >
                  <el-option
                    v-for="item in optionsClient"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  />
                </el-select>
              </div>
            </div>
          </div>
          <div class="col-md-4 d-flex flex-column justify-content-start">
            <div class="d-flex flex-row">
              <div class="form-item mt-4 ml-4 w-100 label-common">
                <label for="projectClient" class="w-100">Proyecto</label>
                <el-select
                  v-model="project"
                  placeholder="TODOS"
                  clearable
                  filterable
                  @change="onSearch()"
                >
                  <el-option
                    v-for="item in optionsProject"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  />
                </el-select>
              </div>
            </div>
          </div>
          <div class="col-md-4 d-flex flex-column justify-content-start">
            <div class="d-flex flex-row">
              <div class="form-item mt-4 ml-4 w-100 label-common">
                <label  for="client.id"  class="w-100">Teléfono</label>
                <el-input
                  v-model="phone"
                  placeholder="Ingrese el número"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="ml-auto d-flex justify-content-end align-items-end">
            <div class="mt-3">
              <button
                style="max-width: 250px;"
                class="btn btn_yellow btn-search"
                @click="onSearch()"
              >
                Buscar
              </button>
            </div>
          </div>
        </div>
      </el-card>
    </el-row>
    <el-row>
      <div class="table table-users">
        <div v-if="clients && clients.length>0">
          <table>
            <thead>
              <tr class="bg-transparent">
                <th>
                  <div
                    class="
                      d-flex
                      flex-row
                      justify-content-start
                      align-items-center
                      bg-transparent
                    "
                  >
                    <div class="mx-2">
                      <img src="@/assets/icons/pc-icon.svg" width="20" height="20"  alt="name-icon" />
                    </div>
                    <div>CLIENTE</div>
                  </div>
                </th>
                <th>
                  <div
                    class="
                      d-flex
                      flex-row
                      justify-content-start
                      align-items-center
                      bg-transparent
                    "
                  >
                    <div class="mx-2">
                      <img src="@/assets/icons/pc-icon.svg" width="20" height="20"  alt="name-icon" />
                    </div>
                    <div>PROYECTO</div>
                  </div>
                </th>
                <th>
                  <div
                    class="
                      d-flex
                      flex-row
                      justify-content-start
                      align-items-center
                      bg-transparent
                    "
                  >
                    <div class="mx-2">
                      <img src="@/assets/icons/pc-icon.svg" width="20" height="20"  alt="name-icon" />
                    </div>
                    <div>TELÉFONO</div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="cl in clients"
                :key="cl['@id']"
              >
                <td>
                  <div class="row-table-image-name-link">
                    <div class="link-name">
                      <router-link
                        :to="{ name: 'show-client', params: { id: getId(cl['@id']) } }"
                      >
                        {{ cl.name }}
                      </router-link>
                    </div>
                  </div>
                </td>
                <td class="pointer">
                  {{ cl.projectsNotArchived.map(v=>v.name).join(',') }}
                </td>
                <td class="pointer">
                  <div class="d-flex justify-content-between">
                    <div>{{ cl.phone }}</div>
                    <div><shared-button-plus @onClick="$router.push({name: 'show-client', params: { id: getId(cl['@id']) }})" /></div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else>
          <h5>
            No se han encontrado clientes según los filtros especificados...
          </h5>
        </div>
        <SharedPagination
          v-if="clients.value && totalResults.value > itemPerPage.value"
          class="pagination"
          :page="page.value"
          :total-results="totalResults.value"
          :page-size="itemPerPage.value"
          @change="page.value = $event;onSearch();"
        />
      </div>
    </el-row>
  </div>
</template>
<style scoped lang="scss">
.card-search {
  border-radius: 45px;
  height: 320px;
  background: white url('../../assets/icons/clients.png') no-repeat;
  background-position-x: -2px;
  background-position-y: -2px;
  padding: 15px;
  background-size: 30%;
  overflow: visible !important;


}
#icon-search{
  fill:$bg-blue;
  width: 3%;

}
.title-text {
  text-align: left;
  font-size: 3em;
  font-weight: 900;
  letter-spacing: -0.05em;
  color: $font-blue-color;

}
</style>
