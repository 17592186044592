<script setup>
import { defineEmits, ref, defineProps, onMounted, computed } from 'vue';

import EmployeeService from "@/api/services/employee.service";
import TimeEntryTagService from "@/api/services/timeEntryTag.service";
import TrackerService from "@/api/services/tracker.service";

import TrackerProjectInfo from "@/components/shared/TrackerProjectInfo.vue";
import TrackerProjectSearch from "@/components/shared/TrackerProjectSearch.vue";
import moment from 'moment';
import { ElNotification } from 'element-plus';

const emits = defineEmits(['reloadEntries', 'closeDialog']);
const props = defineProps({
  selectedEntries: Array,
  entry: Object,
})

const selectedEntries = computed(() => {
  return props.selectedEntries;
})

const page = ref(1);
const itemsPerPage = ref(99999);

const description = ref('');
const descriptionCheck = ref(false);
const oldDescription = ref('')

const dropdownProject = ref(false);
const refDropdownProject = ref();
const project = ref();
const projectCheck = ref(false);
const oldProject = ref();

const userCheck = ref(false);
const employeeOptions = ref([])
const user = ref();
const oldUser = ref();

const setTag = ref('add');
const tagCheck = ref(false);
const tagOptions = ref([])
const tags = ref([]);
const oldTags = ref([]);
const refSelectTag = ref();

const billableCheck = ref(false);
const isBillable = ref(false);

const timeCheck = ref(false)

const refInputTimeStart = ref();
const inputTimeStart = ref();
const newStartTime = ref();
const startTime = ref();

const refInputTimeEnd = ref();
const inputTimeEnd = ref();
const newEndTime = ref();
const endTime = ref();

const maxDate = ref();
const minDate = ref();

const dateCheck = ref(false)
const day = ref();
const datePicker = ref();

onMounted(() => {
  getEmployees();
  getTags();
  resetData();
  if (selectedEntries.value.length === 1) {
    setNewData(selectedEntries.value[0]);
  }
  inputTimeStart.value = startTime.value;
  inputTimeEnd.value = endTime.value;
  project.value = selectedEntries.value[0].project;
})
const reloadEntries = () => {
  emits('reloadEntries');
  closeDialog();
}

const closeDialog = () => {
  emits('closeDialog')
}

const setNewData = (entry) => {
  description.value = entry.description;
  project.value = entry.project;
  isBillable.value = entry.billable;
  day.value = entry.timeStart;
  startTime.value = moment(new Date(entry.timeStart)).format('HH:mm')
  endTime.value = moment(new Date(entry.timeEnd)).format('HH:mm')
}

const resetData = () => {
  description.value = oldDescription.value;
  project.value = oldProject.value;
  isBillable.value = false;
  tags.value = oldTags.value;
  const dates = selectedEntries.value.map(entry => ({
    timeStart: new Date(entry.timeStart),
    timeEnd: new Date(entry.timeEnd)
  }))

  minDate.value = new Date(Math.min(...dates.map(d => d.timeStart)));
  maxDate.value = new Date(Math.max(...dates.map(d => d.timeEnd)));
  startTime.value = moment(minDate.value).format('HH:mm');
  endTime.value = moment(maxDate.value).format('HH:mm');
  day.value = maxDate.value;
}
// User

const getEmployees = () => {
  let params = {
    page: page.value,
    itemsPerPage: itemsPerPage.value
  }
  params['order[name]'] = 'ASC';
  EmployeeService.getEmployees(params)
    .then((res) => {
      employeeOptions.value = res.data['hydra:member'];
      for (let i = 0; i < employeeOptions.value.length; i++) {
        const employee = employeeOptions.value[i];
        if (employee['@id'] == selectedEntries.value[0].employee['@id']) {
          oldUser.value = employee;
          user.value = oldUser.value;
        }

      }
    })
}

// Project

const getNewProject = (newProject) => {
  project.value = newProject;
  refDropdownProject.value.handleClose();
}

// Tags

const getTags = () => {
  let params = {
    page: page.value,
    itemsPerPage: itemsPerPage.value,
  }
  params['order[name]'] = 'ASC';
  TimeEntryTagService.getTags(params)
    .then((response) => {
      tagOptions.value = response.data['hydra:member'];
    })
    .catch((error) => {
      console.log(error);
      Notification.addNotification(
        "Error al cargar las etiquetas"
      );
    });
}

const clearSelectTag = () => {
  if (refSelectTag.value) {
    refSelectTag.value.blur(); // Cierra el dropdown y limpia el input de búsqueda
    refSelectTag.value.focus(); // Vuelve a enfocar para que siga funcional
  }
}

// Time
const focusInputHour = (name) => {
  if (name === 'start') {
    inputTimeStart.value = inputTimeStart.value.split(':');
    let temp = inputTimeStart.value[0] + inputTimeStart.value[1];
    inputTimeStart.value = temp;
  } else {
    inputTimeEnd.value = inputTimeEnd.value.split(':');
    let temp = inputTimeEnd.value[0] + inputTimeEnd.value[1];
    inputTimeEnd.value = temp;
  }
}

const blurInputHour = (name) => {
  if (name === 'start') {
    if (newStartTime.value) {
      inputTimeStart.value = newStartTime.value;
    } else {
      inputTimeStart.value = startTime.value;
    }
  } else {
    if (newEndTime.value) {
      inputTimeEnd.value = newEndTime.value;
    } else {
      inputTimeEnd.value = endTime.value;
    }
  }
}

const checkChangeInputHour = (name) => {
  let temp;
  const newDuration = new Date();
  const now = new Date();
  if (name === 'start') {
    if (inputTimeStart.value.length == 3 || inputTimeStart.value.length == 4) {
      if (inputTimeStart.value.length == 3) temp = inputTimeStart.value.slice(0, 1) + ':' + inputTimeStart.value.slice(1);
      if (inputTimeStart.value.length == 4) temp = inputTimeStart.value.slice(0, 2) + ':' + inputTimeStart.value.slice(2);
      temp = temp.split(':')

      newDuration.setHours(parseInt(temp[0]))
      newDuration.setMinutes(parseInt(temp[1]))
      newDuration.setSeconds(0)
    }
    if (inputTimeStart.value.length == 1 || inputTimeStart.value.length == 2) {
      newDuration.setHours(parseInt(inputTimeStart.value))
      newDuration.setMinutes(0);
      newDuration.setSeconds(0);
    }

    if (newDuration > now) {
      newDuration.setDate(newDuration.getDate() - 1);
    }

    //Comprobar que el valor entra dentro de los valores
    if (newDuration > now || isNaN(newDuration) || inputTimeStart.value.length >= 5) {
      refInputTimeStart.value.blur();
      return;
    }

    const hours = newDuration.getHours().toString().padStart(2, "0");
    const minutes = newDuration.getMinutes().toString().padStart(2, "0");
    newStartTime.value = `${hours}:${minutes}`;
    refInputTimeStart.value.blur();
  } else {
    if (inputTimeEnd.value.length == 3 || inputTimeEnd.value.length == 4) {
      if (inputTimeEnd.value.length == 3) temp = inputTimeEnd.value.slice(0, 1) + ':' + inputTimeEnd.value.slice(1);
      if (inputTimeEnd.value.length == 4) temp = inputTimeEnd.value.slice(0, 2) + ':' + inputTimeEnd.value.slice(2);
      temp = temp.split(':')

      newDuration.setHours(parseInt(temp[0]))
      newDuration.setMinutes(parseInt(temp[1]))
      newDuration.setSeconds(0)
    }
    if (inputTimeEnd.value.length == 1 || inputTimeEnd.value.length == 2) {
      newDuration.setHours(parseInt(inputTimeEnd.value))
      newDuration.setMinutes(0);
      newDuration.setSeconds(0);
    }

    if (newDuration > now) {
      newDuration.setDate(newDuration.getDate() - 1);
    }

    //Comprobar que el valor entra dentro de los valores
    if (newDuration > now || isNaN(newDuration) || inputTimeEnd.value.length >= 5) {
      refInputTimeEnd.value.blur();
      return;
    }
    const hours = newDuration.getHours().toString().padStart(2, "0");
    const minutes = newDuration.getMinutes().toString().padStart(2, "0");
    newEndTime.value = `${hours}:${minutes}`;
    refInputTimeEnd.value.blur();
  }
}

// Calendar
const openCalendar = async () => {
  if (dateCheck.value) {
    datePicker.value.handleOpen();
  }
}


// Save
const saveEntry = () => {
  let updated = 0;
  let count = 0;
  for (let i = 0; i < selectedEntries.value.length; i++) {
    const entry = selectedEntries.value[i];
    let message = "Rellena los siguientes campos:";
    let params = {};
    if (descriptionCheck.value) {
      if (description.value == '') {
        count++;
        message = message + " descripcion";
      } else {
        params.description = description.value;
      }
    } else {
      params.description = entry.description;
    }

    if (userCheck.value) {
      if (!user.value) {
        count++;
        message = message + " usuario"
      } else {
        params.employee = user.value['@id'];
      }
    } else {
      params.employee = entry.employee['@id'];
    }

    if (projectCheck.value) {
      if (!project.value) {
        count++;
        message = message + " proyecto";
      } else {
        params.project = project.value['@id'];
      }
    } else {
      params.project = entry.project['@id'];
    }

    if (tagCheck.value) {
      if (tags.value.length < 1) {
        count++;
        message = message + " etiquetas"
      } else {
        let newTags;
        if (setTag.value == "add") {
          newTags = [...entry.tags, ...tags.value].map(item => item["@id"]);
        } else {
          newTags = tags.value.map(item => item["@id"]);
        }
        params.tags = newTags;
      }
    } else {
      params.tags = entry.tags.map(item => item["@id"]);
    }

    if (billableCheck.value) {
      params.billable = isBillable.value;
    } else {
      params.billable = entry.billable;
    }
    let changed = false;
    if (timeCheck.value) {
      let temp = inputTimeStart.value.split(':');
      const newStart = new Date(day.value);
      newStart.setHours(temp[0])
      newStart.setMinutes(temp[1])

      temp = inputTimeEnd.value.split(':')
      const newEnd = new Date(day.value);
      newEnd.setHours(temp[0])
      newEnd.setMinutes(temp[1])
      if (newStart > newEnd) {
        newEnd.setDate(newStart.getDate());
        if (newStart >= newEnd) {
          newEnd.setDate(newEnd.getDate() + 1);
        }
      }
      params.timeStart = formatDate(newStart);
      params.timeEnd = formatDate(newEnd);
      changed = true;
    } else {
      params.timeStart = entry.timeStart;
      params.timeEnd = entry.timeEnd;
    }

    if (dateCheck.value) {
      let temp = new Date(entry.timeStart);
      temp.setDate(day.value.getDate());
      params.timeStart = formatDate(temp);
      temp = new Date(entry.timeEnd);
      temp.setDate(day.value.getDate());
      params.timeEnd = formatDate(temp);
    } else {
      if (!changed) {
        params.timeStart = entry.timeStart;
        params.timeEnd = entry.timeEnd;
      }
    }
    if (count > 0) {
      ElNotification({
        message: message,
        type: "error",
        duration: 4000
      })
      return;
    }

    TrackerService.updateEntry(entry.id, params)
      .then(() => {
        updated++;
        if (updated === selectedEntries.value.length) {
          ElNotification({
            message: selectedEntries.value.length > 1 ? 'Las tareas han sido actualizadas' : 'La tarea ha sido actualizada',
            type: "success",
            duration: 4000
          })
          closeDialog();
          reloadEntries();
        }
      })
  }




}

const formatDate = (date) => {
  // Obtener el desplazamiento de la zona horaria
  const offset = new Date().getTimezoneOffset();
  const sign = offset > 0 ? '-' : '+';
  const hours = String(Math.floor(Math.abs(offset) / 60)).padStart(2, '0');
  const minutes = String(Math.abs(offset) % 60).padStart(2, '0');

  // Formato de fecha
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  // Formato de hora
  const hoursOfDay = String(date.getHours()).padStart(2, '0');
  const minutesOfDay = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  // Devolver la cadena en formato ISO con el desplazamiento de la zona horaria
  return `${year}-${month}-${day}T${hoursOfDay}:${minutesOfDay}:${seconds}${sign}${hours}:${minutes}`;
}



</script>
<template>
  <div class="container">
    <!-- DESCRIPCION -->
    <div class="row d-flex align-items-center">
      <div class="col-4">
        <el-checkbox v-model="descriptionCheck" label="DESCRIPCIÓN" class="checkPopup" />
      </div>
      <div class="col-8">
        <el-input v-model="description" :disabled="!descriptionCheck"
          :placeholder="selectedEntries.length == 1 ? selectedEntries[0].description : 'Añade una descripción'" />
      </div>
      <hr class="mt-3 mb-3">
    </div>
    <!-- USUARIO -->
    <div class="row d-flex align-items-center">
      <div class="col-4">
        <el-checkbox v-model="userCheck" label="USUARIO" class="checkPopup" />
      </div>
      <div class="col-8">
        <el-select :value-key="'@id'" clearable filterable
          :placeholder="!userCheck ? 'Casilla Inhabilitada' : 'Usuario'" v-model="user" :disabled="!userCheck">
          <el-option v-for="employee in employeeOptions" :key="employee['@id']" :label="employee.fullName"
            :value="employee">
          </el-option>
        </el-select>
      </div>
      <hr class="mt-3 mb-3">
    </div>
    <!-- PROYECTO -->
    <div class="row d-flex align-items-center">
      <div class="col-4">
        <el-checkbox v-model="projectCheck" label="PROYECTO" class="checkPopup" />
      </div>
      <div class="col-8">
        <el-dropdown trigger="click" :hide-on-click="false" ref="refDropdownProject"
          class="addProjectDropdown el-select" :disabled="!projectCheck" :style="!projectCheck ? 'cursor:default;' : ''"
          placement="bottom">
          <p v-if="!project" @click="dropdownProject = true" style="margin-top: 13px;"
            :style="!projectCheck ? 'cursor:default;' : ''">
            Añadir Proyecto
          </p>
          <tracker-project-info :project="project" class="el-dropdown-link" @click="dropdownProject = true"
            v-if="project" :editImport="true" :style="!projectCheck ? 'cursor:default;' : ''"
            style="margin-top: 9px; margin-left: 5px;" />
          <template #dropdown>
            <el-dropdown-menu class="dropProject">
              <el-dropdown-item style="height: 300px;" v-if="dropdownProject">
                <TrackerProjectSearch @get-project="getNewProject" :project="project" :editImport="true" />
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <hr class="mt-3 mb-3">
    </div>
    <!-- ETIQUETAS -->
    <div class="row d-flex align-items-center">
      <div class="col-4">
        <el-checkbox v-model="tagCheck" label="ETIQUETAS" class="checkPopup" />
      </div>
      <div class="col-8">
        <div class="row">
          <div class="col-12">
            <el-select ref="refSelectTag" :value-key="'@id'" clearable filterable multiple collapse-tags
              collapse-tags-tooltip class="tracker-select"
              :placeholder="!tagCheck ? 'Casilla Inhabilitada' : 'Añade etiquetas'" v-model="tags" :disabled="!tagCheck"
              @change="clearSelectTag">
              <el-option v-for="tag in tagOptions" :key="tag['@id']" :label="tag.name" :value="tag">
              </el-option>
            </el-select>
          </div>
          <el-radio-group v-model="setTag" :disabled="!tagCheck">
            <div class="col-6">
              <el-radio value="add">
                Añadir a existentes
              </el-radio>
            </div>
            <div class="col-5">
              <el-radio value="new">
                Sobreescribir existentes
              </el-radio>
            </div>
          </el-radio-group>
        </div>
      </div>
      <hr class="mt-3 mb-3">
    </div>
    <!-- FACTURABLE -->
    <div class="row d-flex align-items-center">
      <div class="col-4">
        <el-checkbox v-model="billableCheck" label="FACTURABLE" class="checkPopup" />
      </div>
      <div class="col-8">
        <el-switch v-model="isBillable" active-text="Sí" inactive-text="No" :disabled="!billableCheck" />
      </div>
      <hr class="mt-3 mb-3">
    </div>
    <!-- TIEMPO -->
    <div class="row d-flex align-items-center">
      <div class="col-4">
        <el-checkbox v-model="timeCheck" label="TIEMPO" class="checkPopup" />
      </div>
      <div class="col-8" :disabled="!timeCheck">
        <el-input ref="refInputTimeStart" v-model="inputTimeStart" class="inputSplit" @focus="focusInputHour('start')"
          @blur="blurInputHour('start')" @change="checkChangeInputHour('start')" :disabled="!timeCheck" />
        -
        <el-input ref="refInputTimeEnd" v-model="inputTimeEnd" class="inputSplit" @focus="focusInputHour('end')"
          @blur="blurInputHour('end')" @change="checkChangeInputHour('end')" :disabled="!timeCheck" />
      </div>
      <hr class="mt-3 mb-3">
    </div>
    <!-- FECHA -->
    <div class="row d-flex align-items-center">
      <div class="col-4">
        <el-checkbox v-model="dateCheck" label="FECHA" class="checkPopup" />
      </div>
      <div class="col-8">
        <div class="row d-flex align-items-center">
          <div class="col-1">
            <img class="tracker-calendar img-date" @click="openCalendar" src="@/assets/icons/calendar-icon.svg"
              alt="calendar-icon" style="cursor: pointer; margin-top: 5px;" width="21" height="22"
              :disabled="!dateCheck">
          </div>
          <div class="col-11">
            <el-date-picker v-model="day" ref="datePicker" type="date" class="myDatePickerReport"
              style="max-width: 175px;" @click="openCalendar" :disabled="!dateCheck" :suffix-icon="null" />
          </div>
        </div>
      </div>
      <hr class="mt-3 mb-3">
    </div>

    <!-- BOTONES GUARDAR Y CANCELAR -->
    <div class="row footer">
      <div class="col-md-12">
        <div class="d-flex justify-content-end gap-3">
          <div class="col-md-3">
            <button class="btn btn-block btn_yellow edit-button" @click="closeDialog"
              style="margin-right: 5px;">Cancelar
            </button>
          </div>
          <div class="col-md-3">
            <button type="submit" class="btn btn-block btn_red edit-button" @click="saveEntry">
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.edit-button {
  min-width: 120px;
}
</style>