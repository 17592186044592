<template>
  <div>
    <HeaderTitle title="Ajustes" />
    <div class="d-flex flex-row justify-content-start">
      <img src="@/assets/icons/group.svg" width="30" alt="">
      <div class="title-text pl-4">
        Departamentos
      </div>
    </div>
    <el-card style="height: 200px"
             class="card-search w-100 wback"
             body-style="padding:0"
    >
      <div class="row mx-5">
        <div class="container-departments">
          <div class="title-departments">
            <div class="row">
              <div class="col-md-8">
                <div class="row">
                  <div class="col-md-6">
                    <div class=" form-item  date-picker  label-common">
                      <label class=" w-100 mb-2">Nombre</label>
                      <el-input
                          v-model="searchFilterDepartmentName"
                          placeholder="Nombre del Departamento"
                          prefix-icon="el-icon-search"
                          clearable />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
            <div class="ml-auto d-flex justify-content-end align-items-end">
              <div class="">
                <button 
                  @click="createOrUpdateDepartment = true" 
                  class="btn btn_darkpink btn-search"
                  style="min-width:250px; max-width: 300px; color:white;"
                >
                  NUEVO DEPARTAMENTO
                </button>
              </div>
              <div class="">
                <button
                  style="max-width: 250px;"
                  class="btn btn_yellow btn-search"
                  @click="search()"
                >
                  Buscar
              </button>
            </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </el-card>
    <div class="table table-departments">
      <div v-if="departments && departments.length > 0">
        <table>
          <thead>
          <tr>
            <th>Nombre</th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="department in departments"
              :key="department.id"
          >
            <td>
              {{ department.name }}
            </td>
            <td>
              <div class="buttons d-inline-flex">
                <div class="pointer">
                  <font-awesome-icon
                      class="btn_icon blue_btn"
                      icon="eye"
                      title="Editar"
                      style="margin-left: 5px"
                      @click="editDepartment(department)"
                  />
                </div>
                <div class="pointer">
                  <font-awesome-icon
                      class="btn_icon delete_btn"
                      icon="times"
                      title="Eliminar"
                      style="margin-left: 5px"
                      @click="deleteDialogShown = true; selectedDepartment = department;"
                  />
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <h5>No se han encontrado departamentos</h5>
      </div>


      <!--      <div class="table-card-departments">-->
      <!--        <div v-if="departments && departments.length > 0">-->
      <!--          -->
      <!--        </div>-->
      <!--      </div>-->


      <SharedPagination
          v-if="departments && departments.length > 4"
          class="pagination"
          :page="page"
          :total-results="totalResults"
          :page-size="itemsPerPage"
          style="padding: 0px 0px 20px 0px;"
          @change="page = $event; getDepartments()"
      />
    </div>

    <el-dialog
        v-model="createOrUpdateDepartment"
        class="dense-dialog el-dialog"
        @closed="closeCreateOrUpdateDialog()"
        :show-close="false"
        size="'medium'"
        width="850"
        :center="true"
        :lock-scroll="true"
        :style="{ borderRadius: `var(--el-border-radius-round)` }"
        >
        <div class="my-header">
        <svg
            id="asterisk"
            xmlns="http://www.w3.org/2000/svg"
            width="724"
            height="1024"
            viewBox="0 0 724 1024"
        >
          <path
              d="M0 499.8l59-181.7 332.5 108.6-59 180.8-332.5-107.7zM79.6 744.3l206.1-282.9 154.5 111.5-206.1 283.8-154.5-112.4zM266.9 167.3h191.1v350.3h-191.1v-350.3zM285.6 572.9l154.5-111.5 206.1 282.9-154.5 112.4-206.1-283.8zM332.5 426.8l332.5-108.6 59 181.7-331.6 107.7-59.9-180.8z"
          />
        </svg>
        <span class="text-header ml-5 mt-3">{{ selectedDepartment ? 'Actualizar Departamento' : 'Nuevo Departamento'}}</span>
        <img
            class="close-icon pointer"
            src="@/assets/images/aspa_blue_small_entrada.png"
            alt=""
            @click="closeCreateOrUpdateDialog()"
        >
      </div>

      <div class="my-body">
        <form @submit.prevent="">
          <div class="form-group row">
            <div class="col-md-10">
              <div class="form-item">
                <label for="departmentName" class="w-100">Nombre del departamento:</label>
                <el-input
                    v-model="departmentName"
                    :label="'Nombre departamento'"
                    :placeholder="'Nombre departamento'"
                />
              </div>

            </div>
            <!--                    <div class="col-md-3 text-right"  v-if="user && ifIsInArray('ROLE_SUPER_ADMIN', user.rol) && title.length > 0">-->
            <div class="row">
              <div class="col-md-4 offset-md-5 d-flex flex-column justify-content-end align-items-end">
                <button
                    class="btn btn_darkpink"
                    @click="selectedDepartment ? handleUpdateDepartment() : createDepartment(department)"
                >
                  {{ selectedDepartment ? 'Actualizar' : 'Nuevo'}}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </el-dialog>

    <el-dialog
        v-model="deleteDialogShown"
        class="dense-dialog"
        :title="'¿Desea eliminar permanentemente este departamento?'"
        @closed="selectedDepartment = null;deleteDialogShown = false;"
    >
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="selectedDepartment = null;deleteDialogShown = false;">
            Cancelar
          </el-button>
          <el-button type="primary" @click="deleteDepartment(selectedDepartment)">Confirmar</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
// Components
import SharedPagination from "@/components/shared/SharedPagination.vue";
import HeaderTitle from "@/components/layout/HeaderTitle.vue";

// Services
import ResourceService from '@/api/services/resource.service';
import Notification from '@/utils/plugins/NotificationService';


export default {
  name: "Departments",
  components: {
    SharedPagination: SharedPagination,
    HeaderTitle,
  },
  props: {},
  data() {
    return {
      departments: [],
      department: {},
      submitted: false,
      deleteDialogShown: false,
      selectedDepartment: null,
      page: 1,
      itemsPerPage: 10,
      totalResults: null,
      departmentName: '',
      createOrUpdateDepartment: false,
      searchFilterDepartmentName: ''
    }
  },
  computed: {},
  mounted() {
    this.getDepartments();
  },
  methods: {
    getDepartments(searchQuery = null) {
      const params = searchQuery ? {name: searchQuery} : {};
      ResourceService.getDepartments(params)
          .then((res) => {
            this.departments = res.data['hydra:member'];
          })
    },
    deleteDepartment(department) {
      ResourceService.deleteDepartment(department.id)
          .then(() => {
            Notification.addNotification('Departamento eliminado correctamente.', 'success', 6000);
            this.selectedDepartment = null;
            this.deleteDialogShown = false;
            this.getDepartments();
          }).catch(() => {
        Notification.addNotification('Error al eliminar el departamento seleccionado', 'error', 6000);
        // this.$store.state.loading = false;
            this.$store.commit('stopLoading');
      })
    },
    createDepartment(department) {
      department.name = this.departmentName;
      // this.$store.state.loading = true;
      this.$store.commit('startLoading');
      ResourceService.createDepartment(department)
          .then(() => {
            Notification.addNotification('Departamento creado correctamente', 'success', 6000);
            // this.$store.state.loading = false;
            this.$store.commit('stopLoading');
            this.cancel()
          })
          .catch(() => {
            Notification.addNotification('Error al crear el departamento', 'error', 6000);
            // this.$store.state.loading = false;
            this.$store.commit('stopLoading');
          })
          .finally(()=>{
            this.closeCreateOrUpdateDialog()
          })

    },
    editDepartment(department){
      this.selectedDepartment = department;
      this.departmentName = department.name;
      this.createOrUpdateDepartment = true
    },
    handleUpdateDepartment(){
      this.selectedDepartment.name = this.departmentName;
      this.updateDepartment(this.selectedDepartment)
    },
    async updateDepartment(department) {
      // this.$store.state.loading = true;
      this.$store.commit('startLoading');
      await ResourceService.updateDepartment(department, department.id)
          .then(() => {
            Notification.addNotification('Departamento actualizado correctamente', 'success', 6000);
            // this.$store.state.loading = false;
            this.$store.commit('stopLoading');
            this.cancel()
          })
          .catch(() => {
            Notification.addNotification('Error al actualizar el departamento', 'error', 6000);
            // this.$store.state.loading = false;
            this.$store.commit('stopLoading');
          })
          .finally(()=>{
            this.closeCreateOrUpdateDialog()
          })
    },
    reset(){
      this.department = {};
      this.departmentName = '';
      this.selectedDepartment = null;
    },
    cancel() {
      this.reset()
      this.getDepartments();
    },
    closeCreateOrUpdateDialog(){
      this.createOrUpdateDepartment = false
      this.reset()
    },
    search(){
     this.getDepartments(this.searchFilterDepartmentName) 
    }
  }

}
</script>

<style lang="scss" scoped>

.department-input {
  /*display: flex;*/
  vertical-align: baseline;
  margin: 30px 10px 10px 10px;
}

.table-departments {
  table {
    //margin-left: auto;
    //margin-right: auto;
    //width: auto;
  }
}



.el-dialog__body {
  background: #f3f0fb !important;
  min-height: 800px !important;

  .el-dialog__header {
    padding: 0;
  }

  .my-header {
    margin-top: 2px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .text-header {
      text-align: left;
      font-size: 4em;
      font-weight: 900;
      letter-spacing: -0.05em;
      color: #f9156f;
    }

    .close-icon {
      width: 8%;
      height: 20%;
    }

    .inter-icon {
      width: 2%;
      height: 2%;
    }

    #asterisk {
      position: absolute;
      vertical-align: super;
      height: auto;
      width: 2.2em;
      fill: #f9156f;
    }

  }

  .my-body{
    margin-right: 5%;
  }

}
</style>
