<script setup>
import { MoreFilled } from "@element-plus/icons-vue";
import { computed, defineEmits, ref, onMounted, nextTick } from "vue";
import moment from "moment";
import TrackerProjectInfo from "@/components/shared/TrackerProjectInfo.vue";
import { ElMessageBox, ElNotification } from "element-plus";
import TrackerService from "@/api/services/tracker.service";
import { useStore } from "vuex";
import TrackerSplit from "@/components/shared/TrackerSplit.vue";
import TrackerProjectSearch from "@/components/shared/TrackerProjectSearch.vue";
import { isDate } from "lodash";

const store = useStore();

const emit = defineEmits(['reloadEntries']);
// eslint-disable-next-line no-undef
const props = defineProps({
  entry: Array,
  isChild: {
    type: Boolean,
    default: false
  },
  dayLength: Number,
  entryLength: Number,
  projects: Object,
  tags1: Object,
})

const getIsChild = () => {
  return props.isChild;
}

const PROJECT_API = "/api/projects/";
const TAGS_API = "/api/time_entry_tags/";

const changeTime = ref(false);
const changeDuration = ref(false);
const changeDescription = ref(false);
const timeEntry = ref({
  startTime: '',
  endTime: ''
});
const showEntries = ref(false);
const changeHour = ref(false);
const duration = ref();
const description = ref();
const project = ref();
const tags = ref();
const day = ref();
const datePicker = ref();
const menuDropdown = ref();
const daysDiff = ref();

const dropdownProject = ref(false);
const dropdownTags = ref(false);
const isFocused = ref(false);

const refInputTimeStart = ref(null);
const refInputTimeEnd = ref(null);
const newStartTime = ref();
const newEndTime = ref();
const oldStartTime = ref();
const oldEndTime = ref();

const refDropdownProject = ref();


const tagsText = computed(() => {
  let temp = "";
  for (let i = 0; i < firstEntry.value.tags.length; i++) {
    const element = firstEntry.value.tags[i];
    if (i == 0) {
      temp = element.name;
    } else {
      temp = temp + ", " + element.name
    }
  }
  return temp;
})

// valores Split
const dialogSplit = ref(false);

const popperOptions = {
  modifiers: [
    {
      name: "preventOverflow",
      options: {
        boundary: "viewport", // Evita que el popup salga del viewport
      },
    },
  ],
};

onMounted(() => {
  let tempo = null;
  for (let i = 0; i < props.entry.length; i++) {
    const element = props.entry[i];
    let firstDate = new Date(element.timeStart)
    if (i == 0) {
      tempo = firstDate;
    } else {
      if (tempo > firstDate) {
        tempo = element.timeStart;
      }
    }
  }
  let start = tempo;
  timeEntry.value.startTime = moment(tempo).format('HH:mm');

  tempo = null;
  for (let i = 0; i < props.entry.length; i++) {
    const element = props.entry[i];
    let lastDate = new Date(element.timeEnd)
    if (i == 0) {
      tempo = lastDate;
    } else {
      if (tempo < lastDate) {
        tempo = element.timeEnd;
      }
    }
  }
  let end = tempo;
  timeEntry.value.endTime = moment(tempo).format('HH:mm');
  // getProjects();
  // getTags();
  duration.value = minutes.value;
  description.value = firstEntry.value.description;
  project.value = firstEntry.value.project;
  tags.value = firstEntry.value.tags;
  day.value = new Date(firstEntry.value.timeStart);
  daysDiff.value = getDaysDifference(start, end);
  oldEndTime.value = timeEntry.value.endTime
  oldStartTime.value = timeEntry.value.startTime
})

const firstEntry = computed(() => {
  return props.entry[0]
})
//eslint-disable-next-line
const lastEntry = computed(() => {
  return props.entry[props.entry.length - 1]
})
const entries = computed(() => {
  return props.entry;
})

const optionTags = computed(() => {
  return props.tags1;
});


const minutes = computed(() => {
  let hours = 0;
  let minutes = 0;
  let remainingSeconds = 0;
  props.entry.map((entry) => {
    if (entry.duration) {
      hours = hours + Math.floor(entry.duration / 3600);
      minutes = minutes + Math.floor((entry.duration % 3600) / 60);
      remainingSeconds = remainingSeconds + (entry.duration % 60);
    } else {
      hours = hours + Math.floor(moment().diff(entry.timeStart.split('T')[0], 'seconds') / 3600);
      minutes = minutes + Math.floor((moment().diff(entry.timeStart.split('T')[0], 'seconds') % 3600) / 60);
      remainingSeconds = remainingSeconds + (moment().diff(entry.timeStart.split('T')[0], 'seconds') % 60);
    }
    do {

      if (remainingSeconds >= 60) {
        remainingSeconds = remainingSeconds - 60;
        minutes = minutes + 1;
      }
      if (minutes >= 60) {
        minutes = minutes - 60;
        hours = hours + 1;
      }
    } while (remainingSeconds >= 60)

  })

  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;



});

const updateEntry = (entry) => {
  let tags = [];
  for (let i = 0; i < entry.tags.length; i++) {
    const tag = entry.tags[i];
    tags.push(TAGS_API + tag.id)
  }
  let params = {
    "employee": entry.employee['@id'],
    "project": PROJECT_API + entry.project.id,
    "tags": tags,
    "description": entry.description,
    "timeStart": entry.timeStart,
    "timeEnd": entry.timeEnd,
    "duration": entry.duration,
    "billable": entry.billable,
  }
  return TrackerService.updateEntry(entry.id, params)
  // .then(()=>{
  //   ElNotification({
  //       message:"Se actualizó la tarea correctamente",
  //       type:"success",
  //       duration:6000
  //     })  
  //   emits('reloadEntries');
  // });
}

const startTimer = async () => {
  if (store.state.isEntryActive) {
    ElNotification({
      message: "Ya hay una tarea en marcha.",
      type: "error",
      duration: 6000
    });
    return;
  }
  let entry = { ...firstEntry.value }
  entry.project = firstEntry.value.project['@id'];
  entry.employee = firstEntry.value.employee['@id'];
  let tags = [];
  entry.tags.forEach((tag) => {
    tags.push(tag['@id']);
  });

  delete entry['@id'];
  delete entry.id;
  delete entry.duration;
  delete entry.timeEnd;
  entry.tags = tags;
  entry.timeStart = formatDate(new Date());

  await TrackerService.createEntry(entry).then(() => {
    reloadEntries();
  }).catch(() => {

  })
}

const formatDate = (date) => {
  // Obtener el desplazamiento de la zona horaria
  const offset = new Date(firstEntry.value.day).getTimezoneOffset();
  const sign = offset > 0 ? '-' : '+';
  const hours = String(Math.floor(Math.abs(offset) / 60)).padStart(2, '0');
  const minutes = String(Math.abs(offset) % 60).padStart(2, '0');

  // Formato de fecha
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  // Formato de hora
  const hoursOfDay = String(date.getHours()).padStart(2, '0');
  const minutesOfDay = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  // Devolver la cadena en formato ISO con el desplazamiento de la zona horaria
  return `${year}-${month}-${day}T${hoursOfDay}:${minutesOfDay}:${seconds}${sign}${hours}:${minutes}`;
}

const duplicate = async () => {
  // store.state.loading = true;
  store.commit('startLoading');
  await TrackerService.duplicateEntry(firstEntry.value.id).then(() => {
    ElNotification({
      message: "Se actualizó la tarea correctamente",
      type: "success",
      duration: 6000
    });
    reloadEntries();
    // store.state.loading = false;
    store.commit('stopLoading');
  }).catch(() => {

  })
}

const reloadEntries = async () => {
  emit('reloadEntries');
}

const discard = () => {

  let message = props.dayLength > 1 ? "estas entradas" : "esta entrada";
  ElMessageBox.confirm(
    '¿Estás seguro de descartar ' + message + ' ?',
    'Warning',
    {
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
      type: 'warning',
    }
  ).then(async () => {
    // store.state.loading = true;
    store.commit('startLoading');
    for (let i = 0; i < props.entry.length; i++) {
      const element = props.entry[i];
      const id = element.id;
      await TrackerService.discardEntry(id);
    }
    // store.state.loading = false;
    store.commit('stopLoading');
    reloadEntries();
    let message = props.dayLength > 1 ? "Las entradas se han" : "La entrada se ha";
    ElNotification({
      message: message + " eliminado",
      type: "success",
      duration: 6000
    })
  })
    .catch(() => {

    })

}

const toggleEntries = () => {
  showEntries.value = !showEntries.value;
}

// Funciones para trabajar con las horas

const changeTimeHour = async (name) => {
  changeHour.value = true;
  if (name === 'start') {
    if (timeEntry.value.startTime.length < 1) {
      refInputTimeStart.value.blur();
      return;
    }
    let tempS = timeEntry.value.startTime.includes(':');
    if (isNaN(timeEntry.value.startTime) && !tempS) {
      refInputTimeStart.value.blur()
      refInputTimeEnd.value.blur()
      return;
    }
    await checkChangeInputTime("start");
  } else {
    if (timeEntry.value.endTime.length < 1) {
      refInputTimeEnd.value.blur();
      return;
    }
    let tempE = timeEntry.value.endTime.includes(':');
    if (isNaN(timeEntry.value.endTime) && !tempE) {
      refInputTimeStart.value.blur();
      refInputTimeEnd.value.blur();
      return
    }
    await checkChangeInputTime("end");
  }

  // store.state.loading = true;
  store.commit('startLoading');
  checkAndChangeHours();
  updateEntry(firstEntry.value).then(() => {
    ElNotification({
      message: "Se actualizó la tarea correctamente",
      type: "success",
      duration: 6000
    })
    reloadEntries();
  }).finally(() => {
    // store.state.loading = false;
    store.commit('stopLoading');
  });
}

const checkAndChangeHours = () => {
  changeTime.value = false;
  let timeStart = timeEntry.value.startTime.split(':');
  let timeEnd = timeEntry.value.endTime.split(':');
  //Cambiamos la hora de inicio
  let newDateStart = new Date(Date.parse(firstEntry.value.timeStart))
  let oldDateStart = new Date(Date.parse(firstEntry.value.timeStart))
  newDateStart.setHours(timeStart[0], timeStart[1], 0)

  //Cambiamos la hora de finalización
  let newDateEnd = new Date(Date.parse(firstEntry.value.timeEnd))
  let oldDateEnd = new Date(Date.parse(firstEntry.value.timeEnd))
  newDateEnd.setHours(timeEnd[0], timeEnd[1], 0)

  //Esto se ejecuta si no hacemos ningún cambio, es decir, no viene de la funcion changeTimeHour
  if (!changeHour.value) {
    timeEntry.value.startTime = moment(oldDateStart).format('HH:mm');
    timeEntry.value.endTime = moment(oldDateEnd).format('HH:mm');
    return;
  }

  if (isNaN(newDateStart)) {
    timeEntry.value.startTime = oldStartTime.value;
    return;
  }
  if (isNaN(newDateEnd)) {
    timeEntry.value.endTime = oldEndTime.value;
    return;
  }

  //Aqui comprobamos si el valor de la fecha es correcto
  if (newDateStart > oldDateEnd || newDateEnd < oldDateStart) {
    newDateEnd.setDate(oldDateEnd.getDate() + 1);
  }

  if (newDateEnd.getHours() > newDateStart.getHours()) {
    if (newDateEnd.getMinutes() > newDateStart.getMinutes()) {
      newDateEnd.setDate(oldDateStart.getDate());
    }
  }

  firstEntry.value.timeStart = formatDate(newDateStart);
  firstEntry.value.timeEnd = formatDate(newDateEnd);
  firstEntry.value.duration = calcularTiempoTarea(newDateStart, newDateEnd);
}

// Tiempo Input horas
const focusInputHour = (name) => {
  if (name === 'start') {
    timeEntry.value.startTime = timeEntry.value.startTime.split(':');
    let temp = timeEntry.value.startTime[0] + timeEntry.value.startTime[1];
    timeEntry.value.startTime = temp;
  } else {
    timeEntry.value.endTime = timeEntry.value.endTime.split(':');
    let temp = timeEntry.value.endTime[0] + timeEntry.value.endTime[1];
    timeEntry.value.endTime = temp;
  }
}

const blurInputHour = (name) => {
  if (name === 'start') {
    if (newStartTime.value) {
      timeEntry.value.startTime = newStartTime.value;
    } else {
      timeEntry.value.startTime = oldStartTime.value;
    }
  } else {
    if (newEndTime.value) {
      timeEntry.value.endTime = newEndTime.value;
    } else {
      timeEntry.value.endTime = oldEndTime.value;
    }
  }
}

const checkChangeInputTime = (name) => {
  let temp;
  const newDuration = new Date();
  if (name === 'start') {
    let inputTemp = parseInt(timeEntry.value.startTime).toString();
    if (inputTemp.length == 3 || inputTemp.length == 4) {
      if (inputTemp.length == 3) temp = inputTemp.slice(0, 1) + ':' + inputTemp.slice(1);
      if (inputTemp.length == 4) temp = inputTemp.slice(0, 2) + ':' + inputTemp.slice(2);
      temp = temp.split(':')

      newDuration.setHours(parseInt(temp[0]))
      newDuration.setMinutes(parseInt(temp[1]))
      newDuration.setSeconds(0)
    }
    if (inputTemp.length == 1 || inputTemp.length == 2) {
      newDuration.setHours(parseInt(inputTemp))
      newDuration.setMinutes(0);
      newDuration.setSeconds(0);
    }
    const hours = newDuration.getHours().toString().padStart(2, "0");
    const minutes = newDuration.getMinutes().toString().padStart(2, "0");
    newStartTime.value = `${hours}:${minutes}`;
    blurInputHour('start');
  } else {
    let inputTemp = parseInt(timeEntry.value.endTime).toString();
    if (inputTemp.length == 3 || inputTemp.length == 4) {
      if (inputTemp.length == 3) temp = inputTemp.slice(0, 1) + ':' + inputTemp.slice(1);
      if (inputTemp.length == 4) temp = inputTemp.slice(0, 2) + ':' + inputTemp.slice(2);
      temp = temp.split(':')

      newDuration.setHours(parseInt(temp[0]))
      newDuration.setMinutes(parseInt(temp[1]))
      newDuration.setSeconds(0)
    }
    if (inputTemp.length == 1 || inputTemp.length == 2) {
      newDuration.setHours(parseInt(inputTemp))
      newDuration.setMinutes(0);
      newDuration.setSeconds(0);
    }
    const hours = newDuration.getHours().toString().padStart(2, "0");
    const minutes = newDuration.getMinutes().toString().padStart(2, "0");
    newEndTime.value = `${hours}:${minutes}`;
    timeEntry.value.endTime = newEndTime.value;
  }
}

const calcularTiempoTarea = (fecha1, fecha2) => {
  let time1 = new Date(fecha1)
  const time2 = new Date(fecha2);
  const diferenciaMs = time2 - time1;

  const segundosTotales = Math.floor(diferenciaMs / 1000);

  return segundosTotales;

}

const checkIfChild = () => {
  return props.isChild || props.entry.length == 1;
}


// Funcionalidad duracion

const checkAndChangeDuration = () => {
  if (!changeDuration.value) {
    return;
  }
  changeDuration.value = false;
  // eslint-disable-next-line no-unused-vars
  const oldDuration = firstEntry.value.duration;
  const newDuration = calcDuration(duration.value);

  if (newDuration <= 0) {
    duration.value = minutes.value;
    return;
  }

  firstEntry.value.duration = newDuration;
  let newDateEnd = new Date(firstEntry.value.timeStart);
  newDateEnd.setSeconds(newDateEnd.getSeconds() + newDuration)
  newDateEnd = formatDate(newDateEnd);
  firstEntry.value.timeEnd = newDateEnd;
  updateEntry(firstEntry.value).then(() => {
    ElNotification({
      message: "Se actualizó la tarea correctamente",
      type: "success",
      duration: 6000
    })
    reloadEntries();
  });
}

const changeEntryDuration = () => {
  changeDuration.value = true;
  checkAndChangeDuration();
}

const calcDuration = (newDuration) => {
  let divDur = newDuration.split(':');
  let seconds = 0;
  if (divDur.length < 2) {
    seconds = Math.floor(divDur[0]) * 60;
  } else {
    if (divDur.length == 2) {
      seconds = Math.floor(divDur[0]) * 60;
      seconds = seconds + Math.floor(divDur[1]);
    } else {
      if (divDur.length == 3) {
        seconds = Math.floor(divDur[0]) * 3600;
        seconds = seconds + Math.floor(divDur[1]) * 60;
        seconds = seconds + Math.floor(divDur[2]);
      } else {
        return
      }
    }
  }
  return seconds;
}


// Billable

const changeBillable = () => {
  let count = 0;
  for (let i = 0; i < props.entry.length; i++) {
    let entry = props.entry[i];
    entry.billable = !entry.billable;
    updateEntry(entry).then(() => {
      count++;
      if (count == props.entry.length) {
        ElNotification({
          message: "Se actualizó la tarea correctamente",
          type: "success",
          duration: 6000
        })
        reloadEntries();
      }
    });
  }
}


const changeEntryDescription = () => {
  changeDescription.value = true;
  checkAndChangeDescription()
}

const checkAndChangeDescription = () => {
  isFocused.value = false;
  if (!changeDescription.value) {
    return;
  }

  if (description.value == '' || !description.value) {
    ElNotification({
      message: "No puedes dejar vacia la descripción",
      type: "error",
      duration: 6000
    })
    description.value = firstEntry.value.description;
    changeDescription.value = false;
    return;
  }

  let count = 0;
  changeDescription.value = false;
  for (let i = 0; i < props.entry.length; i++) {
    const entry = props.entry[i];
    entry.description = description.value;
    updateEntry(entry)
      .then(() => {
        count++;
        if (count == props.entry.length) {
          ElNotification({
            message: "Se actualizó la tarea correctamente",
            type: "success",
            duration: 6000
          })
          count = 0;
          reloadEntries();
        }
      })
  }
}

// Project
const changeProject = () => {
  let count = 0;
  // store.state.loading = true;
  store.commit('startLoading');
  dropdownProject.value = false;
  for (let i = 0; i < props.entry.length; i++) {
    const entry = props.entry[i];
    entry.project = project.value;
    updateEntry(entry)
      .then(() => {
        count++;
        if (count == props.entry.length) {
          ElNotification({
            message: "Se actualizó la tarea correctamente",
            type: "success",
            duration: 6000
          })
          count = 0;
          // store.state.loading = false;
          store.commit('stopLoading');
          reloadEntries();
        }
      })
  }
}

const getNewProject = (newProject) => {
  project.value = newProject;
  dropdownProject.value = false;
  refDropdownProject.value.handleClose();
  changeProject();
}


const isDropdownOpen = ref(false);

const onDropdownToggle = (isOpen) => {
  isDropdownOpen.value = isOpen;
}

// Tags
const changeTags = () => {
  let count = 0;
  // store.state.loading = true;
  store.commit('startLoading');
  dropdownTags.value = false;
  if (tags.value.length <= 0) {
    // store.state.loading = false;
    store.commit('stopLoading');
    ElNotification({
      message: "Tiene que haber minimo una etiqueta",
      type: "error",
      duration: 6000
    })
    tags.value = firstEntry.value.tags;
    return;
  }

  for (let i = 0; i < props.entry.length; i++) {
    const entry = props.entry[i];
    entry.tags = tags.value;
    updateEntry(entry)
      .then(() => {
        count++;
        if (count == props.entry.length) {
          ElNotification({
            message: "Se actualizó la tarea correctamente",
            type: "success",
            duration: 6000
          })
          count = 0;
          // store.state.loading = false;
          store.commit('stopLoading');
          reloadEntries();
        }
      })
  }
}

// Calendar
const openCalendar = async () => {
  if (!checkIfChild()) {
    toggleEntries();
    return;
  }
  await nextTick();
  if (datePicker.value) {
    datePicker.value.handleOpen();
  }
}

const changeDay = () => {
  const newDateStart = new Date(firstEntry.value.timeStart)
  const newDateEnd = new Date(firstEntry.value.timeEnd)
  const month = day.value.getMonth();
  const year = day.value.getFullYear();
  const dayN = day.value.getDate();

  newDateStart.setDate(dayN);
  newDateStart.setFullYear(year);
  newDateStart.setMonth(month);

  newDateEnd.setDate(dayN);
  newDateEnd.setFullYear(year);
  newDateEnd.setMonth(month);
  let count = 0;
  for (let i = 0; i < props.entry.length; i++) {
    const entry = props.entry[i];
    entry.timeStart = formatDate(newDateStart);
    entry.timeEnd = formatDate(newDateEnd);
    updateEntry(entry)
      .then(() => {
        count++;
        if (count == props.entry.length) {
          ElNotification({
            message: "Se actualizó la tarea correctamente",
            type: "success",
            duration: 6000
          })
          count = 0;
          // store.state.loading = false;
          store.commit('stopLoading');
          store.commit('setChangedDayTrackernet', true);
        }
      })
  }
}

const getDaysDifference = (startDate, endDate) => {
  if (!isDate(startDate)) startDate = new Date(startDate);
  if (!isDate(endDate)) endDate = new Date(endDate);

  const d1 = moment(startDate).startOf('day');
  const d2 = moment(endDate).startOf('day');

  return Math.abs(d1.diff(d2, 'days'));
}


// Funciones menu dropdown
const isDropdown = ref(false)
const clickDropdown = () => {
  isDropdown.value = true;
  checkIfDropdownClicked();
}

const checkIfDropdownClicked = async () => {
  if (!isDropdown.value) {
    menuDropdown.value.handleClose();
    return;
  }
  isDropdown.value = false;
  await nextTick();
  if (menuDropdown.value) {
    menuDropdown.value.handleOpen();
  }
}



// Funciones Split
const openDialogSplit = () => {
  dialogSplit.value = true;
}

const closeDialogSplit = () => {
  dialogSplit.value = false;
}
</script>

<template>
  <div v-if="firstEntry">

    <hr v-if="props.dayLength != 1 && props.entryLength != 0">
    <div class="row d-flex flex-row newCard">
      <div class="row d-flex flex-row align-items-center entryCard">
        <!-- COLUMN 1 -->
        <div class="col-5 col-md-5">
          <div class="entry-info row d-flex justify-content-start align-items-center">
            <!-- TOGGLE TAREAS -->
            <el-tooltip class="box-item" effect="dark" :content="entry.length + ` tareas (click para desplegar)`"
              placement="top" v-if="!checkIfChild()">
              <div class="tracker-amount pointer col-1" v-if="!checkIfChild()" @click="toggleEntries">
                {{ entry.length }}
              </div>
            </el-tooltip>
            <!-- DESCRIPTION -->
            <div class="tracker-description colm-desc">
              <el-tooltip class="box-item" effect="dark" :content="description" placement="top-start"
                v-bind:disabled="isFocused">
                <el-input v-model="description" :clearable="false" @blur="checkAndChangeDescription"
                  @change="changeEntryDescription" @focus="isFocused = true" :readonly="false"
                  class="inputDescription" />
              </el-tooltip>
            </div>
            <!-- PROJECT -->
            <div class="tracker-project col-1">
              <el-tooltip class="box-item" effect="dark" :content="firstEntry.project.name" placement="top-start">
                <el-dropdown trigger="click" :hide-on-click="false" ref="refDropdownProject" placement="bottom"
                  @visible-change="onDropdownToggle">
                  <tracker-project-info :project="firstEntry.project" class="el-dropdown-link" :select="true"
                    @click="dropdownProject = true" />
                  <template #dropdown>
                    <el-dropdown-menu class="dropProject" v-if="dropdownProject">
                      <el-dropdown-item style="height: 300px;">
                        <TrackerProjectSearch @get-project="getNewProject" :project="project"
                          :dropdown-open="isDropdownOpen" v-if="dropdownProject" />
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </el-tooltip>
            </div>
          </div>
        </div>
        <!-- COLUMN 2 -->
        <div class="col-7 col-md-7">
          <div class="entry-info row d-flex justify-content-end align-items-center">
            <!-- ETIQUETAS -->
            <div class="col-2" :style="[getIsChild() ? 'margin-left:-55px;' : 'margin-left:-30px']">
              <el-tooltip class="box-item" effect="dark" :content="tagsText" placement="top">
                <el-dropdown trigger="click" :hide-on-click="false">
                  <div class="tracker-tag col-2 truncate-text" @click="dropdownTags = true">
                    {{ tagsText }}
                  </div>
                  <template #dropdown>
                    <el-dropdown-menu class="dropTags">
                      <el-dropdown-item>
                        <el-select :value-key="'@id'" clearable filterable multiple collapse-tags v-if="dropdownTags"
                          collapse-tags-tooltip placeholder="Etiqueta" v-model="tags" class="tracker-select"
                          @change="changeTags()">
                          <el-option v-for="tag in optionTags" :key="tag['@id']" :label="tag.name" :value="tag">
                          </el-option>
                        </el-select>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </el-tooltip>
            </div>
            <!-- IMPORTE -->
            <div class="tracker-button col-auto">
              <el-tooltip class="box-item" effect="dark" :content="firstEntry.billable ? 'Facturable' : 'No facturable'"
                placement="top">
                <span class="w-10 tracker-button pointer col-1"
                  :class="firstEntry.billable ? 'tracker-button--active' : 'tracker-button--inactive'"
                  @click="changeBillable">
                  €
                </span>
              </el-tooltip>
            </div>
            <el-divider class="col-auto" direction="vertical" style="margin: 0; height: 45px;"></el-divider>
            <!-- HORA INICIO/FIN -->
            <div class="tracker-date col-3" :class="changeTime ? 'tracker-date--editing' : ''"
              :style="!changeTime ? 'display:grid; justify-items:center;' : ''">
              <div>
                <input ref="refInputTimeStart" class="inputTime" v-model="timeEntry.startTime"
                  @click="refInputTimeStart.select()" @focus="focusInputHour('start')" @blur="blurInputHour('start')"
                  @change="changeTimeHour('start')" :disabled="!checkIfChild()">
                <span style="width: 3px;">
                  -
                </span>
                <input ref="refInputTimeEnd" class="inputTime" v-model="timeEntry.endTime"
                  @click="refInputTimeEnd.select()" @focus="focusInputHour('end')" @blur="blurInputHour('end')"
                  @change="changeTimeHour('end')" :disabled="!checkIfChild()">
              </div>
            </div>
            <!-- CALENDARIO -->
            <div class="col-auto">
              <el-date-picker v-model="day" ref="datePicker" type="date" class="myDatePicker"
                style="visibility: hidden; max-width: 1px; min-width: 1px; margin-top: 7px; margin-left: 4px; position: absolute;"
                :teleport="false" popper-class="custom-datepicker-popup" :popper-option="popperOptions"
                @click="openCalendar" @change="changeDay">
              </el-date-picker>
              <img class="tracker-calendar img-date" @click="openCalendar" src="@/assets/icons/calendar-icon.svg"
                alt="calendar-icon" style="cursor: pointer;" width="21" height="22">
            </div>
            <!-- DURACION -->
            <div class="tracker-dtime tracker-duration col-2">
              <div class="">
                <el-input v-model="duration" :clearable="false" :disabled="!checkIfChild()"
                  @blur="checkAndChangeDuration" @change="changeEntryDuration" :readonly="false"
                  class="inputDuration" />
              </div>
            </div>
            <div class="col-auto justify-content-end d-flex">
              <!-- BOTON INICIAR TAREA -->
              <div class="playButton">
                <el-tooltip class="box-item" effect="dark" content="Continuar el temporizador para esta actividad"
                  placement="top">
                  <img @click="startTimer" src="@/assets/icons/play-blue.svg" width="22" height="25" alt="play-icon"
                    class="pointer">
                </el-tooltip>
              </div>
              <!-- MENU DROPDOWN -->
              <el-dropdown trigger="click" ref="menuDropdown" :hide-on-click="false" @click="checkIfDropdownClicked()">
                <span class="el-dropdown-link">
                  <el-icon class="el-icon--right" style="height: 30px;" :size="25">
                    <MoreFilled @click="clickDropdown"
                      style="transform: rotate(90deg);color:#1D1D1B80; margin-right: -35px; margin-left: 20px; margin-top: 12px;" />
                  </el-icon>
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item @click="openDialogSplit" v-if="checkIfChild()">Dividir</el-dropdown-item>
                    <el-dropdown-item @click="duplicate">Duplicar</el-dropdown-item>
                    <el-dropdown-item @click="discard" style="color:red;">Descartar</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div v-if="showEntries && entry.length > 1" class="pl-3">
        <tracker-entry v-for="entry in entries" :entry="[entry]" :key="entry.id + Math.random()"
          @reload-entries="reloadEntries" :is-child="true" />
      </div>
    </transition>

    <el-dialog v-model="dialogSplit" title="Dividir tarea" width="600" style="height: 250px; overflow: hidden;"
      @close="closeDialogSplit">
      <TrackerSplit :entry="firstEntry" :timeEntry="timeEntry" @reload-entries="reloadEntries"
        @close-dialog="closeDialogSplit" />
    </el-dialog>
  </div>
</template>

<style scoped lang="scss">
.tracker-button {
  font-size: 32px;
}

.pl-3 {
  padding-left: 2.5rem;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.pointer {
  cursor: pointer;
}

input[type=text] {
  font-size: 1rem !important;
}

.inputDuration {
  font-size: 1rem !important;
  min-width: 135px;
  max-width: 135px;
}

.inputDescription {
  font-size: 16px !important;
  margin-left: -25px;
}

.tracker-button--inactive {
  color: rgb(168, 171, 178);
}

.inputTime {
  width: 50px;
  margin-bottom: -10px;
  margin-top: -10px;
  text-align: center;
  border: 1px solid white;
  box-shadow: none;
  font-size: 1rem;
  color: $font-blue-color;
}

.inputTime:focus,
.inputTime:active,
.inputTime:hover {
  border: 1px solid #929292;
}

.custom-datepicker-popup {
  margin-top: 5px;
  /* Ajusta el espacio entre la imagen y el popup */
  z-index: 2000;
  /* Asegura que el popup esté encima de otros elementos */
}

.myDatePicker {
  visibility: hidden;
  max-width: 10px;
  min-width: 10px;
  position: absolute;
  margin-top: 10px;
  margin-left: 4px;
  top: 0;
}

.playButton {
  border-left: 1px solid rgba(51, 51, 51, 0.2);
  border-right: 1px solid rgba(51, 51, 51, 0.2);
  padding: 9px 10px;
  margin-right: -25px;
  z-index: 2;
}

.el-dialog__body {
  min-height: auto !important;
}

.entryCard {
  margin-top: -3px;
  margin-bottom: -7px;
}


// Media querys

@media (max-width:1366px) {

  .colm-desc {
    width: 53%;
  }

  .playButton {
    margin-left: 25px;
  }
}

@media (min-width:1366px) {
  .colm-desc {
    width: 65%;
  }
}

@media(min-width:1600px) {
  .colm-desc {
    width: 80%;
  }

  .playButton {
    margin-left: -25px;
  }

  .tracker-tag {
    width: 155px;
  }
}

@media (min-width:1820px) {
  .colm-desc {
    width: 85%;
  }

  .tracker-tag {
    max-width: 210px !important;
  }
}
</style>