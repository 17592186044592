<script setup>


import TicketService from "@/api/services/ticket.service";
import Notification from "@/utils/plugins/NotificationService";
import SharedPagination from "@/components/shared/SharedPagination.vue";
import SharedButtonPlus from "@/components/shared/SharedButtonPlus.vue";
import stateColors from "@const/stateColors";
import moment from "moment/moment";
import { onMounted, ref, defineEmits } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const store = useStore();
const router = useRouter();
const emits = defineEmits(['totalTicket']);

const tickets = ref([]);
const clientOrder = ref(null);
const typeOrder = ref(null);
const stateOrder = ref(null);

const descriptionOrder = ref(null);
const totalResults = ref(0);
const itemsPerPage = ref({ id: 4, name: '15', value: 15 });
const page = ref(1);

onMounted(async () => {
  getTickets()
})
const typeColor = (type) => {
  if (type === 'Incidencia')
    return { color: '#F9156F', label: 'INCIDENCIA' };
  else if (type === 'Solicitud de servicio') {
    return { color: '#4AABC3', label: 'SERVICIO' };
  } else if (type === 'Solicitud de soporte') {
    return { color: '#919191', label: 'SOPORTE' };
  }
  return 'grey'
}
/* const client = (ticket) => {
     return ticket.affectedUser ? ticket.affectedUser.name : (ticket.requestUser ? ticket.requestUser.name :'')
   }*/
const gotoEdit = (id) => {
  router.push({ name: 'update_ticket', params: { id: id } })
}
const getTickets = () => {
  const params = {
    itemsPerPage: itemsPerPage.value.value,
    page: page.value,
    'order[createdAt]': 'DESC',
    internal: false
  }
  if (clientOrder.value) {
    params['order[name]'] = clientOrder.value;
  }
  if (stateOrder.value) {
    params['order[state]'] = stateOrder.value;
  }
  if (descriptionOrder.value) {
    params['order[description]'] = descriptionOrder.value;
  }
  // params['requestingUser.id'] = store.state.user.id
  // store.dispatch('setLoading',true);
  store.commit('startLoading');
  TicketService.getAssignedTickets(store.getters.getUserLogged.id, params)
    .then((response) => {
      tickets.value = response.data['hydra:member'];
      totalResults.value = response.data["hydra:totalItems"];
      // store.dispatch('setLoading',false);
      store.commit('stopLoading');
      emits('totalTicket', totalResults);
    })
    .catch((error) => {
      console.log(error);
      Notification.addNotification('Error al obtener listado de tickets.', 'error', 6000);
      // store.dispatch('setLoading',false);
      store.commit('stopLoading');
    }).finally(() => {
      //  store.dispatch('setLoading',false);
      store.commit('stopLoading');
    });

}
const formatDateLocale = (value, loc = 'es') => {
  if (loc) {
    loc = 'es';
  }
  moment.updateLocale('es', {
    monthsShort: [
      "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
      "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ]
  });
  if (value) {
    return moment(value).local(loc).format('DD MMM YYYY');
  } else {
    return null;
  }
}

const changeOrderType = () => {

}

</script>
<template>
  <div class="w-100">
    <el-row>
      <div v-if="tickets && tickets.length > 0" class="table pt-0">
        <table>
          <thead>
            <tr>
              <th style="width: 250px;">
                <div class="d-flex justify-content-start align-items-center">
                  <div>
                    <img width="21" height="20" class="mr-2" src="@/assets/icons/user.svg" alt="cliente">CLIENTE
                  </div>
                </div>
              </th>
              <th style="width: 180px;">
                <div class="d-flex justify-content-start align-items-center pointer" @click="changeOrderType()">
                  <div>
                    <img width="21" height="20" class="mr-2" src="@/assets/icons/group.svg" alt="tipo">TIPO
                    <font-awesome-icon v-if="typeOrder === 'ASC'" icon="sort-alpha-up" />
                    <font-awesome-icon v-else-if="typeOrder === 'DESC'" icon="sort-alpha-down" />
                    <font-awesome-icon v-else icon="sort" />
                  </div>
                </div>
              </th>
              <th style="max-width: 500px;">
                <div class="d-flex justify-content-start align-items-center pointer" @click="changeOrderDescription()">
                  <div>
                    <img width="21" height="20" src="@/assets/icons/group.svg" alt="descripción"> DESCRIPCIÓN
                    <font-awesome-icon v-if="descriptionOrder === 'ASC'" icon="sort-alpha-up" />
                    <font-awesome-icon v-else-if="descriptionOrder === 'DESC'" icon="sort-alpha-down" />
                    <font-awesome-icon v-else icon="sort" />
                  </div>
                </div>
              </th>
              <th style="width: 230px;">
                <div class="d-flex justify-content-start align-items-center pointer" @click="changeOrderType()">
                  <div>
                    <img width="21" height="20" class="mr-2" src="@/assets/icons/calendar-blue.svg"
                      alt="fecha inicio">FECHA INICIO
                    <font-awesome-icon v-if="typeOrder === 'ASC'" icon="sort-alpha-up" />
                    <font-awesome-icon v-else-if="typeOrder === 'DESC'" icon="sort-alpha-down" />
                    <font-awesome-icon v-else icon="sort" />
                  </div>
                </div>
              </th>
              <th style="width: 230px;">
                <div class="d-flex justify-content-start align-items-center pointer" @click="changeOrderType()">
                  <div>
                    <img width="21" height="20" class="mr-2" src="@/assets/icons/calendar-blue.svg"
                      alt="dias abierta">DIAS ABIERTA
                    <font-awesome-icon v-if="typeOrder === 'ASC'" icon="sort-alpha-up" />
                    <font-awesome-icon v-else-if="typeOrder === 'DESC'" icon="sort-alpha-down" />
                    <font-awesome-icon v-else icon="sort" />
                  </div>
                </div>
              </th>
              <th style="width: 200px;">
                <div class="d-flex justify-content-start align-items-center pointer" @click="changeOrderType()">
                  <div>
                    <img width="21" height="20" class="mr-2" src="@/assets/icons/group.svg" alt="estado">ESTADO
                    <font-awesome-icon v-if="typeOrder === 'ASC'" icon="sort-alpha-up" />
                    <font-awesome-icon v-else-if="typeOrder === 'DESC'" icon="sort-alpha-down" />
                    <font-awesome-icon v-else icon="sort" />
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="aTicket in tickets" :key="aTicket.id">
              <td>
                <div class="d-flex justify-content-start align-items-center">
                  {{ aTicket.project?.client?.name }}
                </div>
              </td>
              <td>
                <div class="d-flex justify-content-start align-items-center">
                  <div class="type-color" :style="{ backgroundColor: typeColor(aTicket.ticketType.name).color }" /> {{
                    typeColor(aTicket.ticketType.name).label }}
                </div>
              </td>
              <td>
                {{ aTicket.issue }}
              </td>
              <td>
                <div class="d-flex justify-content-start align-items-center">
                  {{ formatDateLocale(aTicket.createdAt) }}
                </div>
              </td>
              <td>
                <div class="d-flex justify-content-start align-items-center">
                  {{ aTicket.daysOpen }}
                </div>
              </td>
              <td>
                <div class="d-flex justify-content-between align-items-center"
                  :style="{ color: stateColors.getColor(aTicket.status) }">
                  {{ aTicket.status }}
                  <shared-button-plus class="d-inline-block float-right" @onClick="gotoEdit(aTicket.id)"
                    :title="'Editar ticket'" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <p class="alert alert-info mt-3" v-if="!store.state.loading">
          No tiene tickets asignados actualmente
        </p>
      </div>
    </el-row>
    <el-row v-if="tickets.length">
      <SharedPagination v-if="tickets && totalResults > itemsPerPage.value" class="pagination" :page="page"
        :total-results="totalResults" :page-size="itemsPerPage.value" @change="page = $event; getTickets();" />
    </el-row>
  </div>
</template>


<style scoped>
.type-color {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 5px;
}
</style>
