<script setup>

import { MoreFilled } from "@element-plus/icons-vue";
import { computed, defineEmits, onMounted, ref, watch, defineProps } from "vue";
import TrackerProjectInfo from "@/components/shared/TrackerProjectInfo.vue";
import { ElMessageBox, ElNotification } from "element-plus";
import notification from "@/utils/plugins/NotificationService";
import TrackerService from "@/api/services/tracker.service";
import Clock from "@/components/shared/Clock.vue";
import { useStore } from "vuex/dist/vuex.cjs.js";
import TrackerSplit from "@/components/shared/TrackerSplit.vue";
import ProjectsService from "@/api/services/projects.service";
import TimeEntryTagService from "@/api/services/timeEntryTag.service";
import moment from "moment";
import TrackerProjectSearch from "@/components/shared/TrackerProjectSearch.vue";

const store = useStore();
const page = ref(1);
const itemsPerPage = ref(9999);
const archived = ref(false);

const description = ref('');
const changeDescription = ref(false);
const project = ref();

const PROJECT_API = "/api/projects/";
const TAGS_API = "/api/time_entry_tags/";

const tags = ref();
const optionTags = ref([]);
const optionProjects = ref([]);

const refInputStartTime = ref(null);
const inputStartTime = ref();
const newStartTime = ref();
const refDropdownProject = ref();
const dropdownProject = ref(false);

// Autocomplete Description
const refAutocompleteDescription = ref();
const entriesOptions = ref([]);
const isSelectedEntry = ref(false);

const day = computed(() => {
  const entryStart = new Date(props.entry.timeStart)
  const today = new Date();
  if (isSameDay(today, entryStart)) {
    return 'Hoy'
  } else {
    return moment(props.entry.timeStart).format("DD/MM/YYYY");
  }
})
const emits = defineEmits(['reloadEntries']);

const props = defineProps({
  entry: Object
})

onMounted(() => {
  description.value = props.entry.description;
  project.value = props.entry.project;
  tags.value = props.entry.tags;
  getProjects();
  getTags();
  startTotalTime();
  inputStartTime.value = startTime.value;

})


const duration = computed(() => store.state.entryDuration);

const newDuration = ref();
const setDuration = () => {
  const duration = parseInt(newDuration.value, 10);
  if (!isNaN(duration)) {
    store.commit('setEntryDuration', duration);
  }
}

const startTime = computed(() => {
  const temp = moment(props.entry.timeStart).format('HH:mm')
  return temp;
})

const endTime = computed(() => {
  const newDate = new Date(props.entry.timeStart);
  newDate.setHours(newDate.getHours() + (totalTime.value.hours || 0));
  newDate.setMinutes(newDate.getMinutes() + (totalTime.value.minutes || 0));
  newDate.setSeconds(newDate.getSeconds() + (totalTime.value.seconds || 0));

  const temp = moment(newDate).format('HH:mm')

  return temp;
})

const totalTime = ref({
  hours: 0,
  minutes: 0,
  seconds: 0,
});

const startTotalTime = () => {
  totalTime.value.hours = props.entry.totalTime.hours;
  totalTime.value.minutes = props.entry.totalTime.minutes;
  totalTime.value.seconds = props.entry.totalTime.seconds;
}

watch(duration, (newVal) => {
  const hours = Math.floor(newVal / 3600);
  const minutes = Math.floor((newVal % 3600) / 60); // Resto entre 3600 y divide entre 60 para minutos
  const seconds = newVal % 60; // Resto final para segundos  
  totalTime.value = {
    hours,
    minutes,
    seconds
  }
})

const dialogSplit = ref(false);


// eslint-disable-next-line no-unused-vars
const entryDuration = computed(() => store.state.entryDuration);
// eslint-disable-next-line no-unused-vars
const startInterval = () => store.dispatch('startInterval');
// eslint-disable-next-line no-unused-vars
const stopInterval = () => store.dispatch('stopInterval');

const entry = computed(() => {
  return props.entry;
})

const getProjects = () => {
  let params = {
    page: page.value,
    itemsPerPage: itemsPerPage.value,
    archived: archived.value,
    'order[initialDate]': 'DESC'
  }
  // store.state.loading = true;
  store.commit('startLoading');
  ProjectsService.getProjects(params)
    .then((response) => {
      optionProjects.value = response.data['hydra:member'];
    })
    .catch((error) => {
      console.log(error);
      Notification.addNotification(
        "Error al cargar los proyectos"
      );
    })
    .finally(() => {
      // store.state.loading = false;
      store.commit('stopLoading');
    });
}

const getTags = () => {
  let params = {
    page: page.value,
    itemsPerPage: itemsPerPage.value,
  }
  params['order[name]'] = 'ASC';
  // store.state.loading = true;
  store.commit('startLoading');
  TimeEntryTagService.getTags(params)
    .then((response) => {
      optionTags.value = response.data['hydra:member'];
    })
    .catch((error) => {
      console.log(error);
      Notification.addNotification(
        "Error al cargar las etiquetas"
      );
    })
    .finally(() => {
      // store.state.loading = false;
      store.commit('stopLoading');
    });

}

const tagsText = computed(() => {
  let temp = "Elegir etiqueta";
  for (let i = 0; i < entry.value.tags.length; i++) {
    const element = entry.value.tags[i];
    if (i == 0) {
      temp = element.name;
    } else {
      temp = temp + ", " + element.name
    }
  }
  return temp;
})


const stopTimeEntry = async () => {
  let entry = { ...props.entry };

  if (entry.description == '' || !entry.project || !entry.tags) {
    let mssg = "No se ha podido cerrar la tarea, falta: ";
    if (entry.description == '') mssg = mssg + 'descripción '
    if (!entry.project.name) mssg = mssg + 'proyecto '
    if (entry.tags.length < 1) mssg = mssg + 'etiquetas '
    ElNotification({
      message: mssg,
      type: "error",
      duration: 6000
    })
    return;
  }
  entry.project = entry.project['@id'];
  let tags = [];
  entry.tags.forEach((tag) => {
    tags.push(tag['@id']);
  });
  entry.tags = tags;
  entry.timeEnd = formatDate(new Date());
  entry.employee = entry.employee['@id'];

  await TrackerService.stopEntry(entry).then(() => {
    stopInterval();
    reloadEntries();
  }).catch((error) => {
    console.log(error);
    Notification.addNotification(
      "Error al parar la tarea"
    );
  }).finally(() => {
    if (store.state.isEntryActive) {
      store.state.isEntryActive = false;
    }
  })
}

const formatDate = (date) => {
  // Obtener el desplazamiento de la zona horaria
  const offset = new Date().getTimezoneOffset();
  const sign = offset > 0 ? '-' : '+';
  const hours = String(Math.floor(Math.abs(offset) / 60)).padStart(2, '0');
  const minutes = String(Math.abs(offset) % 60).padStart(2, '0');

  // Formato de fecha
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  // Formato de hora
  const hoursOfDay = String(date.getHours()).padStart(2, '0');
  const minutesOfDay = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  // Devolver la cadena en formato ISO con el desplazamiento de la zona horaria
  return `${year}-${month}-${day}T${hoursOfDay}:${minutesOfDay}:${seconds}${sign}${hours}:${minutes}`;
}

const discard = () => {
  ElMessageBox.confirm(
    '¿Estás seguro de descartar esta entrada?',
    'Warning',
    {
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
      type: 'warning',
    }
  )
    .then(async () => {
      const id = props.entry.id;
      await TrackerService.discardEntry(id);
      reloadEntries()
      notification.addNotification("La entrada se ha eliminado", "success", 10000)
    })
    .catch((error) => {
      console.log(error);
      Notification.addNotification(
        "Error al eiminar la tarea"
      );
    })
}

const reloadEntries = () => {
  emits('reloadEntries');
}

// Update
const updateEntry = (entry) => {
  let tags = [];

  for (let i = 0; i < entry.tags.length; i++) {
    const tag = entry.tags[i];
    tags.push(TAGS_API + tag.id)
  }
  let params = {
    "employee": entry.employee['@id'],
    "project": PROJECT_API + entry.project.id,
    "tags": tags,
    "description": entry.description,
    "timeStart": entry.timeStart,
    "billable": entry.billable,
  }
  return TrackerService.updateEntry(entry.id, params);
}


// Description
//Se ejecuta cuando se cambia la descripción
const changeEntryDescription = () => {
  changeDescription.value = true;
  checkAndChangeDescription();

}

// Esto comprueba que esten ok los cambios pertinentes, y si estan ok, pues hace el cambio
const checkAndChangeDescription = () => {
  if (!changeDescription.value) {
    return;
  }
  if (isSelectedEntry.value) {
    isSelectedEntry.value = false;
    return;
  }
  if (description.value.length < 5) {
    //Se podría poner para que pase siempre que sea menor que 5
    if (description.value.length == 0) {
      description.value = props.entry.description;
    }
    return;
  }
  changeDescription.value = false;
  const entry = props.entry;
  entry.description = description.value;
  store.commit('startLoading');
  updateEntry(entry)
    .then(() => {
      ElNotification({
        message: "Se actualizó la tarea correctamente",
        type: "success",
        duration: 6000
      })
      store.commit('stopLoading');
    })
    .catch((error) => {
      console.log(error);
      Notification.addNotification(
        "Error al actualizar la descripción"
      );
      store.commit('stopLoading');
    })
}

// Esto se utiliza para que cuando el autocomplete reciba un cambio en el input, se haga la petición para conseguir los entries
const querySearch = (queryString, callback) => {
  // Filtra las entradas basándose en el queryString (valor actual del input)
  if (queryString.length < 3) {
    callback([]);
    return;
  }
  let params = {
    page: 1,
    itemsPerPage: 9,
    'order[timeStart]': 'DESC',
    description: queryString,
  }

  TrackerService.getEmployeeEntries(store.state.user.id, params).then((res) => {
    entriesOptions.value = [];
    for (let i = 0; i < res.data['hydra:member'].length; i++) {
      const entry = res.data['hydra:member'][i];
      if (entry.timeEnd) {
        entriesOptions.value.push(entry)
      }
    }
    callback(entriesOptions.value);
  })
};

// Cuando se elige una nueva entrada, hace los cambios y lo actualiza
const handleSelect = (val) => {
  selectNewEntry(val)
}

const selectNewEntry = (entry) => {
  isSelectedEntry.value = true;
  let newEntry = props.entry;
  description.value = entry.description;
  newEntry.description = entry.description;
  // props.entry = entry.billable;
  newEntry.billable = entry.billable;
  project.value = entry.project;
  newEntry.project = entry.project;
  tags.value = entry.tags;
  newEntry.tags = entry.tags;

  updateEntry(newEntry).then(() => {
    ElNotification({
      message: "Se actualizó la tarea correctamente",
      type: "success",
      duration: 6000
    })
    store.commit('stopLoading');
  })
    .catch((error) => {
      console.log(error);
      Notification.addNotification(
        "Error al actualizar la descripción"
      );
      store.commit('stopLoading');
    });
}

// Cambia la descripción del model
// eslint-disable-next-line no-unused-vars
const handleUpdateModel = (val) => {
  description.value = val;
}

const getTagsText = (tags) => {
  let text = '';
  for (let i = 0; i < tags.length; i++) {
    const element = tags[i];
    if (i == 0) {
      text = element.name;
    } else {
      text = text + ", " + element.name
    }
  }
  return text;
}

// Billable
const changeBillable = () => {
  let entry = props.entry;
  entry.billable = !entry.billable;
  updateEntry(entry).then(() => {
    ElNotification({
      message: "Se actualizó la tarea correctamente",
      type: "success",
      duration: 6000
    })
  }).catch((error) => {
    console.log(error);
    Notification.addNotification(
      "Error al cambiar la facturacion"
    );
  });

}

// Project
const changeProject = () => {
  // store.state.loading = true;
  store.commit('startLoading');
  const entry = props.entry;
  entry.project = project.value;
  updateEntry(entry)
    .then(() => {
      ElNotification({
        message: "Se actualizó la tarea correctamente",
        type: "success",
        duration: 6000
      })
      // store.state.loading = false;
      store.commit('stopLoading');
      reloadEntries();
    }).catch((error) => {
      console.log(error);
      Notification.addNotification(
        "Error al cambiar el proyecto"
      );
    })
}

// Gets the new project selected
const getNewProject = (newProject) => {
  project.value = newProject;
  refDropdownProject.value.handleClose();
  changeProject();
}

const isDropdownOpen = ref(false);

// Handle when the dropdown is closed
const handleDropdownProjectVisibleChange = (visible) => {
  if (!visible) {
    dropdownProject.value = false;
  }
  isDropdownOpen.value = visible;
}

// Tags
const changeTags = () => {
  // store.state.loading = true;
  store.commit('startLoading');

  if (tags.value.length <= 0) {
    ElNotification({
      message: "Tiene que haber minimo una tarea",
      type: "error",
      duration: 6000
    })
    tags.value = props.entry.tags;
    // store.state.loading = false;
    store.commit('stopLoading');
    return;
  }
  const entry = props.entry;
  entry.tags = tags.value;
  updateEntry(entry)
    .then(() => {
      // store.state.loading = false;
      store.commit('stopLoading');
      ElNotification({
        message: "Se actualizó la tarea correctamente",
        type: "success",
        duration: 6000
      })
      reloadEntries();
    }).catch((error) => {
      console.log(error);
      Notification.addNotification(
        "Error al cambiar las etiquetas"
      );
    })
}

// Funciones Split
const openDialogSplit = () => {
  dialogSplit.value = true;
}

const closeDialogSplit = () => {
  dialogSplit.value = false;
}

const isDisabledSplit = computed(() => {
  if (totalTime.value.hours <= 0) {
    if (totalTime.value.minutes < 15) {
      return true
    } else {
      return false;
    }
  } else { return false }
})

// change start time

const focusInputHour = () => {
  inputStartTime.value = inputStartTime.value.split(':');
  let temp = inputStartTime.value[0] + inputStartTime.value[1];
  inputStartTime.value = temp;
}

const blurInputHour = () => {
  if (newStartTime.value) {
    inputStartTime.value = newStartTime.value;
  } else {
    inputStartTime.value = startTime.value;
  }
}

const checkChangeInputHour = () => {
  let temp;
  const newDuration = new Date();
  const now = new Date();
  if (inputStartTime.value.length == 3 || inputStartTime.value.length == 4) {
    if (inputStartTime.value.length == 3) temp = inputStartTime.value.slice(0, 1) + ':' + inputStartTime.value.slice(1);
    if (inputStartTime.value.length == 4) temp = inputStartTime.value.slice(0, 2) + ':' + inputStartTime.value.slice(2);
    temp = temp.split(':')

    newDuration.setHours(parseInt(temp[0]))
    newDuration.setMinutes(parseInt(temp[1]))
    newDuration.setSeconds(0)
  }
  if (inputStartTime.value.length == 1 || inputStartTime.value.length == 2) {
    newDuration.setHours(parseInt(inputStartTime.value))
    newDuration.setMinutes(0);
    newDuration.setSeconds(0);
  }

  if (newDuration > now) {
    newDuration.setDate(newDuration.getDate() - 1);
  }

  //Comprobar que el valor entra dentro de los valores
  if (newDuration > now || isNaN(newDuration) || inputStartTime.value.length >= 5) {
    refInputStartTime.value.blur();
    return;
  }

  const hours = newDuration.getHours().toString().padStart(2, "0");
  const minutes = newDuration.getMinutes().toString().padStart(2, "0");
  newStartTime.value = `${hours}:${minutes}`;
  changeInputHour(newDuration);
  refInputStartTime.value.blur();
}

const changeInputHour = (newStart) => {
  // store.state.loading = true;
  store.commit('startLoading');
  const entry = props.entry;
  entry.timeStart = formatDate(newStart);
  const totalTime = calcularTiempoTarea(formatDate(newStart));
  let total = totalTime.hours * 3600;
  total += totalTime.minutes * 60;
  total += totalTime.seconds;
  entry.duration = total;
  newDuration.value = total;
  updateEntry(entry)
    .then(() => {
      ElNotification({
        message: "Se actualizó la tarea correctamente",
        type: "success",
        duration: 6000
      })
    })
    .catch((error) => {
      console.log(error);
      Notification.addNotification(
        "Error al cambiar la hora de inicio"
      );
    })
    .finally(() => {
      store.state.entryDuration = newDuration.value;
      // store.state.loading = false;
      store.commit('stopLoading');
      setDuration();
    })
}

const calcularTiempoTarea = (fecha) => {
  let time = new Date(fecha)
  const now = new Date();
  const diferenciaMs = now - time;
  if (diferenciaMs < 0) {
    throw new Error("La fecha proporcionada es  futura, no se puede calcular el tiempo transcurrido.");
  }
  const segundosTotales = Math.floor(diferenciaMs / 1000);
  const hours = Math.floor(segundosTotales / 3600);
  const minutes = Math.floor((segundosTotales % 3600) / 60);
  const seconds = segundosTotales % 60;
  return {
    hours,
    minutes,
    seconds,
  };
}

const isSameDay = (date1, date2) => {
  if (!(date1 instanceof Date) || !(date2 instanceof Date)) return false;
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  );
};

</script>

<template>
  <div class="row currentEntryCard">
    <!-- COLUMN 1 -->
    <div class="col-7 column1 entry-info justify-content-center">
      <div class="row d-flex" style="margin-top: 3px;">
        <!-- DESCRIPTION -->
        <div class="col-7 tracker-description">
          <el-autocomplete ref="refAutocompleteDescription" v-model="description" :fetch-suggestions="querySearch"
            @change="changeEntryDescription" style="width: 100%;" @select="handleSelect"
            placeholder="Descripción de proyecto" class="inputDescription" popper-class="custom-autocomplete-popper"
            :title="description" value-key="description">
            <template #default="{ item }">
              <div class="custom-suggestion d-flex fixed-dropdown-item">
                <span class="ellipsis-span" style="max-width: 40%;" :title="item.description">
                  {{ item.description }}
                </span>
                <span class="ellipsis-span" style="max-width: 20%;" :title="item.project.name">
                  <tracker-project-info :project="item.project" :select="true" class="projectSearch" />
                </span>
                <span class="ellipsis-span tracker-tagD" style="max-width: 40%;" :title="getTagsText(item.tags)">
                  {{ getTagsText(item.tags) }}
                </span>
              </div>
            </template>
          </el-autocomplete>
        </div>
        <!-- PROJECT -->
        <div class="col-5 tracker-project current-project">
          <el-tooltip class="box-item" effect="dark" :content="props.entry.project.name" placement="top">
            <el-dropdown trigger="click" :hide-on-click="false" ref="refDropdownProject" class="addProjectDropdown"
              placement="bottom" @visible-change="handleDropdownProjectVisibleChange">
              <p v-if="!project" @click="dropdownProject = true" style="margin-top: 10px;">
                Añadir Proyecto
              </p>
              <tracker-project-info :project="project" class="el-dropdown-link" @click="dropdownProject = true"
                v-if="project" :currentEntry="true" />
              <template #dropdown>
                <el-dropdown-menu class="dropProject">
                  <el-dropdown-item style="height: 300px;" v-if="dropdownProject">
                    <TrackerProjectSearch @get-project="getNewProject" :project="project"
                      :dropdown-open="isDropdownOpen" />
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </el-tooltip>
        </div>
      </div>
    </div>
    <!-- COLUMN 2 -->
    <div class="col-5 column2 entry-info align-items-center">
      <div class="row d-flex">
        <!-- ETIQUETAS -->
        <div class="col-6">

          <el-tooltip class="box-item" effect="dark" :content="tagsText" placement="top">
            <el-dropdown trigger="click" :hide-on-click="false" max-height="200" class="temp">

              <div class="tracker-tag col-6 align-items-center align-content-center " style="margin-top: 10px;">
                {{ tagsText }}
              </div>
              <template #dropdown>
                <el-dropdown-menu class="dropTags">
                  <el-dropdown-item>
                    <el-select :value-key="'@id'" clearable filterable multiple collapse-tags collapse-tags-tooltip
                      placeholder="Etiqueta" v-model="tags" class="tracker-select" @change="changeTags()">
                      <el-option v-for="tag in optionTags" :key="tag['@id']" :label="tag.name" :value="tag">
                      </el-option>
                    </el-select>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </el-tooltip>

        </div>
        <!-- IMPORTE -->
        <div class="tracker-button col-1" style="margin-left: auto; margin-top: 6px;">
          <el-tooltip class="box-item" effect="dark" :content="props.entry.billable ? 'Facturable' : 'No facturable'"
            placement="top">
            <span class="w-10 tracker-button pointer"
              :class="props.entry.billable ? 'tracker-button--active' : 'tracker-button--inactive'"
              @click="changeBillable">
              €
            </span>
          </el-tooltip>
        </div>
        <div class="col-5">
          <div class="row d-flex">
            <!-- DURACION // HORA DE INICIO -->
            <div class="col-3 text-center p-2 flex-column align-items-center">
              <el-dropdown trigger="click" :hide-on-click="false" max-height="200" class="temp">
                <clock :startTime="totalTime" style="margin-top: 8px;" />
                <template #dropdown>
                  <el-dropdown-menu class="dropClock">
                    <el-dropdown-item>
                      <span>Hora de Inicio </span>
                      <el-input ref="refInputStartTime" v-model="inputStartTime" @focus="focusInputHour()"
                        @change="checkChangeInputHour()" @blur="blurInputHour()"
                        @click="refInputStartTime.select()"></el-input>
                      <span> {{ day }} </span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>

            </div>
            <!-- DETENER -->
            <div class="col-7 text-center p-2 flex-column justify-content-end">
              <el-tooltip class="box-item" effect="dark" content="Detener (s)" placement="top">
                <el-button type="danger" class="w-10" @click="stopTimeEntry">
                  Detener
                </el-button>
              </el-tooltip>

            </div>
            <!-- MENU DROPDOWN -->
            <div class="col-1 text-center p-2 flex-column justify-content-end">
              <el-dropdown>
                <span class="el-dropdown-link">
                  <el-icon class="el-icon--right" style="height: 30px;">
                    <more-filled
                      style="transform: rotate(90deg);color:#1D1D1B80; margin-left: -10px; font-size: 20px;" />
                  </el-icon>
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item @click="openDialogSplit" :disabled="isDisabledSplit">Dividir</el-dropdown-item>
                    <el-dropdown-item @click="discard" style="color:red;">Descartar</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <el-dialog v-model="dialogSplit" title="Dividir tarea" width="600" style="height: 250px; overflow: hidden;"
    @close="closeDialogSplit">
    <TrackerSplit v-if="dialogSplit" :entry="props.entry" :timeEntry="{ startTime, endTime }"
      @reload-entries="reloadEntries" @close-dialog="closeDialogSplit" />
  </el-dialog>
</template>
<style scoped lang="scss">
.tracker-button {
  font-size: 20px;
}

.currentEntryCard {
  margin-bottom: -7px;
  margin-top: -7px;
}

.dropClock {

  .el-dropdown-menu__item:not(.is-disabled):focus,
  .el-dropdown-menu__item:not(.is-disabled):hover {
    color: black !important;
    background-color: red;
  }
}

.dropdownDescription {
  .el-input__wrapper {
    .el-input__inner {
      cursor: text !important;
    }
  }
}

.tracker-tagD {
  background-color: #f7d20e !important;
  color: #474ce6 !important;
  padding-left: 10px;
  padding-right: 10px;
}

.fixed-dropdown-item {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  padding: 3px;

  >span {
    // flex: 1 1 auto; 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 8px;
  }
}

:deep(.custom-autocomplete-popper) {
  width: 300px !important;
  max-height: 200px !important;
}

.custom-autocomplete-popper {
  width: 300px !important;
  /* Ajusta el ancho deseado */
  max-height: 200px !important;
  /* Ajusta el alto deseado, o usa height si es fijo */
  /* Puedes agregar otros estilos, como padding, borde, etc. */
}

@media (max-width:1366px) {
  .tracker-tag {
    margin-left: 0px !important;
    width: 250px !important;
    max-width: 250px !important;
  }
}

@media (min-width:1366px) {
  .tracker-tag {
    margin-left: -35px !important;
    width: 250px !important;
    max-width: 250px !important;
  }
}
</style>