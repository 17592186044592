<template>
  <div id="nav" :style="{ width: width_nav + 'px' }">

    <div class="toggle-button" @click="changeNav">
      <font-awesome-icon :icon="nav_desp ? 'angle-left' : 'angle-right'" class="iconNav" />
    </div>
    <!--    <div id="nav" v-bind:style="{ width: width_nav + 'px'}">-->
    <div class="user">
      <!-- <div id="circle"></div> -->
      <div v-if="isPhoto" class="img-user" :class="nav_desp ? '' : 'closed'">
        <img class="rounded-circle img-fluid thumbnail" :src="baseUrl + user.photo" :alt="'Employee image'"
          :title="'Click para ampliar'">
      </div>
      <div v-else class="img-user" :class="nav_desp ? '' : 'closed'">
        <img class="rounded-circle img-thumbnail thumbnail" src="~@/assets/images/user.png" :alt="'Default Image'">
      </div>
      <div class="username" :class="nav_desp ? '' : 'closed'">
        <p style="font-weight: bold; color: #FFFFFF">
          {{ user.name }} {{ user.lastName }}
        </p>
        <p style="font-weight: bold; color: #B8B8C4">
          {{ user.jobName }}
        </p>
      </div>
    </div>

    <!--    <el-menu class="el-menu-vertical-demo" background-color="transparent">-->
    <!--      <el-menu-item>-->
    <!--        <div @click="showHome()">-->
    <!--          <router-link :to="{name: 'home'}">-->
    <!--            <img style="width: 25px; margin-right: 10px; margin-bottom: 5px;"-->
    <!--                 :src="!openHome ? require(`@/assets/icons/home-blue.png`) : require(`@/assets/icons/home-yellow.png`) "/>-->
    <!--            <span class="pointer">INICIO</span>-->
    <!--          </router-link>-->
    <!--        </div>-->
    <!--      </el-menu-item>-->
    <!--    </el-menu>-->

    <ul class="main-menu">
      <!-- Inicio -->
      <li class="li-border li-group list-menu">
        <div>
          <router-link :to="{ name: 'home' }">
            <img style="width: 25px; margin-right: 10px; margin-bottom: 5px;" :src="sidebarHome">
            <span class="pointer label" v-if="nav_desp">INICIO</span>
          </router-link>
        </div>
      </li>
      <!-- RRHH -->
      <li class="li-border li-group list-menu">
        <div @click="showRRHH()">
          <!--          <font-awesome-icon class="iconNav" :icon="openRRHH ? 'folder-open' : 'folder'"/>-->
          <img style="width: 25px; margin-right: 10px; margin-bottom: 5px;"
            :src="!openRRHH ? require(`@/assets/icons/user-blue.png`) : require(`@/assets/icons/user-yellow.png`)">
          <span class="pointer label" v-if="nav_desp">TALENTO</span>
        </div>
        <div v-if="openRRHH" class="submenu-border">
          <ul class="sub_menu">
            <li v-if="hasRoleAdmin()">
              <router-link :to="{ name: 'employees' }">
                <font-awesome-icon class="iconNav" icon="users" />
                <span v-if="nav_desp">EMPLEADOS</span>
              </router-link>
            </li>
            <li v-if="hasRoleAdmin()">
              <router-link :to="{ name: 'absences' }">
                <font-awesome-icon class="iconNav" icon="list" />
                <span v-if="nav_desp">ABSENTISMO</span>
              </router-link>
            </li>
            <li v-if="hasRoleAdmin()">
              <router-link :to="{ name: 'holidays' }">
                <font-awesome-icon class="iconNav" icon="umbrella-beach" />
                <span v-if="nav_desp">VACACIONES</span>
              </router-link>
            </li>
            <li v-if="hasRoleUser()">
              <!-- TODO: coger el ID del usuario logeado -->
              <router-link :to="{ name: 'holiday', params: { id: user.id } }">
                <font-awesome-icon class="iconNav" icon="umbrella-beach" />
                <span v-if="nav_desp">MIS VACACIONES</span>
              </router-link>
            </li>
            <li v-if="hasRoleUser()">
              <router-link :to="{ name: 'calendar' }">
                <font-awesome-icon class="iconNav" icon="calendar-alt" />
                <span v-if="nav_desp">CALENDARIO</span>
              </router-link>
            </li>
            <li v-if="hasRoleUser()">
              <router-link :to="{ name: 'presence' }">
                <font-awesome-icon class="iconNav" icon="list" />
                <span v-if="nav_desp">PRESENCIA</span>
              </router-link>
            </li>
            <li v-if="hasRoleAdmin()">
              <router-link :to="{ name: 'presence-report' }">
                <font-awesome-icon class="iconNav" icon="list" />
                <span v-if="nav_desp">INFORME DE PRESENCIA</span>
              </router-link>
            </li>
            <li v-if="hasRoleAdmin()">
              <router-link :to="{ name: 'suggestions-search' }">
                <font-awesome-icon class="iconNav" icon="list" />
                <span v-if="nav_desp">SUGERENCIAS</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'my-suggestions', params: { id: user.id } }">
                <font-awesome-icon class="iconNav" icon="list" />
                <span v-if="nav_desp">MIS SUGERENCIAS</span>
              </router-link>
            </li>
            <li v-if="hasRoleAdmin()">
              <router-link :to="{ name: 'benefits-search' }">
                <font-awesome-icon class="iconNav" icon="heart" />
                <span v-if="nav_desp">BENEFICIOS</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'my-benefits' }">
                <font-awesome-icon class="iconNav" icon="heart" />
                <span v-if="nav_desp">MIS BENEFICIOS</span>
              </router-link>
            </li>
          </ul>
        </div>
      </li>
      <!-- Producción -->
      <li v-if="hasRoleAdmin() || hasRoleSystems() || hasTrackernetPermission()" class="li-border li-group">
        <div class="menu-border" @click="showProduccion()">
          <img style="width: 25px; margin-right: 10px; margin-bottom: 5px;"
            :src="!openProduccion ? require(`@/assets/icons/computer-blue.png`) : require(`@/assets/icons/computer-yellow.png`)">
          <span class="pointer" v-if="nav_desp">PRODUCCIÓN</span>
        </div>
        <div v-if="openProduccion" class="submenu-border">
          <ul class="sub_menu">
            <li v-if="hasRoleAdmin()">
              <router-link :to="{ name: 'production' }">
                <font-awesome-icon class="iconNav" icon="umbrella-beach" />
                <span v-if="nav_desp">DASHBOARD</span>
              </router-link>
            </li>
            <li v-if="hasRoleAdmin()">
              <router-link :to="{ name: 'projects' }">
                <font-awesome-icon class="iconNav" icon="users" />
                <span v-if="nav_desp">GESTIÓN DE PROYECTOS</span>
              </router-link>
            </li>
            <li v-if="hasRoleSystems()">
              <router-link :to="{ name: 'clients' }">
                <font-awesome-icon class="iconNav" icon="user" />
                <span v-if="nav_desp">GESTIÓN DE CLIENTES</span>
              </router-link>
            </li>
            <li v-if="hasTrackernetPermission() || hasRoleAdmin()">
              <router-link :to="{ name: 'tracker' }">
                <img style="width: 16px; margin-bottom: 5px;" :src="require(`@/assets/icons/tracker-net-white.png`)">
                <span v-if="nav_desp">TrackerNet</span>
              </router-link>
            </li>
            <li v-if="hasTrackernetPermission() || hasRoleAdmin()">
              <router-link :to="{ name: 'tracker-report' }">
                <img style="width: 16px; margin-bottom: 5px;" :src="require(`@/assets/icons/tracker-net-white.svg`)">
                <span v-if="nav_desp">INFORMES TrackerNet</span>
              </router-link>
            </li>
          </ul>
        </div>
      </li>
      <!-- Hosting -->
      <li v-if="hasRoleAdmin() || hasRoleSystems()" class="li-border li-group">
        <div class="menu-border" @click="showHostings()">
          <img style="width: 25px; margin-right: 10px; margin-bottom: 5px;"
            :src="!openHostings ? require(`@/assets/icons/server-blue.png`) : require(`@/assets/icons/server-yellow.png`)">
          <span class="pointer" v-if="nav_desp">SISTEMAS</span>
        </div>
        <div v-if="openHostings" class="submenu-border">
          <ul class="sub_menu">
            <li>
              <router-link :to="{ name: 'domains-search' }">
                <font-awesome-icon class="iconNav" icon="server" />
                <span v-if="nav_desp">DOMINIOS</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'tickets' }">
                <font-awesome-icon class="iconNav" icon="server" />
                <span v-if="nav_desp">TICKETS</span>
              </router-link>
            </li>
          </ul>
        </div>
      </li>
      <!-- Informes -->
      <li v-if="hasRoleAdmin()" class="li-border li-group">
        <div class="menu-border" @click="showInformes()">
          <!--          <font-awesome-icon class="iconNav" :icon="openInformes ? 'folder-open' : 'folder'"/>-->
          <img style="width: 25px; margin-right: 10px; margin-bottom: 5px;"
            :src="!openInformes ? require(`@/assets/icons/chart-pie-blue.png`) : require(`@/assets/icons/chart-pie-yellow.png`)">
          <span class="pointer" v-if="nav_desp">INFORMES</span>
        </div>
        <div v-if="openInformes" class="submenu-border">
          <ul class="sub_menu">
            <li>
              <router-link :to="{ name: '' }">
                <font-awesome-icon class="iconNav" icon="chart-bar" />
                <span v-if="nav_desp">INFORME 1</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: '' }">
                <font-awesome-icon class="iconNav" icon="chart-bar" />
                <span v-if="nav_desp">INFORME 2</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: '' }">
                <font-awesome-icon class="iconNav" icon="chart-bar" />
                <span v-if="nav_desp">INFORME 3</span>
              </router-link>
            </li>
          </ul>
        </div>
      </li>
      <!-- Comunicacion -->
      <li v-if="isAdmin" class="li-border li-group">
        <div class="menu-border" @click="showComunication()">
          <img style="width: 25px; margin-right: 10px; margin-bottom: 5px;"
            :src="!openComunication ? require(`@/assets/icons/altavoz-menu.png`) : require(`@/assets/icons/altavoz-menu-yellow.png`)">
          <span class="pointer" v-if="nav_desp">COMUNICACIÓN</span>
        </div>
        <div v-if="openComunication" class="submenu-border">
          <ul class="sub_menu">
            <li v-if="hasRoleAdmin()">
              <router-link :to="{ name: 'undanews' }">
                <!-- <font-awesome-icon class="iconNav" icon="umbrella-beach"/> -->
                <img style="width: 12px; margin-bottom: 5px; margin-left:0px;"
                  :src="require(`@/assets/icons/undanet-menu.png`)">
                <span v-if="nav_desp">UNDANEWS</span>
              </router-link>
            </li>
            <li v-if="hasRoleAdmin()">
              <router-link :to="{ name: 'undanitas' }">
                <!--  <font-awesome-icon class="iconNav" icon="users"/> -->
                <img style="width:16px;  margin-bottom: 5px;  " :src="require(`@/assets/icons/tshirt.png`)">
                <span v-if="nav_desp">UNDANITAS</span>
              </router-link>
            </li>
            <li v-if="isAdmin">
              <router-link :to="{ name: 'internal_tickets' }">
                <img class="svg-white" style="width:16px;  margin-bottom: 5px;"
                  :src="require(`@/assets/icons/server-blue.png`)">
                <span v-if="nav_desp">TICKETS INTERNOS</span>
              </router-link>
            </li>
          </ul>
        </div>
      </li>
      <!-- Ajustes -->
      <li v-if="hasRoleAdmin()" class="li-border li-group">
        <div class="menu-border" @click="showSettings()">
          <!--          <font-awesome-icon class="iconNav" :icon="openSettings ? 'folder-open' : 'folder'"/>-->
          <img style="width: 25px; margin-right: 10px; margin-bottom: 5px;"
            :src="!settingsHover ? require(`@/assets/icons/pen-tip-blue.svg`) : require(`@/assets/icons/pen-tip-yellow.svg`)">
          <span class="pointer" v-if="nav_desp">AJUSTES</span>
        </div>
        <div v-if="openSettings" class="menu-border">
          <ul class="sub_menu">
            <li>
              <router-link :to="{ name: 'jobs' }">
                <font-awesome-icon class="iconNav" icon="users" />
                <span v-if="nav_desp">PUESTOS DE TRABAJO</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'departments' }">
                <font-awesome-icon class="iconNav" icon="list" />
                <span v-if="nav_desp">DEPARTAMENTOS</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'workplaces' }">
                <font-awesome-icon class="iconNav" icon="building" />
                <span v-if="nav_desp">CENTRO DE TRABAJO</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'leave-types' }">
                <font-awesome-icon class="iconNav" icon="first-aid" />
                <span v-if="nav_desp">TIPOS DE BAJA</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'absence-types' }">
                <font-awesome-icon class="iconNav" icon="procedures" />
                <span v-if="nav_desp">MOTIVOS DE BAJA</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'holiday-incompatibilities' }">
                <font-awesome-icon class="iconNav" icon="umbrella-beach" />
                <span v-if="nav_desp">VACACIONES INCOMPATIBLES</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'holiday-calendar' }">
                <font-awesome-icon class="iconNav" icon="calendar-alt" />
                <span v-if="nav_desp">CALENDARIO DE FESTIVOS</span>
              </router-link>
            </li>
          </ul>
        </div>
      </li>


      <!--      <li class="li-border li-group" v-if="hasRoleAdmin()">-->
      <!--        <div>-->
      <!--          <button @click="changeNav()" class="rounded-circle border-0" id="sidebarToggle">-->
      <!--            <font-awesome-icon class="iconNav" :icon="nav_desp ? 'angle-left' : 'angle-right'"/>-->
      <!--          </button>-->
      <!--        </div>-->

      <!--      </li>-->
    </ul>
    <div class="logo-undanet">
      <router-link :to="{ name: 'home' }">
        <div>
          <img src="@/assets/images/logo-undanet.png" width="100%" height="100%">
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>

import { mapState, mapGetters } from 'vuex';

export default {
  name: "AdminSidebar",
  data() {
    return {
      folder: 'folder',
      showSubmenus: false,
      nav_desp: true,
      width_nav: 250,
      openHome: false,
      openRRHH: false,
      openProduccion: false,
      openComunication: false,
      openHostings: false,
      openInformes: false,
      openSettings: false,
      settingsHover: false,
      baseUrl: process.env.VUE_APP_BASE_URI,
      hasPhoto: true,
      userEmailsTrackernet: ['jgarcia@undanet.com', 'faguilar@undanet.com']
    }
  },
  created() {
    const storage = localStorage.getItem('sidebarState');
    if (storage != null) {
      this.nav_desp = JSON.parse(storage);
      this.nav_desp ? this.width_nav = 250 : this.width_nav = 110;
    }
  },
  computed: {
    ...mapState({
      user: 'user',
    }),
    ...mapGetters({
      userName: 'getUserLoggedFullName',
    }),
    isAdmin() {
      const user = this.$store.getters.getUserLogged;
      return user.roles.includes('ROLE_ADMIN') || user.roles.includes('ROLE_SYSTEMS') || user.roles.includes('ROLE_CUSTOMER_ADMIN');
    },
    isPhoto() {
      const user = this.$store.getters.getUserLogged;
      if (user.photo) {
        if (!this.imageExists(this.baseUrl + user.photo)) {
          this.changeHasPhoto(false)
        }
      } else {
        this.changeHasPhoto(false)
      }
      return this.hasPhoto;
    },
    sidebarHome() {
      if (!this.$route.path.includes('/home')) {
        return require(`@/assets/icons/home-blue.png`);
      } else {
        return require(`@/assets/icons/home-yellow.png`);
      }
    }
  },
  methods: {
    showRRHH() {
      this.openRRHH = !this.openRRHH;
      if (this.openHome) this.openHome = !this.openHome;
      if (this.openRRHH) {
        if (!this.nav_desp) {
          this.changeNav();
        }
      }
    },
    showComunication() {
      this.openComunication = !this.openComunication;
      if (this.openHome) this.openHome = !this.openHome;
      if (this.openComunication) {
        if (!this.nav_desp) {
          this.changeNav();
        }
      }
    },
    showProduccion() {
      this.openProduccion = !this.openProduccion;
      if (this.openHome) this.openHome = !this.openHome;
      if (this.openProduccion) {
        if (!this.nav_desp) {
          this.changeNav();
        }
      }
    },
    showSettings() {
      this.openSettings = !this.openSettings;
      this.settingsHover = !this.settingsHover;
      if (this.openHome) this.openHome = !this.openHome;
      if (this.openSettings) {
        if (!this.nav_desp) {
          this.changeNav();
        }
      }
    },
    showInformes() {
      this.openInformes = !this.openInformes
      if (this.openHome) this.openHome = !this.openHome;
      if (this.openInformes) {
        if (!this.nav_desp) {
          this.changeNav();
        }
      }
    },
    showHostings() {
      this.openHostings = !this.openHostings;
      if (this.openHome) this.openHome = !this.openHome;
      if (this.openHostings) {
        if (!this.nav_desp) {
          this.changeNav();
        }
      }
    },
    changeFolder() {
      this.folder = 'folder'
    },
    changeNav() {
      this.nav_desp = !this.nav_desp;
      this.nav_desp ? this.width_nav = 250 : this.width_nav = 110;
      localStorage.setItem('sidebarState', JSON.parse(this.nav_desp))

      this.$emit('close', this.nav_desp);
    },

    hasRoleUser() {
      return this.hasRole('ROLE_USER');
    },
    hasRoleAdmin() {
      return this.hasRole('ROLE_ADMIN');
    },
    hasRoleSystems() {
      return this.hasRole('ROLE_SYSTEMS');
    },
    hasRole(role) {
      const user = this.$store.state.user;
      if (user) {
        return user.roles.includes(role);
      }
      return false;
      // const fakeUserRol = ['ROLE_USER'];
      // return fakeUserRol.includes(role);
    },
    hasTrackernetPermission() {
      const user = this.$store.getters.getUserLogged;
      return this.userEmailsTrackernet.includes(user.email);
    },
    eventsResolution(obj, event) {
      console.log('over', obj, event);
      if (screen.width < 768 && event === "click") {
        return !obj;
      } else if (screen.width >= 768 && event === "hover") {
        return !obj;
      }

      return obj;
    },
    imageExists(image_url) {
      var http = new XMLHttpRequest();
      http.open('HEAD', image_url, false);
      http.send();
      return http.status != '404';
    },
    getUser() {
      this.user = this.$store.getters.getUserLogged;
      return this.user;
    },
    changeHasPhoto(exists) {
      this.hasPhoto = exists;
    }
  },
}
</script>

<style lang="scss" scoped>
#nav {
  position: fixed;
  padding-top: 3rem;
  height: calc(100% - 70px);
  left: 0px;
  top: 70px;
  background-color: #11111D;
  transition: 0.6s;
  z-index: 100;
  overflow: hidden auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  div.user {
    margin: 0 auto;
    text-align: center;
    padding: 5px;

    .img-user.closed {
      .thumbnail {
        width: 70px;
        height: 70px;
      }
    }

    .username.closed {
      p {
        font-size: 12px !important;
      }
    }
  }

  .thumbnail {
    width: 100px;
    height: 100px;
    padding: 0.25rem;
    background-color: #fff;
    margin: 1rem;
    box-shadow: 0 0 0 3px #fff;
    border: 4px solid #11111D;
  }

  .badge {
    border-radius: 0.5rem;
    padding: 0.25em 1em;
  }

  ul.main-menu {
    width: 100%;

    li {
      position: relative;
      width: 100%;
      height: auto;
      min-height: 1.77rem;
      margin-bottom: 20px;
      text-align: left;

      .iconNav {
        color: white;
      }

      img {
        max-width: 80%;
        height: auto;
      }

      span {
        font-size: 16px;
        color: white;
        line-height: 1.77rem;
        margin-left: 10px;
        transition: 0.6s;

        &:hover.label {
          color: $yellow-color;

        }

      }

      button {
        width: 2.5rem;
        height: 2.5rem;
        text-align: center;
        cursor: pointer;
        background-color: rgba(255, 255, 255, .2);
        margin: auto;
        margin-top: 1rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;

        .iconNav {
          color: white;
        }
      }

      ul.sub_menu {
        padding-left: 25px;

        li {
          margin-top: 10px;
        }

        a {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;

          svg,
          img {
            margin-top: 5px;
          }
        }

        span:hover {
          color: $yellow-color;
        }
      }
    }

    .li-group {
      height: auto;
      margin-top: 50px;

      div {
        display: flex;
        justify-content: initial;
        text-align: left;
        padding-left: 15px;
        align-items: center;

        >a {
          display: flex;
          justify-content: initial;
          text-align: left;
          align-items: center;
        }

        .title-group {
          font-size: 18px;
          color: white;
          text-transform: uppercase;
          line-height: 3.77rem;
          margin-left: 10px;
          -webkit-transition: 0.6s;
          transition: 0.6s;
        }
      }
    }

    .li-border {
      border-top: 1px solid rgba(255, 255, 255, .15);
      border-bottom: 1px solid rgba(255, 255, 255, .15);
    }

    .li-border:hover {
      border-left: 5px solid $yellow-color;
    }

  }

  .logo-undanet {
    margin-top: auto;
    padding: 20px;

    img {
      width: 150px;
      max-width: 100%;
    }
  }

  .img-user {
    display: inline-block;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      width: 15px;
      height: 15px;
      margin: 0px;
      shape-outside: circle();
      clip-path: circle();
      background: $yellow-color;
      right: 20px;
      top: 20px;
    }
  }

  // width
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    cursor: pointer !important;
  }

  // Track
  &::-webkit-scrollbar-track {
    background: black;
    border-radius: 100px;
  }

  // Handle
  &::-webkit-scrollbar-thumb {
    background-color: rgba(128, 128, 128, 0.6);
    border-radius: 100px;
    width: 8px;
    height: 8px;
    transition: background-color 0.6s linear;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(128, 128, 128, 0.8);
  }

  .toggle-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: white;
    font-size: 20px;
    z-index: 201;

    &:hover {
      color: $yellow-color;
    }
  }
}

.svg-white {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
}


@media (max-width: 992px) {
  #nav ul.main-menu li button {
    display: none;
  }

  #nav {
    width: 110px !important;
  }

  .toggle-button {
    display: none;
  }
}
</style>
