<script setup>
import { onMounted, defineProps, computed, defineEmits, ref } from 'vue';
import { MoreFilled } from "@element-plus/icons-vue";
import { ElMessageBox, ElNotification } from 'element-plus';
import { useStore } from "vuex";
import moment from 'moment';

import TrackerProjectInfo from "@/components/shared/TrackerProjectInfo.vue";
import TrackerService from "@/api/services/tracker.service";

const props = defineProps({
    entry: Object,
    selectedEntries: Array,
})

const store = useStore();

const PROJECT_API = "/api/projects/";
const TAGS_API = "/api/time_entry_tags/";

const changeHour = ref(false)
const refInputTimeStart = ref();
const inputTimeStart = ref();
const newStartTime = ref();
const startTime = ref();

const refInputTimeEnd = ref();
const inputTimeEnd = ref();
const newEndTime = ref();
const endTime = ref();

const dateStart = ref();
const refDateTime = ref();

const selectedEntries = computed(() => {
    return props.selectedEntries;
})

const entry = computed(() => {
    return props.entry;
})

const refMenuDropdown = ref();

onMounted(() => {
    duration.value = minutes.value;
    dateStart.value = entry.value.timeStart;
    startTime.value = getHours(entry.value.timeStart);
    endTime.value = getHours(entry.value.timeEnd);
    blurInputHour("start");
    blurInputHour("end");
})

// Variables de duracion
const duration = ref();
const changeDuration = ref(false);

const minutes = computed(() => {
    let hours = 0;
    let minutes = 0;
    let remainingSeconds = 0;

    if (entry.value.duration) {
        return formatDuration(entry.value.duration)
    } else {
        hours = hours + Math.floor(moment().diff(entry.value.timeStart.split('T')[0], 'seconds') / 3600);
        minutes = minutes + Math.floor((moment().diff(entry.value.timeStart.split('T')[0], 'seconds') % 3600) / 60);
        remainingSeconds = remainingSeconds + (moment().diff(entry.value.timeStart.split('T')[0], 'seconds') % 60);
    }
    do {
        if (remainingSeconds >= 60) {
            remainingSeconds = remainingSeconds - 60;
            minutes = minutes + 1;
        }
        if (minutes >= 60) {
            minutes = minutes - 60;
            hours = hours + 1;
        }
    } while (remainingSeconds >= 60)
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
})

// Etiquetas
const tagsText = computed(() => {
    let temp = "";
    for (let i = 0; i < entry.value.tags.length; i++) {
        const element = entry.value.tags[i];
        if (i == 0) {
            temp = element.name;
        } else {
            temp = temp + ", " + element.name
        }
    }
    return temp;
})

// Maneja el cambio del checkbox
const emits = defineEmits(["update-selection", "reloadEntries"]);
const handleChange = (isChecked) => {
    emits("update-selection", props.entry, isChecked);
};

const reloadEntries = () => {
    emits('reloadEntries');
};

// Tiempo Input horas
const getHours = (date) => {
    const newDate = new Date(date);
    let hours = 0;
    let minutes = 0;
    if (date instanceof Date) {
        hours = date.getHours();
        minutes = date.getMinutes();
    } else {
        hours = newDate.getHours();
        minutes = newDate.getMinutes();
    }
    return `${hours.toString().padStart(2, 0)}:${minutes.toString().padStart(2, '0')}`
}

const focusInputHour = (name) => {
    if (name === 'start') {
        inputTimeStart.value = inputTimeStart.value.split(':');
        let temp = inputTimeStart.value[0] + inputTimeStart.value[1];
        inputTimeStart.value = temp;
    } else {
        inputTimeEnd.value = inputTimeEnd.value.split(':');
        let temp = inputTimeEnd.value[0] + inputTimeEnd.value[1];
        inputTimeEnd.value = temp;
    }
}

const blurInputHour = (name) => {
    if (name === 'start') {
        if (newStartTime.value) {
            inputTimeStart.value = newStartTime.value;
        } else {
            inputTimeStart.value = startTime.value;
        }
    } else {
        if (newEndTime.value) {
            inputTimeEnd.value = newEndTime.value;
        } else {
            inputTimeEnd.value = endTime.value;
        }
    }
}

const changeTimeHour = (name) => {
    changeHour.value = true;
    if (name === 'start') {
        if (inputTimeStart.value.length < 1) {
            refInputTimeStart.value.blur();
            return;
        }
        let tempS = inputTimeStart.value.includes(':');
        if (isNaN(inputTimeStart.value) && !tempS) {
            refInputTimeStart.value.blur()
            refInputTimeEnd.value.blur()
            return;
        }
        checkChangeInputHour('start')
    } else {
        if (inputTimeEnd.value.length < 1) {
            refInputTimeEnd.value.blur();
            return;
        }
        let tempE = inputTimeEnd.value.includes(':');
        if (isNaN(inputTimeEnd.value) && !tempE) {
            refInputTimeStart.value.blur()
            refInputTimeEnd.value.blur()
            return;
        }
        checkChangeInputHour('end')
    }
    // store.state.loading = true;
    store.commit('startLoading');
    checkAndChangeHours();
    updateEntry(entry.value).then(() => {
        ElNotification({
            message: "Se actualizó la tarea correctamente",
            type: "success",
            duration: 6000
        })
        reloadEntries();
    }).finally(() => {
        // store.state.loading = false;
        store.commit('stopLoading');
    });
}

const checkChangeInputHour = (name) => {
    let temp;
    const newDuration = new Date();
    if (name === 'start') {
        let inputTemp = parseInt(inputTimeStart.value).toString();
        if (inputTemp.length == 3 || inputTemp.length == 4) {
            if (inputTemp.length == 3) temp = inputTemp.slice(0, 1) + ':' + inputTemp.slice(1);
            if (inputTemp.length == 4) temp = inputTemp.slice(0, 2) + ':' + inputTemp.slice(2);
            temp = temp.split(':')

            newDuration.setHours(parseInt(temp[0]))
            newDuration.setMinutes(parseInt(temp[1]))
            newDuration.setSeconds(0)
        }
        if (inputTemp.length == 1 || inputTemp.length == 2) {
            newDuration.setHours(parseInt(inputTemp))
            newDuration.setMinutes(0);
            newDuration.setSeconds(0);
        }
        const hours = newDuration.getHours().toString().padStart(2, "0");
        const minutes = newDuration.getMinutes().toString().padStart(2, "0");
        newStartTime.value = `${hours}:${minutes}`;
        blurInputHour('start');
    } else {
        let inputTemp = parseInt(inputTimeEnd.value).toString();
        if (inputTemp.length == 3 || inputTemp.length == 4) {
            if (inputTemp.length == 3) temp = inputTemp.slice(0, 1) + ':' + inputTemp.slice(1);
            if (inputTemp.length == 4) temp = inputTemp.slice(0, 2) + ':' + inputTemp.slice(2);
            temp = temp.split(':')
            newDuration.setHours(parseInt(temp[0]))
            newDuration.setMinutes(parseInt(temp[1]))
            newDuration.setSeconds(0)
        }
        if (inputTemp.length == 1 || inputTemp.length == 2) {
            newDuration.setHours(parseInt(inputTemp))
            newDuration.setMinutes(0);
            newDuration.setSeconds(0);
        }
        const hours = newDuration.getHours().toString().padStart(2, "0");
        const minutes = newDuration.getMinutes().toString().padStart(2, "0");
        newEndTime.value = `${hours}:${minutes}`;
        blurInputHour('end');
    }
}

const checkAndChangeHours = () => {
    let timeStart = inputTimeStart.value.split(':');
    let timeEnd = inputTimeEnd.value.split(':');
    //Cambiamos la hora de inicio
    let newDateStart = new Date(Date.parse(entry.value.timeStart))
    let oldDateStart = new Date(Date.parse(entry.value.timeStart))
    newDateStart.setHours(timeStart[0], timeStart[1], 0)

    //Cambiamos la hora de finalización
    let newDateEnd = new Date(Date.parse(entry.value.timeEnd))
    let oldDateEnd = new Date(Date.parse(entry.value.timeEnd))
    newDateEnd.setHours(timeEnd[0], timeEnd[1], 0)

    //Esto se ejecuta si no hacemos ningún cambio, es decir, no viene de la funcion changeTimeHour
    if (!changeHour.value) {
        refInputTimeStart.value.blur();
        refInputTimeEnd.value.blur();
        return;
    }

    if (isNaN(newDateStart)) {
        inputTimeStart.value = startTime.value;
        return;
    }
    if (isNaN(newDateEnd)) {
        inputTimeEnd.value = endTime.value;
        return;
    }

    //Aqui comprobamos si el valor de la fecha es correcto
    if (newDateStart > oldDateEnd || newDateEnd < oldDateStart) {
        newDateEnd.setDate(oldDateEnd.getDate() + 1);
    }

    if (newDateEnd.getHours() > newDateStart.getHours()) {
        if (newDateEnd.getMinutes() > newDateStart.getMinutes()) {
            newDateEnd.setDate(oldDateStart.getDate());
        }
    }

    entry.value.timeStart = formatDate(newDateStart);
    entry.value.timeEnd = formatDate(newDateEnd);
    entry.value.duration = calcularTiempoTarea(newDateStart, newDateEnd);
}

const calcularTiempoTarea = (fecha1, fecha2) => {
    let time1 = new Date(fecha1)
    const time2 = new Date(fecha2);
    const diferenciaMs = time2 - time1;

    const segundosTotales = Math.floor(diferenciaMs / 1000);

    return segundosTotales;

}

// Date
const changeDateEntry = () => {
    const newDateStart = new Date(entry.value.timeStart);
    const newDateEnd = new Date(entry.value.timeEnd);

    const newDate = new Date(dateStart.value)


    newDateStart.setDate(newDate.getDate());
    newDateStart.setMonth(newDate.getMonth());
    newDateStart.setFullYear(newDate.getFullYear());
    newDateEnd.setDate(newDate.getDate());
    newDateEnd.setMonth(newDate.getMonth());
    newDateEnd.setFullYear(newDate.getFullYear());

    const newEntry = entry.value;
    newEntry.timeStart = formatDate(newDateStart);
    newEntry.timeEnd = formatDate(newDateEnd);

    updateEntry(newEntry)
        .then(() => {
            ElNotification({
                message: "Se actualizó la tarea correctamente",
                type: "success",
                duration: 6000
            })
            reloadEntries();
        })
}

// Duration
const changeEntryDuration = () => {
    changeDuration.value = true;
    checkAndChangeDuration();
}

const calcDuration = (newDuration) => {
    let divDur = newDuration.split(':');
    let seconds = 0;
    if (divDur.length < 2) {
        seconds = Math.floor(divDur[0]) * 60;
    } else {
        if (divDur.length == 2) {
            seconds = Math.floor(divDur[0]) * 60;
            seconds = seconds + Math.floor(divDur[1]);
        } else {
            if (divDur.length == 3) {
                seconds = Math.floor(divDur[0]) * 3600;
                seconds = seconds + Math.floor(divDur[1]) * 60;
                seconds = seconds + Math.floor(divDur[2]);
            } else {
                return
            }
        }
    }
    return seconds;
}

const checkAndChangeDuration = () => {
    if (!changeDuration.value) return;

    changeDuration.value = false;
    const newDuration = calcDuration(duration.value);

    if (newDuration <= 0) {
        duration.value = minutes.value;
        return;
    }

    const newEntry = entry.value;
    newEntry.duration = newDuration;
    let newDateEnd = new Date(newEntry.timeStart);
    newDateEnd.setSeconds(newDateEnd.getSeconds() + newDuration)
    newDateEnd = formatDate(newDateEnd);
    newEntry.timeEnd = newDateEnd;
    updateEntry(newEntry).then(() => {
        ElNotification({
            message: "Se actualizó la tarea correctamente",
            type: "success",
            duration: 6000
        })
        duration.value = formatDuration(newDuration);
        reloadEntries();
    });
}

const formatDate = (date) => {
    // Obtener el desplazamiento de la zona horaria
    const offset = new Date().getTimezoneOffset();
    const sign = offset > 0 ? '-' : '+';
    const hours = String(Math.floor(Math.abs(offset) / 60)).padStart(2, '0');
    const minutes = String(Math.abs(offset) % 60).padStart(2, '0');

    // Formato de fecha
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    // Formato de hora
    const hoursOfDay = String(date.getHours()).padStart(2, '0');
    const minutesOfDay = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Devolver la cadena en formato ISO con el desplazamiento de la zona horaria
    return `${year}-${month}-${day}T${hoursOfDay}:${minutesOfDay}:${seconds}${sign}${hours}:${minutes}`;
}

const formatDuration = (duration) => {
    let hours = 0;
    let minutes = 0;
    let remainingSeconds = 0;
    hours = hours + Math.floor(duration / 3600);
    minutes = minutes + Math.floor((duration % 3600) / 60);
    remainingSeconds = remainingSeconds + (duration % 60);
    do {
        if (remainingSeconds >= 60) {
            remainingSeconds = remainingSeconds - 60;
            minutes = minutes + 1;
        }
        if (minutes >= 60) {
            minutes = minutes - 60;
            hours = hours + 1;
        }
    } while (remainingSeconds >= 60)
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
}

// Actualizar la tarea
const updateEntry = (entry) => {
    let tags = [];
    for (let i = 0; i < entry.tags.length; i++) {
        const tag = entry.tags[i];
        tags.push(TAGS_API + tag.id)
    }
    let params = {
        "employee": entry.employee['@id'],
        "project": PROJECT_API + entry.project.id,
        "tags": tags,
        "description": entry.description,
        "timeStart": entry.timeStart,
        "timeEnd": entry.timeEnd,
        "duration": entry.duration,
        "billable": entry.billable,
    }
    return TrackerService.updateEntry(entry.id, params)
}

const discard = () => {
    let message = props.dayLength > 1 ? "estas entradas" : "esta entrada";
    ElMessageBox.confirm(
        '¿Estás seguro de descartar ' + message + ' ?',
        'Warning',
        {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning',
        }
    ).then(async () => {
        // store.state.loading = true;
        store.commit('startLoading');
        const id = entry.value.id;
        await TrackerService.discardEntry(id);
        // store.state.loading = false;
        store.commit('stopLoading');
        reloadEntries();
        ElNotification({
            message: "La entrada se ha eliminado",
            type: "success",
            duration: 6000
        })
    }).catch(() => {
    })
}

const duplicate = async () => {
    refMenuDropdown.value.handleClose();
    // store.state.loading = true;
    store.commit('startLoading');
    await TrackerService.duplicateEntry(entry.value.id).then(() => {
        ElNotification({
            message: "Se actualizó la tarea correctamente",
            type: "success",
            duration: 6000
        });
        reloadEntries();
        // store.state.loading = false;
        store.commit('stopLoading');
    }).catch(() => {

    })
}
</script>
<template>
    <div v-if="entry" class="pt-2 pb-1">
        <div class="row align-items-center">
            <!-- COLUMN 1 -->
            <div class="col-md-5 entry-info columnReport1">
                <div class="row d-flex align-items-center">
                    <!-- DESCRIPCION -->
                    <div class="col-4 overflowDescription">
                        <el-tooltip class="box-item" effect="dark" :content="entry.description" placement="top-start">
                            <el-checkbox :checked="selectedEntries.includes(entry)" @change="handleChange"
                                class="el-check-report" ref="checkboxRef" :value="entry">
                                {{ entry.description }}
                            </el-checkbox>
                        </el-tooltip>
                    </div>
                    <!-- PROYECTO -->
                    <div class="col-5 tracker-project">

                        <el-tooltip class="box-item" effect="dark" :content="entry.project.name" placement="top-start">
                            <tracker-project-info :project="entry.project" :report="true" style="height: 100%;" />
                        </el-tooltip>
                    </div>
                    <!-- ETIQUETAS -->
                    <div class="col-3">
                        <el-tooltip class="box-item" effect="dark" :content="tagsText" placement="top-start">
                            <span class="tracker-tag truncate-text">
                                {{ tagsText }}
                            </span>
                        </el-tooltip>
                    </div>
                </div>
            </div>
            <!-- COLUMN 2 -->
            <div class="col-md-7 ms-auto columnReport2">
                <div class="row align-items-center">
                    <!-- IMPORTE -->
                    <div class="col-3">
                        <span class="import">
                            <el-divider direction="vertical" class="divider dividerImport" />
                            200.5
                            <el-divider direction="vertical" class="divider dividerImport" />
                        </span>
                        <span class="tracker-button"
                            :class="entry.billable ? 'tracker-button--active' : 'tracker-button--inactive'">
                            €
                        </span>
                    </div>
                    <!-- EMPLEADO / USUARIO -->
                    <div class="col-2">
                        <el-tooltip class="box-item" effect="dark" :content="entry.employee.fullName"
                            placement="top-start">
                            <span class="spanUser"> {{ entry.employee.fullName }}
                            </span>
                        </el-tooltip>
                    </div>
                    <!-- TIEMPO -->
                    <div class="col-3" style="margin-right: -25px; margin-left: 25px;">
                        <div class="time-container">
                            <div>
                                <input ref="refInputTimeStart" class="inputTime" v-model="inputTimeStart"
                                    @click="refInputTimeStart.select()" @focus="focusInputHour('start')"
                                    @blur="blurInputHour('start')" @change="changeTimeHour('start')">
                                <span style="width: 3px;">
                                    -
                                </span>
                                <input ref="refInputTimeEnd" class="inputTime" v-model="inputTimeEnd"
                                    @click="refInputTimeEnd.select()" @focus="focusInputHour('end')"
                                    @blur="blurInputHour('end')" @change="changeTimeHour('end')">
                            </div>
                            <el-date-picker ref="refDateTime" v-model="dateStart" format="DD/MM/YYYY"
                                class="reportDatePicker" @click="refDateTime.select()" @change="changeDateEntry"
                                style="width: 65%; min-width: 115px;"></el-date-picker>
                        </div>
                    </div>
                    <!-- DURACION -->
                    <div class="col-3 tracker-duration" style="display: flex;">
                        <el-divider direction="vertical" class="divider dividerInput" style="margin-right: 5px;" />
                        <el-input v-model="duration" class="inputDurationReport" @blur="checkAndChangeDuration"
                            @change="changeEntryDuration" />
                    </div>
                    <!-- MENU DROPDOWN-->
                    <div class="col-1">
                        <el-dropdown trigger="click" :hide-on-click="false" ref="refMenuDropdown">
                            <span class="el-dropdown-link">
                                <el-icon class="el-icon--right" style="height: 30px;" :size="25">
                                    <MoreFilled
                                        style="transform: rotate(90deg);color:#1D1D1B80; margin-left: -10px; margin-top: 8px;" />
                                </el-icon>
                            </span>
                            <template #dropdown>
                                <el-dropdown-menu>
                                    <el-dropdown-item @click="duplicate">Duplicar</el-dropdown-item>
                                    <el-dropdown-item @click="discard" style="color:red;">Descartar</el-dropdown-item>
                                </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                    </div>
                </div>
            </div>
        </div>
        <hr>
    </div>
</template>
<style lang="scss" scoped>
.overflowDescription {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.tracker-button {
    font-size: 32px;
    margin-left: -17px;
    cursor: default;
}

.inputDurationReport {
    margin-left: 10px;
    font-size: 1rem !important;
    width: auto !important;

    input[type=text] {
        text-align: center;
        border: none !important;
        cursor: default !important;
    }
}

.import {
    font-weight: 500;
    font-size: 24px;
    margin-right: 15px;
}

.spanUser {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
    line-height: 1.5;
    display: block;
    min-width: 120px;
    margin-left: -10px;
}

.divider {
    font-size: 30px;
    font-weight: 700;
    border-width: 1px;
    height: 40px;
    border-color: #B8B7B9;
}

.time-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    ;
}



.date-span {
    margin-top: 15px;
    color: #474CE6;
}

.dividerInput {
    flex-grow: 1;
    z-index: 1;
}

.time-span {
    min-width: 100px;
}

.inputTime {
    width: 50px;
    margin-bottom: -10px;
    margin-top: -10px;
    text-align: center;
    border: 1px solid white;
    box-shadow: none;
}

.inputTime:focus,
.inputTime:active,
.inputTime:hover {
    border: 1px solid #929292;
}

@media (max-width: 1630px) {
    .tracker-project {
        margin-left: 10px;
        margin-right: -15px;
    }

    .inputDurationReport {
        margin-right: -20px;

        input[type=text] {
            padding-right: 5px;
            padding-left: 5px;
        }

        .el-input__wrapper {
            padding-right: 5px;
            padding-left: 5px;

            .el-input__inner {
                padding-right: 5px;
                padding-left: 5px;
            }
        }
    }

    .dividerImport {
        margin-left: 0px;
        margin-right: 0px;
    }
}

@media (min-width:1630px) {
    .spanUser {
        min-width: 170px;
        margin-left: -25px;
    }
}
</style>