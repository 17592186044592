<template>
  <form-project v-if="showFormProject" :project="project" @change="updateObservation"
    @add-document="updateObservationDocuments" @delete="deleteObservationDocuments" />
</template>
<script>
import FormProject from "./FormProject.vue";
import ProjectsService from '@/api/services/projects.service';
import { ElNotification } from "element-plus";
//import moment from 'moment';
export default {
  components: { FormProject },
  data() {
    return {
      showFormProject: false,
      project: {
        name: '',
        date_ini: '',
        client: {
          id: '',
          name: ''
        },
        nro_horas: '',
        projectType: {
          id: '',
          name: ''
        },
        projectManager: '',
        clockifyProject: '',
        consumedTime: '',
        contractedTime: '',
        tag: '',
        initialDate: '',
        lastSynchronization: '',
        observations: '',
        minConsumptionHours: 0,
        reviewPeriodMonths: null,
      },


    }
  },
  mounted() {
    this.getProject(this.$route.params.id);
  },
  methods: {
    getProject(id) {
      const $this = this;
      this.$store.commit('startLoading');
      ProjectsService.getProject(id).then((result) => {

        $this.project = result.data;
        this.showFormProject = true
        this.$store.commit('stopLoading');

        //moment.locale('es');
        //$this.project.lastSynchronization = moment($this.project.lastSynchronization,"yyyy-MM-DDTHH:mm:ssZ").format("yyyy-MM-dd HH:mm:ss");
      }).catch(err => {
        console.error("Types", err);
        ElNotification({
          title: 'Proyecto no encontrado',
          message: 'Error al intentar obtener los campos del proyecto seleccionado' + err,
          type: 'error',
          duration: 6000
        });
        // this.$store.state.loading = false;
        this.$store.commit('stopLoading');
        this.showFormProject = false
      });
    },
    updateObservation(obj) {
      console.log("Update Project", obj);
      this.$store.commit('startLoading');
      ProjectsService.udpdateObservations({
        observations: obj.observations,
        tag: obj.tag,
        minConsumptionHours: obj.minConsumptionHours,
        reviewPeriodMonths: obj.reviewPeriodMonths,
      },
        this.$route.params.id)
        .then(() => {
          ElNotification({
            title: 'Actualización del proyecto',
            message: 'Se han actualizado los del proyecto seleccionado',
            type: 'success',
            duration: 6000
          });
          this.$store.commit('stopLoading');
        }).catch(err => {
          ElNotification({
            title: 'Error al intentar modificar el proyecto seleccionado',
            message: err,
            type: 'error',
            duration: 6000
          });
          // this.$store.state.loading = false;
          this.$store.commit('stopLoading');
        });
    },
    async updateObservationDocuments(obj) {
      await this.changeObservationDocuments(obj);
      ElNotification({
        title: 'Actualización del proyecto',
        message: 'Se ha actualizado el proyecto',
        type: 'success',
        duration: 6000
      });
    },
    async deleteObservationDocuments(obj) {
      await this.changeObservationDocuments(obj);
    },
    changeObservationDocuments(obj) {
      let documentEstimate = [];
      let documentProposal = [];
      if (obj.projectEstimationDocuments.length > 0) {
        for (let i = 0; i < obj.projectEstimationDocuments.length; i++) {
          const id = obj.projectEstimationDocuments[i];
          documentEstimate.push(id['@id'])
        }
      } else {
        if (obj.projectEstimationDocuments['@id']) {
          documentEstimate.push(obj.projectEstimationDocuments['@id'])
        }
      }
      if (obj.projectProposalDocuments.length > 0) {
        for (let i = 0; i < obj.projectProposalDocuments.length; i++) {
          const id = obj.projectProposalDocuments[i];
          documentProposal.push(id['@id'])
        }
      } else {
        if (obj.projectProposalDocuments['@id']) {
          documentProposal.push(obj.projectProposalDocuments['@id'])
        }
      }
      let params = {}
      if (documentEstimate.length > 0) {
        params.projectEstimationDocuments = documentEstimate;
      }
      if (documentProposal.length > 0) {
        params.projectProposalDocuments = documentProposal;
      }
      ProjectsService.udpdateObservations(params,
        this.$route.params.id)
        .then((res) => {
          this.project = res.data;
          console.log(this.project);
        }).catch(err => {
          console.log(err);

          ElNotification({
            title: 'Error al intentar modificar el proyecto seleccionado',
            message: err,
            type: 'error',
            duration: 6000
          });
          // this.$store.state.loading = false;
          this.$store.commit('stopLoading');
        });
    }
  }
}
</script>
