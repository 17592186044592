<template>
  <div class="mt-2 my-body">
    <el-form ref="absenceForm" class="absence-form" :model="absenceFormModel" name="absenceForm" status-icon
      @submit.prevent>
      <!-- Nombre del empleado -->
      <div class="form-item">
        <div class="row">
          <div class="col-md-12 col-sm-12 pointer">
            <div class="row">
              <div :class="!absenceFormModel.id ? 'col-md-8' : 'col-md-12'">
                <div class="col-md-3 d-inline-flex text-start">
                  <label class="label-absence" for="employeeName">*Nombre</label>
                </div>
                <div class="col-md-8 form-item">
                  <el-form-item prop="employeeName">
                    <el-select v-model="absenceFormModel.employee" filterable placeholder="Nombre del empleado"
                      @change="changeEmployeeModal" :value-key="'@id'">
                      <el-option v-for="employee in employees" :key="employee.id" :label="employee.fullName"
                        :value="employee">
                      </el-option>
                    </el-select>
                    <p v-if="v.employeeName.$error" class="invalid-feedback mb-0">
                      <small>
                        {{ v.employeeName.$errors[0].$message }}
                      </small>
                    </p>
                  </el-form-item>
                </div>
              </div>
              <div v-if="!absenceFormModel.id" class="col-md-4 col-sm-4 text-right">
                <button class="btn btn_red btn_new_modal" style="padding-top: 4px" @click="openModalEmployees">
                  Seleccionar Empleado
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
      <!-- Fecha de inicio y de fin -->
      <div class="form-item">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div :class="absenceFormModel.absentWorkTime ? 'col-md-5' : 'col-md-6'">
                <div class="form-item date-picker">
                  <label class="w-100">*Desde:</label>
                  <el-date-picker v-model="fechaDesde" type="datetime" placeholder="Desde" @change="manejarCambioDesde"
                    format="DD/MM/YYYY HH:mm" :prefix-icon="customPrefix" :disabled-date="deshabilitarFechasDesde"
                    :clearable="false" class="mb-1" />
                  <p v-if="v.fromDate.$error" class="invalid-feedback mb-0">
                    <small>
                      {{ v.fromDate.$errors[0].$message }}
                    </small>
                  </p>
                </div>
              </div>
              <div :class="absenceFormModel.absentWorkTime ? 'col-md-4' : 'col-md-6'">
                <div class="form-item date-picker">
                  <label class="w-100">*Hasta:</label>
                  <el-date-picker v-model="fechaHasta" type="datetime" placeholder="Hasta" @change="manejarCambioHasta"
                    format="DD/MM/YYYY HH:mm" :prefix-icon="customPrefix" :disabled-date="deshabilitarFechasHasta"
                    :clearable="false" class="mb-1" />
                  <p v-if="v.toDate.$error" class="invalid-feedback mb-0">
                    <small>
                      {{ v.toDate.$errors[0].$message }}
                    </small>
                  </p>
                </div>
              </div>
              <div class="col-md-3 align-content-center" v-if="absenceFormModel.absentWorkTime">
                <div class="form-item date-picker">
                  <label class="w-100">
                    *Total:
                  </label>
                  <el-input v-model="totalTime" class="mb-1 inputTotal" disabled style="cursor: pointer;">
                  </el-input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Tipo de absentismo -->
      <div class="form-item">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="row tex-center mb-3">
                <div class="col-md-8 d-inline-flex">
                  <label class="label-absence" for="absenceType">*Tipo de absentismo</label>
                </div>
                <div class="col-md-8 form-item">
                  <el-select v-model="absenceFormModel.absenceType" placeholder="Tipo de Ausencia" :value-key="'@id'">
                    <el-option v-for="item in absenceTypes" :key="item.id" :label="item.name" :value="item" />
                  </el-select>
                  <p v-if="v.absenceType.$error" class="invalid-feedback mb-0">
                    <small>
                      {{ v.absenceType.$errors[0].$message }}
                    </small>
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- Observaciones -->
      <div class="form-item">
        <div class="row text-center">
          <div class="col-md-12">
            <div class="row tex-center">
              <div class="col-md-3 d-inline-flex">
                <label class="label-absence label-observations">Observaciones</label>
              </div>
              <div class="col-md-12">
                <el-form-item prop="observations">
                  <textarea v-model="absenceFormModel.observations" class="font18 w-100 p-2" style="height: 200px;"
                    placeholder="Observaciones del motivo de la baja" />
                  <small v-if="v.observations.$error" class="invalid-feedback mb-0">
                    {{ v.observations.$errors[0].$message }}
                  </small>
                </el-form-item>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Justificantes -->
      <div v-if="absenceFormModel.id != null">
        <div
          v-if="absenceFormModel && absenceFormModel.absenceSupportingDocuments && absenceFormModel.absenceSupportingDocuments.length > 0"
          class="row text-center">
          <!-- Listado de Justificantes -->
          <div class="col-md-8">
            <div class="row tex-center">
              <div class="col-md-3 d-inline-flex">
                <label class="label-absence">Justificantes</label>
              </div>
              <div class="col-md-9">
                <div class="table-documents">
                  <table>
                    <tbody>
                      <tr v-for="(document, index) in fileList" :key="index">
                        <td class="pointer" @click="downloadDocument(document.id, document.name)">
                          {{ document.name }}
                        </td>
                        <td>
                          <div class="buttons d-inline-flex">
                            <div class="pointer">
                              <font-awesome-icon class="btn_icon delete_btn" icon="times" title="Eliminar documento"
                                style="margin-left: 5px" @click="deleteSupportingDocument(document, index)" />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Insertar Justificantes -->
        <div class="row text-center mt-4">
          <div class="col-md-8">
            <div class="row text-center">
              <div class="col-md-3 d-inline-flex">
                <label class="label-absence">Añadir justificante</label>
              </div>
              <div class="col-md-9 form-item text-left" @click.stop="focusOnInput($event)">
                <input id="file" ref="file" type="file" @change.stop="handleFileUpload">
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Botones Cancelar y Nuevo/Guardar -->
      <div class="row mt-4">
        <div class="col d-flex justify-content-end gap-3">
          <router-link :to="{ name: 'absences' }" class="btn btn_red px-4" @click="cancelDialog()" style="width: 25%">
            Cancelar
          </router-link>

          <button class="btn btn_yellow px-4" @click="saveAbsence()" style="width: 25%">
            Guardar
          </button>
        </div>
      </div>
    </el-form>
  </div>
</template>
<script setup>
import { computed, h, onMounted, onUnmounted, ref, shallowRef } from 'vue';
import AbsenceService from '@/api/services/absence.service';
import AbsenceSupportingDocument from '@/api/services/absenceSupportingDocument.service';
import EmployeeService from '@/api/services/employee.service';
import ResourceService from '@/api/services/resource.service';
import { ElNotification } from 'element-plus';
import Api from "../../api/Api";
import { helpers, required } from "@vuelidate/validators";
import dayjs from "dayjs";
import useVuelidate from '@vuelidate/core';
import { useStore } from 'vuex';

const store = useStore();
//const router = useRouter();

// eslint-disable-next-line no-unused-vars
const absence = ref({ employee: { name: '', lastName: '' } });
const absenceTypes = ref([]);
const totalResults = ref(0);
const params = ref({});
const employees = ref([]);
const deleteDialogShown = ref(false);
const selectedAbsence = ref(null);
const showModalNewAbsence = ref(false);
const newFileList = ref([]);
const file = ref(null);
const fileList = ref([]);
const baseUrl = ref(process.env.VUE_APP_BASE_URI);

const now = new Date();
const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 0);
const fechaDesde = ref(startOfDay)
const fechaHasta = ref(endOfDay)

const absenceFormModel = ref({
  id: null,
  employee: null,
  employeeName: '',
  fromDate: startOfDay,
  toDate: endOfDay,
  absenceType: null,
  observations: null,
  absenceSupportingDocuments: []
});
const customPrefix = shallowRef({
  render() {
    return h('img', {
      src: '/calendar-blue.svg',
      style: 'width: 16px; height: 16px; margin-right: 10px;',
    });
  },
})

// eslint-disable-next-line no-undef
const props = defineProps({
  absenceModal: Object,
});

// eslint-disable-next-line no-undef
const emits = defineEmits(['closeDialog', 'changeAbsence']);

const rules = computed(() => {
  return {
    employeeName: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    fromDate: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    toDate: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    absenceType: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    observations: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
  };
});

const totalTime = computed(() => {
  return formatAbsentTime(absenceFormModel.value.absentWorkTime);
})

const v$ = useVuelidate(rules, absenceFormModel);
const v = v$.value;





onMounted(() => {
  getEmployees();
  getAbsenceTypes();

  if (props.absenceModal) {
    if (props.absenceModal.id) {
      const absence = props.absenceModal;
      absenceFormModel.value = {
        id: absence.id,
        employee: absence.employee,
        employeeName: absence.employee.name + ' ' + absence.employee.lastName,
        fromDate: absence.fromDate,
        toDate: absence.toDate,
        absenceType: absence.absenceType,
        observations: absence.observations,
        absenceSupportingDocuments: absence.absenceSupportingDocuments,
        absentWorkTime: absence.absentWorkTime,
      }
      fileList.value = absence.absenceFileList;

      fechaDesde.value = absenceFormModel.value.fromDate;
      manejarCambioDesde(fechaDesde.value);
      fechaHasta.value = absenceFormModel.value.toDate;
      manejarCambioHasta(fechaHasta.value);
    }
  } else {
    fechaDesde.value = startOfDay;
    manejarCambioDesde(fechaDesde.value);
    fechaHasta.value = endOfDay;
    manejarCambioHasta(fechaHasta.value);
  }
  // fileList.value = props.absenceModel.absenceSupportingDocuments;
})


onUnmounted(() => {
  v$.value.$reset()
  absenceFormModel.value = {};
  closeDialog();
})

const getAbsenceTypes = (searchQuery = '') => {
  const params = { query: { name: searchQuery } };
  ResourceService.getAbsenceTypes(params)
    .then((res) => {
      absenceTypes.value = res.data['hydra:member'];
    })
}


// Funciones Modal
const removeDocuments = () => {
  newFileList.value.forEach(doc => {
    AbsenceSupportingDocument.deleteAbsenceSupportingDocument(doc.id)
      .then(() => {
        ElNotification({
          type: 'success',
          message: 'Justificante eliminado correctamente',
          duration: 6000
        });
      })
      .catch(() => {
        ElNotification({
          type: 'error',
          message: 'Error al intentar eliminar el justificante seleccionado.',
          duration: 6000
        });
      })
  });
}

const saveAbsence = async () => {
  absenceFormModel.value.fromDate = fechaDesde.value;
  absenceFormModel.value.toDate = fechaHasta.value;
  v$.value.$reset()
  v$.value.$validate();

  if (!v$.value.$invalid) {
    absenceFormModel.value.employee = absenceFormModel.value.employee['@id'];
    absenceFormModel.value.fromDate = dayjs.utc(absenceFormModel.value.fromDate).tz("Europe/Madrid").format();
    absenceFormModel.value.toDate = dayjs.utc(absenceFormModel.value.toDate).tz("Europe/Madrid").format();
    if (absenceFormModel.value.id != null) {
      await updateAbsence(updateAbsenceFromData(absenceFormModel.value));
    } else {
      await createAbsence(updateAbsenceFromData(absenceFormModel.value));
    }
  } else {
    ElNotification({
      type: 'error',
      message: 'Error al crear el registro. Revise los campos marcados en rojo',
      duration: 6000
    })
    emits('closeDialog');
  }

}

const getEmployees = (filters) => {

  params.value = {
    ...params.value,
    filter: filters,
    'order[name]': 'asc',
    'active': 1,
    page: 1,
    itemsPerPage: 999999
  };
  store.commit('startLoading');
  EmployeeService.getEmployees(params.value)
    .then((res) => {
      employees.value = res.data['hydra:member'];
      totalResults.value = res.data['hydra:totalItems'];
      store.commit('stopLoading');
    })
}


const cancelDialog = () => {
  closeDialog();
  removeDocuments();
  emits('closeDialog');
}

const closeDialog = () => {
  showModalNewAbsence.value = false;
  deleteDialogShown.value = false;
  selectedAbsence.value = null;
  absenceFormModel.value = {
    id: null,
    employee: null,
    employeeName: '',
    fromDate: startOfDay,
    toDate: endOfDay,
    absenceType: null,
    observations: null,
    absenceSupportingDocuments: []
  };
}

const handleFileUpload = () => {
  const fileTypes = ['pdf', 'zip', 'rar', 'jpg', 'jpeg', 'png', 'doc', 'docx'];
  const newFile = file.value.files[0];
  const extension = newFile.name.split('.').pop().toLowerCase(),
    isSuccess = fileTypes.indexOf(extension) > -1;
  if (newFile && newFile.size < 5242880) {
    if (isSuccess) {
      const reader = new FileReader();
      reader.readAsBinaryString(newFile);
      reader.onloadstart = () => {
        file.value = 'loadStart';
      };
      reader.onload = () => {
        file.value = newFile;
        saveDocument();
      };
      reader.onerror = () => {
        file.value = 'error';
      };
    } else {
      ElNotification({
        type: 'error',
        message: 'Este formato no está permitido.',
        duration: 6000
      })
      file.value = null;
    }
  } else {
    ElNotification({
      type: 'error',
      message: 'Tamaño máximo permitido 5 MB.',
      duration: 6000
    })
    file.value = null;
  }
}

const downloadDocument = (url, label) => {
  let type = 'application/' + label.split('.').pop();

  let contentUrl;
  AbsenceSupportingDocument.getAbsenceAbsenceSupportingDocument(url)
    .then(res => {
      contentUrl = res.data.contentUrl
      Api().get(baseUrl.value + contentUrl, {
        responseType: 'blob',
      })
        .then(response => {
          const blob = new Blob([response.data], { type: type })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = label
          link.click()
          URL.revokeObjectURL(link.href)
        }).catch(() => {
          ElNotification({
            type: 'error',
            message: 'Error al descargar el justificante',
            duration: 6000
          });
        })
    }).catch(() => {
      ElNotification({
        type: 'error',
        message: 'Error al descargar el justificante',
        duration: 6000
      })
    })


}

const saveDocument = async () => {
  // store.state.loading = true;
  store.commit('startLoading');
  await AbsenceSupportingDocument.uploadDocumentFile(file.value)
    .then((res) => {
      let id = res.data['@id'];
      absenceFormModel.value.absenceSupportingDocuments.push(id);
      fileList.value.push(res.data);
      newFileList.value.push(res.data);
      ElNotification({
        type: 'success',
        message: 'Documento cargado correctamente',
        duration: 6000
      });
      store.commit('stopLoading');
    })
    .catch(() => {
      ElNotification({
        type: 'error',
        message: 'Error al cargar el documento',
        duration: 6000
      });
      // store.state.loading = false;
      store.commit('stopLoading');
    });
}
const focusOnInput = () => {
  setTimeout(() => {
    document.querySelector('#file').focus()
    file.value.focus()
  }, 1000)
}


const updateAbsence = async (absence) => {
  // store.state.loading = true;
  // store.commit('startLoading');
  AbsenceService.updateAbsence(absence, absence.id)
    .then(() => {
      // store.state.loading = false;
      // store.commit('stopLoading');
      ElNotification({
        type: 'success',
        message: 'Ausencia actualizada correctamente'
      });
      showModalNewAbsence.value = false;
      emits('changeAbsence')
    })
    .catch(() => {
      ElNotification({
        type: 'error',
        message: 'Error al actualizar la ausencia'
      });
      // store.state.loading = false;
      // store.commit('stopLoading');
    });
}
const createAbsence = (absence) => {
  // store.state.loading = true;
  store.commit('startLoading');
  AbsenceService.createAbsence(absence)
    .then(() => {
      ElNotification({
        type: 'success',
        message: 'Ausencia creada correctamente'
      });
      // store.state.loading = false;
      store.commit('stopLoading');
      showModalNewAbsence.value = false;
      emits('changeAbsence')
    })
    .catch(() => {
      ElNotification({
        type: 'error',
        message: 'Error al crear la ausencia'
      });
      // store.state.loading = false;
      store.commit('stopLoading');
    });
}

const updateAbsenceFromData = (absence) => {
  const { absenceType, ...rest } = absence;
  const updatedAbsence = {
    ...rest, // Any other field
    absenceType: absenceType?.['@id'],
  };
  return updatedAbsence
}

const changeEmployeeModal = (selected) => {
  absenceFormModel.value.employeeName = selected.fullName;
}

const deleteSupportingDocument = (document, index) => {
  // store.state.loading = true;
  store.commit('startLoading');
  AbsenceSupportingDocument.deleteAbsenceSupportingDocument(document.id)
    .then(() => {
      ElNotification({
        type: 'success',
        message: 'Justificante eliminado correctamente',
        duration: 6000
      });
      let value = fileList.value[index]['@id'];
      fileList.value.splice(index, 1);
      absenceFormModel.value.absenceSupportingDocuments = absenceFormModel.value.absenceSupportingDocuments.filter(e => e !== value);
      if (absenceFormModel.value.absenceSupportingDocuments.length === 0) {
        delete absenceFormModel.value.absenceSupportingDocuments;
      }
      // store.state.loading = false;
      store.commit('stopLoading');
    })
    .catch(() => {
      ElNotification({
        type: 'error',
        message: 'Error al intentar eliminar el justificante seleccionado.',
        duration: 6000
      });
      // store.state.loading = false;
      store.commit('stopLoading');
    })
}

const deshabilitarFechasHasta = (date) => {
  if (fechaDesde.value) {
    const fechaDesdeDate = new Date(fechaDesde.value);
    const dateToCheck = new Date(date);

    // Permitir el mismo día, pero no fechas anteriores
    return (
      dateToCheck.getFullYear() < fechaDesdeDate.getFullYear() ||
      (dateToCheck.getFullYear() === fechaDesdeDate.getFullYear() &&
        dateToCheck.getMonth() < fechaDesdeDate.getMonth()) ||
      (dateToCheck.getFullYear() === fechaDesdeDate.getFullYear() &&
        dateToCheck.getMonth() === fechaDesdeDate.getMonth() &&
        dateToCheck.getDate() < fechaDesdeDate.getDate())
    );
  }
  return false
}
const deshabilitarFechasDesde = (date) => {
  if (fechaHasta.value) {
    const fechaHastaDate = new Date(fechaHasta.value);
    const dateToCheck = new Date(date);

    // Permitir el mismo día, pero no fechas posteriores
    return (
      dateToCheck.getFullYear() > fechaHastaDate.getFullYear() ||
      (dateToCheck.getFullYear() === fechaHastaDate.getFullYear() &&
        dateToCheck.getMonth() > fechaHastaDate.getMonth()) ||
      (dateToCheck.getFullYear() === fechaHastaDate.getFullYear() &&
        dateToCheck.getMonth() === fechaHastaDate.getMonth() &&
        dateToCheck.getDate() > fechaHastaDate.getDate())
    );
  }
  return false
}

const manejarCambioDesde = (val) => {
  if (fechaHasta.value && val > fechaHasta.value) {
    const fechaDesdeDate = new Date(val);
    const fechaHastaDate = new Date(fechaHasta.value);

    // Si la fecha seleccionada es posterior a la fecha hasta, 
    // reset la fecha hasta
    if (fechaDesdeDate > fechaHastaDate) {
      fechaHasta.value = null;
    }
  }
}

const manejarCambioHasta = (val) => {
  if (fechaDesde.value && val < new Date(fechaDesde.value)) {
    const fechaHastaDate = new Date(val);
    const fechaDesdeDate = new Date(fechaDesde.value);

    // Si la fecha seleccionada es anterior a la fecha desde, 
    // reset la fecha desde
    if (fechaHastaDate < fechaDesdeDate) {
      fechaDesde.value = null;
    }
  }
}


const formatAbsentTime = (minutes) => {
  if (minutes === 0) return '0'

  const days = Math.floor(minutes / (24 * 60))
  const hours = Math.floor((minutes % (24 * 60)) / 60)
  const mins = minutes % 60

  let result = ''
  if (days > 0) result += `${days}D:`
  if (hours > 0 || days > 0) result += `${hours.toString().padStart(2, '0')}H:`
  result += `${mins.toString().padStart(2, '0')}M`

  return result.trim()
}


</script>
<style lang="scss">
.my-body {
  margin-right: 5%;
}


.btn_new_modal {
  min-height: 40px;
  margin-top: 10%;
  margin-left: -2%;
}

.absences {
  .label-absence {
    color: #434343;
    float: left;
    display: block;
    white-space: nowrap;

  }
}

.inputTotal {
  .el-input__wrapper {
    .el-input__inner {
      cursor: default;
      -webkit-text-fill-color: #434343;
    }
  }
}
</style>