<template>
  <div class="employee-calendar">
    <!--        <h1>{{title}}</h1>-->
    <HeaderTitle :title="title" />
    <div class="employee-calendar-container">
      <!-- Leyenda -->
      <div class="row leyend-container d-flex align-items-center justify-content-start flex-wrap">
        <div class="px-3">
          <label class="leyend-title">Leyenda:</label>
        </div>
        <div class="px-3 d-flex align-items-center justify-content-start">
          <div class="leyend-block">
            <span class="dot green-dot" />
            <label class="leyend-label">Día actual</label>
          </div>
          <div class="leyend-block">
            <span class="dot red-dot" />
            <label class="leyend-label">Festivo nacional</label>
          </div>
          <div class="leyend-block">
            <span class="dot blue-dot" />
            <label class="leyend-label">Festivo local</label>
          </div>
        </div>
      </div>

      <!-- Calendario -->
      <div  v-if="dataLoaded" class="row">
        <div class="col-12">
          <VCalendar
              id="holidays-calendar"
              is-expanded
              :rows="3"
              :columns="4"
              :min-date="minDate"
              :max-date="maxDate"
              :attributes="attributes"
              :first-day-of-week="2"
              :disabled-dates="disabledDates"
              nav-visibility="hidden"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>


// Services
import PublicHolidayService from '@/api/services/publicHoliday.service';
import CommonService from '@/api/services/common.service';
import  {getYear}  from '@/views/production/mixin/common'
import moment from "moment";

// Components
import HeaderTitle from "@/components/layout/HeaderTitle";
import {onMounted, ref} from "vue";
import {useStore} from "vuex";

const store = useStore();

const disabledDates = ref([{
  repeat: {
    weekdays: [1, 7]
  }
}]);
const title = ref("Calendario");
const serverTimestamp = ref(null);
const currentServerYear = ref(null);
const dataLoaded = ref(false);
const minDate = ref(null);
const maxDate = ref(null);
const attributes = ref([
  // Día actual
  {
    highlight: 'green',
    popover: {
      label: 'Hoy',
    },
    dates: { start: new Date(), end: new Date() },
  }
]);
const params = ref({});
const publicHolidays = ref([]);
const totalResults = ref(0);

onMounted(() => {
  initializeCalendarData();
})

/* Inicializa la vista */
const initializeCalendarData = async () => {
  // await store.dispatch('setLoading', true);
  await store.commit('startLoading');
  await CommonService.getServerTimestamp().then(async (resTimestamp) => {
    serverTimestamp.value = resTimestamp.data.serverTimestamp;
    currentServerYear.value = getYear(serverTimestamp);
    title.value = "Calendario " + currentServerYear.value;

    // Configuramos la fecha mínima y máxima a mostrar en el calendario
    minDate.value = new Date(parseInt(currentServerYear.value), 0, 1);
    maxDate.value = new Date(parseInt(currentServerYear.value), 11, 31);

    dataLoaded.value = true;

    // await store.dispatch('setLoading', false);
    await store.commit('stopLoading');
    // Obtenemos los días festivos y los procesamos...
    await getPublicHolidays();
  })
      .catch(() => {
        //  Notification.addNotification('Error al obtener el timestamp del servidor', 'error', 6000);
        // store.dispatch('setLoading', false);
        store.commit('stopLoading');
      });
}

/* Obtiene los dias festivos */
const getPublicHolidays = async () => {
  params.value['date[before]'] = moment(maxDate.value).format('YYYY-MM-DD')
  params.value['date[after]'] = moment(minDate.value).format('YYYY-MM-DD')

  // await store.dispatch('setLoading', true);
  await store.commit('startLoading');
  await PublicHolidayService.getPublicHolidays(params.value)
      .then((res) => {

        publicHolidays.value = res.data['hydra:member'];
        totalResults.value = res.data['hydra:totalItems'];

        // Rellenamos la información a mostrar en el calendario
        publicHolidays.value.forEach((publicHoliday) => {
          let NewAttribute = {
            highlight: publicHoliday.type === "nacional" ? 'red' : 'blue',
            popover: {
              label: publicHoliday.type === "nacional" ? publicHoliday.description
                  : publicHoliday.workplace.name + ': ' + publicHoliday.description,
            },
            dates: new Date(publicHoliday.date),
            rawDate: publicHoliday.date
          }

          const exist = attributes.value.findIndex(attr => attr.rawDate === publicHoliday.date);
          if (publicHoliday.type === "local" || (publicHoliday.type === "nacional" && exist === -1)) {
            attributes.value.push(NewAttribute);
          }
        });
        // store.dispatch('setLoading', false);
        store.commit('stopLoading');
      })
      .catch(() => {
        // Notification.addNotification('Error al obtener el listado de dias festivos', 'error', 6000);
        // store.dispatch('setLoading', false);
        store.commit('stopLoading');
      });
}

</script>

<style lang="scss" scoped>
.employee-calendar {
  margin-bottom: 20px;

  .leyend-container {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .leyend-label {
    vertical-align: bottom;
  }

  .leyend-block {
    display: inline-block;
    margin-right: 20px;
  }

  .dot {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
  }

  .red-dot {
    background-color: #e53e3e;
  }

  .blue-dot {
    background-color: #3182ce;
  }

  .green-dot {
    background-color: #38a169;
  }
}
</style>
