<template>
  <div>
    <HeaderTitle title="Sistemas" />

    <TicketForm v-if="ticket" action="update" :ticket="ticket" @saveData="updateTicket" @get-ticket="getTicket" />
  </div>
</template>

<script setup>

import HeaderTitle from "@/components/layout/HeaderTitle.vue";

import TicketService from "@/api/services/ticket.service";
import TicketForm from "@/views/tickets/TicketForm.vue";
import notification from "@/utils/plugins/NotificationService";
import router from "@/app/router/router";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { ElNotification } from "element-plus";

const route = useRoute()
const ticket = ref(null)


onMounted(async () => {
  await getTicket();
})


const getTicket = async () => {
  const id = route.params.id;
  await TicketService.getTicket(id).then(response => {
    if (response && response.status === 200) {
      ticket.value = response.data;
    }
  });

}

const updateTicket = (form) => {

  const formTicket = JSON.parse(JSON.stringify(form));
  formTicket.ticketType = form.ticketType ? typeof form.ticketType === 'object' ? form.ticketType['@id'] : form.ticketType : null;
  formTicket.ticketPriority = form.ticketPriority && typeof form.ticketPriority === 'object' ? form.ticketPriority['@id'] : form.ticketPriority;
  formTicket.department = form.department ? form.department['@id'] : null;
  formTicket.project = form.project ? typeof form.project === 'object' ? form.project['@id'] : form.project : null;
  formTicket.affectedUser = form.affectedUser ? typeof form.affectedUser === 'object' ? form.affectedUser['@id'] : form.affectedUser : null;
  formTicket.requestingUser = typeof form.requestingUser === 'object' ? form.requestingUser['@id'] : form.requestingUser;
  formTicket.employeeCreatingTicket = form.employeeCreatingTicket ? form.employeeCreatingTicket['@id'] : null;
  formTicket.internal = false
  delete (formTicket.status)
  delete (formTicket.ticketStatus)
  delete (formTicket.daysOpen)
  delete (formTicket.comments)
  delete (formTicket.ticketStatusHistories)
  if (formTicket.ticketDocuments.length > 0) {
    formTicket.ticketDocuments = formTicket.ticketDocuments.map((doc) => {
      return doc['@id'] ? doc['@id'] : doc
    })
  }
  TicketService.updateTicket(formTicket, ticket.value.id).then((res) => {
    if (res && (res.status === 200 || res.status === 201)) {
      notification.addNotification("Datos guardados correctamente", "success", 10000)
      router.go(-1)
    } else {
      ElNotification({
        title: 'Error',
        message: res.data.detail,
        type: 'error'
      })
    }

  }).catch((e) => {
    ElNotification({
      title: 'Error',
      message: e.response.data.detail,
      type: 'error'
    })

  })
}



</script>

<style scoped></style>
