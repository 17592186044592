<template>
  <div>
    <!--        <h1>Puestos de Trabajo</h1>-->
    <HeaderTitle title="Ajustes" />
    <div class="d-flex flex-row justify-content-start">
      <img src="@/assets/icons/group.svg" width="30" alt="">
      <div class="title-text pl-4">
        Puestos de Trabajo
      </div>
    </div>
    <el-card class="card-search w-100 wback" body-style="padding:0">
      <div class="row mx-5">
        <div class="container-jobs">
          <div class="row">
            <div class="col-md-8">
              <div class="col-md-6">
                <div class=" form-item  date-picker  label-common">
                  <label class=" w-100 mb-2">Nombre</label>
                  <el-input v-model="searchFilterJobName" placeholder="Nombre del Puesto de trabajo"
                    prefix-icon="el-icon-search" clearable />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="ml-auto d-flex justify-content-end align-items-end">
              <div class="">
                <button @click="createOrUpdateJobDialog = true" class="btn btn_darkpink btn-search"
                  style="min-width:250px; max-width: 300px; color:white;">
                  NUEVO PUESTO DE TRABAJO
                </button>
              </div>
              <div class="">
                <button class="btn btn_yellow btn-search" style="max-width: 250px;" @click="search()">
                  Buscar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-card>
    <div class="table">
      <div v-if="jobs && jobs.length > 0">
        <table>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Productivo</th>
              <th>Departamento</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="job in jobs" :key="job.id">
              <td>
                {{ job.name }}
              </td>
              <td> {{ isProductive(job.productive) }}</td>
              <td v-if="job.department && job.department.name">
                {{ job.department.name }}
              </td>
              <td v-else />
              <td>
                <div class="buttons d-inline-flex">
                  <div class="pointer">
                    <font-awesome-icon class="btn_icon blue_btn" icon="eye" title="Editar" style="margin-left: 5px"
                      @click="editJob(job)" />
                  </div>
                  <div class="pointer">
                    <font-awesome-icon class="btn_icon delete_btn" icon="times" title="Eliminar"
                      style="margin-left: 5px" @click="deleteDialogShown = true; selectedJob = job;" />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <h5>No se han encontrado tipos de puestos de trabajo</h5>
      </div>
      <SharedPagination v-if="jobs && jobs.length > 4" class="pagination" :page="page" :total-results="totalResults"
        :page-size="itemsPerPage" style="padding: 0px 0px 20px 0px;" @change="page = $event; getJobs()" />

    </div>

    <el-dialog class="el-dialog" v-model="createOrUpdateJobDialog" @closed="closeCreateOrUpdateDialog()"
      :show-close="false" size="'medium'" width="850" :center="true" :lock-scroll="true"
      :style="{ borderRadius: `var(--el-border-radius-round)` }">
      <div class="my-header">
        <svg id="asterisk" xmlns="http://www.w3.org/2000/svg" width="724" height="1024" viewBox="0 0 724 1024">
          <path
            d="M0 499.8l59-181.7 332.5 108.6-59 180.8-332.5-107.7zM79.6 744.3l206.1-282.9 154.5 111.5-206.1 283.8-154.5-112.4zM266.9 167.3h191.1v350.3h-191.1v-350.3zM285.6 572.9l154.5-111.5 206.1 282.9-154.5 112.4-206.1-283.8zM332.5 426.8l332.5-108.6 59 181.7-331.6 107.7-59.9-180.8z" />
        </svg>
        <span class="text-header ml-5 mt-3">{{ isUpdateJob ? 'Actualizar ' : 'Nuevo ' }}Puesto de trabajo</span>
        <img class="close-icon pointer" src="@/assets/images/aspa_blue_small_entrada.png" alt=""
          @click="closeCreateOrUpdateDialog()">
      </div>
      <div class="my-body">
        <form @submit.prevent="">
          <div class="row">
            <div class="col-md-9">
              <div class="form-item">
                <label class="w-100">Puesto de trabajo</label>
                <el-form-item prop="username" :error="$v.jobName.$errors[0]?.$message">
                  <el-input v-model="$v.jobName.$model" placeholder="Nombre del Puesto de trabajo"
                    prefix-icon="el-icon-search" clearable />
                </el-form-item>

              </div>
            </div>
            <div class="col-md-4">
              <div class="form-item">
                <label class="w-100">Productividad</label>
                <el-select v-model="productive" placeholder="Productividad" filterable clearable value-key="name">
                  <el-option v-for="item in productiveArray" :key="item.name" :label="item.name" :value="item" />
                </el-select>
              </div>
            </div>
            <div class="col-md-5">
              <div class="form-item">
                <label class="w-100">Departamento</label>
                <el-select v-model="department" placeholder="Departamento" filterable clearable value-key="@id">
                  <el-option v-for="item in departments" :key="item.id" :label="item.name" :value="item" />
                </el-select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 offset-md-5 d-flex flex-column justify-content-center align-items-center">
              <button class="btn btn_darkpink" :disabled="$v.$invalid"
                @click="isUpdateJob ? updateJob(job) : createJob(job)">
                {{ isUpdateJob ? 'Actualizar' : 'Nuevo' }}
              </button>
            </div>
          </div>


          <!--                    <div class="col-md-3 text-right"  v-if="user && ifIsInArray('ROLE_SUPER_ADMIN', user.rol) && title.length > 0">-->
        </form>
      </div>
    </el-dialog>

    <el-dialog v-model="deleteDialogShown" :title="'¿Desea eliminar permanentemente este tipo de trabajo?'"
      class="dense-dialog" @closed="selectedJob = null; deleteDialogShown = false">
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="selectedJob = null; deleteDialogShown = false;">
            Cancelar
          </el-button>
          <el-button type="primary" @click="deleteJob(selectedJob)">Confirmar</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
/* eslint-disable */
// Components
import SharedPagination from "@/components/shared/SharedPagination.vue";
import SharedDialog from "@/components/shared/SharedDialog.vue";
import HeaderTitle from "@/components/layout/HeaderTitle.vue";

// Services
import ResourceService from '@/api/services/resource.service';
import Notification from '@/utils/plugins/NotificationService';
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { helpers, minLength, required, } from '@vuelidate/validators'
import message from "@const/message";

const searchFilterJobName = ref('')
const createOrUpdateJobDialog = ref(false)
const isUpdateJob = ref(false)
const jobs = ref([]);
const job = ref({});
const department = ref({});
const productiveArray = ref([
  { 'name': 'Productivo', 'productive': true },
  { 'name': 'No productivo', 'productive': false }
]);
const productive = ref({});
const departments = ref([]);
const deleteDialogShown = ref(false);
const selectedJob = ref([]);
const page = ref(1);
const itemsPerPage = ref(10);
const totalResults = ref(0);
const jobName = ref('');

const rules = computed(() => {
  return {
    jobName: {
      required: helpers.withMessage(message.required, required),
      minLength: helpers.withMessage("La longitud mínima del campo es de 3", minLength(3))
    }
  }
})
const $v = useVuelidate(rules, { jobName });

const store = useStore();
onMounted(() => {
  getJobs();
  getDepartments();
})

const search = () => {
  getJobs(searchFilterJobName.value);
}
const notEmptyObject = (someObject) => {
  return Object.keys(someObject).length;
}
const editJob = (job) => {
  selectedJob.value = job;
  jobName.value = job.name;
  productive.value = {
    productive: job.productive,
    name: job.productive ? 'Productivo' : 'No productivo'
  };
  department.value = (job.department && notEmptyObject(job.department)) ? job.department : {};
  isUpdateJob.value = true
  createOrUpdateJobDialog.value = true
}
const getJobs = (searchQuery = null) => {
  const params = searchQuery ? { name: searchQuery } : {};
  ResourceService.getJobs(params)
    .then((res) => {
      jobs.value = res.data['hydra:member'];
    })
}
const getDepartments = (searchQuery = null) => {
  const params = searchQuery ? { name: searchQuery } : {};
  ResourceService.getDepartments(params)
    .then((res) => {
      departments.value = res.data['hydra:member'];
    })
}
const deleteJob = (job) => {
  ResourceService.deleteJob(job.id)
    .then(() => {
      Notification.addNotification('Tipo de trabajo eliminado correctamente.', 'success', 6000);
      deleteDialogShown.value = false;
      cancel();
    }).catch(() => {
      Notification.addNotification('Error al eliminar el tipo de trabajo seleccionado', 'error', 6000);
      // store.state.loading = false;
      store.commit('stopLoading');
    })
}
const createJob = (job) => {
  job.name = jobName.value;
  job.productive = productive.value.productive;
  if (notEmptyObject(department.value)) {
    job.department = department.value['@id'];
  }
  // store.state.loading = true;
  store.commit('startLoading');
  ResourceService.createJob(job)
    .then(() => {
      Notification.addNotification('Tipo de trabajo creado correctamente', 'success', 6000);
      // store.state.loading = false;
      store.commit('stopLoading');
      getJobs();
    })
    .catch(() => {
      Notification.addNotification('Error al crear el tipo de trabajo', 'error', 6000);
      // store.state.loading = false;
      store.commit('stopLoading');
    })
    .finally(() => {
      closeCreateOrUpdateDialog()
    })

}
// eslint-disable-next-line no-unused-vars
const updateJob = async (job) => {
  if (jobName.value) {
    job.name = jobName;
  }

  job.productive = productive.value.productive;

  if (notEmptyObject(department.value)) {
    job.department = department.value['@id'];
  }

  // store.state.loading = true;
  store.commit('startLoading');
  await ResourceService.updateJob(job, selectedJob.value.id)
    .then(() => {
      Notification.addNotification('Tipo de trabajo actualizado correctamente', 'success', 6000);
      // store.state.loading = false;
      store.commit('stopLoading');
      getJobs();
    })
    .catch(() => {
      Notification.addNotification('Error al actualizar el tipo de trabajo', 'error', 6000);
      // store.state.loading = false;
      store.commit('stopLoading');
    })
    .finally(() => {
      closeCreateOrUpdateDialog()
    })
}

const reset = () => {
  job.value = {};
  jobName.value = '';
  selectedJob.value = null;
  department.value = {};
  productive.value = {};
  isUpdateJob.value = false
}
const cancel = () => {
  reset()
  getJobs();
}

const closeCreateOrUpdateDialog = () => {
  createOrUpdateJobDialog.value = false
  reset()
}



// eslint-disable-next-line no-unused-vars
const isProductive = (isProductive) => {
  return isProductive ? 'Si' : 'No';
}


</script>

<style lang="scss" scoped>
.card-search {
  height: 200px;
}

.job-input {
  /*display: flex;*/
  vertical-align: baseline;
  margin: 30px 10px 10px 10px;
}

.table-jobs {
  padding: 40px;

  table {
    border-collapse: separate;
    border-spacing: 0 25px;
    color: #858796;
    // text-align-all: left;
    text-align: left;
    width: 100%;

    th {
      //border: 1px solid #e3e6f0;
      color: $font-blue-color;
      padding: 12px 12px 12px 20px;
      font-size: 20px;
      text-align: center;
    }

    tbody {
      //tr:nth-child(odd) {
      //  td {
      //    background-color: rgba(0, 0, 0, .05);
      //  }
      //}

      td {

        background-color: white;
        border: 1px solid #e3e6f0;
        padding: 12px 12px 12px 20px;
        text-align: left;

        a {
          font-size: 18px;
          color: #4e73df;
        }
      }

      tr:nth-child(n) {
        td:first-child {
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          -moz-border-radius-topleft: 10px;
          -moz-border-radius-bottomleft: 10px;
          -webkit-border-top-left-radius: 10px;
          -webkit-border-bottom-left-radius: 10px;

          -webkit-box-shadow: 0 .5rem 1rem rgba(58, 59, 69, .15);
          -moz-box-shadow: 0 .5rem 1rem rgba(58, 59, 69, .15);
          box-shadow: 0 .5rem 1rem rgba(58, 59, 69, .15);
        }

        td:last-child {
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          -moz-border-radius-topright: 10px;
          -moz-border-radius-bottomright: 10px;
          -webkit-border-top-right-radius: 10px;
          -webkit-border-bottom-right-radius: 10px;

          -webkit-box-shadow: 0 0.5rem 1rem rgba(58, 59, 69, .15);
          -moz-box-shadow: 0 0.5rem 1rem rgba(58, 59, 69, .15);
          box-shadow: 0 0.5rem 1rem rgba(58, 59, 69, .15);
        }

        td:nth-child(n) {
          -webkit-box-shadow: 0 0.5rem 1rem rgba(58, 59, 69, .15);
          -moz-box-shadow: 0 0.5rem 1rem rgba(58, 59, 69, .15);
          box-shadow: 0 0.5rem 1rem rgba(58, 59, 69, .15);
        }
      }
    }
  }
}

.el-dialog {
  background: #f3f0fb !important;
}

.el-dialog__body {
  background: #f3f0fb !important;
  min-height: 800px !important;

  .el-dialog__header {
    padding: 0;
  }

  .my-header {
    margin-top: 2px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .text-header {
      text-align: left;
      font-size: 4em;
      font-weight: 900;
      letter-spacing: -0.05em;
      color: #f9156f;
    }

    .close-icon {
      width: 8%;
      height: 20%;
    }

    .inter-icon {
      width: 2%;
      height: 2%;
    }

    #asterisk {
      position: absolute;
      vertical-align: super;
      height: auto;
      width: 2.2em;
      fill: #f9156f;
    }

  }

  .my-body {
    margin-right: 5%;
  }

}
</style>
