<template>
  <div>
    <HeaderTitle title="InformeDePresencia" />
    <el-row>
      <el-card class="card-search w-100 p-5" body-style="padding:0">
        <form>
          <div class="form-item mb-3 row">
            <div class="col-md-4">
              <label class="col-md-12">Nombre</label>
              <el-input clearable v-model="employeeName" placeholder="Nombre" />
            </div>
            <div class="col-md-4">
              <label>Departamento</label>
              <el-select v-model="department" placeholder="Todos" clearable filterable>
                <el-option v-for="item in departments" :key="item.id" :label="item.name" :value="item.id" />
              </el-select>
            </div>
            <div class="col-md-4">
              <label class="col-md-12">Producción</label>
              <el-select id="status" v-model="selectedStatusFilter" label="ddd" @change="updateTable()">
                <el-option v-for="item in statusOptions" :key="item.id" :label="item.name" :value="item.value" />
              </el-select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8">
              <div class="row">
                <div class="col-md-6">
                  <label class="">Desde</label>
                  <div class="date-picker col-md-12">
                    <el-date-picker v-model="fechaDesde" type="date" placeholder="Desde"
                      :disabled-date="deshabilitarFechasDesde" @change="manejarCambioDesde" format="DD/MM/YYYY"
                      :prefix-icon="customPrefix" />
                  </div>
                </div>
                <div class="col-md-6">
                  <label>Hasta</label>
                  <div class="date-picker col-md-12">
                    <el-date-picker v-model="fechaHasta" type="date" placeholder="Hasta"
                      :disabled-date="deshabilitarFechasHasta" @change="manejarCambioHasta" format="DD/MM/YYYY"
                      :prefix-icon="customPrefix" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="d-flex justify-content-end align-content-end" style="margin-bottom: -23px; margin-top: 23px;">
                <div>
                  <button class="btn btn_yellow btn-search btn-new" @click="updateTable()">
                    Buscar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </el-card>
    </el-row>

    <el-row>
      <div class="table table-users">
        <div v-if="$store.state.reportPresence && $store.state.reportPresence.length > 0">
          <table>
            <thead>
              <tr>
                <th>
                  <div class="d-flex flex-row align-items-center">
                    <img src="@/assets/icons/user.svg" alt="name-icon" style="width: 2em;">
                    <div>NOMBRE</div>
                  </div>
                </th>
                <th>
                  <div class="d-flex flex-row align-items-center">
                    <svg id="chronometer1" version="1.1" xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 233.766 233.766"
                      style="enable-background: new 0 0 233.766 233.766" xml:space="preserve">
                      <g>
                        <path
                          d="M188.169,79.423l6.059-6.058l2.906,2.906c1.465,1.465,3.384,2.197,5.304,2.197c1.919,0,3.839-0.732,5.303-2.196
                                                                                    c2.93-2.929,2.93-7.678,0.001-10.606l-16.419-16.421c-2.929-2.93-7.678-2.929-10.607-0.001c-2.93,2.929-2.93,7.678-0.001,10.607
                                                                                    l2.907,2.907l-6.06,6.059c-10.441-8.901-22.855-15.553-36.484-19.201c4.082-5.216,6.524-11.773,6.524-18.895
                                                                                    C147.602,13.781,133.821,0,116.883,0c-16.939,0-30.72,13.781-30.72,30.721c0,7.121,2.442,13.679,6.525,18.895
                                                                                    c-13.63,3.647-26.043,10.3-36.485,19.201l-6.059-6.059l2.907-2.907c2.929-2.929,2.928-7.678-0.001-10.607
                                                                                    c-2.93-2.928-7.678-2.929-10.607,0.001L26.024,65.666c-2.929,2.929-2.928,7.678,0,10.606c1.465,1.464,3.384,2.196,5.303,2.196
                                                                                    s3.839-0.732,5.304-2.197l2.906-2.906l6.058,6.058c-13.944,16.357-22.375,37.551-22.375,60.68
                                                                                    c0,51.646,42.017,93.663,93.662,93.663s93.662-42.017,93.662-93.663C210.545,116.974,202.113,95.78,188.169,79.423z
                                                                                    M101.163,30.721c0-8.668,7.052-15.721,15.72-15.721s15.72,7.053,15.72,15.721s-7.052,15.72-15.72,15.72
                                                                                    S101.163,39.389,101.163,30.721z M116.883,218.766c-43.375,0-78.662-35.288-78.662-78.663c0-43.374,35.288-78.662,78.662-78.662
                                                                                    c43.374,0,78.662,35.288,78.662,78.662C195.545,183.477,160.257,218.766,116.883,218.766z" />
                        <path
                          d="M116.883,76.981c-4.142,0-7.5,3.357-7.5,7.5v55.621c0,4.143,3.358,7.5,7.5,7.5c4.143,0,7.5-3.357,7.5-7.5V84.481
                                                                                    C124.383,80.339,121.025,76.981,116.883,76.981z" />
                      </g>
                    </svg>
                    <div>
                      HORAS
                    </div>
                  </div>
                </th>
                <th>
                  <div class="d-flex flex-row align-items-center">
                    <svg id="chronometer1" version="1.1" xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 233.766 233.766"
                      style="enable-background: new 0 0 233.766 233.766" xml:space="preserve">
                      <g>
                        <path
                          d="M188.169,79.423l6.059-6.058l2.906,2.906c1.465,1.465,3.384,2.197,5.304,2.197c1.919,0,3.839-0.732,5.303-2.196
                                                                                    c2.93-2.929,2.93-7.678,0.001-10.606l-16.419-16.421c-2.929-2.93-7.678-2.929-10.607-0.001c-2.93,2.929-2.93,7.678-0.001,10.607
                                                                                    l2.907,2.907l-6.06,6.059c-10.441-8.901-22.855-15.553-36.484-19.201c4.082-5.216,6.524-11.773,6.524-18.895
                                                                                    C147.602,13.781,133.821,0,116.883,0c-16.939,0-30.72,13.781-30.72,30.721c0,7.121,2.442,13.679,6.525,18.895
                                                                                    c-13.63,3.647-26.043,10.3-36.485,19.201l-6.059-6.059l2.907-2.907c2.929-2.929,2.928-7.678-0.001-10.607
                                                                                    c-2.93-2.928-7.678-2.929-10.607,0.001L26.024,65.666c-2.929,2.929-2.928,7.678,0,10.606c1.465,1.464,3.384,2.196,5.303,2.196
                                                                                    s3.839-0.732,5.304-2.197l2.906-2.906l6.058,6.058c-13.944,16.357-22.375,37.551-22.375,60.68
                                                                                    c0,51.646,42.017,93.663,93.662,93.663s93.662-42.017,93.662-93.663C210.545,116.974,202.113,95.78,188.169,79.423z
                                                                                    M101.163,30.721c0-8.668,7.052-15.721,15.72-15.721s15.72,7.053,15.72,15.721s-7.052,15.72-15.72,15.72
                                                                                    S101.163,39.389,101.163,30.721z M116.883,218.766c-43.375,0-78.662-35.288-78.662-78.663c0-43.374,35.288-78.662,78.662-78.662
                                                                                    c43.374,0,78.662,35.288,78.662,78.662C195.545,183.477,160.257,218.766,116.883,218.766z" />
                        <path
                          d="M116.883,76.981c-4.142,0-7.5,3.357-7.5,7.5v55.621c0,4.143,3.358,7.5,7.5,7.5c4.143,0,7.5-3.357,7.5-7.5V84.481
                                                                                    C124.383,80.339,121.025,76.981,116.883,76.981z" />
                      </g>
                    </svg>
                    <div>
                      HORAS TRABAJADAS
                    </div>
                  </div>
                </th>
                <th>
                  <div class="d-flex flex-row align-items-center">
                    <svg id="chronometer1" version="1.1" xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 233.766 233.766"
                      style="enable-background: new 0 0 233.766 233.766" xml:space="preserve">
                      <g>
                        <path
                          d="M188.169,79.423l6.059-6.058l2.906,2.906c1.465,1.465,3.384,2.197,5.304,2.197c1.919,0,3.839-0.732,5.303-2.196
                                                                                    c2.93-2.929,2.93-7.678,0.001-10.606l-16.419-16.421c-2.929-2.93-7.678-2.929-10.607-0.001c-2.93,2.929-2.93,7.678-0.001,10.607
                                                                                    l2.907,2.907l-6.06,6.059c-10.441-8.901-22.855-15.553-36.484-19.201c4.082-5.216,6.524-11.773,6.524-18.895
                                                                                    C147.602,13.781,133.821,0,116.883,0c-16.939,0-30.72,13.781-30.72,30.721c0,7.121,2.442,13.679,6.525,18.895
                                                                                    c-13.63,3.647-26.043,10.3-36.485,19.201l-6.059-6.059l2.907-2.907c2.929-2.929,2.928-7.678-0.001-10.607
                                                                                    c-2.93-2.928-7.678-2.929-10.607,0.001L26.024,65.666c-2.929,2.929-2.928,7.678,0,10.606c1.465,1.464,3.384,2.196,5.303,2.196
                                                                                    s3.839-0.732,5.304-2.197l2.906-2.906l6.058,6.058c-13.944,16.357-22.375,37.551-22.375,60.68
                                                                                    c0,51.646,42.017,93.663,93.662,93.663s93.662-42.017,93.662-93.663C210.545,116.974,202.113,95.78,188.169,79.423z
                                                                                    M101.163,30.721c0-8.668,7.052-15.721,15.72-15.721s15.72,7.053,15.72,15.721s-7.052,15.72-15.72,15.72
                                                                                    S101.163,39.389,101.163,30.721z M116.883,218.766c-43.375,0-78.662-35.288-78.662-78.663c0-43.374,35.288-78.662,78.662-78.662
                                                                                    c43.374,0,78.662,35.288,78.662,78.662C195.545,183.477,160.257,218.766,116.883,218.766z" />
                        <path
                          d="M116.883,76.981c-4.142,0-7.5,3.357-7.5,7.5v55.621c0,4.143,3.358,7.5,7.5,7.5c4.143,0,7.5-3.357,7.5-7.5V84.481
                                                                                    C124.383,80.339,121.025,76.981,116.883,76.981z" />
                      </g>
                    </svg>
                    <div>
                      DIFERENCIA
                    </div>
                  </div>
                </th>
                <th>
                  <div>ALERTA</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(sign, index) in $store.state.reportPresence" :key="sign.id + index">
                <td>
                  <div class="row-table-image-name-link">
                    <div class="link-name">
                      <router-link :to="{ name: 'my-presence', params: { id: sign.id } }">
                        {{ sign.name }}
                      </router-link>
                    </div>
                  </div>
                </td>
                <td class="pointer text-center">
                  {{ sign.hours }}
                </td>
                <td class="pointer" style="text-align: center;">
                  {{ sign.totalHoursWorked }}
                </td>
                <td class="pointer text-center">
                  {{ sign.difference }}
                </td>
                <td class="pointer text-center">
                  <div v-if="sign.alert === 'OK'">
                    OK
                  </div>
                  <div v-else class="badge badge-danger" role="alert">
                    <a class="text-alert" data-toggle="tooltip" :title="dayIncomplet(sign.daysIncompleteSignings)">
                      <span>
                        {{ sign.alert }}
                        <font-awesome-icon icon="eye" />
                      </span>
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </el-row>
  </div>
</template>
<script>
import HeaderTitle from "@/components/layout/HeaderTitle.vue";
import Services from "@/api/services/presencia.service";
import ResourceService from "@/api/services/resource.service";
import moment from 'moment';
import { ElNotification } from "element-plus";
import { h, shallowRef } from "vue";

export default {
  components: {
    HeaderTitle,
  },
  data() {
    return {
      pickerOptions: {
        firstDayOfWeek: 1
      },
      // daterange:[moment(new Date()).weekday(-4).format("YYYY-MM-DD"),moment(new Date()).add(-1, 'day').format("YYYY-MM-DD")],
      daterange: [],
      employeeName: '',
      statusOptions: [
        { id: 0, name: 'Producción', value: 0 },
        { id: 1, name: 'No', value: 1 },

      ],
      selectedStatusFilter: 0,
      signings: [],
      department: null,
      departments: [],
      departmentsOriginal: [],
      fechaDesde: null,
      fechaHasta: null,
      customPrefix: shallowRef({
        render() {
          return h('img', {
            src: '/calendar-blue.svg',
            style: 'width: 16px; height: 16px; margin-right: 10px;',
          });
        }
      })
    }
  },

  watch: {
    daterange() {
      this.updateTable();
    }
  },

  mounted() {
    this.dateRange();
    this.updateTable();
    this.getDepartments();
  },
  methods: {

    dayIncomplet(days) {

      if (!days || days.length < 1) return 'No llega al día incompletos';
      return "Días incompletos:" + days.join();
    },
    updateTable() {
      const $this = this;
      let params = {
        filterUsers: this.selectedStatusFilter,
        from: moment(this.daterange[0]).format("YYYY-MM-DD"),
        to: moment(this.daterange[1]).format("YYYY-MM-DD"),
        //TODO: Falta añadir departamentos
      }
      if (this.employeeName) {
        if (this.employeeName.length === 1) {
          this.employeeName = this.upperCaseFirstLetter(this.employeeName);
        }
        params.name = this.upperCaseFirstLetter(this.employeeName);
      }
      Services.getSigningsWithEmployee(params)
        .then(response => {
          //console.log("Response",response);
          $this.signings = response.data.data;
          if ($this.employeeName) {
            $this.signings = $this.signings.filter(v => v.name.indexOf(this.upperCaseFirstLetter(this.employeeName)) !== -1);
          }
          $this.$store.commit('setReportPresence', $this.signings);
          $this.$store.commit('setDateRangeReport', $this.daterange);
          //console.log("Table",$this.signings);

        })
    },
    filterByEmployee() {
      this.updateTable()
    },
    dateRange() {
      let from_date = "";
      let to_date = "";
      let day = moment(this.now);
      const day_week = moment(day).day();
      if (day_week === 1) {
        from_date = moment().weekday(-6);
        to_date = moment().weekday(-2);
      } else {
        from_date = moment().weekday(1);
        to_date = moment().weekday(day.add(-1, 'day').day());
      }
      this.daterange = [moment(from_date).format("YYYY-MM-DD"), moment(to_date).format("YYYY-MM-DD")];
    },
    getDepartments(searchQuery = '') {
      const params = (searchQuery.length > 0 ? { name: searchQuery } : {});
      params['order[name]'] = 'ASC';
      ResourceService.getDepartments(params).then((res) => {
        this.departments = res.data['hydra:member'];
      }).catch(() => {
        ElNotification({
          message: 'Error al obtener los departamentos',
          type: 'error',
        });
      }).finally(() => {
      }
      );
    },
    deshabilitarFechasDesde(date) {
      if (this.fechaHasta) {
        return date > this.fechaHasta;
      }
      return false;
    },
    deshabilitarFechasHasta(date) {
      if (this.fechaDesde) {
        return date < this.fechaDesde;
      }
      return false;
    },
    manejarCambioDesde(val) {
      if (this.fechaHasta && val > this.fechaHasta) {
        this.fechaHasta = null;
      }
    },
    manejarCambioHasta(val) {

      if (this.fechaDesde && val < this.fechaDesde) {
        this.fechaDesde = null;
      }
    },
    upperCaseFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  }

}
</script>
<style lang="scss" scoped>
.card-search {
  border-radius: 15px;
  height: 250px;
  /* background: url('../../assets/icons/bombilla.png'), white;
  background-repeat: no-repeat;
  background-position-x: 100%; */

}

#chronometer1 {
  fill: $bg-blue;
  width: 2.0em;

}

.table-users {
  table {
    thead {
      tr {
        th {
          padding: 0px !important;
          margin: 0px !important;
        }
      }
    }

    tbody {
      td {
        .link-name {
          margin: auto;
          margin-left: 10px;
        }
      }
    }
  }
}

.text-alert {
  font-size: 11px;
  color: white;
}
</style>
