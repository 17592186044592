<script setup>
import TrackerProjectInfo from "@/components/shared/TrackerProjectInfo.vue";
import { onMounted, ref, defineEmits, defineProps, computed, watch } from "vue";
import ProjectsService from "@/api/services/projects.service";
import { ElNotification } from "element-plus";

// variables parametros
const page = ref(1);
const itemsPerPage = ref(9999);
const archived = ref(false);

// variables Clientes y proyecto
const optionClients = ref([]);
const allClients = ref([]);
const searchProject = ref('');

// variable loading
const isLoaded = ref(false);

// emits and props
const emit = defineEmits(['getProject'])
const props = defineProps({
    project: Object,
    client: Object,
    dropdownOpen: {
        type: Boolean,
        default: false,
    },
})

const currentProject = computed(() => {
    return props.project;
})

const selectedClient = ref();
const refSearchProject = ref(null);

watch(() => props.client, (newClient) => {
    selectedClient.value = newClient;
    searchForClientAndProject();
})


// Mounted
onMounted(() => {
    setTimeout(() => {
        refSearchProject.value?.focus();
    }, 100);
    getClients();
})

watch(() => props.dropdownOpen, (newValue) => {
    if (newValue) {
        setTimeout(() => {
            refSearchProject.value?.focus();
        }, 100);
    }
})

// getter
const getClients = () => {
    isLoaded.value = false;
    let params = {
        page: page.value,
        itemsPerPage: itemsPerPage.value,
        archived: archived.value,
        'order[name]': 'ASC'
    }
    ProjectsService.getClients(params)
        .then((response) => {
            // filtrar para que solo aparezcan los clientes con mas de 1 proyecto
            optionClients.value = response.data['hydra:member'].filter(client => {
                return client?.projectsNotArchived?.length > 0;
            });

            allClients.value = optionClients.value;

        }).catch((error) => {
            console.log(error);
            ElNotification({
                message: "Error al cargar los proyectos",
                type: "error",
                duration: 6000
            });
        })
        .finally(() => {
            isLoaded.value = true;
        });
}

// function for the search
const searchForClientAndProject = () => {
    if (searchProject.value == '' || !searchProject.value) {
        optionClients.value = allClients.value;
    }
    let filterClients = allClients.value;
    if (selectedClient.value) {
        const temporal = allClients.value.map(client => {
            // Aquí comprueba que el nombre del cliente es igual al input
            const clientNameMatch = client.name.toLowerCase().includes(selectedClient.value.name.toLowerCase());

            // Si el nombre del cliente es igual al input, te muestra todos sus proyectos
            if (clientNameMatch) {
                return { ...client, projectsNotArchived: client?.projectsNotArchived };
            }
            return null;
        }).filter(result => result !== null);

        filterClients = temporal;
    }
    const temp = filterClients.map(client => {
        // Aquí comprueba que el nombre del cliente es igual al input
        const clientNameMatch = client.name.toLowerCase().includes(searchProject.value.toLowerCase());

        // Aquí comprueba que el nombre del proyecto sea igual al input
        const childPojectMatch = client?.projectsNotArchived.filter(project =>
            project.name.toLowerCase().includes(searchProject.value.toLowerCase())
        )

        // Si el nombre del cliente es igual al input, te muestra todos sus proyectos
        if (clientNameMatch) {
            return { ...client, projectsNotArchived: client?.projectsNotArchived };
        }

        // Si el nombre del proyecto es igual al input, solo te muestra esos proyectos con ese nombre 
        if (childPojectMatch.length > 0) {
            return { ...client, projectsNotArchived: childPojectMatch }
        }
        return null;
    }).filter(result => result !== null);
    optionClients.value = temp;
}


// function project is selected
const selectedProject = (project) => {
    searchProject.value = '';
    emit('getProject', project)
}

// Funcion para darle estilo al proyecto que este seleccionado
const getSelectedProjectStyle = (project) => {
    if (!props.project) return Object.assign({});
    if (currentProject.value.id === project.id) {
        return Object.assign(
            { fontWeight: 700 }
        )
    } else {
        return Object.assign({})
    }
}


</script>
<template>
    <div class="initialDiv">
        <el-input ref="refSearchProject" v-model="searchProject" placeholder="Busca un proyecto"
            @input="searchForClientAndProject()" class="projectInput mb-2" />

        <el-scrollbar class="scroll">
            <div v-for="client in optionClients" :key="client['@id']" :label="client.name" :value="client">
                <span class="trackerClient">
                    {{ client.name }}
                </span>
                <div v-for="project in client.projectsNotArchived" :key="project['@id']" :label="project.name"
                    :value="project">
                    <div class="row">
                        <p class="col-12">
                            <tracker-project-info :project="project" class="projectTracker pointer"
                                @click="selectedProject(project)" :style="getSelectedProjectStyle(project)" />
                        </p>
                    </div>
                </div>
            </div>
            <div v-if="!isLoaded" v-loading="!isLoaded" class="mt-5"></div>
            <div v-if="optionClients.length < 1 && isLoaded">
                <span class="trackerClient" style="color: #000;">
                    No se han encontrado proyectos
                </span>
            </div>
        </el-scrollbar>
    </div>
</template>
<style lang="scss" scoped>
.initialDiv {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
}

.scroll {
    height: 240px;
    width: 100%;
    margin-top: 10px;
}

.projectInput {
    width: 100%;
}

.projectTracker {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.trackerClient {
    color: #474CE6;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 3px;
}
</style>