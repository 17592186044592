<script setup>

import TrackerTotalTime from "@/components/shared/TrackerTotalTime.vue";
import TrackerEntry from "@/components/shared/TrackerEntry.vue";
import { computed, defineProps, defineEmits, ref, onMounted, watch } from "vue";
import moment from "moment/moment";
import 'moment/locale/es';
import { useStore } from "vuex";

const emits = defineEmits(['reloadEntries'])
const props = defineProps({
  day: Object,
  projects: Object,
  tags: Object
})

const store = useStore();

onMounted(() => {
  groupedEntries.value = getGroupedEntries();
  dayLength.value = getDayLength();
})

// Con esto comprobamos cuando se cambia la fecha, para que se actualice las entradas
const isChangedDay = computed(()=> {
  return store.state.changedDayTrackernet;
})

watch(isChangedDay, (newVal)=>{
  if(newVal){
    reloadEntries();
    store.commit('setChangedDayTrackernet', false);
  }
})

const groupedEntries = ref();

const getGroupedEntries = () => {
  const groupedEntries = {};
  props.day.entries.forEach((entry) => {
    // Assuming tags is an array and needs to be converted to a string
    const tagsString = entry.tags.sort().join('|');
    const key = `${entry.project}-${entry.description}-${tagsString}-${entry.billable}`;
    if (entry.timeEnd) {
      if (!groupedEntries[key]) {
        groupedEntries[key] = [];
      }
      groupedEntries[key].push(entry);
    }
  });
  return groupedEntries;
}

const getDayLength = () => {
  const groupedEntries = {};

  let num = 0;
  props.day.entries.forEach((entry) => {
    // Assuming tags is an array and needs to be converted to a string
    const tagsString = entry.tags.sort().join('|');
    const key = `${entry.project}-${entry.description}-${tagsString}`;
    if (entry.timeEnd) {
      num++;
      if (!groupedEntries[key]) {
        groupedEntries[key] = [];
      }
      groupedEntries[key].push(entry);
    }
  });
  return num;
}

const dayLength = ref();

const totalTime = computed(() => {
  let total = 0
  if (props.day.entries.length === 0) return 0
  props.day.entries.forEach((entry) => {
    if (!entry.timeEnd) {
      total += store.state.entryDuration;
    } else {
      if (entry.duration) {
        total += entry.duration
      } else {
        total += moment().diff(entry.timeStart, 'seconds')
      }
    }
  })
  return total;
});
const reloadEntries = () => {
  emits('reloadEntries')
}
const dayLabel = computed(() => {
  moment.locale('es');
  const formattedDate = moment(props.day.entries[0]?.timeStart).format('dddd D [de] MMMM');
  return capitalizeTitle(formattedDate);
})
function capitalizeTitle(text) {
  const prepositions = ["de", "la", "el", "en", "y", "con", "por", "a", "del", "los", "las", "al", "un", "una", "unos", "unas", "para", "sobre"];

  return text
    .toLowerCase()
    .split(" ")
    .map((word, index) =>
      prepositions.includes(word) && index !== 0
        ? word // No cambia preposiciones (excepto si es la primera palabra)
        : word.charAt(0).toUpperCase() + word.slice(1)
    )
    .join(" ");
}
</script>

<template>
  <el-card class="card-tracker w-100 mt-3" v-if="dayLength > 0">
    <template #header>
      <div class="card-tracker-header">
        <span>{{ dayLabel }}</span>
        <tracker-total-time :totalTime="totalTime" />
      </div>
    </template>
    <template #default>
      <tracker-entry v-for="(entry, key, index) in groupedEntries" :entry="entry" :key="entry[0].id"
        :dayLength="dayLength" :entryLength="index" :projects="props.projects" :tags1="props.tags"
        @reload-entries="reloadEntries"/>
    </template>
  </el-card>
</template>

<style scoped lang="scss"></style>